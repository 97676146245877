import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    width: '100%',
    flex: 1,
  },
  label: {
    textAlign: 'left',
    marginBottom: theme.spacing(1),
    fontWeight: 400,
    color: theme.palette.grey[800],
  },
  input: {
    marginBottom: theme.spacing(2.25),
    background: '#FFF',
  },
  inputDisabled: {
    background: '#F5F5F5',
  },
  smallLabel: {
    fontSize: '11px',
  },
}));
