import React from 'react';

import { LoginFlowLayout } from 'ui/loginFlowLayout/LoginFlowLayout';
import { EnterNewPasswordForm } from '../enterNewPasswordForm/EnterNewPasswordForm';

export const EnterNewPasswordFormWrapper: React.FC = () => {
  return (
    <LoginFlowLayout>
      <EnterNewPasswordForm />
    </LoginFlowLayout>
  );
};
