import { fade, makeStyles } from '@material-ui/core';

const drawerWidth = 300;

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    background: theme.palette.background.default,
    minHeight: '100vh',
    height: '100%',
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  root: {
    display: 'flex',
  },
  logoContainer: {
    padding: theme.spacing(3),
  },
  linkContainer: {
    padding: theme.spacing(2),
    borderRadius: 5,
    marginTop: theme.spacing(1),
  },
  linkGroupContainer: {
    padding: theme.spacing(2),
    borderRadius: 5,
    marginTop: theme.spacing(0.2),
    marginBottom: theme.spacing(0.2),
    background: fade(theme.palette.grey[400], 0.1),
  },
  linkContainerActive: {
    background: fade(theme.palette.primary.main, 0.1),
  },
  linkGroupContainerActive: {
    fontWeight: 600,
    textColor: theme.palette.common.black,
  },
  drawerLink: {
    color: theme.palette.grey[600],
    textDecoration: 'none',
    cursor: 'pointer',
    padding: 0,
  },
  logo: {
    display: 'block',
    height: 32,
    width: 'auto',
    objectFit: 'contain',
  },
  drawer: {
    width: drawerWidth,
    padding: 20,
    flexShrink: 0,
  },
  drawerPaper: {
    padding: theme.spacing(2),
    width: drawerWidth,
  },
  inputsContainer: {
    paddingLeft: 16,
    paddingRight: 16,

    '& label': {
      display: 'block',
      paddingLeft: 16,
      fontSize: 12,
      color: 'rgba(0, 0, 0, 0.4)',
      width: '100%',
      textAlign: 'left',
    },

    '& button': {
      width: '100%',
    },
  },
  groupItem: {
    display: 'flex',
    alignItems: 'center',
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    minHeight: '100vh',
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(8),
  },
  listItem: {
    width: '13,5px',
    opacity: '50%',
  },
  newOfferButton: {
    textTransform: 'none',
    height: 48,
  },
  newOfferButtonText: {
    fontSize: 16,
    color: theme.palette.common.white,
    textDecoration: 'none',
  },
}));
