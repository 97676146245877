"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Message = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var core_1 = require("@material-ui/core");
var Message_styles_1 = require("./Message.styles");
var Message = function (_a) {
    var text = _a.text, type = _a.type;
    var theme = core_1.useTheme();
    var classes = Message_styles_1.useStyles(theme);
    return (jsx_runtime_1.jsx("div", __assign({ className: classes[type] }, { children: jsx_runtime_1.jsx(core_1.Typography, __assign({ className: type === 'success' ? classes.successText : classes.errorText }, { children: text }), void 0) }), void 0));
};
exports.Message = Message;
