import React from 'react';
import { Box, Divider, Typography, useTheme } from '@material-ui/core';

import { Link } from 'ui/link/Link';

import { DashboardCounterPanelProps } from './DashboardCounterPanel.types';
import { useStyles } from './DashboardCounterPanel.styles';

export const DashboardCounterPanel: React.FC<DashboardCounterPanelProps> = ({
  title,
  link,
  counters,
  handleLinkClick,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.countersPanel}>
      <div className={classes.countersPanelHeader}>
        <Typography className={classes.title}>{title}</Typography>
        <Link href="#">
          <Typography variant="body2" className={classes.link} onClick={handleLinkClick}>
            {link}
          </Typography>
        </Link>
      </div>
      <Box mb={2} />
      <div className={classes.counters}>
        <div className={classes.counter}>
          <Typography variant="h2">{counters[0].value}</Typography>
          <Box mb={1} />
          <Typography variant="subtitle1">{counters[0].label}</Typography>
        </div>
        <Divider orientation="vertical" flexItem />
        <div className={classes.counter}>
          <Typography variant="h2">{counters[1].value}</Typography>
          <Box mb={1} />
          <Typography variant="subtitle1">{counters[1].label}</Typography>
        </div>
        <Divider orientation="vertical" flexItem />
        <div className={classes.counter}>
          <Typography variant="h2">{counters[2].value}</Typography>
          <Box mb={1} />
          <Typography variant="subtitle1">{counters[2].label}</Typography>
        </div>
      </div>
    </div>
  );
};
