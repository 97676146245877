"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
var core_1 = require("@material-ui/core");
exports.useStyles = core_1.makeStyles(function (theme) { return ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    labelBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
    },
    value: {
        fontWeight: 600,
    },
    graphBackground: {
        width: '100%',
        height: 5,
        borderRadius: 2.5,
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    graph: {
        height: 5,
        borderRadius: 2.5,
    },
}); });
