import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: 'lowercase',
    fontWeight: 'normal',
    //TODO: define in the palette
    background: `#3E2950`,
    color: theme.palette.common.white,
    cursor: 'pointer',
    width: 'inherit',
  },
}));
