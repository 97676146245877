import { makeStyles } from '@material-ui/core';
import { HrMeTheme } from '@hrme/shared/src/theme/theme';

export const useStyles = makeStyles<HrMeTheme>((theme) => ({
  container: {
    width: 525,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'start',
    padding: theme.spacing(1.5, 3),
    backgroundColor: theme.palette.background.paper,
    borderLeft: `1px solid ${theme.borders.main}`,
    marginLeft: '6.5px',
  },
  strong: {
    fontWeight: theme.typography.fontWeightBold,
  },
  bulletBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'start',
    marginLeft: `calc(-6.5px - ${theme.spacing(3)}px)`,
    paddingTop: '6px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'start',
    marginLeft: `calc(${theme.spacing(3)}px - 6.5px)`,
  },
  bullet: {
    width: 13,
    height: 13,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
  },
}));
