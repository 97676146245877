import { makeStyles } from '@material-ui/core';
import { HrMeTheme } from '@hrme/shared/src/theme/theme';

export const useStyles = makeStyles<HrMeTheme>((theme) => ({
  container: {
    marginRight: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  counter: {
    padding: theme.spacing(1.5),
    border: `1px solid ${theme.borders.light}`,
    borderRadius: 5,
    [theme.breakpoints.up('xl')]: {
      minWidth: 56,
    },
    marginRight: theme.spacing(3),
  },
  counterLabel: {
    [theme.breakpoints.up('xl')]: {
      minWidth: 298,
    },
  },
  counterTitle: {
    textAlign: 'left',
    fontWeight: 700,
  },
  counterSubtitle: {
    textAlign: 'left',
    fontSize: '15px',
    //TODO: not found in theme palette yet
    color: '#595c97',
  },
}));
