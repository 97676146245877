import { Box, InputLabel, Typography, useTheme } from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { TextInput } from 'ui/textInput/TextInput';
import { ButtonDefault } from 'ui/button/ButtonDefault';
import { Link } from 'ui/link/Link';
import { useAuthContext } from 'hooks/useAuthContext/useAuthContext';
import { RegisterUserProps } from 'context/authentication/AuthenticationContext.types';
import { AuthenticationPaths } from 'routing/authentication/AuthenticationPaths';

import { StyledCheckbox, useStyles } from './RegisterForm.styles';
import { RegisterFormProps } from './RegisterForm.types';

const initialValues = {
  email: '',
  password: '',
  firstName: '',
  lastName: '',
  acceptTerms: false,
};

const validationSchema = Yup.object({
  email: Yup.string().email('Nieprawidłowy adres email.').required('Pole nie może być puste.'),
  acceptTerms: Yup.bool().oneOf([true], 'Wymagane jest zaakceptowanie regulaminu oraz pp.'),
  firstName: Yup.string().required('Pole nie może być puste.'),
  lastName: Yup.string().required('Pole nie może być puste.'),
  password: Yup.string().min(8, 'Hasło musi składać się z przynajmniej 8 znaków.').required('Pole nie może być puste.'),
});

export const RegisterForm: React.FC<RegisterFormProps> = () => {
  const { registerUser } = useAuthContext();
  const [captchaValue, setCaptchaValue] = useState<string | null>(null);
  const [captchaError, setCaptchaError] = useState(false);
  const [blockButton, setBlockButton] = useState(false);

  const captchaKey = process.env.REACT_APP_CAPTCHA_KEY;

  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();

  const { handleSubmit, errors, register } = useForm({
    defaultValues: initialValues,
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (values: typeof initialValues) => {
    const userRegisterObject: RegisterUserProps = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
    };

    if (captchaValue === null) {
      setCaptchaError(true);
      return;
    }

    setCaptchaError(false);
    setBlockButton(true);
    const response = await registerUser(userRegisterObject);

    if (response) {
      history.push('/welcome');
    } else {
      console.error('Error happened.');
      setBlockButton(false);
    }
  };

  const onCaptchaChange = (value: string | null) => {
    setCaptchaValue(value);
    if (value !== null) {
      setCaptchaError(false);
    }
  };

  if (captchaKey === undefined) {
    return <Typography>Setup Google reCaptcha properly</Typography>;
  }

  return (
    <div>
      <Typography variant="h4">Stwórz nowe konto</Typography>
      <Box mb={3} />
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <InputLabel htmlFor="firstName" className={classes.label}>
          imię
        </InputLabel>
        <TextInput
          name="firstName"
          variant="outlined"
          fullWidth
          ref={register}
          type="text"
          placeholder="Wpisz swoje imię"
          helperText={errors.firstName?.message}
          error={!!errors.firstName}
          className={classes.input}
        />

        <InputLabel htmlFor="lastName" className={classes.label}>
          nazwisko
        </InputLabel>
        <TextInput
          name="lastName"
          variant="outlined"
          fullWidth
          ref={register}
          type="text"
          placeholder="Wpisz swoje nazwisko"
          helperText={errors.lastName?.message}
          error={!!errors.lastName}
          className={classes.input}
        />

        <InputLabel htmlFor="email" className={classes.label}>
          adres e-mail
        </InputLabel>
        <TextInput
          name="email"
          variant="outlined"
          fullWidth
          ref={register}
          type="email"
          placeholder="Wpisz swój adres email"
          helperText={errors.email?.message}
          error={!!errors.email}
          className={classes.input}
        />

        <InputLabel htmlFor="password" className={classes.label}>
          hasło do konta
        </InputLabel>
        <TextInput
          name="password"
          variant="outlined"
          fullWidth
          ref={register}
          type="password"
          placeholder="Wpisz swoje hasło"
          helperText={errors.password?.message}
          error={!!errors.password}
          className={classes.input}
        />

        <div className={classes.termsWrapper}>
          <StyledCheckbox name="acceptTerms" inputRef={register} />
          <Typography variant="body1">
            rejestrując się akceptuję{' '}
            <Link href={AuthenticationPaths.termsAndConditions} external>
              regulamin serwisu
            </Link>{' '}
            oraz{' '}
            <Link href={AuthenticationPaths.policy} external>
              politykę prywatności
            </Link>
          </Typography>
        </div>

        {!!errors.acceptTerms && (
          <Typography className={classes.termsError} color="error" variant="body1">
            {errors.acceptTerms.message}
          </Typography>
        )}

        <ReCAPTCHA size="normal" sitekey={captchaKey} onChange={onCaptchaChange} />

        {captchaError && (
          <Typography className={classes.captchaError} color="error" variant="body1">
            Proszę uzupełnić captcha.
          </Typography>
        )}

        <Box mb={2} />
        <ButtonDefault disabled={blockButton}>zarejestruj się</ButtonDefault>
      </form>
    </div>
  );
};
