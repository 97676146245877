import { Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import { useAuthContext } from 'hooks/useAuthContext/useAuthContext';
import { AuthenticationPaths } from 'routing/authentication/AuthenticationPaths';
import { Link } from 'ui/link/Link';
import { LoginFlowLayout } from 'ui/loginFlowLayout/LoginFlowLayout';
import { useUserContext } from 'hooks/useUserContext/useUserContext';

import { LoginForm } from './loginForm/LoginForm';

const LoginFooter = () => (
  <Typography variant="body2">
    Nie posiadasz konta?{' '}
    <Link type="bold" href={AuthenticationPaths.register}>
      Zarejestruj się
    </Link>
  </Typography>
);

export const LoginPage: React.FC = () => {
  const { currentUser } = useAuthContext();
  const { fetchUserData, setInitialData } = useUserContext();
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchUserData();

      if (response.success) {
        history.replace('/onboarding');
      } else {
        const dataCreateResponse = await setInitialData();

        if (dataCreateResponse.success) {
          history.replace('/onboarding');
        }
      }
    };

    if (currentUser?.emailVerified) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  return (
    <LoginFlowLayout footerComponent={<LoginFooter />}>
      <LoginForm confirmed={true} />
    </LoginFlowLayout>
  );
};
