import React from 'react';
import { Box, FormControlLabel, Typography, useTheme } from '@material-ui/core';

import { StyledCheckbox } from 'ui/StyledCheckbox/StyledCheckbox';

import { CheckBoxWithLabelProps } from './CheckBoxWithLabel.types';
import { useStyles } from './CheckBoxWithLabel.styles';

export const CheckBoxWithLabel: React.FC<CheckBoxWithLabelProps> = ({ value, onChange, label }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <>
      <FormControlLabel
        className={classes.wrapper}
        control={<StyledCheckbox checked={value} onChange={(event) => onChange(event.target.checked)} />}
        label={<Typography variant="subtitle1">{label}</Typography>}
      />
      <Box mr={2} />
    </>
  );
};
