import React, { useState } from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import { ButtonDefault } from '@hrme/shared';

import { DashboardContentContainer } from 'ui/dashboard/dashboardContentContainer/DashboardContentContainer';
import { JobOfferActivationStatusBar } from 'app/jobOffer/jobOfferActivationStatusBar/JobOfferActivationStatusBar';
import { DashboardContent } from 'ui/dashboard/dashboardContent/DashboardContent';
// import { HistoryContainer } from 'ui/historyContainer/HistoryContainer';
import { MainLayout } from 'ui/mainLayout/MainLayout';
import { useJobOfferContext } from 'hooks/useJobOfferContext/useJobOfferContext';

import { paymentHistoryDetails, PaymentHistoryProps } from './PaymentHistory.types';
import { useStyles } from './PaymentHistory.styles';

export const PaymentHistory: React.FC<PaymentHistoryProps> = ({ historyItems = paymentHistoryDetails }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { createStripePanelSafeLink } = useJobOfferContext();
  const [createPanelLink, setCreatePanelLink] = useState(false);

  const handleStripePanelLogin = async () => {
    setCreatePanelLink(true);
    const panelURL = await createStripePanelSafeLink();
    window.open(panelURL.message);
    setCreatePanelLink(false);
  };

  return (
    <MainLayout>
      <DashboardContentContainer>
        <JobOfferActivationStatusBar />
        <Box mb={3} />
        <DashboardContent hasBottomPadding={false}>
          <div className={classes.dashboardContent}>
            <Box mt={6} />
            <Typography variant="h2" className={classes.title}>
              Historia opłat
            </Typography>
            <Box mb={1} />
            <Typography variant="body2" className={classes.subtitle}>
              Sprawdź historię swoich płatności i faktur
            </Typography>
            <Box mt={6} />
            <Box className={classes.loginButton}>
              <ButtonDefault disabled={createPanelLink} onClick={handleStripePanelLogin}>
                {createPanelLink ? 'Proszę czekać' : 'Zaloguj się do panelu Stripe'}
              </ButtonDefault>
            </Box>
            <Box mt={6} />
            {/* <HistoryContainer items={historyItems} title="" /> */}
          </div>
        </DashboardContent>
      </DashboardContentContainer>
    </MainLayout>
  );
};
