import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';

import { DividerWithTextProps } from 'ui/dividerWithText/DividerWithText.types';

import { useStyles } from './DividerWithText.styles';

export const DividerWithText: React.FC<DividerWithTextProps> = ({ children, title, subtitle }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div>
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <div className={classes.border} />
          <Typography className={classes.content}>{children ? children : title}</Typography>
          <div className={classes.border} />
        </div>
        {subtitle && (
          <>
            <Box mb={1} />
            <Typography className={classes.subtitle}>{subtitle}</Typography>
          </>
        )}
      </div>
    </div>
  );
};
