import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { PlatformSelect } from '@hrme/shared';

import CheckEmailImage from 'assets/ilustrations/check-email.png';
import { LoginFlowLayout } from 'ui/loginFlowLayout/LoginFlowLayout';
import { Link } from 'ui/link/Link';
import { AuthenticationPaths } from 'routing/authentication/AuthenticationPaths';

export const CheckEmailPage: React.FC = () => {
  return (
    <LoginFlowLayout type="imageWithPadding">
      <Typography variant="h4">Sprawdź swój e-mail</Typography>
      <Box mb={3} />
      <Typography variant="body2">
        Na Twój adres e-mail został wysłany link resetujący hasło. Kliknij w niego, a następnie ustaw nowe hasło.
      </Typography>
      <Box mb={3} />
      <PlatformSelect onlyLinks />
      <Box mb={3} />
      <Typography variant="body2">
        E-mail nie dotarł?{' '}
        <Link color="primary" href={AuthenticationPaths.forgotPassword}>
          Wyślij ponownie
        </Link>
      </Typography>
      <Box mb={5} />
      <img src={CheckEmailImage} alt="Check email illustration." />
    </LoginFlowLayout>
  );
};
