import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  offerBoxIsActive: {
    display: 'flex',
    flexDirection: 'row',
    color: theme.palette.success.dark,
  },
  offerBoxIsDraft: {
    display: 'flex',
    flexDirection: 'row',
    color: theme.palette.grey[400],
  },
  offerBoxIsInactive: {
    display: 'flex',
    flexDirection: 'row',
    color: theme.palette.error.dark,
  },
  offerBox: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
  },
  offerBoxButtonBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  offerBoxButtonBarLeft: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  offerBoxPosition: {
    fontWeight: 800,
    fontStyle: 'normal',
    fontSize: '36px',
    lineHeight: '54px',
  },
  offerBoxButtonBarRight: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'end',
  },
  offerBoxCounters: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
  },
  offerBoxDetails: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  strong: {
    fontWeight: 700,
  },
  box: {
    height: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
  },
  newOfferButtonText: {
    fontSize: 16,
    color: theme.palette.common.white,
    textDecoration: 'none',
  },
}));
