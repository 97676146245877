import React, { useEffect, useState } from 'react';
import { Box, Typography, useTheme, Switch } from '@material-ui/core';
import { Controller } from 'react-hook-form';

import { TextInput } from 'ui/textInput/TextInput';
import { FormControlLabelItem } from 'ui/radioGroupWithOthersInput/RadioGroupWithOthersInput.types';
import { Currencies } from '../AddJobOfferPositionBasicsRHF.types';
import { FieldErrorWrap } from 'ui/fieldErrorWrap/FieldErrorWrap';
import { ShowErrorText } from 'ui/showErrorText/ShowErrorText';
import { TextSelect } from 'ui/textSelect/TextSelect';
import { CheckBoxWithLabel } from 'ui/checkBoxWithLabel/CheckBoxWithLabel';

import { AddJobOfferSalaryRHFProps } from './AddJobOfferSalaryRHF.types';
import { useStyles } from './AddJobOfferSalaryRHF.styles';

export const AddJobOfferSalaryRHF: React.FC<AddJobOfferSalaryRHFProps> = ({ methods, checked = false }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [isSalaryChange, setSalaryChange] = useState(false);
  const [salaryRangeChecked, setSalaryRangeChecked] = useState(checked);

  const currencies: FormControlLabelItem[] = Object.values(Currencies);

  useEffect(() => {
    methods?.setValue('salaryRange', salaryRangeChecked);
  }, [methods, salaryRangeChecked]);

  const errorMsg =
    methods?.errors.currency?.message ||
    methods?.errors.salaryRangeFrom?.message ||
    methods?.errors.salaryRangeTo?.message ||
    methods?.errors.currency?.message;
  const isError = !!methods?.errors.currency || !!methods?.errors.salaryRangeFrom || !!methods?.errors.salaryRangeTo;
  return (
    <FieldErrorWrap showErrorBackground={isError}>
      <div className={classes.checkboxContainer}>
        <Controller
          name="salaryRange"
          control={methods?.control}
          render={() => (
            <CheckBoxWithLabel
              value={salaryRangeChecked}
              onChange={() => {
                setSalaryRangeChecked((currentState) => !currentState);
              }}
              label="wynagrodzenie w widełkach"
            />
          )}
        />
      </div>

      <div className={classes.innerContainer}>
        <div className={salaryRangeChecked ? classes.panel : classes.fullWidth}>
          <TextInput
            name="salaryRangeFrom"
            label={`wynagrodzenie${salaryRangeChecked ? ' od' : ''}`}
            placeholder="Podaj wynagrodzenie"
            required={true}
            valueName="salaryRangeFrom"
            ref={methods?.register}
            smallText={salaryRangeChecked}
          />

          <div className={classes.checkboxContainer}>
            <Controller
              name="isNetto"
              render={({ value, onChange }) => (
                <Box alignItems="center" display="flex">
                  <Typography>brutto</Typography>
                  <Switch
                    checked={value ?? false}
                    value={value}
                    onChange={() => {
                      if (value === 'false') {
                        onChange(true);
                      } else if (value === 'true') {
                        onChange(false);
                      }
                      onChange(!value);
                    }}
                  />
                  <Typography>netto</Typography>
                </Box>
              )}
            />
          </div>

          <div className={classes.checkboxContainer}>
            <Controller
              name="paidLeave"
              render={({ value, onChange }) => (
                <CheckBoxWithLabel value={value} onChange={onChange} label="płatny urlop" />
              )}
            />
          </div>

          <div className={classes.checkboxContainer}>
            <Controller
              name="relocationPackage"
              render={({ value, onChange }) => (
                <CheckBoxWithLabel value={value} onChange={onChange} label="pakiet relokacyjny" />
              )}
            />
          </div>
        </div>

        {salaryRangeChecked && (
          <div className={classes.panel}>
            <TextInput
              name="salaryRangeTo"
              label="wynagrodzenie do:"
              placeholder="Podaj wynagrodzenie"
              required={true}
              valueName="salaryRangeTo"
              ref={methods?.register}
              smallText
            />
          </div>
        )}

        {!salaryRangeChecked && <Box mr={5} />}

        <div className={classes.panel}>
          {isSalaryChange ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
                paddingTop: 40,
              }}
            >
              <span>PLN</span>
              <Typography style={{ color: '#d8527c', cursor: 'pointer' }} onClick={() => setSalaryChange(true)}>
                zmień
              </Typography>
            </div>
          ) : (
            <TextSelect
              name={'currency'}
              label={'waluta'}
              defaultValue={{ value: 'PLN', label: 'PLN' }}
              items={currencies}
              smallText={salaryRangeChecked}
            />
          )}
        </div>
      </div>
      {errorMsg && <ShowErrorText errorMessage={errorMsg} />}
    </FieldErrorWrap>
  );
};
