import { makeStyles, styled } from '@material-ui/core';
import { HrMeTheme } from '@hrme/shared/src/theme/theme';

export const useStyles = makeStyles<HrMeTheme>((theme) => ({
  widePanel: {
    flex: 2.4,
    marginRight: theme.spacing(4),
  },
  narrowPanel: {
    flex: 1,
  },
  wideContainer: {
    padding: theme.spacing(4),
    border: `1px solid ${theme.borders.light}`,
    textAlign: 'left',
  },
  narrowWrapper: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(4),
    marginBottom: theme.spacing(4),
    border: '1px solid #d8d5dc',
    textAlign: 'left',
  },
  linkActive: {
    background: theme.palette.background.default,
  },
  flexGrow: {
    flexGrow: 1,
  },
  city: {
    flexGrow: 12,
  },
  postal: {
    flexGrow: 0.1,
  },
  header: {
    fontWeight: 600,
    textAlign: 'left',
    marginBottom: theme.spacing(3),
  },
  textSelect: {
    marginBottom: theme.spacing(0.75),
  },

  button: {
    marginBottom: theme.spacing(3),
  },
  listItem: {
    display: 'flex',
  },
  listItemIcon: {
    minWidth: 32,
    margin: 'auto',
  },
  listItemText: {
    margin: 0,
  },
  img: {
    height: '24px',
    width: '24px',
  },
  formControlWrapper: {
    background: '#fff',
    border: '1px solid #D8D5DC',
    padding: 16,
    width: '100%',
  },
}));

export const EditNavigationLink = styled('a')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  padding: theme.spacing(2),
  borderRadius: 5,
  color: theme.palette.common.black,
  textDecoration: 'none',

  '&:hover': {
    textDecoration: 'underline',
  },
}));

export const TwoItemsRow = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export const SummaryRowRegular = styled('p')({
  fontWeight: 'normal',
  margin: 0,
  padding: 0,
  marginBottom: 8,
});

export const SummaryRowBold = styled(SummaryRowRegular)({
  fontWeight: 'bold',
});

export const SummaryTax = styled('p')({
  marginTop: 0,
  color: '#A4A4A4',
  fontSize: 9,
  width: '100%',
  textAlign: 'center',
});

export const RequiredStar = styled('span')({
  color: 'red',
});

export const NotificationPanel = styled('div')(({ theme }) => ({
  width: '100%',
  background: '#FBEEF2',
  border: '1px solid #FED6E2',
  color: '#D8527C',
  borderRadius: '2px',
  padding: theme.spacing(1),
  fontSize: '12px',
  textAlign: 'center',
}));
