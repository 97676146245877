import React from 'react';
import { Box, Button, Typography, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { BoxWithButtonProps } from './BoxWithButton.types';
import { useStyles } from './BoxWithButton.styles';

export const BoxWithButton: React.FC<BoxWithButtonProps> = ({ title, buttonCapture, onClick }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.lowerPanel}>
      <Typography variant="h4">{title}</Typography>
      <Box mb={3} />
      <div>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          startIcon={<AddIcon />}
          className={classes.buttonSend}
          onClick={onClick}
        >
          <Typography variant="body2">{buttonCapture}</Typography>
        </Button>
      </div>
    </div>
  );
};
