import { Box, Typography } from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { ButtonDefault } from 'ui/button/ButtonDefault';
import OnboardingImage from 'assets/ilustrations/onboarding.png';
import { LoginFlowLayout } from 'ui/loginFlowLayout/LoginFlowLayout';
import { useUserContext } from 'hooks/useUserContext/useUserContext';
import { AuthenticationPaths } from 'routing/authentication/AuthenticationPaths';
import { UserProfileType } from 'context/user/UserContext.types';
import { MainPaths } from 'routing/main/MainPaths';

export const WelcomeToHrMePage: React.FC = () => {
  const history = useHistory();
  const { user, loading } = useUserContext();

  const onSubmit = useCallback(() => {
    history.push(AuthenticationPaths.profile);
  }, [history]);

  useEffect(() => {
    if (user?.profileType === UserProfileType.AGENCY) {
      if (user?.companies?.length) {
        history.replace(AuthenticationPaths.companyProfiles);
      } else {
        history.replace(AuthenticationPaths.addCompany);
      }
    } else if (user?.profileType === UserProfileType.COMPANY) {
      if (user?.companies?.length) {
        history.replace(MainPaths.dashboard);
      } else {
        history.replace(AuthenticationPaths.addCompany);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <LoginFlowLayout type="image">
      <Typography variant="h4">Witaj w HR-ME</Typography>
      <Box mb={3} />
      <Typography variant="body2">Uzupełnij swój profil, aby móc korzystać z wszystkich funkcji serwisu.</Typography>
      <Box mb={3} />
      <ButtonDefault onClick={onSubmit}>uzupełnij profil</ButtonDefault>
      <Box mb={3} />
      <Box mb={5} />
      <img src={OnboardingImage} alt="Welcome to hr me, onboarding illustration." />
    </LoginFlowLayout>
  );
};
