import { UseFormMethods } from 'react-hook-form';

import { TextSelectItem } from 'ui/textSelect/TextSelect.types';
import { TextInputVariant } from 'ui/textInputExtendableList/TextInputExtendableList.types';
import { AddJobOfferInitialValuesTypeRHF } from 'app/jobOffer/addJobOfferRHF/addJobOfferFormWrapperRHF/addJobOfferFormWrapperRHF.types';

export type AddJobOfferApplicantLanguageProps = {
  label: string;
  listItems?: string[];
  buttonCaption: string;
  addInputLabel?: string;
  addInputPlaceholder?: string;
  variant?: TextInputVariant;
  selectItems?: TextSelectItem[];
  valueName?: string;
  name?: string;
  methods?: UseFormMethods<AddJobOfferInitialValuesTypeRHF>;
};

export const LANGUAGES = [
  { value: 'pl', label: 'Polski' },
  { value: 'en', label: 'Angielski' },
  { value: 'cs', label: 'Czeski' },
  { value: 'es', label: 'Hiszpański' },
  { value: 'de', label: 'Niemiecki' },
  { value: 'ru', label: 'Rosyjski' },
  { value: 'uk', label: 'Ukraiński' },
];
