"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.typeConvert = void 0;
var typeConvert = function (contractType) {
    if (contractType === 'uop')
        return 'umowa o pracę';
    if (contractType === 'uod')
        return 'umowa o dzieło';
    if (contractType === 'uzl')
        return 'umowa zlecenie';
    if (contractType === 'b2b')
        return 'kontrakt b2b';
    if (contractType === 'inn')
        return 'inna forma';
    if (contractType === 'office')
        return 'biuro';
    if (contractType === 'remote')
        return 'zdalnie';
    if (contractType === 'covid')
        return 'zdalnie na czas COVID';
    if (contractType === 'hybrid')
        return 'praca hybrydowa';
    if (contractType === 'full')
        return 'pełny etat';
    if (contractType === 'local')
        return 'lokalnie';
    if (contractType === 'expert')
        return 'ekspert';
    if (contractType === 'part')
        return 'pół etatu';
    if (contractType === 'casu')
        return 'dorywczo';
    if (contractType === 'othe')
        return 'inny wymiar';
    if (contractType === 'flexible')
        return 'elastyczne';
    if (contractType === 'determinable')
        return 'do ustalenia';
    if (contractType === 'basic')
        return 'podstawowy';
    if (contractType === 'advanced')
        return 'komunikatywny';
    if (contractType === 'fluent')
        return 'biegły';
    if (contractType === 'none')
        return 'brak';
    return 'inne';
};
exports.typeConvert = typeConvert;
