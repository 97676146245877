import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  label: {
    fontWeight: 600,
    color: '#64c08b',
  },
  circular: {
    backgroundColor: 'linear-gradient(180deg, rgba(255, 255, 255, 0.37) 0%, rgba(255, 255, 255, 0) 100%), #6FCF97',
    color: 'linear-gradient(180deg, rgba(255, 255, 255, 0.37) 0%, rgba(255, 255, 255, 0) 100%), #6FCF97',
  },
}));
