import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, CircularProgress, Typography, useTheme } from '@material-ui/core';
import { Link } from '@hrme/shared';

import { firebaseRef } from 'api/firebaseRef';
import { useAuthContext } from 'hooks/useAuthContext/useAuthContext';
import { DashboardContentContainer } from 'ui/dashboard/dashboardContentContainer/DashboardContentContainer';
import { DashboardContent } from 'ui/dashboard/dashboardContent/DashboardContent';
import { SummaryContainer } from 'ui/summaryContainer/SummaryContainer';
import { MainLayout } from 'ui/mainLayout/MainLayout';
import { useJobOfferContext } from 'hooks/useJobOfferContext/useJobOfferContext';
import { MainPaths } from 'routing/main/MainPaths';
// import { useUserContext } from 'hooks/useUserContext/useUserContext';

import { PaymentConfirmedProps } from './PaymentConfirmed.types';
import { useStyles } from './PaymentConfirmed.styles';

export const PaymentConfirmed: React.FC<PaymentConfirmedProps> = ({ location }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { currentUser } = useAuthContext();
  const { fetchLastPayment, lastPayment, fetchCurrentSubscription } = useJobOfferContext();
  // const { user } = useUserContext();

  // const invoiceUrl = location?.state?.invoiceURL;

  const invoiceUrl = useMemo(() => {
    return location?.state?.invoiceURL;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [invoicePaid, setInvoicePaid] = useState(false);
  const [paymentInProgress, setPaymentInProgress] = useState(false);
  let timer: NodeJS.Timeout | undefined = undefined;
  const checkPayment = () => {
    console.log('checking payment...');
    if (currentUser && invoiceUrl) {
      const checkInvoice = async () =>
        await firebaseRef
          .firestore()
          .collection('users')
          .doc(currentUser.uid)
          .collection('payments')
          .where('stripeInvoiceUrl', '==', invoiceUrl)
          .get()
          .then(async (result) => {
            if (!result.empty && result.docs[0].data().status === 'succeeded') {
              await fetchCurrentSubscription();
              setInvoicePaid(true);
              setPaymentInProgress(false);
              if (timer) clearInterval(timer);
            }
          });
      checkInvoice();
    } else {
      console.log('user not ready or invoiceUrl empty');
      setInvoicePaid(false);
    }
  };
  const waitForPayment = () => {
    setPaymentInProgress(true);
    timer = setInterval(() => checkPayment(), 1000);
  };

  useEffect(() => {
    if (lastPayment.type === '' && !invoiceUrl) {
      const getLastPayment = async () => await fetchLastPayment();
      getLastPayment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  const paymentConfirmedDetails = [
    { label: 'Pakiet', value: lastPayment.type as string },
    { label: 'Liczba kontaktów z kandydatami', value: lastPayment.tokens as string },
    { label: 'Forma płatności', value: 'STRIPE' },
    { label: 'Suma', value: `${lastPayment.price as string} zł + VAT` },
  ];
  return (
    <MainLayout>
      <DashboardContentContainer>
        <DashboardContent>
          <Box className={classes.dashboardContent}>
            <Box mt={6} />
            <Typography variant="h2">
              {!invoicePaid ? 'Faktura została wygenerowana' : 'Twoja płatność została zatwierdzona'}
            </Typography>
            <Box mb={1} />
            {!invoicePaid ? (
              <>
                <Typography variant="body2" className={classes.subtitle}>
                  {/* Faktura została przesłana na adres <span className={classes.email}>{user?.email}</span> */}
                  Opłać fakturę i powróć na ten ekran aby kontynuować.
                </Typography>
                <Box mt={5} />
                {!paymentInProgress && (
                  <Typography variant="body2" className={classes.subtitle}>
                    Możesz też pobrać fakturę i wysłać do odpowiednich osób w firmie. Termin zapłaty wynosi 7 dni.
                  </Typography>
                )}
              </>
            ) : (
              <Typography variant="body2" className={classes.subtitle}>
                Odblokuj kontakt z kandydatami już teraz
              </Typography>
            )}
            <Box mt={6} />
            {!invoicePaid ? (
              paymentInProgress && (
                <>
                  <CircularProgress color="primary" />
                  <Typography variant="body2" className={classes.subtitle}>
                    Oczekiwanie na płatność...
                  </Typography>
                </>
              )
            ) : (
              <>
                {lastPayment.type === '' ? (
                  <CircularProgress color="primary" />
                ) : (
                  <SummaryContainer title="Podsumowanie zamówienia" summaryItems={paymentConfirmedDetails} />
                )}
              </>
            )}
            <Box mt={6} />
          </Box>
          <Box className={classes.buttonContainer}>
            <Link href={!invoicePaid ? invoiceUrl : MainPaths.applicantsListFromJobOffers} external={!!invoiceUrl}>
              <Button
                variant="contained"
                color="secondary"
                disableElevation
                className={classes.button}
                onClick={() => waitForPayment()}
              >
                {!invoicePaid ? 'zobacz fakturę' : 'lista kandydatów'}
              </Button>
            </Link>
          </Box>
        </DashboardContent>
      </DashboardContentContainer>
    </MainLayout>
  );
};
