import { Checkbox, makeStyles, styled } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    textAlign: 'left',
  },
  label: {
    marginBottom: theme.spacing(1),
  },
  input: {
    marginBottom: theme.spacing(2.25),
  },
  remind: {
    marginTop: 16,
  },
  termsWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    marginTop: 6,
    marginBottom: 24,
  },
  termsError: {
    marginTop: -20,
    marginBottom: 24,
  },
  captchaError: {
    marginTop: 4,
    marginBottom: 24,
  },
}));

export const StyledCheckbox = styled(Checkbox)({
  paddingLeft: 0,
  paddingTop: 0,
});
