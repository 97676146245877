import React, { useEffect, useMemo, useState } from 'react';
import { Box, CircularProgress, ListItemText, Modal, Typography, useTheme } from '@material-ui/core';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { AlertMessage } from '@hrme/shared';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import { DefaultLayout } from 'ui/defaultLayout/DefaultLayout';
import { TextSelectRHF } from 'ui/textSelect/TextSelectRHF';
import { DividerWithText } from 'ui/dividerWithText/DividerWithText';
import { ButtonAdd } from 'ui/buttonAdd/ButtonAdd';
import { MainLayout } from 'ui/mainLayout/MainLayout';
import { BundleTypes } from 'app/paymentVariantScreen/components/variantHeaders/VariantHeaders.types';
import { useUserContext } from 'hooks/useUserContext/useUserContext';
import { useJobOfferContext } from 'hooks/useJobOfferContext/useJobOfferContext';
import { MainPaths } from 'routing/main/MainPaths';
import { errorMessages } from 'helpers/errorMessages';

import {
  NotificationPanel,
  RequiredStar,
  SummaryRowBold,
  SummaryRowRegular,
  SummaryTax,
  TwoItemsRow,
  useStyles,
} from './InvoiceScreen.styles';
import { InvoiceScreenForm } from './invoiceScreenForm/InvoiceScreenForm';
import { BundleTimestamp, InvoiceClasses } from './InvoiceScreen.types';

const validationSchema = Yup.object({
  firstName: Yup.string().required('imię jest wymagane'),
  lastName: Yup.string().required('nazwisko jest wymagane'),
  companyName: Yup.string().required('nazwa firmy jest wymagana'),
  companyAddressLine1: Yup.string().required('adres jest wymagany'),
  country: Yup.string().required('kraj jest wymagany'),
  city: Yup.string().required('miasto jest wymagane'),
  postcode: Yup.string()
    .required('kod pocztowy jest wymagany')
    .matches(/[0-9]{2}-[0-9]{3}/, 'format kodu XX-XXX'),
  state: Yup.string().required('województwo jest wymagane'),
});

export const InvoiceScreen: React.FC = (): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { user, fetchUserData, updateUserData, loading } = useUserContext();

  const initialValues = {
    firstName: user?.firstName ? user?.firstName : '',
    lastName: user?.lastName ? user?.lastName : '',
    email: user?.email ? user?.email : '',
    companyName: user?.companyName ? user?.companyName : '',
    companyAddressLine1: user?.companyAddressLine1 ? user?.companyAddressLine1 : '',
    country: user?.country ? user?.country : '',
    city: user?.city ? user?.city : '',
    postcode: user?.postcode ? user?.postcode : '',
    state: user?.state ? user?.state : '',
    nip: user?.nip ? user?.nip : '',
  };

  const { sendToCheckout, createStripeInvoice, prices } = useJobOfferContext();
  const location: { state: { bundleType: BundleTypes; offerID?: string } } = useLocation();
  const offerID_PAYG = location?.state?.offerID;
  const bundle = offerID_PAYG ? BundleTypes.PAY_AS_YOU_GO : location?.state?.bundleType;
  const [selectedBundle, setSelectedBundle] = useState(bundle || BundleTypes.PREMIUM);
  const [selectedTime, setSelectedTime] = useState(BundleTimestamp.MONTH);
  const [modified, setModified] = useState(initialValues.firstName === '');
  const [showAlertMessage, setShowAlertMessage] = useState<{ show: boolean; success: boolean; message?: string }>({
    show: false,
    success: false,
    message: '',
  });
  const [openModal, setOpenModal] = useState(false);
  const [priceValues, setPriceValues] = useState({
    payAsYouGoPrice: 0,
    premiumMonthBundle: 0,
    premiumYearBundle: 0,
    enterpriseMonthBundle: 0,
    enterpriseYearBundle: 0,
    pricesLoaded: false,
  });

  const history = useHistory();

  const { register, handleSubmit, control, errors, getValues } = useForm({
    defaultValues: initialValues,
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
  const { payAsYouGoPrice, premiumMonthBundle, premiumYearBundle, enterpriseMonthBundle, enterpriseYearBundle } =
    priceValues;

  const monthSelected = selectedTime === BundleTimestamp.MONTH;
  const yearSelected = selectedTime === BundleTimestamp.YEAR;
  const payAsYouGoBundle = selectedBundle === BundleTypes.PAY_AS_YOU_GO;
  const premiumBundle = selectedBundle === BundleTypes.PREMIUM;
  const enterpriseBundle = selectedBundle === BundleTypes.ENTERPRISE;

  const returnBundleType = (bundleType: BundleTypes) => {
    switch (bundleType) {
      case BundleTypes.PAY_AS_YOU_GO:
        return 'Pay as you go';
      case BundleTypes.PREMIUM:
        return 'Premium';
      case BundleTypes.ENTERPRISE:
        return 'Enterprise';
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchUserData();
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (prices !== null && Object.keys(prices).length > 0) {
      const { pay_as_you_go, premium, enterprise } = prices;

      const setPrice = (item: { value: number }) => item.value / 100;

      setPriceValues({
        payAsYouGoPrice: setPrice(pay_as_you_go[0]),
        premiumMonthBundle: setPrice(premium[0]),
        premiumYearBundle: setPrice(premium[1]),
        enterpriseMonthBundle: setPrice(enterprise[0]),
        enterpriseYearBundle: setPrice(enterprise[1]),
        pricesLoaded: true,
      });
    }
  }, [prices]);

  const taxIncluded = useMemo(() => {
    switch (selectedBundle) {
      case BundleTypes.PAY_AS_YOU_GO:
        return prices?.pay_as_you_go[0].taxIncluded;
      case BundleTypes.PREMIUM:
        return prices?.premium[0].taxIncluded;
      case BundleTypes.ENTERPRISE:
        return prices?.enterprise[0].taxIncluded;
      default:
        return false;
    }
  }, [prices?.enterprise, prices?.pay_as_you_go, prices?.premium, selectedBundle]);

  const tokens = useMemo(() => {
    switch (selectedBundle) {
      case BundleTypes.PAY_AS_YOU_GO:
        return prices?.pay_as_you_go[0].tokens;
      case BundleTypes.PREMIUM:
        return prices?.premium[0].tokens;
      case BundleTypes.ENTERPRISE:
        return prices?.enterprise[0].tokens;
      default:
        return undefined;
    }
  }, [prices?.enterprise, prices?.pay_as_you_go, prices?.premium, selectedBundle]);

  const moneySaved = useMemo(() => {
    switch (selectedBundle) {
      case BundleTypes.PREMIUM:
        return premiumMonthBundle * 12 - premiumYearBundle;
      case BundleTypes.ENTERPRISE:
        return enterpriseMonthBundle * 12 - enterpriseYearBundle;
      default:
        return 0;
    }
  }, [enterpriseMonthBundle, enterpriseYearBundle, premiumMonthBundle, premiumYearBundle, selectedBundle]);

  if (!user && !loading) return <Redirect to={MainPaths.paymentVariants} />;

  const onSubmit = async (values: typeof initialValues) => {
    if (!user) {
      return;
    }

    const payload = { ...values, email: user.email };
    const response = await updateUserData(payload);

    if (response.success) {
      setModified(false);
      setShowAlertMessage({ show: true, success: true, message: 'Dane zostały zaktualizowane' });
    } else {
      setShowAlertMessage({ show: true, success: false, message: errorMessages(response.message) });
    }
  };

  const handleBundleValidity = () => {
    if (payAsYouGoBundle) return 'Bezterminowo';
    if (monthSelected) return dayjs().add(1, 'month').format('DD.MM.YYYY');
    if (yearSelected) return dayjs().add(12, 'month').format('DD.MM.YYYY');
  };

  const handleTokens = () => {
    if (tokens === undefined) return '-';
    if (payAsYouGoBundle) return tokens.toString();
    else return tokens.toString() + '/m-c';
  };

  const handleBundlePrice = () => {
    if (payAsYouGoBundle) return payAsYouGoPrice;
    if (premiumBundle && monthSelected) return premiumMonthBundle;
    if (premiumBundle && yearSelected) return premiumYearBundle;
    if (enterpriseBundle && monthSelected) return enterpriseMonthBundle;
    else return enterpriseYearBundle;
  };

  const handlePay = async () => {
    if (!user || !prices) {
      return;
    }

    const selectedItem = prices[selectedBundle];

    const { companies, email, profileType, ...data } = user;
    const invoiceData = { ...data };

    const priceID = selectedItem[selectedTime === (BundleTimestamp.MONTH || BundleTimestamp.NONE) ? 0 : 1].id;

    setOpenModal(true);
    if (bundle === BundleTypes.PAY_AS_YOU_GO) {
      if (tokens) {
        const stripeInvoice = await createStripeInvoice(selectedItem[0].priceRef, tokens);

        if (stripeInvoice.message)
          history.push({ pathname: MainPaths.paymentConfirmed, state: { invoiceURL: stripeInvoice.message } });
      } else {
        console.log('Couldnt retreive the token count for given bundle');
      }
    } else {
      sendToCheckout(priceID, payAsYouGoBundle, invoiceData, offerID_PAYG);
    }
  };

  const buttonDisabled = modified || !priceValues?.pricesLoaded || !getValues()?.nip;

  return (
    <MainLayout>
      <DefaultLayout
        subHeader={'Sprawdź czy wszystko się zgadza i uzupełnij dane do faktury'}
        headerText="Podsumowanie i dane do faktury"
      >
        <div className={classes.widePanel}>
          <Modal open={openModal} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress color="primary" />
          </Modal>
          <div className={classes.wideContainer}>
            <form onSubmit={handleSubmit(onSubmit)} onChange={() => setModified(true)}>
              <InvoiceScreenForm
                classes={classes as InvoiceClasses}
                register={register}
                errors={errors}
                disabled={!modified}
                control={control}
                onClick={() => setModified(true)}
                loading={loading}
              />
            </form>
          </div>
        </div>

        <div className={classes.narrowPanel}>
          <div className={classes.narrowWrapper}>
            {!payAsYouGoBundle && (
              <>
                <Typography variant="h6">Podsumowanie zamówienia</Typography>
                <Box mb={3} />
                <div className={classes.textSelect}>
                  <TextSelectRHF
                    name="variant"
                    label="pakiet"
                    control={control}
                    value={selectedBundle}
                    onChange={(event) => setSelectedBundle(event.target.value as BundleTypes)}
                    items={[
                      {
                        value: BundleTypes.PREMIUM,
                        label: (
                          <div className={classes.listItem}>
                            <ListItemText>Premium</ListItemText>
                          </div>
                        ),
                      },
                      {
                        value: BundleTypes.ENTERPRISE,
                        label: (
                          <div className={classes.listItem}>
                            <ListItemText>Enterprise</ListItemText>
                          </div>
                        ),
                      },
                    ]}
                  />
                </div>
                <div className={classes.textSelect}>
                  <TextSelectRHF
                    name="time"
                    label="czas trwania"
                    control={control}
                    value={selectedTime}
                    onChange={(event) => setSelectedTime(event.target.value as BundleTimestamp)}
                    items={[
                      {
                        value: BundleTimestamp.MONTH,
                        label: (
                          <div className={classes.listItem}>
                            <ListItemText>Miesiąc</ListItemText>
                          </div>
                        ),
                      },
                      {
                        value: BundleTimestamp.YEAR,
                        label: (
                          <div className={classes.listItem}>
                            <ListItemText>Rok</ListItemText>
                          </div>
                        ),
                      },
                    ]}
                  />
                  {monthSelected && (
                    <NotificationPanel>
                      rozliczenie roczne pozwoli Ci zaoszczędzić {moneySaved} złotych
                    </NotificationPanel>
                  )}
                </div>
              </>
            )}
            <DividerWithText>Zamówienie</DividerWithText>
            <TwoItemsRow>
              <SummaryRowRegular>Pakiet</SummaryRowRegular>
              <SummaryRowBold>{returnBundleType(selectedBundle)}</SummaryRowBold>
            </TwoItemsRow>
            <TwoItemsRow>
              <SummaryRowRegular>Liczba kontaktów do kandydatów</SummaryRowRegular>
              <SummaryRowBold>{handleTokens}</SummaryRowBold>
            </TwoItemsRow>
            <TwoItemsRow>
              <SummaryRowRegular>Ważność pakietu</SummaryRowRegular>
              <SummaryRowBold>{handleBundleValidity}</SummaryRowBold>
            </TwoItemsRow>
            <TwoItemsRow>
              <SummaryRowRegular>Łączna suma:</SummaryRowRegular>
              <SummaryRowBold>
                {handleBundlePrice()}zł{' '}
                {taxIncluded ? (
                  <>brutto</>
                ) : (
                  <>
                    netto<RequiredStar>*</RequiredStar>
                  </>
                )}
              </SummaryRowBold>
            </TwoItemsRow>
            <Box my={1} style={{ width: '100%' }}>
              <ButtonAdd disabled={buttonDisabled} onClick={() => handlePay()}>
                płacę
              </ButtonAdd>
            </Box>
            {!taxIncluded && (
              <SummaryTax>
                <RequiredStar>*</RequiredStar> podatek zostanie doliczony przez Stripe
              </SummaryTax>
            )}
          </div>
        </div>
      </DefaultLayout>
      <AlertMessage
        open={showAlertMessage}
        close={() =>
          setShowAlertMessage({ show: false, success: showAlertMessage.success, message: showAlertMessage.message })
        }
        message={showAlertMessage.message}
      />
    </MainLayout>
  );
};
