import React from 'react';
import { Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';

import { SummaryContainerProps } from './SummaryContainer.types';
import { useStyles } from './SummaryContainer.styles';

export const SummaryContainer: React.FC<SummaryContainerProps> = ({
  title,
  subtitle,
  summaryItems,
  positionDescription,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <div className={classes.summaryContainer}>
      <Typography className={classes.summaryHeader}>{title}</Typography>
      <Typography align="left" className={classes.summarySubtitle} variant="body2">
        {subtitle}
      </Typography>
      {summaryItems?.map((item, key) => (
        <div
          key={key}
          className={clsx({
            [classes.summaryItem]: true,
            [classes.summaryItemLast]: key === summaryItems.length - 1,
            [classes.positionDescription]: positionDescription && key === summaryItems.length - 2,
          })}
        >
          <Typography variant="body2">{item.label}</Typography>
          <Typography variant="body2" className={classes.summaryItemValue}>
            {item.value}
          </Typography>
        </div>
      ))}
    </div>
  );
};
