import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {},
  success: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: 8,
    width: '100%',
    background: theme.palette.success.light,
    border: '1px solid',
    borderColor: theme.palette.success.dark,
    boxSizing: 'border-box',
    borderRadius: 2,
  },
  successText: {
    fontWeight: 400,
    fontSize: 12,
    color: theme.palette.success.dark,
  },
  error: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: 8,
    width: '100%',
    background: theme.palette.error.light,
    border: '1px solid',
    borderColor: theme.palette.error.dark,
    boxSizing: 'border-box',
    borderRadius: 2,
  },
  errorText: {
    fontWeight: 400,
    fontSize: 12,
    color: theme.palette.error.dark,
  },
}));
