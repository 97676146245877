import React, { useEffect, useState } from 'react';

import { AuthenticationContext } from 'context/authentication/AuthenticationContext';
import { firebaseRef } from 'api/firebaseRef';
import { LoginUserProps, RegisterUserProps } from 'context/authentication/AuthenticationContext.types';
import { onError, onSuccess } from 'providers/providersHelpers';

export const AuthenticationProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [authPending, setAuthPending] = useState(true);
  const [currentUser, setCurrentUser] = useState<firebase.default.User | null>(null);

  useEffect(() => {
    firebaseRef.auth().onAuthStateChanged((user) => {
      setCurrentUser(user);
      setAuthPending(false);
    });
  }, []);

  const registerUser = async (registeUserObject: RegisterUserProps) => {
    setLoading(true);
    const { firstName, lastName, email, password } = registeUserObject;

    try {
      const userResponse = await firebaseRef.auth().createUserWithEmailAndPassword(email, password);

      if (userResponse.user !== null) {
        const { uid } = userResponse.user;

        const documentObject = {
          firstName,
          lastName,
          email,
        };

        await firebaseRef.firestore().collection('users').doc(uid).set(documentObject);

        return onSuccess(setLoading, 'Account successfully created');
      }
    } catch (error) {
      return onError(setLoading, `Error occured: ${error.message}`);
    }

    return onError(setLoading, `Unable to create account. Try again later.`);
  };

  const loginUser = async (loginUserObject: LoginUserProps) => {
    const { email, password } = loginUserObject;
    setLoading(true);
    try {
      const userResponse = await firebaseRef.auth().signInWithEmailAndPassword(email, password);

      if (userResponse.user !== null) {
        const { emailVerified } = userResponse.user;

        if (emailVerified) {
          return onSuccess(setLoading, 'User logged in successfully');
        } else {
          return onError(setLoading, 'You need to verify your email address.');
        }
      }
    } catch (error) {
      return onError(setLoading, `Error occured: ${error.message}`);
    }

    return onError(setLoading, `Unable to log in. Try again later.`);
  };

  const logoutUser = async () => {
    setLoading(true);
    try {
      await firebaseRef.auth().signOut();
      return onSuccess(setLoading, 'User logged out successfully');
    } catch (error) {
      return onError(setLoading, `Unable to log out. Try again later.`);
    }
  };

  const changePassword = async (newPassword: string, oldPassword?: string) => {
    setLoading(true);

    try {
      if (currentUser !== null && currentUser.email) {
        const userEmail = currentUser.email;

        if (oldPassword) await firebaseRef.auth().signInWithEmailAndPassword(userEmail, oldPassword);

        await currentUser.updatePassword(newPassword);

        return onSuccess(setLoading, 'Password changes successfully.');
      } else {
        return onError(setLoading, `There is no user logged in, so we can not change the password.`);
      }
    } catch (error) {
      return onError(setLoading, `Error occured: ${error.message}`);
    }
  };

  const forgotPassword = async (email: string) => {
    setLoading(true);
    try {
      await firebaseRef.auth().sendPasswordResetEmail(email);

      return onSuccess(setLoading, 'Password reset link sent');
    } catch (error) {
      return onError(setLoading, `Error occured: ${error.message}`);
    }
  };

  return (
    <AuthenticationContext.Provider
      value={{ loading, authPending, currentUser, registerUser, loginUser, logoutUser, forgotPassword, changePassword }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};
