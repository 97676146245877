import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  box: {
    height: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  button: {
    textTransform: 'lowercase',
    fontWeight: 'normal',
    background: `linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.15) 66.67%, rgba(255, 255, 255, 0) 100%), ${theme.palette.secondary.main}`,
  },
  link: {
    textDecoration: 'none',
    color: 'unset',
  },
}));
