import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, useTheme } from '@material-ui/core';
import { Controller } from 'react-hook-form';

import { Interview, PlaceOfWork, WorkingTime } from '../AddJobOfferPositionBasicsRHF.types';
import { TextInput } from 'ui/textInput/TextInput';
import { FormControlLabelItem } from 'ui/radioGroupWithOthersInput/RadioGroupWithOthersInput.types';
import { RadioGroupWithOthersInputRHF } from 'ui/radioGroupWithOthersInput/RadioGroupWithOthersInputRHF';
import { FieldErrorWrap } from 'ui/fieldErrorWrap/FieldErrorWrap';
import { ShowErrorText } from 'ui/showErrorText/ShowErrorText';
import { TextSelect } from 'ui/textSelect/TextSelect';
import { CheckBoxWithLabel } from 'ui/checkBoxWithLabel/CheckBoxWithLabel';

import { AddJobOfferTimeAndPlaceRHFProps } from './AddJobOfferTimeAndPlaceRHF.types';
import { useStyles } from './AddJobOfferTimeAndPlaceRHF.styles';

export const AddJobOfferTimeAndPlaceRHF: React.FC<AddJobOfferTimeAndPlaceRHFProps> = ({ methods, checked = false }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [hireAsapChecked, setHireAsapChecked] = useState(checked);

  const workingTimeLabels: FormControlLabelItem[] = Object.values(WorkingTime);
  const placeOfWorkLabels: FormControlLabelItem[] = Object.values(PlaceOfWork);
  const interviewLabels: FormControlLabelItem[] = Object.values(Interview);

  useEffect(() => {
    methods?.setValue('hireAsap', hireAsapChecked);
  }, [hireAsapChecked, methods]);

  if (!methods) return <CircularProgress />;

  return (
    <>
      <FieldErrorWrap showErrorBackground={!!methods?.errors.hireSince?.message && !hireAsapChecked}>
        <div className={classes.checkboxContainer}>
          <Controller
            name="hireAsap"
            control={methods?.control}
            render={() => (
              <CheckBoxWithLabel
                value={hireAsapChecked}
                onChange={() => {
                  setHireAsapChecked((currentState) => !currentState);
                  methods?.clearErrors('hireSince');
                }}
                label="zatrudnimy od zaraz"
              />
            )}
          />
        </div>
        <TextInput
          name={'hireSince'}
          valueName={'hireSince'}
          disabled={hireAsapChecked}
          type="date"
          ref={methods?.register}
        />
        {methods?.errors.hireSince?.message && !hireAsapChecked && (
          <ShowErrorText marginTop errorMessage={methods.errors.hireSince.message} />
        )}
      </FieldErrorWrap>
      <Box mb={2} />

      <TextSelect
        name={'workingTime'}
        label={'wymiar czasu pracy'}
        control={methods?.control}
        required
        helperText={methods?.errors.workingTime?.message}
        items={workingTimeLabels}
      />
      <Box mb={2} />

      <RadioGroupWithOthersInputRHF
        items={placeOfWorkLabels}
        label={'miejsce pracy'}
        other={{ name: 'otherPlaceOfWork', placeholder: 'Podaj formę pracy' }}
        required={true}
        name={'placeOfWork'}
        methods={methods}
        helperText={methods?.errors.otherPlaceOfWork?.message || methods?.errors.placeOfWork?.message}
      />
      <Box mb={2} />

      <TextInput
        label={'miasto'}
        name={'companyCity'}
        placeholder={'Wpisz ulicę, na której znajduje się firma'}
        required={true}
        valueName={'companyCity'}
        ref={methods?.register}
        helperText={methods?.errors.companyCity?.message}
        FormHelperTextProps={{ classes: { root: classes.helperTextError } }}
      />
      <Box mb={2} />

      <TextInput
        label={'ulica'}
        name={'companyStreet'}
        placeholder={'Wpisz ulicę, na której znajduje się firma'}
        valueName={'companyStreet'}
        ref={methods?.register}
      />
      <Box mb={2} />

      <RadioGroupWithOthersInputRHF
        items={interviewLabels}
        label={'rozmowa rekrutacyjna'}
        required={true}
        name={'interview'}
        methods={methods}
        helperText={methods?.errors.interview?.message}
      />
    </>
  );
};
