import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  cityPostcode: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  city: {
    flex: 1,
    flexGrow: 2,
    marginRight: theme.spacing(2),
  },
  postcode: {
    flex: 1,
    flexGrow: 1,
  },
}));
