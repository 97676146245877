import React from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { useTheme } from '@material-ui/core';

import { useStyles } from './IconChecked.styles';

export const IconChecked = (): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return <CheckCircleOutlineIcon fontSize="small" className={classes.icon} />;
};
