import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
}));
