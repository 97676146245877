import React from 'react';
import { Link } from 'react-router-dom';
import { Box, useTheme } from '@material-ui/core';

import LogoBlack from 'assets/logo/logo-black.svg';

import { useStyles } from './PlatformHeaderMain.styles';
import { PlatformHeaderMainProps } from './PlatformHeaderMain.types';

export const PlatformHeaderMain: React.FC<PlatformHeaderMainProps> = ({ children, link, mono = false }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.wrapper}>
      <Link to={link ? link : '/'} className={classes.logoWrapper}>
        <img src={LogoBlack} className={classes.logo} alt="Logo HR-ME" />
      </Link>
      {!!children && (
        <Box className={classes.logoInfo}>
          <span className={classes.divider} />
          {children}
        </Box>
      )}
    </div>
  );
};
