import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(4),
    marginBottom: theme.spacing(4),
    border: '1px solid #d8d5dc',
    height: 'fit-content',
    position: 'sticky',
    top: '20px',
  },
  header: {
    fontWeight: 600,
    textAlign: 'left',
    marginBottom: theme.spacing(3),
  },
  textSelect: {
    marginBottom: theme.spacing(0.75),
  },
  divider: {
    marginBottom: theme.spacing(3),
  },
  confirmContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'start',
    marginBottom: theme.spacing(3),
  },
  confirmCheckbox: {
    padding: 0,
    marginRight: theme.spacing(1),
  },
  confirmLabel: {
    fontWeight: 400,
    lineHeight: '18px',
    textAlign: 'left',
  },
  button: {
    marginBottom: theme.spacing(3),
  },
  listItem: {
    display: 'flex',
  },
  listItemIcon: {
    minWidth: 32,
    margin: 'auto',
  },
  listItemText: {
    margin: 0,
  },
  img: {
    height: '24px',
    width: '24px',
  },
  saveDraft: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
