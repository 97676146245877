import React, { ChangeEvent, useState } from 'react';
import { FormControl, Grid, MenuItem, Select, useTheme } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Filters, OfferStatus } from 'app/applicant/applicantsListFromJobOffers/ApplicantsListFromJobOffers.types';
import { useJobOfferContext } from 'hooks/useJobOfferContext/useJobOfferContext';
import { useCompanyContext } from 'hooks/useCompanyContext/useCompanyContext';
import { JobOfferStatus } from 'context/jobOffer/JobOfferContext.types';

import { useStyles } from './FiltersPanel.styles';
import { FiltersPanelProps } from './FiltersPanel.types';

export const FiltersPanel: React.FC<FiltersPanelProps> = ({ handleJobOfferChange }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  // TODO: move to HOC?
  const { jobOffers } = useJobOfferContext();
  const { selectedCompany } = useCompanyContext();

  //TODO: move this to props!
  const [filterStatus, setFilterStatus] = useState<string>('wszystkie');
  const [filterOffers, setFilterOffers] = useState<string>('all');
  const [filterOrder, setFilterOrder] = useState<string>('najnowsi');

  const handleChange = ({ target }: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const { name, value } = target;
    if (name === Filters.STATUS) setFilterStatus(value as string);
    if (name === Filters.OFFER) setFilterOffers(value as string);
    if (name === Filters.ORDER) setFilterOrder(value as string);

    if (handleJobOfferChange) handleJobOfferChange(value as string);
  };

  const offerOptions = [{ label: 'Wszystkie oferty pracy', value: 'all' }].concat(
    jobOffers
      .filter((jobOffer) => jobOffer.companyId === selectedCompany?.docRef)
      .filter((jobOffer) => jobOffer.status === JobOfferStatus.ACTIVE)
      .map((jobOffer) => ({ label: jobOffer.position, value: jobOffer.jobOfferId })),
  );

  return (
    <Grid container spacing={4} className={classes.gridContainer}>
      <Grid item xs={3} className={classes.gridItem}>
        <FormControl fullWidth>
          <Select
            name={Filters.STATUS}
            value={filterStatus}
            onChange={handleChange}
            IconComponent={ExpandMoreIcon}
            className={classes.select}
          >
            <MenuItem value={OfferStatus.ALL}>Wszystkie statusy kandydatów</MenuItem>
            <MenuItem value={OfferStatus.PAID}>Odblokowano</MenuItem>
            <MenuItem value={OfferStatus.ACCEPTED}>Zaakceptowano</MenuItem>
            <MenuItem value={OfferStatus.SENT}>Wysłano</MenuItem>
            <MenuItem value={OfferStatus.AWAITING}>Oczekuje</MenuItem>
            <MenuItem value={OfferStatus.REJECTED}>Odrzucono</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3} className={classes.gridItem}>
        <FormControl fullWidth>
          <Select
            name={Filters.OFFER}
            value={filterOffers}
            onChange={handleChange}
            IconComponent={ExpandMoreIcon}
            className={classes.select}
          >
            {offerOptions.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3} className={classes.gridItem}>
        <FormControl fullWidth>
          <Select
            name={Filters.ORDER}
            value={filterOrder}
            onChange={handleChange}
            IconComponent={ExpandMoreIcon}
            className={classes.select}
          >
            <MenuItem value="najnowsi">Od: Najlepiej dopasowani kandydaci</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
