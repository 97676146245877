import { Box, Typography } from '@material-ui/core';
import React from 'react';

import { TextInput } from 'ui/textInput/TextInput';

import { EditProfilePasswordProps } from './EditProfilePassword.types';

export const EditProfilePassword = ({ register, errors }: EditProfilePasswordProps): JSX.Element => {
  return (
    <>
      <Typography variant="h6">Zmiana hasła</Typography>
      <Box mb={2} />
      <TextInput
        valueName={'oldPassword'}
        type="password"
        name={'oldPassword'}
        label={'aktualne hasło'}
        placeholder={'Wpisz aktualne hasło'}
        ref={register}
        helperText={errors.oldPassword ? errors.oldPassword.message : null}
        error={!!errors.oldPassword}
      />
      <TextInput
        valueName={'newPassword'}
        type="password"
        name={'newPassword'}
        label={'nowe hasło'}
        placeholder={'Wpisz hasło'}
        ref={register}
        helperText={errors.newPassword ? errors.newPassword.message : null}
        error={!!errors.newPassword}
      />
      <TextInput
        valueName={'newPasswordConfirm'}
        type="password"
        name={'newPasswordConfirm'}
        label={'potwierdź nowe hasło'}
        placeholder={'Wpisz ponownie nowe hasło'}
        ref={register}
        helperText={errors.newPasswordConfirm ? errors.newPasswordConfirm.message : null}
        error={!!errors.newPasswordConfirm}
      />
    </>
  );
};
