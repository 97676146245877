import React from 'react';
import { Typography, useTheme } from '@material-ui/core';

import LogoBlack from 'assets/logo/logo-black.svg';

import { useStyles } from './PlatformHeader.styles';

export const PlatformHeader: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.wrapper}>
      <img src={LogoBlack} className={classes.logo} alt="Logo HR-ME" />
      <span className={classes.divider} />
      <Typography>Platforma rozwoju kariery</Typography>
    </div>
  );
};
