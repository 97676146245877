import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {},
  title: {
    fontSize: '18px',
    fontWeight: 600,
    textAlign: 'left',
  },
  chipsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'start',
    maxWidth: 525,
    flexWrap: 'wrap',
  },
}));
