import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.background.paper,
    paddingBottom: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },
  noPadding: {
    paddingBottom: 0,
  },
}));
