import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 400,
    color: theme.palette.grey[800],
    textAlign: 'left',
    marginBottom: theme.spacing(1),
  },
  labelGrey: {
    //TODO: define in the palette
    color: '#3e2950',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  textFieldAdd: {
    marginTop: theme.spacing(2),
  },
  listContainer: {},
  listContainerGrey: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  smallLabel: {
    fontSize: '11px',
  },
}));
