import React from 'react';
import { Button, ButtonProps, useTheme } from '@material-ui/core';

import { useStyles } from './ButtonAdd.styles';

export const ButtonAdd: React.FC<ButtonProps> = ({ onClick, children, startIcon, disabled }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Button
      disabled={disabled}
      fullWidth
      variant="contained"
      className={classes.button}
      size="large"
      disableElevation
      type="button"
      onClick={onClick}
      startIcon={startIcon}
    >
      {children}
    </Button>
  );
};
