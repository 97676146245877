import { Typography } from '@material-ui/core';
import React from 'react';

import { AuthenticationPaths } from 'routing/authentication/AuthenticationPaths';
import { Link } from 'ui/link/Link';
import { LoginFlowLayout } from 'ui/loginFlowLayout/LoginFlowLayout';

import { RegisterForm } from './registerForm/RegisterForm';

const RegisterFooter = () => (
  <Typography variant="body2">
    Posiadasz już konto?{' '}
    <Link type="bold" href={AuthenticationPaths.login}>
      Zaloguj się
    </Link>
  </Typography>
);

export const RegisterPage: React.FC = () => {
  return (
    <LoginFlowLayout footerComponent={<RegisterFooter />}>
      <RegisterForm confirmed={false} />
    </LoginFlowLayout>
  );
};
