/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Box, InputLabel, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { Controller } from 'react-hook-form';

import { TextInputWithClose } from 'ui/textInputWithClose/TextInputWithClose';
import { TextInput } from 'ui/textInput/TextInput';
import { ButtonDefault } from 'ui/button/ButtonDefault';

import { TextInputExtendableListProps } from './ExtendableList.types';
import { useStyles } from './ExtendableList.styles';

export const ExtendableList: React.FC<TextInputExtendableListProps> = ({
  label,
  buttonCaption,
  addInputLabel,
  addInputPlaceholder,
  name,
  setValue,
  getValues,
  control,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [newSocialMedia, setNewSocialMedia] = useState('');
  const [listItems, setListItems] = useState<string[] | undefined>([]);

  const listContainerStyle = clsx({
    [classes.listContainer]: true,
  });

  const labelStyle = clsx({
    [classes.label]: true,
  });

  useEffect(() => {
    const fetchValues = async () => setListItems(await getValues(name));

    fetchValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setValue(name, listItems);
  }, [listItems, name, setValue]);

  const handleNewSocialMediaButtonClick = (list: string[]) => {
    if (list !== undefined) {
      const elementExists = list.find((el) => el.toLowerCase() === newSocialMedia.toLowerCase());

      if (elementExists === undefined) {
        setListItems([...list, newSocialMedia]);
      }
    } else {
      setListItems([newSocialMedia]);
    }

    setNewSocialMedia('');
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ value, name }) => (
        <div>
          <div className={listContainerStyle}>
            <InputLabel>
              <Typography variant="subtitle2" className={labelStyle}>
                {label}
              </Typography>
            </InputLabel>
            {value?.map((item: string, key: string | number | null | undefined) => {
              return (
                <TextInputWithClose
                  key={key}
                  value={item}
                  onClick={() => {
                    setValue(
                      name,
                      value.filter((element: string) => element !== item),
                    );
                  }}
                />
              );
            })}
          </div>
          <div>
            <Box mb={2.25} />
            <div className={classes.inputContainer}>
              <TextInput
                label={addInputLabel}
                placeholder={addInputPlaceholder}
                value={newSocialMedia}
                onChange={(event) => {
                  setNewSocialMedia(event.target.value);
                }}
              />
            </div>
            <ButtonDefault type="button" onClick={() => handleNewSocialMediaButtonClick(value)}>
              {buttonCaption}
            </ButtonDefault>
          </div>
        </div>
      )}
    />
  );
};
