import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0.5, 1),
    height: 32,
    borderRadius: 16,
    marginBottom: theme.spacing(0.5),
  },
  backgroundDark: {
    backgroundColor: theme.palette.background.default,
  },
  columnGap: {
    marginRight: theme.spacing(2),
  },
  rowGap: {
    marginBottom: theme.spacing(2),
  },
}));
