import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
  },
  imageBox: {
    width: 71,
    height: 71,
    border: '1px solid #F1F1F1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0.5),
    fontSize: '8px',
  },
  companyInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'start',
  },
  companyName: {
    fontSize: '18px',
    fontWeight: 600,
  },
  offers: {
    color: theme.palette.grey.A200,
  },
  img: {
    height: 62,
    width: 62,
    objectFit: 'contain',
  },
}));
