import React from 'react';
import { Box, Divider, Grid, useTheme } from '@material-ui/core';

import { DashboardContent } from 'ui/dashboard/dashboardContent/DashboardContent';
import { DashboardContentContainer } from 'ui/dashboard/dashboardContentContainer/DashboardContentContainer';
import { DashboardContentHeader } from 'ui/dashboard/dashboardContentHeader/DashboardContentHeader';
import { MainLayout } from 'ui/mainLayout/MainLayout';
import { JobOfferActivationStatusBar } from 'app/jobOffer/jobOfferActivationStatusBar/JobOfferActivationStatusBar';
// import { PaymentBenefits } from 'ui/paymentBenefits/PaymentBenefits';
import { PaymentQuestions } from 'ui/paymentQuestions/PaymentQuestions';
import { PaymentFooter } from 'ui/paymentFooter/PaymentFooter';
import { useJobOfferContext } from 'hooks/useJobOfferContext/useJobOfferContext';

import { useStyles } from './PaymentVariantScreen.styles';
import { VariantHeaders } from './components/variantHeaders/VariantHeaders';
import { ColumnData } from './components/columnData/ColumnData';

export const PaymentVariantScreen: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { prices } = useJobOfferContext();

  return (
    <MainLayout>
      <DashboardContentContainer>
        <JobOfferActivationStatusBar />
        <Box mb={4} />
        <DashboardContent hasBottomPadding={false}>
          <DashboardContentHeader headerText={'Płatności'} subHeader={'Zapoznaj się z naszymi planami cenowymi'} />
          <Grid className={classes.gridContainer} container spacing={0}>
            <VariantHeaders />
          </Grid>
          <ColumnData
            firstColumn={'Wartość którą dostajesz'}
            secondColumn={`${prices?.pay_as_you_go[0].tokens} kontaktów do kandydatów`}
            thirdColumn={`${prices?.premium[0].tokens} kontaktów miesięcznie`}
            fourthColumn={`${prices?.enterprise[0].tokens} kontaktów miesięcznie`}
          />
          <ColumnData
            firstColumn={'Ważne przez'}
            secondColumn={'bezterminowo'}
            thirdColumn={'1 miesiąc'}
            fourthColumn={'1 miesiąc'}
          />
          {/* <ColumnData
            firstColumn={'Liczba profili firmowych'}
            secondColumn={'1'}
            thirdColumn={'Nielimitowane'}
            fourthColumn={'Nielimitowane'}
          />
          <ColumnData
            firstColumn={'Dopasowanie % pracowników'}
            secondColumn={'Od 80% dopasowania'}
            thirdColumn={'Od 80% dopasowania'}
            fourthColumn={'Od 60% dopasowania'}
          />
          <ColumnData
            firstColumn={'Śledzenie ścieżki rozwoju kandydata'}
            secondColumn={<img src={Cross} alt="Uncheck icon" />}
            thirdColumn={<img src={Check} alt="Checked icon" />}
            fourthColumn={<img src={Check} alt="Checked icon" />}
          />
          <ColumnData
            firstColumn={'Dodatkowy kontakt z kandydatem'}
            secondColumn={<img src={Cross} alt="Uncheck icon" />}
            thirdColumn={'1'}
            fourthColumn={'2'}
          />
          <ColumnData
            firstColumn={'Analityka oferty'}
            secondColumn={'podstawowa'}
            thirdColumn={'pełna'}
            fourthColumn={'pełna'}
          /> */}
        </DashboardContent>
        <Box mb={4} />
        {/* <Grid className={classes.gridContainer} container spacing={0}>
          <VariantHeaders />
        </Grid>
        <Typography variant="caption" className={classes.finePrint}>
          * przy zakupie rocznym
        </Typography>
        <PaymentBenefits /> */}
        <Divider light />
        <PaymentQuestions />
        <PaymentFooter />
      </DashboardContentContainer>
    </MainLayout>
  );
};
