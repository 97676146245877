import React from 'react';
import { Box, InputLabel, Typography, useTheme } from '@material-ui/core';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Message } from 'ui/message/Message';
import { ButtonDefault } from 'ui/button/ButtonDefault';
import { useAuthContext } from 'hooks/useAuthContext/useAuthContext';
import { TextInput } from 'ui/textInput/TextInput';

import { useStyles } from './ForgotPasswordForm.styles';

const initialValues = {
  email: '',
};

const validationSchema = Yup.object({
  email: Yup.string().email('Nieprawidłowy adres email.').required('Pole nie może być puste.'),
});

export const ForgotPasswordForm: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { forgotPassword } = useAuthContext();
  const history = useHistory();

  const { handleSubmit, register, errors } = useForm({
    defaultValues: initialValues,
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (values: typeof initialValues) => {
    const response = await forgotPassword(values.email);

    if (response.success) {
      history.push('/check-email');
    } else {
      //todo: 'set errors'
    }
  };

  return (
    <div>
      <Typography variant="h4">Zresetuj hasło do konta</Typography>
      <Box mb={3} />
      <Message
        type="success"
        text="Jeżeli użytkownik z danym adresem email istnieje, wiadomość z linkiem resetującym hasło zostanie wysłana."
      />
      <Box mb={4} />
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <InputLabel htmlFor="email" className={classes.label}>
          adres e-mail
        </InputLabel>

        <TextInput
          name="email"
          variant="outlined"
          fullWidth
          type="email"
          label=""
          ref={register}
          placeholder="Podaj swój adres e-mail"
          helperText={errors.email?.message}
          error={!!errors.email}
          className={classes.input}
        />

        <ButtonDefault>Wyślij link resetujący hasło</ButtonDefault>
      </form>
    </div>
  );
};
