import React, { useEffect, useState } from 'react';
import { Box, Checkbox, Divider, Typography, useTheme } from '@material-ui/core';
import { AlertMessage } from '@hrme/shared';
import { useHistory } from 'react-router-dom';

import { ButtonDefault } from 'ui/button/ButtonDefault';
import { AddJobOfferInitialValuesTypeRHF } from 'app/jobOffer/addJobOfferRHF/addJobOfferFormWrapperRHF/addJobOfferFormWrapperRHF.types';
import { useCompanyContext } from 'hooks/useCompanyContext/useCompanyContext';
import { Message } from 'ui/message/Message';
import { DashboardContentWideNarrowRHFProps } from 'ui/dashboard/dashboardContentWideNarrow/DashboardContentWideNarrow.types';
import { CompanySelectInput } from 'ui/companySelectInput/CompanySelectInput';
import { MainPaths } from 'routing/main/MainPaths';
import { useJobOfferContext } from 'hooks/useJobOfferContext/useJobOfferContext';
import { JobOfferStatus } from 'context/jobOffer/JobOfferContext.types';
import { useUserContext } from 'hooks/useUserContext/useUserContext';
import { UserProfileType } from 'context/user/UserContext.types';

import { useStyles } from './AddJobOfferNarrowPanelRHF.styles';

export const AddJobOfferNarrowPanelRHF: React.FC<DashboardContentWideNarrowRHFProps> = ({ methods, editedOffer }) => {
  const { companies, selectedCompany, updateSelectedCompany } = useCompanyContext();
  const { addNewJobOffer, updateOffer, changeOfferStatus } = useJobOfferContext();
  const theme = useTheme();
  const classes = useStyles(theme);
  const [confirmedChecked, setConfirmedChecked] = useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState({ show: false, success: false });

  const { user } = useUserContext();

  const isAgencyProfile = user?.profileType === UserProfileType.AGENCY;

  const subscriptionActive = false; // enabling unpaid offer launch
  const history = useHistory();

  const editedOfferID = editedOffer?.offerID;
  const editedOfferStatus = editedOffer?.offerStatus;
  const addNewOffer = editedOfferStatus !== JobOfferStatus.ACTIVE && !editedOfferID;
  const activateOffer = editedOfferStatus !== JobOfferStatus.ACTIVE && !!editedOfferID && !subscriptionActive;
  const activatePaidOffer = editedOfferStatus !== JobOfferStatus.ACTIVE && subscriptionActive && !!editedOfferID;
  const saveOffer = editedOfferID && editedOfferStatus === JobOfferStatus.ACTIVE;
  const saveOfferAsDraft = editedOfferStatus !== JobOfferStatus.ACTIVE;
  const companyId = selectedCompany ? (selectedCompany.docRef as string) : '';

  const thereAreErrors = methods?.errors && Object.keys(methods.errors).length > 0;

  const returnStatusType = (status: JobOfferStatus) => {
    switch (status) {
      case JobOfferStatus.ACTIVE:
        return JobOfferStatus.INACTIVE;
      case JobOfferStatus.INACTIVE:
        return JobOfferStatus.ACTIVE;
      default:
        return JobOfferStatus.DRAFT;
    }
  };

  const toggleOfferStatus = (status: JobOfferStatus, offerID: string | undefined) => {
    if (offerID) changeOfferStatus(offerID, returnStatusType(status));
  };

  const onSubmit = async (data: AddJobOfferInitialValuesTypeRHF) => {
    const payload: AddJobOfferInitialValuesTypeRHF = {
      company: selectedCompany ? selectedCompany.companyName : '',
      companyId: selectedCompany ? (selectedCompany.docRef as string) : '',
      confirmed: data.confirmed,
      experienceTime: data.experienceTime ? data.experienceTime : '',

      // informacje podstawowe
      position: data.position ? data.position : '',
      category: data.category ? data.category : '',
      contractType: data.contractType ? data.contractType : [],

      // wynagrodzenie
      currency: data.currency ? data.currency : 'PLN',
      paidLeave: data.paidLeave,
      relocationPackage: data.relocationPackage,
      isNetto: data.isNetto,
      salaryRange: data.salaryRange,
      salaryRangeFrom: +data.salaryRangeFrom,
      salaryRangeTo: data.salaryRangeTo ? +data.salaryRangeTo : 0,

      // czas i miejsce pracy
      hireAsap: data.hireAsap,
      hireSince: data.hireSince ? data.hireSince : '',
      workingTime: data.workingTime ? data.workingTime : '',
      placeOfWork: data.placeOfWork ? data.placeOfWork : '',
      otherPlaceOfWork: data.otherPlaceOfWork ? data.otherPlaceOfWork : '',
      companyCity: data.companyCity ? data.companyCity : '',
      companyStreet: data.companyStreet ? data.companyStreet : '',
      officeHours: data.officeHours ? data.officeHours : '',
      interview: data.interview ? data.interview : '',

      // dodatkowe informacje
      benefits: data.benefits ? data.benefits : [],
      positionDescription: data.positionDescription ? data.positionDescription : '',

      //
      // kandydat
      mainTechnologies: data.mainTechnologies ? data.mainTechnologies : [],
      experienceType: data.experienceType ? data.experienceType : '',

      // technologie na stanowisku
      positionTechnologies: data.positionTechnologies ? data.positionTechnologies : [],
      welcomeTechnologies: data.welcomeTechnologies ? data.welcomeTechnologies : [],
      willLearnTechnologies: data.willLearnTechnologies ? data.willLearnTechnologies : [],

      // dodatkowe informacje o stanowisku
      collaborators: data.collaborators ? data.collaborators : [],
      willLearn: data.willLearn ? data.willLearn : [],

      // metodologia stanowiska
      methodology: data.methodology ? data.methodology : [],

      // codzienne zadania
      dailyTasks: data.dailyTasks ? data.dailyTasks : [],

      // możliwość rozwoju
      personalGrowth: data.personalGrowth ? data.personalGrowth : [],

      // wymagane języki obce
      language: data.language ? data.language : [],
      valueLanguageLevel: data.valueLanguageLevel ? data.valueLanguageLevel : '',
    };

    const status = subscriptionActive ? JobOfferStatus.DRAFT : JobOfferStatus.ACTIVE;
    const response = await addNewJobOffer(payload, status, subscriptionActive);

    const newOffer = {
      ...payload,
      position: data.position,
      hireWhen: data.hireAsap ? 'od już' : data.hireSince,
      company: selectedCompany?.companyName,
      salary: data.salaryRange ? `${data.salaryRangeFrom} - ${data.salaryRangeTo}` : data.salaryRangeFrom,
      contractType: data.contractType,
      localization: data.placeOfWork,
      mainTechnology: data.mainTechnologies[0],
      offerStatus: JobOfferStatus.ACTIVE,
    };
    if (response.success) {
      history.push(
        subscriptionActive
          ? {
              pathname: MainPaths.invoice,
              state: { offerID: response.message?.split('.')[1] },
            }
          : {
              pathname: MainPaths.jobOfferActivated,
              state: { jobOfferId: response.message?.split('.')[1], offerData: newOffer },
            },
      );
    } else {
      alert(response?.message);
    }
  };

  const saveAsDraft = async (payload: AddJobOfferInitialValuesTypeRHF | undefined, activeOffer?: boolean) => {
    if (!payload) return;

    const payloadWithCompanyId = {
      ...payload,
      company: selectedCompany ? selectedCompany.companyName : '',
      companyId,
      salaryRangeFrom: +payload.salaryRangeFrom,
      salaryRangeTo: payload.salaryRangeTo ? +payload.salaryRangeTo : 0,
    };

    const response = editedOfferID
      ? await updateOffer(editedOfferID, payloadWithCompanyId)
      : await addNewJobOffer(payloadWithCompanyId, JobOfferStatus.DRAFT, subscriptionActive);

    if (response.success) {
      history.push({
        pathname: MainPaths.jobOfferActivated,
        state: {
          offerData: {
            offerStatus: activeOffer ? JobOfferStatus.EDITED : JobOfferStatus.DRAFT,
            ...payloadWithCompanyId,
            jobOfferId: response.message?.split('.')[1],
          },
        },
      });
    } else {
      alert(response?.message);
    }
  };

  useEffect(() => {
    methods?.register('confirmed');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className={classes.wrapper}>
        <Typography variant="h6" className={classes.header}>
          Dodaj ofertę
        </Typography>

        {selectedCompany !== undefined && isAgencyProfile && (
          <>
            <CompanySelectInput
              handleChange={(e) => updateSelectedCompany(e.target.value)}
              items={companies}
              name="company"
              label={'firma wystawiająca ofertę'}
              value={selectedCompany}
            />
            <Divider className={classes.divider} />
          </>
        )}

        <div className={classes.confirmContainer}>
          <Checkbox
            name={'confirmed'}
            className={classes.confirmCheckbox}
            checked={confirmedChecked}
            onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
              const { checked } = target;
              methods?.clearErrors('confirmed');
              setConfirmedChecked(checked);
              methods?.setValue('confirmed', checked);
            }}
          />
          <Typography variant="subtitle2" className={classes.confirmLabel}>
            zapoznałem i zgadzam się z&nbsp;regulaminem wystawiania ofert na platformie hr-me
          </Typography>
        </div>

        {addNewOffer && (
          <div className={classes.button}>
            <ButtonDefault type="button" onClick={methods?.handleSubmit(onSubmit)}>
              dodaj ofertę
            </ButtonDefault>
          </div>
        )}

        {activateOffer && (
          <div className={classes.button}>
            <ButtonDefault
              type="button"
              onClick={methods?.handleSubmit(() => {
                const data = methods.getValues();
                const newOffer = {
                  ...data,
                  position: data.position,
                  hireWhen: data.hireAsap ? 'od już' : data.hireSince,
                  company: selectedCompany?.companyName,
                  salary: data.salaryRange ? `${data.salaryRangeFrom} - ${data.salaryRangeTo}` : data.salaryRangeFrom,
                  contractType: data.contractType,
                  localization: data.placeOfWork,
                  mainTechnology: data.mainTechnologies[0],
                  offerStatus: JobOfferStatus.ACTIVE,
                };
                toggleOfferStatus(editedOfferStatus as JobOfferStatus, editedOfferID);
                history.push({
                  pathname: MainPaths.jobOfferActivated,
                  state: { offerData: newOffer },
                });
              })}
            >
              aktywuj ofertę
            </ButtonDefault>
          </div>
        )}

        {activatePaidOffer && (
          <div className={classes.button}>
            <ButtonDefault
              type="button"
              onClick={() => {
                if (!methods?.getValues('confirmed'))
                  methods?.setError('confirmed', new Error('Musisz zaakceptować regulamin'));
                else {
                  history.push({
                    pathname: MainPaths.invoice,
                    state: { offerID: editedOfferID },
                  });
                }
              }}
            >
              aktywuj ofertę (PAYG)
            </ButtonDefault>
          </div>
        )}

        {saveOffer && (
          <div className={classes.button}>
            <ButtonDefault
              type="button"
              onClick={methods?.handleSubmit(() => {
                saveAsDraft(methods?.getValues(), true);
              })}
            >
              aktualizuj
            </ButtonDefault>
          </div>
        )}

        {saveOfferAsDraft && (
          <Typography className={classes.saveDraft} onClick={() => saveAsDraft(methods?.getValues())}>
            zapisz jako wersję roboczą
          </Typography>
        )}
        {thereAreErrors && (
          <>
            <Box mt={5} />
            <Message
              text={
                methods?.errors.confirmed?.message
                  ? methods.errors.confirmed.message
                  : 'Uzupełnij wszystkie wymagane pola'
              }
              type="error"
            />
          </>
        )}
      </div>
      <AlertMessage open={showAlertMessage} close={setShowAlertMessage} message={'Zapisano kopię roboczą'} />
    </>
  );
};
