import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  fieldError: {
    borderRadius: '4px',
    background: '#FFF9F9',
    margin: '-5px -10px 0px',
    padding: '5px 10px 0px',
  },
  wide: {
    width: '100%',
  },
}));
