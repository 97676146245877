import { makeStyles } from '@material-ui/core';
import { HrMeTheme } from '@hrme/shared/src/theme/theme';

export const useStyles = makeStyles<HrMeTheme>((theme) => ({
  container: {
    alignItems: 'start',
    border: `1px solid ${theme.palette.background.default}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: theme.spacing(4),
  },
  typographyRight: {
    fontSize: '18px',
    fontWeight: 600,
  },
  strong: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  gridRightItem: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  copy: {
    cursor: 'pointer',
  },
}));
