import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    background: theme.palette.background.default,
    minHeight: '100vh',
    height: '100%',
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  innerWrapper: {
    width: 484,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },

  container: {
    background: theme.palette.background.paper,
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'stretch',

    '& img': {
      alignSelf: 'center',
    },
  },

  wide: {
    width: 'auto',
    minWidth: 732,
  },

  paddingBottom: {
    paddingBottom: theme.spacing(4),
  },

  smallPadding: {
    paddingBottom: theme.spacing(2),
  },
}));
