import { makeStyles } from '@material-ui/core';
import { HrMeTheme } from '@hrme/shared/lib/index.types';

export const useStyles = makeStyles((theme: HrMeTheme) => ({
  applicant: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '40px 3fr 3fr 1.5fr 3.5fr',
    columnGap: theme.spacing(2),
    alignItems: 'center',
    padding: theme.spacing(2, 1),
    borderBottom: `1px solid ${theme.borders.main}`,
  },
  applicantInAccordion: {
    gridTemplateColumns: '40px 3fr 3fr 1.5fr 2.5fr',
    padding: 0,
    borderBottom: 0,
  },
  progress: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
  label: {
    textAlign: 'left',
  },
  description: {
    textAlign: 'left',
    fontSize: '12px',
    color: '#cccccc',
  },
  status: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  statusTypography: {
    textAlign: 'left',
    fontSize: '12px',
  },
  circle: {
    width: 16,
    height: 16,
  },
  circleWaits: {
    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%), #DFDED9',
    borderRadius: '50%',
  },
  circleSent: {
    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%), #FFDD00',
    borderRadius: '50%',
  },
  circleAccepted: {
    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%), #6041B8',
    borderRadius: '50%',
  },
  circlePaid: {
    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%), #41B883',
    borderRadius: '50%',
  },
  circleRejected: {
    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 102, 96, 0) 100%), #FF6660',
    borderRadius: '50%',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    alignItems: 'center',
  },
  buttonSend: {
    padding: theme.spacing(1, 3),
    textTransform: 'lowercase',
    cursor: 'pointer',
  },
  buttonPaid: {
    padding: theme.spacing(1, 3),
    textTransform: 'lowercase',
    width: '100%',
    cursor: 'pointer',
    fontWeight: 'normal',
    background: `#41B883`,
    boxShadow: 'none',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#41B883 !important',
      boxShadow: 'none !important',
    },
  },
  buttonAccepted: {
    padding: theme.spacing(1, 3),
    textTransform: 'lowercase',
    width: '100%',
    cursor: 'pointer',
    fontWeight: 'normal',
    background: `#6041B8`,
    color: theme.palette.common.white,
    '&:hover': { backgroundColor: '#6041B8 !important' },
  },
  messageSent: {
    padding: theme.spacing(1, 3),
    width: '100%',
    color: '#D8D5DC',
  },
}));
