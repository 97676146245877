import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  helperTextError: {
    color: '#f44336',
    fontSize: '.8em',
    margin: '0',
    background: '#fff9f9',
    padding: '5px',
  },
  helperTextMargin: {
    marginTop: '-15px',
  },
}));
