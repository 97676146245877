"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatsBarItems = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var core_1 = require("@material-ui/core");
var StatsBarItems_styles_1 = require("./StatsBarItems.styles");
var icons_1 = require("@material-ui/icons");
var StatsBarItems = function (_a) {
    var _b = _a.data, data = _b === void 0 ? [] : _b, _c = _a.valuesInLabels, valuesInLabels = _c === void 0 ? false : _c;
    var theme = core_1.useTheme();
    var classes = StatsBarItems_styles_1.useStyles(theme);
    var composeLabel = function (_a) {
        var label = _a.label, value = _a.value, withValues = _a.withValues;
        return "" + label + (withValues ? ' (' : '') + (withValues ? value : '') + (withValues ? ')' : '');
    };
    // depending on passed 'withValues' parameter label does contain 'value' shown in brackets or doesn't
    return (jsx_runtime_1.jsx(jsx_runtime_1.Fragment, { children: data.map(function (item, index) { return ((item.value !== 0 || valuesInLabels) && (jsx_runtime_1.jsxs("div", __assign({ className: classes.chartKey }, { children: [jsx_runtime_1.jsx(icons_1.RadioButtonUnchecked, { fontSize: "small", className: classes.circle, style: { color: item.color } }, void 0), jsx_runtime_1.jsx(core_1.Typography, __assign({ variant: "subtitle1", className: classes.text }, { children: composeLabel({ label: item.label, value: item.value, withValues: valuesInLabels }) }), void 0), jsx_runtime_1.jsx(jsx_runtime_1.Fragment, {}, void 0), index < data.length - 1 && jsx_runtime_1.jsx(core_1.Box, { mr: 3 }, void 0)] }), index))); }) }, void 0));
};
exports.StatsBarItems = StatsBarItems;
