import React from 'react';
import { Box, Divider, Typography, useTheme } from '@material-ui/core';

import VerticalArrow from 'assets/icons/verticalArrow.svg';
import { ChipsBox } from 'ui/chipsBox/ChipsBox';
import { ExperienceBox } from 'ui/experienceBox/ExperienceBox';
// import { CertificateBox } from 'ui/certificateBox/CertificateBox';
import { EducationBox } from 'ui/educationBox/EducationBox';

import { useStyles } from './ApplicantsAttributesMatchingDetails.styles';
import { ApplicantsAttributesMatchingDetailsProps } from './ApplicantsAttributesMatchingDetails.types';

export const ApplicantsAttributesMatchingDetails: React.FC<ApplicantsAttributesMatchingDetailsProps> = ({
  queryResultMainTechnology,
  queryResultRequired,
  queryResultNiceToHave,
  queryResultWillLearn,
  queryCandidateTechnologyKnown,
  queryCandidateTechnologyLearning,
  queryCandidateTechnologyWantToLearn,
  queryResultExperience,
  queryResultCertificates,
  queryResultEducation,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.container}>
      <Typography variant="h2">Szczegóły dopasowania</Typography>
      <Box mb={3} />

      <ChipsBox title={queryResultMainTechnology.title} chips={queryResultMainTechnology.chips} />
      <Box mb={5} />

      <ChipsBox title={queryResultRequired.title} chips={queryResultRequired.chips} />
      <Box mb={5} />
      <Divider className={classes.divider} light />
      <Box mb={5} />

      <ChipsBox title={queryResultNiceToHave.title} chips={queryResultNiceToHave.chips} />
      <Box mb={5} />
      <Divider className={classes.divider} light />
      <Box mb={5} />

      <ChipsBox title={queryResultWillLearn.title} chips={queryResultWillLearn.chips} />
      <Box mb={5} />
      <Divider className={classes.divider} light />
      <Box mb={5} />

      <ChipsBox title={queryCandidateTechnologyKnown.title} chips={queryCandidateTechnologyKnown.chips} />
      <Box mb={5} />
      <Divider className={classes.divider} light />
      <Box mb={5} />

      <ChipsBox title={queryCandidateTechnologyLearning.title} chips={queryCandidateTechnologyLearning.chips} />
      <Box mb={5} />
      <Divider className={classes.divider} light />
      <Box mb={5} />

      <ChipsBox title={queryCandidateTechnologyWantToLearn.title} chips={queryCandidateTechnologyWantToLearn.chips} />
      <Box mb={5} />
      <Divider className={classes.divider} light />
      <Box mb={5} />

      <Typography className={classes.title}>Doświadczenie zawodowe:</Typography>
      <Box mb={3} />
      {queryResultExperience.length ? (
        queryResultExperience.map((item, key) => {
          const isNotLast = key !== queryResultExperience.length - 1;
          return (
            <React.Fragment key={key}>
              <ExperienceBox
                company={item.company}
                position={item.position}
                period={item.period}
                city={item.city}
                technology={item.technology}
                learned={item.learned}
              />
              {isNotLast && (
                <>
                  <Box mb={3} />
                  <img src={VerticalArrow} alt="divider arrow" style={{ margin: '0 auto' }} />
                </>
              )}
              <Box mb={3} />
            </React.Fragment>
          );
        })
      ) : (
        <Typography>Brak danych</Typography>
      )}
      <Box mb={5} />
      <Divider className={classes.divider} light />
      <Box mb={5} />

      <Typography className={classes.title}>Edukacja:</Typography>
      <Box mb={3} />
      {queryResultEducation.length ? (
        queryResultEducation.map((item, key) => (
          <EducationBox key={key} title={item.title} subtitle={item.subtitle} period={item.period} />
        ))
      ) : (
        <Typography>Brak danych</Typography>
      )}

      <Box mb={5} />
      {/* <Divider className={classes.divider} light />
      <Box mb={5} />

      <Typography className={classes.title}>Szkolenia i certyfikaty:</Typography>
      <Box mb={3} />
      {queryResultCertificates.length ? (
        queryResultCertificates.map((item, key) => (
          <React.Fragment key={key}>
            <CertificateBox title={item.title} subtitle={item.subtitle} period={item.period} skills={item.skills} />
            {key !== queryResultCertificates.length - 1 && <Box mb={2} />}
          </React.Fragment>
        ))
      ) : (
        <Typography>Brak danych</Typography>
      )} */}
    </div>
  );
};
