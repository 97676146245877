import { useContext } from 'react';

import { CustomerContextProps } from 'context/customer/CustomerContext.types';
import { CustomerContext } from 'context/customer/CustomerContext';

export const useCustomerContext: () => CustomerContextProps = () => {
  const context = useContext(CustomerContext);

  if (context === undefined) {
    throw Error('useCustomerContext must be used within an CustomerContext');
  }

  return context;
};
