import React from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import { Controller } from 'react-hook-form';

import { FieldErrorWrap } from 'ui/fieldErrorWrap/FieldErrorWrap';

import { TextSelectProps } from './TextSelect.types';
import { useStyles } from './TextSelect.styles';

export const TextSelect: React.FC<TextSelectProps> = ({
  name,
  control,
  items,
  label,
  disabled,
  defaultValue,
  required,
  narrow,
  error,
  helperText,
  placeholder,
  noBackground,
  smallText,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const matches = useMediaQuery('(max-width:1280px)');

  const container = clsx({
    [classes.container]: true,
    [classes.containerNarrow]: narrow,
  });

  if (name === undefined)
    throw Error('name must be used within an TextSelect component (reequired by react-hook-form)');

  const getLabel = (value: unknown) => {
    const result = items.find((item) => item.value === value);
    return result?.label;
  };

  return (
    <FieldErrorWrap showErrorBackground={!!helperText && !noBackground}>
      <div className={container}>
        <InputLabel>
          <Typography variant="subtitle2" className={classes.label}>
            <span className={smallText && matches ? classes.smallLabel : ''}>{label}</span>
            {required && <span style={{ color: theme.palette.primary.main }}>*</span>}
          </Typography>
        </InputLabel>
        <FormControl variant="outlined" className={classes.select} disabled={disabled} error={error}>
          <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ value, onChange }) => {
              if (value === undefined) return <div />;

              return (
                <Select
                  value={value}
                  onChange={onChange}
                  error={error}
                  className={value ? '' : classes.placeholder}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                  displayEmpty={true}
                  renderValue={(value: unknown) => (value ? getLabel(value) : 'Wybierz') as string}
                >
                  {items.map((item, index) => (
                    <MenuItem key={index} value={item.value} disabled={item.value === 'placeholder'}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              );
            }}
          />
          {helperText && (
            <FormHelperText className={noBackground ? '' : classes.helperTextError}>{helperText}</FormHelperText>
          )}
        </FormControl>
      </div>
    </FieldErrorWrap>
  );
};
