import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  expandIcon: {
    padding: theme.spacing(0, 1),
    '& .MuiAccordionSummary-expandIcon': {
      transform: 'rotate(0deg)',
    },
  },
  accordionDetails: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(4),
  },
}));
