import { useContext } from 'react';

import { CompanyContext } from 'context/company/CompanyContext';
import { CompanyContextProps } from 'context/company/CompanyContext.types';

export const useCompanyContext: () => CompanyContextProps = () => {
  const context = useContext(CompanyContext);

  if (context === undefined) {
    throw Error('useCompanyContext must be used within an CompanyContext');
  }

  return context;
};
