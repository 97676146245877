import React, { useState } from 'react';
import { Box, Divider, Typography, useTheme } from '@material-ui/core';
import { AlertMessage } from '@hrme/shared';

import CopyIcon from 'assets/icons/copy-icon.svg';

import { useStyles } from './ApplicantsAttributesMatchingContact.styles';
import { ApplicantsAttributesMatchingContactProps } from './ApplicantsAttributesMatchingContact.types';

export const ApplicantsAttributesMatchingContact: React.FC<ApplicantsAttributesMatchingContactProps> = ({ mail }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [showAlertMessage, setShowAlertMessage] = useState({ show: false, success: false });

  const handleCopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    setShowAlertMessage({ show: true, success: true });
  };

  return (
    <>
      <div className={classes.container}>
        <Typography className={classes.typographyRight}>Kontakt z kandydatem</Typography>
        <Box mb={3} />
        {/* // TODO - add phone number in b2c */}
        {/* <Typography variant="caption">Telefon</Typography>
        <Box mb={0.5} />
        <div className={classes.gridRightItem}>
          <Typography variant="subtitle1" className={classes.strong}>
            668 929 101
          </Typography>
          <img
            src={CopyIcon}
            alt="copy"
            className={classes.copy}
            onClick={() => handleCopyToClipboard('668 929 101')}
          />
        </div>
        <Box mb={2} /> */}
        <Divider light style={{ width: '100%' }} />
        <Box mb={2} />
        <Typography variant="caption">E-mail</Typography>
        <Box mb={0.5} />
        <div className={classes.gridRightItem}>
          <Typography variant="subtitle1" className={classes.strong}>
            {mail}
          </Typography>
          <img src={CopyIcon} alt="copy" className={classes.copy} onClick={() => handleCopyToClipboard(mail)} />
        </div>
      </div>
      <AlertMessage open={showAlertMessage} close={setShowAlertMessage} message={'Skopiowano'} />
    </>
  );
};
