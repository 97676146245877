"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.palette = exports.progress = exports.borders = exports.colors = void 0;
exports.colors = {
    white: '#ffffff',
    label: '#4B4B4B',
    background: '#f5f5f5',
    lightGray: '#E1E1E1',
    black: '#0E0E0E',
    greenMain: '#00c48c',
    greenLight: '#d5f2ea',
    pink: '#d8527c',
    darkPink: '#DA1B57',
    lightPink: '#F9E5EB',
    veryLightPink: '#D8D5DC',
};
exports.borders = {
    light: exports.colors.lightGray,
    main: exports.colors.veryLightPink,
};
exports.progress = {
    main: exports.colors.greenMain,
    light: exports.colors.greenLight,
    active: exports.colors.pink,
};
var primary = {
    main: exports.colors.pink,
    light: exports.colors.lightPink,
    dark: exports.colors.pink,
    contrastText: exports.colors.white,
};
var secondary = {
    main: exports.colors.pink,
    light: exports.colors.lightPink,
    dark: exports.colors.pink,
    contrastText: exports.colors.white,
};
var background = {
    default: exports.colors.background,
    paper: exports.colors.white,
};
exports.palette = {
    primary: primary,
    secondary: secondary,
    background: background,
    common: {
        white: exports.colors.white,
        black: exports.colors.black,
    },
    success: {
        light: '#eefbef',
        main: '#4caf50',
        dark: '#27ae60',
    },
    error: {
        light: '#fbeeee',
        main: '#f44336',
        dark: '#eb5757',
    },
    grey: {
        '800': '#4b4b4b',
        A200: '#a4a4a4',
    },
    divider: '#3E2950',
};
