import { makeStyles, styled } from '@material-ui/core';

import { DashboardContent } from 'ui/dashboard/dashboardContent/DashboardContent';

export const useStyles = makeStyles((theme) => ({
  gridContainer: {
    paddingTop: 1,
    background: '#E1E1E1',
    boxShadow: '0px 7px 24px rgba(0, 0, 0, 0.03)',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  money: {
    fontWeight: 'bold',
    fontSize: '40px',
  },
  finePrint: {
    fontSize: '14px',
    color: theme.palette.text.secondary,
    padding: theme.spacing(1),
  },
  divider: {
    height: '1px',
    width: '85%',
    margin: '0 auto',
    backgroundColor: theme.palette.grey[300],
  },
}));

export const FirstColumn = styled('div')({
  padding: 32,
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  background: '#fff',
  textAlign: 'left',
  fontWeight: 'bold',
  marginRight: 0.5,
});

export const NextColumn = styled(FirstColumn)({
  textAlign: 'center',
  justifyContent: 'center',
  fontWeight: 'normal',
  marginLeft: 0.5,
  marginRight: 0.5,
});

export const LastColumn = styled(NextColumn)({
  marginRight: 0,
  marginLeft: 1,
});

export const StyledDashboardContent = styled(DashboardContent)({
  paddingBottom: 0,
  marginBottom: 16,
});
