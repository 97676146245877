/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef } from 'react';
import { Box, InputLabel, Typography, useTheme } from '@material-ui/core';
import { Controller } from 'react-hook-form';

import { TextInput } from 'ui/textInput/TextInput';
import { ButtonDefault } from 'ui/button/ButtonDefault';

import { OtherProps } from './Other.types';
import { useStyles } from './Other.styles';

export const Other: React.FC<OtherProps> = ({ register, control, errors, setValue }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (event.target.files) {
      event.persist();

      setValue('file', event.target.files[0]);
      setValue('logo', URL.createObjectURL(event.target.files[0]));
    }
  };

  const handleFileOpen = () => {
    fileInputRef?.current?.click();
  };

  return (
    <>
      <InputLabel className={classes.label}>
        <Typography variant="subtitle2">dodaj logo</Typography>
      </InputLabel>
      <Controller
        control={control}
        name="logo"
        render={({ value }) => (
          <label htmlFor="logo">
            <div className={classes.draggableContainer}>
              <input
                ref={fileInputRef}
                id="logo"
                name="logo"
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
              {value && <img src={value} className={classes.image} alt="logo" />}
            </div>
          </label>
        )}
      />
      <ButtonDefault type="button" onClick={handleFileOpen}>
        dodaj logo
      </ButtonDefault>
      <Box mb={2.25} />
      <TextInput
        name={'companyDescription'}
        label={'opis firmy'}
        placeholder={'Wpisz w kilku zdaniach opis firmy'}
        valueName={'companyDescription'}
        rows={3}
        ref={register}
        error={!!errors.companyDescription}
      />
      <TextInput
        name={'predefinedMessage'}
        label={'predefiniowana wiadomość do kandydata'}
        placeholder={'Wpisz wiadomość, którą powinien otrzymać kandydat po zaproszeniu do oferty.'}
        valueName={'predefinedMessage'}
        rows={3}
        ref={register}
        error={!!errors.predefinedMessage}
      />
    </>
  );
};
