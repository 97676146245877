"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
var core_1 = require("@material-ui/core");
exports.useStyles = core_1.makeStyles(function (theme) { return ({
    container: {
        cursor: 'pointer',
        border: "1px solid #ccc",
        padding: theme.spacing(1, 2),
        maxHeight: '36px',
        borderRadius: '18px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
        },
    },
    fullWidth: {
        width: '100%',
    },
}); });
