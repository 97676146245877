import React from 'react';
import { Box, Link, Typography, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router';

import { PolicyRules } from 'ui/policyRules/policyRules';
import { TermsAndConditionsData } from 'ui/termsAndConditionsData/termsAndConditionsData';
import { PlatformHeaderMain } from 'ui/platformHeaderMain/PlatformHeaderMain';
import { AuthenticationPaths } from 'routing/authentication/AuthenticationPaths';

import { useStyles } from './termsAndPolicyWrapper.styles';

export const TermsAndPolicyWrapper: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const history = useHistory();

  const goToLogin = () => history.replace(AuthenticationPaths.login);

  const isTerms = history.location.pathname === AuthenticationPaths.termsAndConditions;

  return (
    <div className={classes.wrapper}>
      <Box mb={11} />
      <PlatformHeaderMain link={AuthenticationPaths.login} mono>
        Platforma rozwoju kariery
      </PlatformHeaderMain>
      <Box mb={6} />
      <div className={classes.contentWrapper}>{isTerms ? <TermsAndConditionsData /> : <PolicyRules />}</div>
      <Box mb={5} />
      <div className={classes.footer}>
        <Typography>Posiadasz konto?</Typography>
        <Box mr={1} />
        <Link onClick={goToLogin} className={classes.link}>
          Zaloguj się
        </Link>
      </div>
      <Box mb={10} />
    </div>
  );
};
