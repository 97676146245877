import React, { useState } from 'react';
import { Box, Typography, useTheme, withStyles } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import clsx from 'clsx';
import { HrMeTheme } from '@hrme/shared/src/theme/theme';

import { ChipDefault } from 'ui/chip/chipDefault/ChipDefault';

import { ApplicantDetailsDataProps } from './ApplicantDetailsData.types';
import { useStyles } from './ApplicantDetailsData.styles';

export const ApplicantDetailsData: React.FC<ApplicantDetailsDataProps> = ({ panel, detailItems }) => {
  const theme = useTheme<HrMeTheme>();
  const classes = useStyles(theme);

  const [expanded, setExpanded] = useState('');

  const Accordion = withStyles({
    root: {
      borderBottom: `1px solid ${theme.borders.light}`,
      boxShadow: 'none',
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
  })(MuiAccordion);

  const AccordionSummary = withStyles({
    root: {
      backgroundColor: theme.palette.background.default,
      padding: 0,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expandIcon: {
      padding: 0,
      margin: 0,
      '&$expanded': {
        transform: 'rotate(0deg)',
      },
    },
    expanded: {},
  })(MuiAccordionSummary);

  const AccordionDetails = withStyles({
    root: {
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(0, 0, 3, 0),
      display: 'flex',
      flexWrap: 'wrap',
    },
    expanded: {},
  })(MuiAccordionDetails);

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    setExpanded(event.currentTarget.id === expanded ? '' : event.currentTarget.id);
  };

  return (
    <div>
      {detailItems.map((item, key) => (
        <Accordion
          key={`${panel}${key}`}
          id={`${panel}${key}`}
          expanded={expanded === `${panel}${key}`}
          onClick={handleClick}
        >
          <AccordionSummary
            expandIcon={
              item.items.length > 0 && (
                <Typography variant="body2" className={clsx({ [classes.buttonExpanded]: expanded })}>
                  {expanded === `${panel}${key}` ? 'Zwiń (-)' : 'Rozwiń(+)'}
                </Typography>
              )
            }
          >
            <Typography variant="body2" className={classes.title}>
              {item.label} <span className={classes.greyed}>{item.counter}</span>
            </Typography>
          </AccordionSummary>
          {!!item.items.length && (
            <AccordionDetails>
              {item.items.map((chipItem, key) => (
                <React.Fragment key={key}>
                  <ChipDefault caption={chipItem.text} checked={chipItem.checked} />
                  {key !== item.items.length - 1 && <Box mr={0.5} />}
                </React.Fragment>
              ))}
            </AccordionDetails>
          )}
        </Accordion>
      ))}
    </div>
  );
};
