"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
var core_1 = require("@material-ui/core");
var palette_1 = require("../../../theme/palette");
var useStyles = function (theme) {
    var style = core_1.makeStyles(function (theme) { return ({
        button_wrapper: {
            width: '100%',
            textTransform: 'lowercase',
            fontWeight: 'normal',
            background: "linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.15) 66.67%, rgba(255, 255, 255, 0) 100%), " + palette_1.colors.pink,
            '&:hover': {
                background: "linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.15) 66.67%, rgba(255, 255, 255, 0) 100%), " + palette_1.colors.darkPink,
            },
        },
    }); });
    return style(theme);
};
exports.useStyles = useStyles;
