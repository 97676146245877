/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';
import { InputLabel, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';

import { FieldErrorWrap } from 'ui/fieldErrorWrap/FieldErrorWrap';

import { TextInputProps } from './TextInput.types';
import { useStyles } from './TextInput.styles';

export const TextInput = forwardRef(
  (
    {
      name,
      label,
      type,
      valueName,
      placeholder,
      rows,
      disabled,
      required,
      error,
      helperText,
      noBackground,
      smallText,
      ...props
    }: TextInputProps,
    ref,
  ) => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const matches = useMediaQuery('(max-width:1280px)');

    const inputStyle = clsx({
      [classes.input]: true, //always applies
      [classes.inputDisabled]: disabled,
    });

    return (
      <FieldErrorWrap showErrorBackground={!!helperText && !noBackground}>
        <div className={classes.container}>
          {label && (
            <InputLabel htmlFor={name}>
              <Typography variant="subtitle2" className={classes.label}>
                <span className={smallText && matches ? classes.smallLabel : ''}>{label}</span>
                {required && <span style={{ color: theme.palette.primary.main }}>*</span>}
              </Typography>
            </InputLabel>
          )}
          <TextField
            {...props}
            inputRef={ref}
            name={name}
            variant="outlined"
            fullWidth
            type={type}
            placeholder={placeholder}
            className={inputStyle}
            multiline={!!rows}
            rows={rows}
            disabled={disabled}
            error={error}
            helperText={helperText}
          />
        </div>
      </FieldErrorWrap>
    );
  },
);
