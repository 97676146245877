import React from 'react';
import { Button, CircularProgress, useTheme } from '@material-ui/core';

import { ButtonDefaultProps } from './ButtonDefault.types';
import { useStyles } from './ButtonDefault.styles';

const SpinnerAdornment = () => <CircularProgress style={{ color: '#ffd1df ' }} size={26} />;

export const ButtonDefault: React.FC<ButtonDefaultProps> = ({
  onClick,
  children,
  type = 'submit',
  startIcon,
  loading = false,
  disabled,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Button
      variant="contained"
      color="secondary"
      disableElevation
      size="large"
      onClick={onClick}
      className={classes.wrapper}
      type={type}
      startIcon={startIcon}
      disabled={disabled}
    >
      {!loading && children}
      {loading && <SpinnerAdornment />}
    </Button>
  );
};
