import { LoginPage } from 'app/login/loginPage/LoginPage';
import { WelcomeToHrMePage } from 'app/login/welcomeToHrMePage/WelcomeToHrMePage';
import { WelcomeRegister } from 'app/login/welcomeRegister/WelcomeRegister';
import { RegisterPage } from 'app/login/registerPage/RegisterPage';
import { ForgotPasswordPage } from 'app/login/forgotPasswordPage/ForgotPasswordPage';
import { CheckEmailPage } from 'app/login/checkEmailPage/CheckEmailPage';
import { AddCompanyForm } from 'app/company/addCompany/addCompanyForm/AddCompanyForm';
import { ChooseProfileType } from 'app/login/profile/chooseProfileType/ChooseProfileType';
import { TermsAndPolicyWrapper } from 'ui/termsAndPolicyWrapper/termsAndPolicyWrapper';

import { AuthenticationPaths } from './AuthenticationPaths';

export const AuthenticationRoutes = [
  {
    id: 0,
    path: AuthenticationPaths.login,
    component: LoginPage,
  },
  {
    id: 1,
    path: AuthenticationPaths.onboarding,
    component: WelcomeToHrMePage,
  },

  {
    id: 2,
    path: AuthenticationPaths.register,
    component: RegisterPage,
  },

  {
    id: 3,
    path: AuthenticationPaths.welcome,
    component: WelcomeRegister,
  },

  {
    id: 4,
    path: AuthenticationPaths.forgotPassword,
    component: ForgotPasswordPage,
  },

  {
    id: 5,
    path: AuthenticationPaths.checkEmail,
    component: CheckEmailPage,
  },

  {
    id: 6,
    path: AuthenticationPaths.addCompany,
    component: AddCompanyForm,
  },

  {
    id: 7,
    path: AuthenticationPaths.profile,
    component: ChooseProfileType,
  },
  {
    id: 8,
    path: AuthenticationPaths.termsAndConditions,
    component: TermsAndPolicyWrapper,
  },
  {
    id: 9,
    path: AuthenticationPaths.policy,
    component: TermsAndPolicyWrapper,
  },
];
