import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
  },
  label: {
    marginBottom: theme.spacing(1),
  },
  input: {
    marginBottom: theme.spacing(2.25),
  },
  remind: {
    display: 'block',
  },
}));
