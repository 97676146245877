import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  buttonDetails: {
    padding: theme.spacing(1, 1),
    textTransform: 'lowercase',
    cursor: 'pointer',
  },
  buttonDetailsExpanded: {
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  },
}));
