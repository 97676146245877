import React from 'react';
import { AccordionDetails, useTheme, withStyles } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { HrMeTheme } from '@hrme/shared/src/theme/theme';

import { ButtonExpand } from 'ui/buttonExpand/ButtonExpand';
import { ApplicantDetails } from 'app/applicant/applicantDetails/ApplicantDetails';
import { ApplicantDetailsHeader } from 'app/applicant/applicantDetails/applicantDetailsHeader/ApplicantDetailsHeader';
import { ChipItem } from 'app/applicant/applicantDetails/applicantDetailsHeader/ApplicantDetailsHeader.types';
import { ApplicantDetailsData } from 'app/applicant/applicantDetails/applicantDetailsData/ApplicantDetailsData';
import { DetailItem } from 'app/applicant/applicantDetails/applicantDetailsData/ApplicantDetailsData.types';
import { expYears } from 'helpers/expYears';

import { ApplicantsListAccordionProps } from './ApplicantsListAccordion.types';
import { useStyles } from './ApplicantsListAccordion.styles';

export const ApplicantsListAccordion: React.FC<ApplicantsListAccordionProps> = React.memo(
  ({ panel, caption, expanded, handleChange, children, applicant, categoryTranslator }) => {
    const theme = useTheme<HrMeTheme>();
    const classes = useStyles(theme);

    let candidatePosition = 'nieznana';
    if (applicant?.position?.position_name !== undefined) {
      candidatePosition =
        applicant.position.position_name in Object.keys(categoryTranslator)
          ? categoryTranslator[applicant.position.position_name]
          : applicant.position.position_name;
    }

    const Accordion = withStyles({
      root: {
        borderBottom: `1px solid ${theme.borders.light}`,
        boxShadow: 'none',
        '&$expanded': {
          margin: 'auto',
        },
      },
      expanded: {},
    })(MuiAccordion);

    const AccordionSummary = withStyles({
      root: {
        minHeight: 56,
        '&$expanded': {
          minHeight: 56,
        },
      },
      content: {
        cursor: 'default',
        '&$expanded': {
          margin: '12px 0',
        },
      },
      expanded: {},
    })(MuiAccordionSummary);

    const headerItems = [
      {
        label: 'Główna technologia:',
        visible: true,
        items: [
          {
            text: applicant?.mainTechnology?.text,
            checked: applicant?.mainTechnology?.checked,
          },
        ],
      },
      {
        label: 'Lata doświadczenia:',
        visible: !!applicant?.yearsOfExperience?.text,
        items: [
          {
            text: expYears(applicant?.yearsOfExperience?.text),
            checked: applicant?.yearsOfExperience?.checked,
          },
        ],
      },
      {
        label: 'Specjalizacja:',
        visible: !!candidatePosition,
        items: [
          {
            text: candidatePosition,
            checked: undefined,
          },
        ],
      },
      {
        label: 'Język:',
        visible: !!applicant?.languages?.length,
        items: applicant?.languages?.map((lang) => ({ text: lang.text, checked: lang.checked })),
      },
    ];

    const detailItems = (): DetailItem[] => {
      const result: DetailItem[] = [];

      if (
        !applicant ||
        !applicant.jobOfferDetails ||
        !applicant.jobOfferDetails.technology ||
        !applicant.customerDetails.technology
      )
        return result;

      const { mustHave, niceToHave, willLearn } = applicant.jobOfferDetails.technology;

      if (mustHave) {
        const items = [...mustHave];
        const count = items.reduce((accumulator, currentValue) => accumulator + (currentValue.checked ? 1 : 0), 0);
        result.push({
          label: 'Dopasowane - umiejętności obowiązkowe',
          counter: `(${count}/${items.length})`,
          items,
        });
      }

      if (niceToHave) {
        const items = [...niceToHave];
        const count = items.reduce((accumulator, currentValue) => accumulator + (currentValue.checked ? 1 : 0), 0);
        result.push({
          label: 'Dopasowane - umiejętności mile widziane',
          counter: `(${count}/${items.length})`,
          items,
        });
      }

      if (willLearn) {
        const items = [...willLearn];
        const count = items.reduce((accumulator, currentValue) => accumulator + (currentValue.checked ? 1 : 0), 0);
        result.push({
          label: 'Dopasowane - umiejętności, których kandydat się nauczy',
          counter: `(${count}/${items.length})`,
          items,
        });
      }

      const { known, learning, wantToLearn } = applicant.customerDetails.technology;

      if (known) {
        const items = new Array<ChipItem>();
        known.forEach((e: string) => {
          items.push({ text: e, check: null } as ChipItem);
        });
        result.push({
          label: 'Umiejętności kandydata',
          counter: `(${items.length})`,
          items,
        });
      }

      if (learning) {
        const items = new Array<ChipItem>();
        learning.forEach((e: string) => {
          items.push({ text: e, check: null } as ChipItem);
        });
        result.push({
          label: 'Umiejętności, których kandydat się uczy',
          counter: `(${items.length})`,
          items,
        });
      }

      if (wantToLearn) {
        const items = new Array<ChipItem>();
        wantToLearn.forEach((e: string) => {
          items.push({ text: e, check: null } as ChipItem);
        });
        result.push({
          label: 'Umiejętności, w których kandydat chce się rozwijać',
          counter: `(${items.length})`,
          items,
        });
      }

      return result;
    };

    return (
      <Accordion expanded={expanded}>
        <AccordionSummary
          className={classes.expandIcon}
          expandIcon={
            <ButtonExpand
              expanded={expanded}
              caption={caption}
              handleClick={() => {
                if (expanded) handleChange('');
                else handleChange(panel);
              }}
            />
          }
        >
          {children}
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <ApplicantDetails>
            <ApplicantDetailsHeader headerItems={headerItems} />
            {expanded && <ApplicantDetailsData panel={panel} detailItems={detailItems()} />}
          </ApplicantDetails>
        </AccordionDetails>
      </Accordion>
    );
  },
);

ApplicantsListAccordion.displayName = 'ApplicantsListAccordion';
