import { makeStyles } from '@material-ui/core';
import { HrMeTheme } from '@hrme/shared/src/theme/theme';

export const useStyles = makeStyles<HrMeTheme>((theme) => ({
  container: {
    alignItems: 'start',
    border: `1px solid ${theme.palette.background.default}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: theme.spacing(4),
  },
  divider: {
    width: '100%',
  },
  title: {
    fontSize: '18px',
    fontWeight: 600,
    textAlign: 'left',
  },
}));
