import { ApplicantsAttributesMatching } from 'app/applicant/applicantsAttributesMatching/applicantsAttributesMatching';
import { ApplicantsListFromCareerPaths } from 'app/applicant/applicantsListFromCareerPaths/ApplicantsListFromCareerPaths';
import { ApplicantsListFromJobOffers } from 'app/applicant/applicantsListFromJobOffers/ApplicantsListFromJobOffers';
import { AddCompanyConfirmationForm } from 'app/company/addCompany/addCompanyConfirmationForm/AddCompanyConfirmationForm';
import { AddCompanyForm } from 'app/company/addCompany/addCompanyForm/AddCompanyForm';
import { Dashboard } from 'app/dashboard/Dashboard';
import { ErrorMessage } from 'app/errorMessage/ErrorMessage';
import { InvoiceScreen } from 'app/invoiceScreen/InvoiceScreen';
import { AddJobOfferContainerRHF } from 'app/jobOffer/addJobOfferRHF/addJobOfferContainerRHF/AddJobOfferContainerRHF';
import { JobOfferActivated } from 'app/jobOffer/jobOfferActivated/JobOfferActivated';
import { JobOffers } from 'app/jobOffer/jobOffers/JobOffers';
import { JobOfferDetail } from 'app/jobOfferDetail/JobOfferDetail';
import { ChooseProfileType } from 'app/login/profile/chooseProfileType/ChooseProfileType';
import { CompanyProfiles } from 'app/login/profile/companyProfiles/CompanyProfiles';
import { EnterNewPasswordFormWrapper } from 'app/login/resetPasswordPage/enterNewPasswordFormWrapper/EnterNewPasswordFormWrapper';
import { PasswordResetConfirmationFormWrapper } from 'app/login/resetPasswordPage/passwordResetConfirmationFormWrapper/PasswordResetConfirmationFormWrapper';
import { PaymentConfirmed } from 'app/paymentVariantScreen/paymentConfirmed/PaymentConfirmed';
import { PaymentHistory } from 'app/paymentVariantScreen/paymentHistory/PaymentHistory';
import { PaymentVariantScreen } from 'app/paymentVariantScreen/PaymentVariantScreen';
import { ProjectTheme } from 'app/root/tempRoutingToComponents/projectTheme/ProjectTheme';
import { EditCompanyPageContainer } from 'app/user/editCompanyPage/EditCompanyPageContainer';
import { EditProfilePage } from 'app/user/editProfilePage/EditProfilePage';
import { TermsAndConditionsData } from 'ui/termsAndConditionsData/termsAndConditionsData';
import DashboardIcon from 'assets/icons/menu/pie_chart_outline.svg';
import CandidatesIcon from 'assets/icons/menu/user_pin.svg';
import OffersIcon from 'assets/icons/menu/note.svg';
import PaymentsIcon from 'assets/icons/menu/credit_card.svg';
import PaymentHistoryIcon from 'assets/icons/menu/clock.svg';
import CompanyProfilesIcon from 'assets/icons/menu/id_card.svg';
import SettingsIcon from 'assets/icons/menu/union.svg';

import { MainPaths } from './MainPaths';

export const MainRoutes = [
  {
    id: 0,
    path: MainPaths.theme,
    component: ProjectTheme,
    description: 'Theme Projektu',
  },
  {
    id: 1,
    path: MainPaths.dashboard,
    component: Dashboard,
    description: 'Dashboard',
  },
  {
    id: 2,
    path: MainPaths.newPassword,
    component: EnterNewPasswordFormWrapper,
    description: 'Nowe Hasło',
  },
  {
    id: 3,
    path: MainPaths.passwordResetConfirm,
    component: PasswordResetConfirmationFormWrapper,
    description: 'Reset Hasła - Potwierdzenie',
  },
  {
    id: 4,
    path: MainPaths.addComapnyConfirmation,
    component: AddCompanyConfirmationForm,
    description: 'Potwierdzenie Dodania Firmy',
  },
  {
    id: 5,
    path: MainPaths.addCompany,
    component: AddCompanyForm,
    description: 'Nowa Firma',
  },
  {
    id: 6,
    path: MainPaths.addJobOffer,
    component: AddJobOfferContainerRHF,
    description: 'Nowa Oferta Pracy',
  },
  {
    id: 7,
    path: MainPaths.editProfile,
    component: EditProfilePage,
    description: 'Edycja Profilu',
  },
  {
    id: 8,
    path: MainPaths.editCompany,
    component: EditCompanyPageContainer,
    description: 'Edycja Firmy',
  },
  {
    id: 9,
    path: MainPaths.jobOfferActivated,
    component: JobOfferActivated,
    description: 'Oferty Pracy Aktywowana',
  },
  {
    id: 10,
    path: MainPaths.paymentVariants,
    component: PaymentVariantScreen,
    description: 'Pakiety do kupienia',
  },
  {
    id: 11,
    path: MainPaths.invoice,
    component: InvoiceScreen,
    description: 'Faktura',
  },
  {
    id: 12,
    path: MainPaths.paymentConfirmed,
    component: PaymentConfirmed,
    description: 'Potwierdzenie Płatności',
  },
  {
    id: 13,
    path: MainPaths.paymentHistory,
    component: PaymentHistory,
    description: 'Historia Płatności',
  },
  {
    id: 14,
    path: MainPaths.error,
    component: ErrorMessage,
    description: 'Error',
  },
  {
    id: 15,
    path: MainPaths.jobOffers,
    component: JobOffers,
    description: 'Oferty Pracy',
  },
  {
    id: 16,
    path: MainPaths.jobOfferDetail,
    component: JobOfferDetail,
    description: 'Szczegóły Oferty Pracy',
  },
  {
    id: 17,
    path: MainPaths.applicantsListFromJobOffers,
    component: ApplicantsListFromJobOffers,
    description: 'Lista kandydatów (oferty pracy)',
  },
  {
    id: 18,
    path: MainPaths.applicantsListFromCareerPaths,
    component: ApplicantsListFromCareerPaths,
    description: 'Lista kandydatów (ściezki kariery)',
  },
  {
    id: 19,
    path: MainPaths.applicantsAttributesMatching,
    component: ApplicantsAttributesMatching,
    description: 'Matching Kandydata',
  },
  {
    id: 20,
    path: MainPaths.chooseProfileType,
    component: ChooseProfileType,
    description: 'Wybierz profil',
  },
  {
    id: 21,
    path: MainPaths.companyProfles,
    component: CompanyProfiles,
    description: 'Profile',
  },
  {
    id: 22,
    path: MainPaths.terms,
    component: TermsAndConditionsData,
    description: 'Regulamin',
  },
];

export const MenuRoutes = [
  {
    id: 0,
    icon: DashboardIcon,
    path: MainPaths.dashboard,
    description: 'Dashboard',
  },
  {
    id: 1,
    icon: CandidatesIcon,
    path: [MainPaths.applicantsListFromJobOffers, MainPaths.applicantsListFromCareerPaths],
    description: ['Dopasowani', 'W trakcie nauki'],
    group: 'Kandydaci',
  },
  {
    id: 2,
    icon: OffersIcon,
    path: MainPaths.jobOffers,
    description: 'Oferty Pracy',
  },
  {
    id: 3,
    icon: PaymentsIcon,
    path: MainPaths.paymentVariants,
    description: 'Płatności',
  },
  {
    id: 4,
    icon: PaymentHistoryIcon,
    path: MainPaths.paymentHistory,
    description: 'Historia Opłat',
  },
  {
    id: 5,
    icon: CompanyProfilesIcon,
    path: MainPaths.companyProfles,
    description: 'Profile firmowe',
  },
  {
    id: 6,
    icon: SettingsIcon,
    path: MainPaths.editProfile,
    description: 'Ustawienia',
  },
];
