import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';

import { CompanyProfileBoxInfoProps } from './CompanyProfileBoxInfo.types';
import { useStyles } from './CompanyProfileBoxInfo.styles';

export const CompanyProfileBoxInfo: React.FC<CompanyProfileBoxInfoProps> = ({ logotype, name, activeOffers }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.container}>
      <div className={classes.imageBox}>
        {logotype && <img src={logotype} alt="company's logo" className={classes.img} />}
      </div>
      <Box mr={2} />
      <div className={classes.companyInfo}>
        <Typography className={classes.companyName}>{name}</Typography>
        <Typography variant="body2" className={classes.offers}>
          {!activeOffers && 'brak aktywnych ogłoszeń'}
          {activeOffers === 1 && `${activeOffers} aktywne ogłoszenie`}
          {!!activeOffers && activeOffers > 1 && `${activeOffers} aktywnych ogłoszeń`}
        </Typography>
      </div>
    </div>
  );
};
