import React, { useEffect, useMemo, useState } from 'react';
import { Box, CircularProgress, Modal, Typography, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';

import { DashboardContentContainer } from 'ui/dashboard/dashboardContentContainer/DashboardContentContainer';
import { DashboardContent } from 'ui/dashboard/dashboardContent/DashboardContent';
import { ButtonAdd } from 'ui/buttonAdd/ButtonAdd';
import { JobOffersItem } from 'app/jobOffer/jobOffers/jobOffersItem/JobOffersItem';
import { useJobOfferContext } from 'hooks/useJobOfferContext/useJobOfferContext';
import { MainLayout } from 'ui/mainLayout/MainLayout';
import { MainPaths } from 'routing/main/MainPaths';
import { useCompanyContext } from 'hooks/useCompanyContext/useCompanyContext';

import { useStyles } from './JobOffers.styles';

export const JobOffers: React.FC = () => {
  const theme = useTheme();
  const { jobOffers, fetchJobOffers, currentSubscription } = useJobOfferContext();

  const { selectedCompany } = useCompanyContext();
  const classes = useStyles(theme);
  const [openModal, setOpenModal] = useState(false);

  const activeOffers = currentSubscription?.activeOffers;

  useEffect(() => {
    const fetchData = async () => {
      await fetchJobOffers();
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOffers]);

  const selectedCompanyOffers = useMemo(() => {
    return jobOffers.filter((jobOffer) => jobOffer.companyId === selectedCompany?.docRef);
  }, [selectedCompany, jobOffers]);

  return (
    <MainLayout>
      <DashboardContentContainer>
        <Modal open={openModal} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress color="primary" />
        </Modal>
        <Box mb={3} />
        <DashboardContent hasBottomPadding={false}>
          <Box className={classes.dashboardContent}>
            <Box mb={6} />
            <Box className={classes.titleBar}>
              <Box className={classes.titles}>
                <Typography variant="h2" className={classes.title}>
                  Oferty pracy
                </Typography>
                <Box mb={1} />
                <Typography variant="body2" className={classes.subtitle}>
                  Sprawdź dodane oferty pracy
                </Typography>
              </Box>
              <Box>
                <ButtonAdd startIcon={<AddIcon />}>
                  <Link to={MainPaths.addJobOffer} className={classes.newOfferButtonText}>
                    dodaj nową ofertę
                  </Link>
                </ButtonAdd>
              </Box>
            </Box>
            <Box mt={6} />
          </Box>
          <Box className={classes.box} />
          <JobOffersItem offers={selectedCompanyOffers} setOpenModal={setOpenModal} />
        </DashboardContent>
      </DashboardContentContainer>
    </MainLayout>
  );
};
