import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';

import { TextInput } from 'ui/textInput/TextInput';
import { DividerWithText } from 'ui/dividerWithText/DividerWithText';
import { TwoItemsRow, useStyles } from '../EditCompanyPage.styles';
import { TextSelect } from 'ui/textSelect/TextSelect';
import { countryCodes } from 'helpers/countryCodes';

import { Other } from './components/Other';
import { SocialMedia } from './components/SocialMedia';
import { EditCompanyFormProps } from './EditCompanyForm.types';

export const EditCompanyForm: React.FC<EditCompanyFormProps> = ({ methods, register, errors }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <>
      <Typography variant="h6">Edycja konto firmowe</Typography>
      <Box mb={2} />
      <TextInput
        valueName={'companyName'}
        name={'companyName'}
        label={'nazwa firmy'}
        ref={register}
        required={true}
        placeholder={'hr-me.co'}
        helperText={errors.companyName ? errors.companyName.message : null}
        error={!!errors.companyName}
        noBackground
      />
      <TextInput
        ref={register}
        valueName={'nip'}
        name={'nip'}
        label={'nip'}
        required={true}
        placeholder={'0000000000'}
        noBackground
      />
      <TextInput
        valueName={'companyAddressLine1'}
        name={'companyAddressLine1'}
        label={'ulica'}
        placeholder={'Wpisz adres'}
        required={true}
        ref={register}
        helperText={errors.companyAddressLine1 ? errors.companyAddressLine1.message : null}
        error={!!methods.errors.companyAddressLine1}
        noBackground
      />
      <TextSelect
        name={'country'}
        label={'kraj'}
        control={methods.control}
        required
        helperText={errors.country?.message}
        error={!!errors.country}
        items={countryCodes}
      />
      <TwoItemsRow>
        <div className={classes.city}>
          <TextInput
            name={'city'}
            label={'miasto'}
            placeholder={'Wpisz nazwę miasta'}
            valueName={'city'}
            required={true}
            ref={register}
            helperText={errors.city ? errors.city.message : null}
            error={!!methods.errors.city}
            noBackground
          />
        </div>
        <Box width={24} />
        <div className={classes.postal}>
          <TextInput
            name={'postcode'}
            label={'kod pocztowy'}
            placeholder={'00-000'}
            valueName={'postcode'}
            required={true}
            ref={register}
            helperText={errors.postcode ? errors.postcode.message : null}
            error={!!methods.errors.postcode}
            noBackground
          />
        </div>
      </TwoItemsRow>
      <TextInput
        valueName={'state'}
        name={'state'}
        label={'województwo'}
        placeholder={'Wpisz województwo'}
        required={true}
        ref={register}
        helperText={errors.state ? errors.state.message : null}
        error={!!methods.errors.state}
        noBackground
      />
      <TextSelect
        name={'companySize'}
        label={'wielkość firmy'}
        required={true}
        control={methods.control}
        helperText={errors.companySize ? errors.companySize.message : undefined}
        error={!!methods.errors.companySize}
        items={[
          { value: 'do 10 osób', label: 'do 10 osób' },
          { value: '10-50 osób', label: '10-50 osób' },
          { value: '50-200 osób', label: '50-200 osób' },
          { value: '200-1000 osób', label: '200-1000 osób' },
          { value: 'powyżej 1000 osób', label: 'powyżej 1000 osób' },
        ]}
      />
      <TextInput
        valueName={'industry'}
        name={'industry'}
        label={'sektor działalności'}
        placeholder={'np. IT'}
        required={true}
        helperText={errors.industry ? errors.industry.message : undefined}
        error={!!methods.errors.industry}
        ref={register}
        noBackground
      />
      <DividerWithText>Social Media</DividerWithText>
      <SocialMedia errors={errors} methods={methods} register={register} />
      <DividerWithText>Firma</DividerWithText>
      <Other errors={errors} setValue={methods.setValue} control={methods.control} register={register} />
    </>
  );
};
