import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useAuthContext } from 'hooks/useAuthContext/useAuthContext';
import { ButtonDefault } from 'ui/button/ButtonDefault';
import { LoginFlowLayout } from 'ui/loginFlowLayout/LoginFlowLayout';

export const PasswordResetConfirmationFormWrapper: React.FC = () => {
  const { logoutUser } = useAuthContext();
  const history = useHistory();

  const onSubmit = async () => {
    await logoutUser();
    history.replace('/');
  };

  return (
    <LoginFlowLayout>
      <Typography variant="h4">Twoje hasło zostało zresetowane</Typography>
      <Box mb={3} />
      <Typography variant="body2">Możesz już zalogować się do swojego konta podając nowe hasło.</Typography>
      <Box mb={3} />
      <ButtonDefault onClick={onSubmit}>zaloguj się</ButtonDefault>
    </LoginFlowLayout>
  );
};
