import React, { useState } from 'react';
import { Box, Button, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

import { CircularProgressWithLabel } from 'ui/circularProgressWithLabel/CircularProgressWithLabel';
import { InvitationStatus } from 'ui/dashboard/dashboardApplicantsPanel/DashboardApplicantsPanel.types';
import { useJobOfferContext } from 'hooks/useJobOfferContext/useJobOfferContext';
import { MainPaths } from 'routing/main/MainPaths';
import { useCandidateContext } from 'hooks/candidate/useCandidateContex';

import { DashboardApplicantsPanelDetailsProps } from './DashboardApplicantsPanelDetails.types';
import { useStyles } from './DashboardApplicantsPanelDetails.styles';

export const DashboardApplicantsPanelDetails: React.FC<DashboardApplicantsPanelDetailsProps> = React.memo(
  ({ applicant, status, index, inAccordion = false, children, careerPath }) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const { inviteUserToJobOffer, setCandidateStatusInJobOffers } = useJobOfferContext();
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [statusState, setStatus] = useState<InvitationStatus>(status);
    const history = useHistory();
    const { isPopupVisible, showPopup, setCandidates } = useCandidateContext();

    const classApplicant = clsx({
      [classes.applicant]: true,
      [classes.applicantInAccordion]: inAccordion,
    });

    if (!applicant) return null;

    return (
      <div className={classApplicant} key={index}>
        <div className={classes.progress}>
          <CircularProgressWithLabel value={careerPath ? applicant.potentialScore : applicant.generalScore} />
        </div>
        <div>
          <Typography variant="body2" className={classes.label}>
            {applicant.name}
          </Typography>
          <Typography variant="subtitle1" className={classes.description}>
            {applicant.candidateId}
          </Typography>
        </div>
        <div>
          <Typography variant="body2" className={classes.label}>
            {applicant.offerName}
          </Typography>
          <Typography variant="subtitle1" className={classes.description}>
            {applicant.offerId}
          </Typography>
        </div>
        <div className={classes.status}>
          <div
            className={clsx({
              [classes.circle]: true,
              [classes.circleWaits]: statusState === InvitationStatus.WAITS,
              [classes.circleSent]: statusState === InvitationStatus.SENT,
              [classes.circleAccepted]: statusState === InvitationStatus.ACCEPTED,
              [classes.circlePaid]: statusState === InvitationStatus.PAID,
              [classes.circleRejected]: statusState === InvitationStatus.REJECTED,
            })}
          />
          <Box mr={1} />
          <Typography variant="subtitle1" className={classes.statusTypography}>
            {statusState === InvitationStatus.WAITS ? 'Oczekuje' : null}
            {statusState === InvitationStatus.SENT ? 'Wysłano' : null}
            {statusState === InvitationStatus.ACCEPTED ? 'Zaakceptowano' : null}
            {statusState === InvitationStatus.PAID ? 'Zapłacono' : null}
            {statusState === InvitationStatus.REJECTED ? 'Odrzucono' : null}
          </Typography>
        </div>
        <div className={classes.buttonContainer}>
          {statusState === InvitationStatus.WAITS && (
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disableElevation
              disabled={buttonDisabled}
              className={classes.buttonSend}
              onClick={async () => {
                setButtonDisabled(true);
                const inviteUser = await inviteUserToJobOffer({
                  jobOfferId: applicant.offerId,
                  candidateId: applicant.candidateId,
                  candidatesCollection: careerPath ? 'potential_candidates' : 'matched_candidates',
                });
                if (inviteUser.success) {
                  setStatus(InvitationStatus.SENT);
                  setCandidateStatusInJobOffers(applicant.offerId, InvitationStatus.SENT, applicant.candidateId);
                }
              }}
            >
              <Typography variant="body2">{buttonDisabled ? 'Proszę czekać' : 'zaproś'}</Typography>
            </Button>
          )}
          {statusState === InvitationStatus.ACCEPTED && (
            <Button
              startIcon={<></>}
              fullWidth
              className={classes.buttonAccepted}
              onClick={(event) => {
                console.log(event);
                setCandidates({
                  candidateId: applicant.candidateId,
                  offerId: applicant.offerId,
                  b2bUserId: applicant.b2bUserId,
                  score: applicant.generalScore,
                  careerPath: careerPath,
                });
                showPopup(!isPopupVisible);
              }}
            >
              <Typography variant="body2">odblokuj</Typography>
            </Button>
          )}
          {statusState === InvitationStatus.PAID && (
            <Button
              startIcon={<></>}
              fullWidth
              variant="contained"
              className={classes.buttonPaid}
              onClick={(event) => {
                event.stopPropagation();
                history.push({
                  pathname: MainPaths.applicantsAttributesMatching,
                  state: {
                    candidateId: applicant.candidateId,
                    offerId: applicant.offerId,
                    b2bUserId: applicant.b2bUserId,
                    score: applicant.generalScore,
                    careerPath: careerPath,
                  },
                });
              }}
            >
              <Typography variant="body2">zobacz profil</Typography>
            </Button>
          )}
          {statusState === InvitationStatus.SENT && <Typography className={classes.messageSent}>wysłano</Typography>}
          {statusState === InvitationStatus.REJECTED && (
            <Typography className={classes.messageSent}>odrzucono</Typography>
          )}
          <Box mr={2} />
          {children}
        </div>
      </div>
    );
  },
);

DashboardApplicantsPanelDetails.displayName = 'DashboardApplicantsPanelDetails';
