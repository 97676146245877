/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { CompanySelectInput } from 'ui/companySelectInput/CompanySelectInput';
import { useCompanyContext } from 'hooks/useCompanyContext/useCompanyContext';
import { MainPaths } from 'routing/main/MainPaths';

import { AddFirm, CompanyWrapper, useStyles } from './ChangeCompanySelect.styles';

export const ChangeCompanySelect: React.FC = () => {
  const { companies, selectedCompany, updateSelectedCompany } = useCompanyContext();
  const theme = useTheme();
  const classes = useStyles(theme);
  if (selectedCompany === undefined) return <div />;

  return (
    <div className={classes.wrapper}>
      <Typography variant="h6">Firma</Typography>
      <Box mr={4} />
      <CompanyWrapper>
        <CompanySelectInput
          handleChange={(e) => updateSelectedCompany(e.target.value)}
          items={companies}
          name="company"
          value={selectedCompany}
        />
        <AddFirm href={MainPaths.addCompany}>
          <Add />
          dodaj nową firmę
        </AddFirm>
      </CompanyWrapper>
    </div>
  );
};
