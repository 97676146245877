import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';

import { IconChecked } from 'ui/icon/iconChecked/IconChecked';
import { IconUnchecked } from 'ui/icon/iconUnchecked/IconUnchecked';

import { useStyles } from './ApplicantDetailsHeader.styles';
import { ApplicantDetailsHeaderProps } from './ApplicantDetailsHeader.types';

export const ApplicantDetailsHeader: React.FC<ApplicantDetailsHeaderProps> = React.memo(({ headerItems }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.header}>
      {headerItems.map((item, key) => (
        <>
          {item.visible && (
            <div key={`ApplicantDetailsHeader-${key}`} className={classes.headerItem}>
              <Typography variant="body2" className={classes.headerItemLabel}>
                {item.label}
              </Typography>
              <Box mr={2} />
              {item.items.map((item, key) => (
                <div key={`ApplicantDetailsHeaderItem-${key}`} style={{ display: 'flex', flexDirection: 'row' }}>
                  {key !== 0 && <Box mr={2} />}
                  <Typography>{item.text}</Typography>
                  {item.checked !== undefined && (
                    <>
                      <Box mr={0.5} />
                      {item.checked ? <IconChecked /> : <IconUnchecked />}
                    </>
                  )}
                </div>
              ))}
            </div>
          )}
        </>
      ))}
    </div>
  );
});

ApplicantDetailsHeader.displayName = 'ApplicantDetailsHeader';
