import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'start',
    border: `1px solid ${theme.palette.background.default}`,
    padding: theme.spacing(3),
  },
  line: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  strong: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left',
  },
  text: {
    textAlign: 'left',
  },
}));
