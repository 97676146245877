import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  dashboardContent: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    borderRadius: '5px',
  },
  titleBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titles: {},
  title: {
    textAlign: 'left',
  },
  subtitle: {
    textAlign: 'left',
    color: theme.palette.grey.A200,
  },
  box: {
    backgroundColor: theme.palette.background.default,
  },
  messageContainer: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'row',
  },
  image: {
    width: `calc(516px - ${theme.spacing(4)}px)`,
    paddingTop: theme.spacing(8),
    paddingLeft: theme.spacing(4),
    textAlign: 'left',
  },
  message: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'start',
  },
  buttonSend: {
    padding: theme.spacing(1, 3),
    textTransform: 'lowercase',
  },
  link: {
    textDecoration: 'none',
    width: 'max-content',
  },
}));
