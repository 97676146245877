"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StyledButton = exports.useStyles = void 0;
var core_1 = require("@material-ui/core");
exports.useStyles = core_1.makeStyles(function (theme) { return ({
    header: {
        width: '100%',
        textAlign: 'left',
    },
    subheader: {
        width: '100%',
        textAlign: 'left',
    },
    buttonBusiness: {
        border: '1px solid #e6e6e6',
    },
    link: {
        '&:hover': {
            textDecoration: 'none',
        },
    },
}); });
exports.StyledButton = core_1.styled(core_1.Button)({
    padding: '10px 40px',
    textTransform: 'lowercase',
    width: '100%',
});
