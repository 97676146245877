import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  regularLink: {
    color: theme.palette.primary.main,
  },

  boldLink: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
}));
