import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  text: {
    textAlign: 'left',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '150%',
  },
}));
