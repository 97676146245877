import { CircularProgress, CssBaseline, useTheme } from '@material-ui/core';

import { useCompanyContext } from 'hooks/useCompanyContext/useCompanyContext';
import { Navbar } from 'ui/navbar/Navbar';

import { MainLayoutProps } from './MainLayout.types';
import { useStyles } from './MainLayout.styles';
import { LeftMenu } from './LeftMenu';

export const MainLayout = ({ children }: MainLayoutProps): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { loading } = useCompanyContext();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Navbar />
      {!loading && <LeftMenu />}

      <main className={classes.content} id="id-main">
        {loading ? <CircularProgress /> : children}
      </main>
    </div>
  );
};
