import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import { ButtonDefault } from '@hrme/shared';

import { useStyles } from './PaymentQuestions.styles';

export const PaymentQuestions: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const contactUs = () => (window.location.href = 'mailto:hello@hr-me.co');

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.itemContainter}>
        <Typography variant="h3" className={classes.text}>
          Masz pytania do oferty?
        </Typography>
        <Typography variant="h4" className={classes.subTitle}>
          Skontaktuj się z nami
        </Typography>
        <Box className={classes.buttonContainer}>
          <ButtonDefault onClick={contactUs}>Kontakt</ButtonDefault>
        </Box>
        <Box mb={6} />
      </Box>
      <Box className={classes.itemContainter}>
        <Typography variant="h3" className={classes.text}>
          Potrzebujesz większego pakietu?
        </Typography>
        <Typography variant="h4" className={classes.subTitle}>
          Napisz i ustalmy indywidualną wycenę dla Twojej firmy
        </Typography>
        <Box className={classes.buttonContainer}>
          <ButtonDefault onClick={contactUs}>kontakt</ButtonDefault>
        </Box>
        <Box mb={6} />
      </Box>
    </Box>
  );
};
