import { makeStyles } from '@material-ui/core';
import { HrMeTheme } from '@hrme/shared/src/theme/theme';

export const useStyles = makeStyles<HrMeTheme>((theme) => ({
  container: {
    width: 307,
    padding: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    border: `1px solid ${theme.borders.main}`,
  },
  title: {
    fontWeight: 600,
  },
  subtitle: {
    color: theme.palette.grey[800],
  },
}));
