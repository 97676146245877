import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    textAlign: 'justify',
  },
  label: {
    fontWeight: 400,
    color: theme.palette.grey[800],
  },
  subtitle: {},
  tags: {
    display: 'flex',
    flexDirection: 'row',
  },
  textField: {},
  root: {
    minHeight: 64,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(1.5, 0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1),
    borderRadius: 3,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight,
  },
}));
