import { makeStyles } from '@material-ui/core';
import { HrMeTheme } from '@hrme/shared/src/theme/theme';

export const useStyles = makeStyles<HrMeTheme>((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'start',
    paddingBottom: theme.spacing(3),
    borderBottom: `1px solid ${theme.borders.light}`,
  },
  headerItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  headerItemLabel: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));
