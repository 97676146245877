import { CustomerSummary } from 'context/customer/CustomerContext.types';
import { InvitationStatus } from 'ui/dashboard/dashboardApplicantsPanel/DashboardApplicantsPanel.types';

export const findNameByStatus = (customer: CustomerSummary, status: InvitationStatus): string => {
  if (status === InvitationStatus.PAID) return customer.userName ? customer.userName : 'brak danych';
  if (status === InvitationStatus.ACCEPTED) return '(oczekuje na odblokowanie)';
  if (status === InvitationStatus.SENT) return '(oczekuje na akceptację)';
  if (status === InvitationStatus.WAITS) return '(oczekuje na zaproszenie)';
  if (status === InvitationStatus.REJECTED) return '(odrzucił zaproszenie)';
  return '';
};
