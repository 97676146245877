"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
var core_1 = require("@material-ui/core");
exports.useStyles = core_1.makeStyles(function (theme) { return ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    border: {
        borderBottom: '1px solid #d8d5dc',
        width: '100%',
    },
    content: {
        textAlign: 'center',
        whiteSpace: 'nowrap',
        padding: theme.spacing(0.5, 2),
        fontWeight: 600,
        color: theme.palette.text.primary,
    },
    subtitle: {
        fontSize: '12px',
        maxWidth: 460,
        textAlign: 'center',
    },
}); });
