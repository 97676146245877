"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
var core_1 = require("@material-ui/core");
var palette_1 = require("../../../theme/palette");
exports.useStyles = core_1.makeStyles(function (theme) { return ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'start',
        width: '100%',
        flex: 1,
    },
    label: {
        textAlign: 'left',
        marginBottom: 8,
        color: palette_1.colors.label,
    },
    input: {
        width: '100%',
        borderColor: palette_1.colors.pink,
        '& .MuiOutlinedInput-root': {
            background: palette_1.colors.white,
            '& fieldset': {
                borderColor: '#D8D5DC',
            },
            '&:hover fieldset': {
                borderColor: palette_1.colors.pink,
            },
            '&.Mui-focused fieldset': {
                borderColor: palette_1.colors.pink,
            },
        },
    }
}); });
