/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Box, Fade, Typography, useTheme } from '@material-ui/core';

import { DefaultLayout } from 'ui/defaultLayout/DefaultLayout';
import { ButtonDefault } from 'ui/button/ButtonDefault';
import { Message } from 'ui/message/Message';
import { MainLayout } from 'ui/mainLayout/MainLayout';
import { MainPaths } from 'routing/main/MainPaths';
import { useUserContext } from 'hooks/useUserContext/useUserContext';
import { UserProfileType } from 'context/user/UserContext.types';
import { useCompanyContext } from 'hooks/useCompanyContext/useCompanyContext';
import { ConfirmationModal } from 'ui/confirmationModal/ConfirmationModal';

import { EditNavigationLink, useStyles } from './EditCompanyPage.styles';
import { ChangeCompanySelect } from './changeCompanySelect/ChangeCompanySelect';
import { EditCompanyForm } from './editCompanyForm/EditCompanyForm';
import { EditCompanyPageProps } from './EditCompanyPage.types';

export const EditCompanyPage: React.FC<EditCompanyPageProps> = ({
  loading,
  formSuccess,
  formError,
  methods,
  onSubmit,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { user } = useUserContext();
  const { selectedCompany, deleteCompany, companies } = useCompanyContext();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const isAgencyProfile = user?.profileType === UserProfileType.AGENCY;

  return (
    <MainLayout>
      <DefaultLayout headerText="Edycja profilu">
        <div className={classes.narrowPanel}>
          <Typography variant="h6">Nawigacja</Typography>

          <Box mb={2} />
          <EditNavigationLink to={MainPaths.editProfile}>Edytuj profil</EditNavigationLink>
          <EditNavigationLink to={MainPaths.editCompany} className={classes.linkActive}>
            Edytuj konto firmowe
          </EditNavigationLink>
        </div>
        <div className={classes.widePanel}>
          {isAgencyProfile && (
            <>
              <div className={classes.wideContainer}>
                <ChangeCompanySelect />
              </div>
              <Box mb={4} />
            </>
          )}
          <div className={classes.wideContainer}>
            <form
              onKeyDown={(e) => {
                if (e.key === 'Enter') e.preventDefault();
              }}
            >
              <EditCompanyForm methods={methods} errors={methods.errors} register={methods.register} />
              <ButtonDefault loading={loading} onClick={methods.handleSubmit(onSubmit)}>
                zapisz zmiany
              </ButtonDefault>

              <Fade style={{ height: formSuccess ? 'auto' : 0 }} in={formSuccess}>
                <Box>
                  <Box height={26} />
                  <Message text="Pomyślnie zapisano dane" type="success" />
                </Box>
              </Fade>
              <Fade style={{ height: formError ? 'auto' : 0 }} in={formError}>
                <Box>
                  <Box height={26} />
                  <Message text="Wystąpił błąd podczas zapisywania danych" type="error" />
                </Box>
              </Fade>
            </form>
          </div>
          <Box mt={3} />
          {companies.length > 1 && (
            <Typography className={classes.deleteButton} onClick={handleOpen}>
              usuń firmę
            </Typography>
          )}
        </div>
        <ConfirmationModal
          close={handleClose}
          open={open}
          handleClick={() => {
            if (selectedCompany?.docRef) deleteCompany(selectedCompany.docRef);
            handleClose();
          }}
        />
      </DefaultLayout>
    </MainLayout>
  );
};
