import React from 'react';
import { Box, Button, Modal, Typography, useTheme } from '@material-ui/core';

import { ConfirmationModalProps } from './ConfirmationModal.types';
import { useStyles } from './ConfirmationModal.styles';

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ open, close, handleClick }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Modal open={open} onClose={close} className={classes.wrapper}>
      <Box className={classes.modalBody}>
        <Typography variant="h6" className={classes.title}>
          Czy jesteś pewien?
        </Typography>
        <Typography>Usunięcie firmy usunie wszystkie powiązane oferty pracy</Typography>
        <Box className={classes.buttonBox} my={3}>
          <Button variant="contained" color="primary" onClick={handleClick}>
            Usuń firmę
          </Button>
          <Box mr={5} />
          <Button variant="contained" onClick={close}>
            Anuluj
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
