import React from 'react';
import { Box, CircularProgress, Grid, Typography, useTheme } from '@material-ui/core';
import { Redirect, useHistory } from 'react-router-dom';
import { useEffect } from 'react';

import { DashboardContentContainer } from 'ui/dashboard/dashboardContentContainer/DashboardContentContainer';
import { DashboardContent } from 'ui/dashboard/dashboardContent/DashboardContent';
import BackArrow from 'assets/icons/back_arrow.svg';
import { useStyles } from 'app/applicant/applicantsAttributesMatching/applicantsAttributesMatching.styles';
import { ApplicantsAttributesMatchingSummary } from 'app/applicant/applicantsAttributesMatching/applicantsAttributesMatchingSummary/ApplicantsAttributesMatchingSummary';
import { ApplicantsAttributesMatchingContact } from 'app/applicant/applicantsAttributesMatching/applicantsAttributesMatchingContact/ApplicantsAttributesMatchingContact';
import { MainLayout } from 'ui/mainLayout/MainLayout';
import { MainPaths } from 'routing/main/MainPaths';
import { useCustomerContext } from 'hooks/useCustomerContext/useCustomerContext';
import { useJobOfferContext } from 'hooks/useJobOfferContext/useJobOfferContext';
import { CertificateBoxProps } from 'ui/certificateBox/CertificateBox.types';
import { ChipsBoxProps } from 'ui/chipsBox/ChipsBox.types';
import { EducationBoxProps } from 'ui/educationBox/EducationBox.types';

import { ApplicantsAttributesMatchingDetails } from './applicantsAttributesMatchingDetails/ApplicantsAttributesMatchingDetails';
import { ApplicantsAttributesMatchingAbout } from './applicantsAttributesMatchingAbout/ApplicantsAttributesMatchingAbout';
import { aboutApplicantItemType, ApplicantsAttributesMatchingProps } from './applicantsAttributesMatching.types';

export const ApplicantsAttributesMatching: React.FC<ApplicantsAttributesMatchingProps> = ({ location }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { fetchSingleCustomer, singleCustomer, loading } = useCustomerContext();
  const { fetchSingleJobOffer, singleJobOffer, loading: loadingOffer } = useJobOfferContext();

  useEffect(() => {
    const getData = async () => {
      const candidateType = location?.state?.careerPath ? 'potential_candidates' : 'matched_candidates';

      await fetchSingleCustomer({
        candidateId: location.state.candidateId,
        jobOfferId: location.state.offerId,
        candidatesCollection: candidateType,
      });
      await fetchSingleJobOffer(location.state.offerId);
    };
    if (location.state) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();

  if (!location.state) return <Redirect to={MainPaths.dashboard} />;
  if (!singleCustomer || !singleJobOffer || loading || loadingOffer) return <CircularProgress />;

  const { candidateId, score } = location.state;
  const userName = singleCustomer.name || 'Nie podano imienia';
  const userEmail = singleCustomer.email;
  const userPosition = singleCustomer.position?.position_name;
  const userCity = singleCustomer.fillData?.city;
  const userExperience = singleCustomer.experience || [];
  const userEducation = singleCustomer.school || [];
  const offerMainTechnology = singleJobOffer?.mainTechnologies[0];
  const offerPositionTechnlogies = singleJobOffer?.positionTechnologies;
  const offerWelcomeTechnologies = singleJobOffer?.welcomeTechnologies;
  const offerWillLarnTechnologies = singleJobOffer?.willLearnTechnologies;

  const aboutApplicant: aboutApplicantItemType[] = [
    {
      label: 'Aktualna specjalizacja',
      text: userPosition,
      checked: userPosition?.toLowerCase() === singleJobOffer.category.toLowerCase(),
    },
    // { label: 'Poziom', text: 'Mid', checked: false },
    // { label: 'Lata doświadczenia', text: '5 lat', checked: false },
    // { label: 'Możliwość rozpoczęcia pracy', text: 'Od zaraz', checked: true },
    // { label: 'Dostępność', text: 'Pełny etat', checked: true },
    { label: 'Miasto', text: userCity, checked: userCity?.toLowerCase() === singleJobOffer.companyCity.toLowerCase() },
    // { label: 'Relokacja', text: 'Tak', checked: true },
    // { label: 'Rodzaj pracy', text: 'Zdalna, Częściowo stacjonarna', checked: true },
    // { label: 'Preferowane zarobki', text: '10 000 - 14 000 zł netto', checked: true },
  ];
  const aboutApplicantLanguages: aboutApplicantItemType[] = [
    { text: 'Angielski - Biegły, Certyfikat C1, C2', checked: true },
    { text: 'Niemiecki - Podstawowy', checked: false },
  ];

  const queryResultMainTechnology: ChipsBoxProps = {
    title: 'Główna technologia',
    chips: [{ caption: offerMainTechnology, checked: singleCustomer.technology?.known.includes(offerMainTechnology) }],
  };

  const queryResultRequired: ChipsBoxProps = {
    title: 'Dopasowane - umiejętności obowiązkowe',
    chips: offerPositionTechnlogies.map((item) => ({
      caption: item,
      checked: singleCustomer.technology?.known.includes(item),
    })),
  };

  const queryResultNiceToHave: ChipsBoxProps = {
    title: 'Dopasowane - umiejętności mile widziane',
    chips: offerWelcomeTechnologies.map((item) => ({
      caption: item,
      checked: singleCustomer.technology?.known.includes(item),
    })),
  };

  const queryResultWillLearn: ChipsBoxProps = {
    title: 'Dopasowane - umiejętności, których kandydat się nauczy',
    chips: offerWillLarnTechnologies.map((item) => ({
      caption: item,
      checked: singleCustomer.technology?.known.includes(item),
    })),
  };

  const queryCandidateTechnologyKnown: ChipsBoxProps = singleCustomer.technology
    ? {
        title: 'Umiejętności kandydata',
        chips: singleCustomer.technology?.known.map((item) => ({
          caption: item,
          checked: undefined,
        })),
      }
    : { title: 'Umiejętności kandydata', chips: [{ caption: 'brak danych', checked: undefined }] };

  const queryCandidateTechnologyLearning: ChipsBoxProps = singleCustomer.technology
    ? {
        title: 'Umiejętności, których kandydat się uczy',
        chips: singleCustomer.technology?.learning.map((item) => ({
          caption: item,
          checked: undefined,
        })),
      }
    : { title: 'Umiejętności, których kandydat się uczy', chips: [{ caption: 'brak danych', checked: undefined }] };

  const queryCandidateTechnologyWantToLearn: ChipsBoxProps = singleCustomer.technology
    ? {
        title: 'Umiejętności, w których kandydat chce się rozwijać',
        chips: singleCustomer.technology?.wantToLearn.map((item) => ({
          caption: item,
          checked: undefined,
        })),
      }
    : {
        title: 'Umiejętności, w których kandydat chce się rozwijać',
        chips: [{ caption: 'brak danych', checked: undefined }],
      };

  const queryResultExperience = userExperience.map((item) => ({
    company: item.company,
    city: item.city,
    position: item.position,
    period: `${item.start} - ${item.end || 'aktualnie'}`,
    learned: item.skills.map((tech) => tech).join(', '),
    // technology: 'JAVA',
  }));

  const queryResultCertificates: CertificateBoxProps[] = [
    {
      title: 'CAE Certificate',
      subtitle: 'Zachodniopomorski Uniwersytet Technologiczny',
      skills: 'JavaScript, HTML',
      period: '10.2011 - 09.2014',
    },
  ];

  const queryResultEducation: EducationBoxProps[] = userEducation.map((item) => ({
    title: item.name,
    subtitle: item.major,
    period: `${item.start || ''} - ${item.end || ''}`,
  }));

  return (
    <MainLayout>
      <DashboardContentContainer>
        <DashboardContent hasBottomPadding={true}>
          <div className={classes.dashboardContent}>
            <Box mb={6} />
            <div className={classes.titleBar}>
              <div className={classes.titles}>
                <div className={classes.backContainer}>
                  <img src={BackArrow} alt="back" />
                  <Box mr={1.5} />
                  <Typography variant="subtitle2" onClick={() => history.goBack()} style={{ cursor: 'pointer' }}>
                    Lista kandydatów
                  </Typography>
                </div>
                <Typography variant="h2" className={classes.title}>
                  {userName}
                </Typography>
                <Box mb={1} />
                <Typography variant="body2" className={classes.subtitle}>
                  {candidateId}
                </Typography>
              </div>
            </div>
            <Box mt={6} />
            <Grid container spacing={4}>
              <Grid item xs={8}>
                <div className={classes.gridLeft}>
                  <ApplicantsAttributesMatchingSummary score={score} />
                  <Box mb={4} />
                  <ApplicantsAttributesMatchingDetails
                    queryResultMainTechnology={queryResultMainTechnology}
                    queryResultRequired={queryResultRequired}
                    queryResultNiceToHave={queryResultNiceToHave}
                    queryResultWillLearn={queryResultWillLearn}
                    queryCandidateTechnologyKnown={queryCandidateTechnologyKnown}
                    queryCandidateTechnologyLearning={queryCandidateTechnologyLearning}
                    queryCandidateTechnologyWantToLearn={queryCandidateTechnologyWantToLearn}
                    queryResultExperience={queryResultExperience}
                    queryResultCertificates={queryResultCertificates}
                    queryResultEducation={queryResultEducation}
                  />
                  <Box mb={4} />
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className={classes.gridRight}>
                  <ApplicantsAttributesMatchingContact mail={userEmail} />
                  <Box mb={4} />
                  <ApplicantsAttributesMatchingAbout
                    aboutApplicant={aboutApplicant}
                    aboutApplicantLanguages={aboutApplicantLanguages}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </DashboardContent>
      </DashboardContentContainer>
    </MainLayout>
  );
};
