import React from 'react';
import { Box, InputLabel, Typography, useTheme } from '@material-ui/core';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { ButtonDefault } from 'ui/button/ButtonDefault';
import { useAuthContext } from 'hooks/useAuthContext/useAuthContext';
import { TextInput } from 'ui/textInput/TextInput';
import { MainPaths } from 'routing/main/MainPaths';

import { useStyles } from './EnterNewPasswordForm.styles';

const initialValues = {
  newPassword: '',
  retypeNewPassword: '',
};

const validationSchema = Yup.object({
  newPassword: Yup.string()
    .min(8, 'Hasło musi składać się z przynajmniej 8 znaków.')
    .required('Pole nie może być puste.'),
  retypeNewPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
});

export const EnterNewPasswordForm: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { changePassword } = useAuthContext();
  const history = useHistory();

  const onSubmit = async (values: typeof initialValues) => {
    const response = await changePassword(values.retypeNewPassword);

    if (response.success) {
      history.push(MainPaths.passwordResetConfirm);
    } else {
      console.log(response);
    }
  };

  const { handleSubmit, register, errors } = useForm({
    defaultValues: initialValues,
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  return (
    <div>
      <Typography variant="h4">Ustaw nowe hasło do konta</Typography>
      <Box mb={3} />
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <InputLabel htmlFor="newPassword" className={classes.label}>
          nowe hasło
        </InputLabel>
        <TextInput
          name="newPassword"
          variant="outlined"
          fullWidth
          type="password"
          label=""
          ref={register}
          placeholder="Wpisz nowe hasło do konta"
          error={!!errors.newPassword}
          className={classes.input}
        />

        <InputLabel htmlFor="retypeNewPassword" className={classes.label}>
          powtórz hasło
        </InputLabel>
        <TextInput
          name="retypeNewPassword"
          variant="outlined"
          fullWidth
          type="password"
          label=""
          ref={register}
          placeholder="Potwierdź swoje hasło"
          error={!!errors.retypeNewPassword}
          className={classes.input}
        />

        <ButtonDefault>zresetuj hasło</ButtonDefault>
      </form>
    </div>
  );
};
