import React from 'react';
import { Box, Button, Collapse, Drawer, List, ListItem, ListItemText, Typography, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { useEffect, useState } from 'react';

import { MainPaths } from '../../routing/main/MainPaths';
import { MenuRoutes } from '../../routing/main/MainRoutes';
import { CompanySelectInput } from '../companySelectInput/CompanySelectInput';
import LogoBlack from '../../assets/logo/logo-black.svg';
import { useCompanyContext } from '../../hooks/useCompanyContext/useCompanyContext';
import { UserProfileType } from '../../context/user/UserContext.types';
import { useUserContext } from '../../hooks/useUserContext/useUserContext';

import { useStyles } from './MainLayout.styles';

export const LeftMenu: React.FC = React.memo(() => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { updateSelectedCompany, companies, selectedCompany } = useCompanyContext();
  const { user } = useUserContext();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [menuLoaded, setMenuLoaded] = useState(false);

  const isAgencyProfile = user?.profileType === UserProfileType.AGENCY;

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const groups = MenuRoutes.filter((item) => item.group);
    groups.forEach((group) => {
      if (Array.isArray(group.path))
        group.path.forEach((path) => {
          if (path === location.pathname) setOpen(true);
          setMenuLoaded(true);
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
      id="id-drawer"
    >
      <div className={classes.logoContainer}>
        <img className={classes.logo} src={LogoBlack} alt="Logo HR-ME" />
      </div>
      {selectedCompany !== undefined && (
        <div className={classes.inputsContainer}>
          {isAgencyProfile && (
            <>
              <label>profil firmy</label>
              <CompanySelectInput
                handleChange={(e) => updateSelectedCompany(e.target.value)}
                items={companies}
                name="company"
                value={selectedCompany}
              />
              <Box mb={1} />
            </>
          )}
          <Button variant="contained" color="primary" startIcon={<AddIcon />} className={classes.newOfferButton}>
            <Link to={MainPaths.addJobOffer} className={classes.newOfferButtonText}>
              <Typography>dodaj ofertę</Typography>
            </Link>
          </Button>
        </div>
      )}
      <Box mb={2} />

      <List>
        {MenuRoutes.map((item) => {
          if (!isAgencyProfile && item.id === 5) return <></>;
          const menuItem = (item.path === location.pathname && !open) || (Array.isArray(item.path) && open);
          return (
            <Box key={item.id}>
              <Link className={classes.drawerLink} to={item.path as string}>
                <ListItem
                  className={clsx({
                    [classes.linkContainer]: true,
                    [classes.linkContainerActive]: menuItem,
                  })}
                >
                  <img src={item.icon} alt="menu icon" className={classes.listItem} />
                  <Box mr={2} />
                  {item.group ? (
                    <>
                      <ListItemText primary={item.group} className={classes.groupItem} onClick={handleClick} />
                      {open ? <ExpandLess /> : <ExpandMore />}
                    </>
                  ) : (
                    <ListItem className={classes.drawerLink}>{item.description}</ListItem>
                  )}
                </ListItem>
              </Link>

              {item.group && menuLoaded && (
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {MenuRoutes.filter((groupItem) => groupItem.group === item.group).map(
                      (groupItem) =>
                        Array.isArray(groupItem.path) &&
                        groupItem.path.map((path, index) => (
                          <Link className={classes.drawerLink} to={path}>
                            <ListItem
                              key={index}
                              className={clsx({
                                [classes.linkGroupContainer]: true,
                                [classes.linkGroupContainerActive]: path === location.pathname,
                              })}
                            >
                              <Box mr={3} />
                              {groupItem.description[index]}
                            </ListItem>
                          </Link>
                        )),
                    )}
                  </List>
                </Collapse>
              )}
            </Box>
          );
        })}
      </List>
    </Drawer>
  );
});

LeftMenu.displayName = 'LeftMenu';
