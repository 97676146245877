import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  message: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  messageIcon: {
    color: theme.palette.error.dark,
  },
  messageTypography: {
    fontSize: '18px',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  link: {
    fontSize: 16,
    color: theme.palette.grey.A200,
    textAlign: 'center',
  },
  button: {
    textTransform: 'lowercase',
    fontWeight: 'normal',
  },
  cross: {
    color: theme.palette.text.primary,
  },
}));
