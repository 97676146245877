import React from 'react';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { Box, Typography, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { LoginFlowLayout } from 'ui/loginFlowLayout/LoginFlowLayout';
import { ButtonDefault } from 'ui/button/ButtonDefault';
import { MainPaths } from 'routing/main/MainPaths';

import { ErrorMessageProps } from './ErrorMessage.types';
import { useStyles } from './ErrorMessage.styles';

export const ErrorMessage: React.FC<ErrorMessageProps> = ({
  message = 'Błąd 404',
  comment = 'Przykro nam, nic tu nie ma',
  caption = 'dashboard',
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();

  const handleClick = () => history.replace(MainPaths.dashboard);

  return (
    <LoginFlowLayout wide={true}>
      <NotInterestedIcon color="secondary" className={classes.checkIcon} />
      <Box mb={4} />
      <Typography variant="h2" className={classes.header}>
        {message}
      </Typography>
      <Box mb={1} />
      <Typography variant="body2" className={classes.description} align="center">
        {comment}
      </Typography>
      <Box mb={5} />
      <div className={classes.buttonContainer}>
        <ButtonDefault onClick={handleClick}>{caption}</ButtonDefault>
      </div>
    </LoginFlowLayout>
  );
};
