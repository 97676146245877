/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';

import { DefaultLayout } from 'ui/defaultLayout/DefaultLayout';
import { useCompanyContext } from 'hooks/useCompanyContext/useCompanyContext';
import { MainLayout } from 'ui/mainLayout/MainLayout';
import { MainPaths } from 'routing/main/MainPaths';

import { EditNavigationLink, useStyles } from './EditProfilePage.styles';
import { EditProfileGeneralContainer } from './editProfileGeneral/EditProfileGeneralContainer';
import { EditProfilePasswordContainer } from './editProfilePassword/EditProfilePasswordContainer';

export const EditProfilePage: React.FC = () => {
  const { companies } = useCompanyContext();
  const theme = useTheme();
  const classes = useStyles(theme);

  const isActive = !!companies.length;

  return (
    <MainLayout>
      <DefaultLayout headerText="Edycja profilu">
        <div className={classes.narrowPanel}>
          <Typography variant="h6">Nawigacja</Typography>
          <Box mb={2} />
          <EditNavigationLink to={MainPaths.editProfile} className={classes.linkActive}>
            Edytuj profil
          </EditNavigationLink>
          {isActive && <EditNavigationLink to={MainPaths.editCompany}>Edytuj konto firmowe</EditNavigationLink>}
        </div>
        <div className={classes.widePanel}>
          <div className={classes.wideContainer}>
            <EditProfileGeneralContainer classes={classes as any} />
          </div>
          <Box mb={4} />
          <div className={classes.wideContainer}>
            <EditProfilePasswordContainer />
          </div>
        </div>
      </DefaultLayout>
    </MainLayout>
  );
};
