import React, { useState } from 'react';
import { Box, Divider, Typography, useTheme, CircularProgress } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Link, useHistory } from 'react-router-dom';

import { DashboardContent } from 'ui/dashboard/dashboardContent/DashboardContent';
import { DashboardContentContainer } from 'ui/dashboard/dashboardContentContainer/DashboardContentContainer';
import { ButtonAdd } from 'ui/buttonAdd/ButtonAdd';
import { COLUMN_HEADERS, InvitationStatus } from 'ui/dashboard/dashboardApplicantsPanel/DashboardApplicantsPanel.types';
import { BoxWithButton } from 'ui/boxWithButton/BoxWithButton';
import { FiltersPanel } from 'ui/filtersPanel/FiltersPanel';
import { useJobOfferContext } from 'hooks/useJobOfferContext/useJobOfferContext';
import { DashboardApplicantsAccordions } from 'ui/dashboard/dashboardApplicantsAccordions/DashboardApplicantsAccordions';
import { MainLayout } from 'ui/mainLayout/MainLayout';
import { MainPaths } from 'routing/main/MainPaths';
import { MessageBar } from 'ui/messageBar/MessageBar';
import { JobOfferActivationStatusBar } from 'app/jobOffer/jobOfferActivationStatusBar/JobOfferActivationStatusBar';

import { OfferStatus, ApplicantsListFromJobOffersState } from './ApplicantsListFromJobOffers.types';
import { useStyles } from './ApplicantsListFromJobOffers.styles';

export const ApplicantsListFromJobOffers: React.FC<ApplicantsListFromJobOffersState> = ({ location }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();
  const { loading, jobOffers } = useJobOfferContext();

  const [jobOfferSelection, setJobOfferSelection] = useState('all');
  const [jobStatusSelection, setJobStatusSelection] = useState<OfferStatus | InvitationStatus>(OfferStatus.ALL);

  const showMessageBar = jobOffers.length > 0 && !jobOffers.some((jobOffer) => jobOffer?.matchedCandidates?.length > 0);
  const [showMessage, setShowMessage] = useState(showMessageBar);

  if (loading) return <CircularProgress />;

  const handleJobOfferChange = (value: string) => {
    switch (value) {
      case OfferStatus.ALL:
        setJobStatusSelection(OfferStatus.ALL);
        break;
      case OfferStatus.AWAITING:
        setJobStatusSelection(InvitationStatus.WAITS);
        break;
      case OfferStatus.ACCEPTED:
        setJobStatusSelection(InvitationStatus.ACCEPTED);
        break;
      case OfferStatus.SENT:
        setJobStatusSelection(InvitationStatus.SENT);
        break;
      case OfferStatus.PAID:
        setJobStatusSelection(InvitationStatus.PAID);
        break;
      case OfferStatus.REJECTED:
        setJobStatusSelection(InvitationStatus.REJECTED);
        break;
      case 'all':
        setJobOfferSelection('all');
        break;
      default:
        setJobOfferSelection(value);
        break;
    }
  };

  if (location?.state?.jobOfferId !== undefined && location.state.jobOfferId !== jobOfferSelection) {
    setJobOfferSelection(location.state.jobOfferId);
  }

  return (
    <MainLayout>
      <DashboardContentContainer>
        <JobOfferActivationStatusBar />
        <Box mb={3} />
        {showMessage && (
          <>
            <MessageBar
              message="Trwa ładowanie. Dopasowani kandydaci pojawią się w przeciągu paru minut."
              dismiss={() => setShowMessage(false)}
            />
            <Box mb={5} />
          </>
        )}

        <DashboardContent hasBottomPadding={false}>
          <div className={classes.dashboardContent}>
            <Box mb={5} />
            <div className={classes.titleBar}>
              <div className={classes.titles}>
                <Typography variant="h2" className={classes.title}>
                  Lista kandydatów z ofert pracy
                </Typography>
                <Box mb={1} />
                <Typography variant="body2" className={classes.subtitle}>
                  Zobacz wszystkich kandydatów dopasowanych do Twoich ofert pracy
                </Typography>
              </div>
              <div>
                <Link to={MainPaths.addJobOffer} className={classes.link}>
                  <ButtonAdd startIcon={<AddIcon />}>dodaj nową ofertę</ButtonAdd>
                </Link>
              </div>
            </div>
            <Box mt={5} />
            <Divider />
            <Box mt={5} />
            <FiltersPanel handleJobOfferChange={handleJobOfferChange} />
            <Box mt={5} />
          </div>
          <Box pb={2} className={classes.box} />

          <DashboardApplicantsAccordions
            titles={COLUMN_HEADERS}
            jobOfferSelection={jobOfferSelection}
            jobStatusSelection={jobStatusSelection}
          />

          <Box pb={4} className={classes.box} />
          <div className={classes.lowerPanels}>
            <BoxWithButton
              title="Stwórz nową ofertę pracy"
              buttonCapture="nowa oferta pracy"
              onClick={() => history.push(MainPaths.addJobOffer)}
            />
            <Box mr={4} />
            <BoxWithButton
              title="Chcesz stworzyć swoją ścieżkę kariery?"
              buttonCapture="nowa ścieżka kariery"
              onClick={() => (window.location.href = 'mailto:hello@hr-me.co')}
            />
          </div>
        </DashboardContent>
      </DashboardContentContainer>
    </MainLayout>
  );
};
