import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    width: 80,
    height: 24,
  },
  divider: {
    /* Line */
    width: 0.82,
    minHeight: 38,
    display: 'block',
    marginLeft: 20,
    marginRight: 20,
    //TODO: define in the palette
    background: '#3E2950',
    opacity: 0.25,
    //TODO: define in the palette
    border: '0.825px solid #3E2950',
  },

  block: {
    display: 'block',
  },
}));
