import { makeStyles } from '@material-ui/core';
const drawerWidth = 300;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '&:hover': { backgroundColor: 'transparent' },
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    color: theme.palette.text.primary,
    background: theme.palette.background.default,
  },
  profile: {
    width: '38px',
    height: '38px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: 'rgba(216, 82, 124, 1)',
  },
}));
