import { useTheme } from '@material-ui/core';
import React from 'react';

import { DashboardContentWideNarrowRHFProps } from 'ui/dashboard/dashboardContentWideNarrow/DashboardContentWideNarrow.types';

import { useStyles } from './DashboardContentWideNarrow.styles';

export const DashboardContentWideNarrowRHF: React.FC<DashboardContentWideNarrowRHFProps> = ({ methods, children }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.wrapper}>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { methods });
        }
        return child;
      })}
    </div>
  );
};
