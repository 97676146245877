import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
  },
  contentWrapper: {
    maxWidth: '700px',
    width: '100%',
    minHeight: '50vh',
    padding: '48px 32px 48px 32px',
    backgroundColor: theme.palette.background.paper,
    textAlign: 'left',
    wordBreak: 'break-word',
  },
  footer: {
    display: 'flex',
  },
  link: { cursor: 'pointer' },
}));
