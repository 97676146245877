"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomChipGroup = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var core_1 = require("@material-ui/core");
var CustomChipGroup_styles_1 = require("./CustomChipGroup.styles");
var CustomChip_1 = require("../customChip/CustomChip");
var CustomChipGroup = function (_a) {
    var chips = _a.chips;
    var theme = core_1.useTheme();
    var classes = CustomChipGroup_styles_1.useStyles(theme);
    return (jsx_runtime_1.jsx("div", __assign({ className: classes.container }, { children: chips.map(function (chip, index) { return (jsx_runtime_1.jsxs("div", __assign({ className: classes.container }, { children: [jsx_runtime_1.jsx(CustomChip_1.CustomChip, { label: chip.label, checked: chip.checked }, void 0), index !== chips.length - 1 && jsx_runtime_1.jsx(core_1.Box, { mr: 2 }, void 0)] }), index)); }) }), void 0));
};
exports.CustomChipGroup = CustomChipGroup;
