import React from 'react';
import { useTheme } from '@material-ui/core';

import { ApplicantDetailsProps } from './ApplicantDetails.types';
import { useStyles } from './ApplicantDetails.styles';

export const ApplicantDetails: React.FC<ApplicantDetailsProps> = React.memo(({ children }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return <div className={classes.detailsContainer}>{children}</div>;
});

ApplicantDetails.displayName = 'ApplicantDetails';
