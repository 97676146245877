import { Box, Typography, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import WelcomeIllustration from 'assets/ilustrations/welcome-to-hr-me.png';
import { LoginFlowLayout } from 'ui/loginFlowLayout/LoginFlowLayout';
import { useAuthContext } from 'hooks/useAuthContext/useAuthContext';

import { useStyles } from './WelcomeRegister.styles';

export const WelcomeRegister: React.FC = () => {
  const { currentUser } = useAuthContext();
  const [welcomeEmailSent, setWelcomeEmailSent] = useState(false);

  const theme = useTheme();
  const classes = useStyles(theme);

  useEffect(() => {
    const verifyEmail = () => {
      if (currentUser !== null && !currentUser.emailVerified) {
        console.log('I am going to send email verification mail!');
        currentUser.sendEmailVerification();
      }
    };

    verifyEmail();

    if (welcomeEmailSent) {
      verifyEmail();
      setTimeout(() => {
        setWelcomeEmailSent(false);
      }, 10000);
    }
  }, [currentUser, welcomeEmailSent]);

  return (
    <LoginFlowLayout type="image">
      <Typography variant="h4">Witaj w gronie HR-ME</Typography>
      <Box mb={3} />
      <Typography variant="body2">
        Na Twój adres e-mail został wysłany link aktywacyjny. Kliknij w niego, a następnie zaloguj się do platformy.
      </Typography>
      <Box mb={3} />
      {welcomeEmailSent ? (
        <Typography variant="body2">Wysłano ponownie!</Typography>
      ) : (
        <Typography variant="body2">
          E-mail nie dotarł?
          <Typography className={classes.link} onClick={() => setWelcomeEmailSent(true)}>
            Wyślij ponownie
          </Typography>
        </Typography>
      )}
      <Box mb={5} />
      <img src={WelcomeIllustration} alt="Welcome to hr me illustration" />
    </LoginFlowLayout>
  );
};
