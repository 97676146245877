import React, { useEffect, useState } from 'react';
import { Box, InputLabel, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';

import { TextInputWithClose } from 'ui/textInputWithClose/TextInputWithClose';
import { TextInput } from 'ui/textInput/TextInput';
import { ButtonAdd } from 'ui/buttonAdd/ButtonAdd';
import { TextSelect } from 'ui/textSelect/TextSelect';

import { TextInputExtendableListProps, TextInputVariant } from './TextInputExtendableList.types';
import { useStyles } from './TextInputExtendableList.styles';

export const TextInputExtendableList: React.FC<TextInputExtendableListProps> = ({
  label,
  listItems = [],
  buttonCaption,
  addInputLabel,
  addInputPlaceholder,
  variant = TextInputVariant.White,
  selectItems,
  valueName = '',
  name,
  methods,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const listContainerStyle = clsx({
    [classes.listContainer]: true,
    [classes.listContainerGrey]: variant === TextInputVariant.Grey,
  });

  const labelStyle = clsx({
    [classes.label]: true,
    [classes.labelGrey]: variant === TextInputVariant.Grey,
  });

  const [items, setItems] = useState(listItems);
  const [newSocialMedia, setNewSocialMedia] = useState('');
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');

  const handleNewSocialMediaButtonClick = () => {
    if (!newSocialMedia) {
      setError(true);
      setHelperText('adres nie może być pusty');
      return;
    }
    setItems([...items, newSocialMedia]);
    setNewSocialMedia('');
  };

  useEffect(() => {
    if (name) methods?.setValue(name, items);
  }, [items, methods, name]);

  useEffect(() => {
    if (name) methods?.register(name);
    setItems(listItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>
        <div className={listContainerStyle}>
          <InputLabel>
            <Typography variant="subtitle2" className={labelStyle}>
              {label}
            </Typography>
          </InputLabel>
          {items.map((item, key) => {
            return (
              <TextInputWithClose
                key={key}
                value={item}
                onClick={() => {
                  setItems(items.filter((element) => element !== item));
                }}
                variant={variant}
              />
            );
          })}
        </div>
        <div>
          <Box mb={2.25} />
          <div className={classes.inputContainer}>
            {selectItems && <TextSelect name={name} items={selectItems} label="metodologia" />}
            <TextInput
              valueName={newSocialMedia}
              label={addInputLabel}
              placeholder={addInputPlaceholder}
              value={newSocialMedia}
              error={error}
              helperText={helperText}
              onChange={(event) => {
                setError(false);
                setHelperText('');
                setNewSocialMedia(event.target.value);
              }}
            />
          </div>
          <ButtonAdd onClick={handleNewSocialMediaButtonClick}>{buttonCaption}</ButtonAdd>
        </div>
      </div>
    </>
  );
};
