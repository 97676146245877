import React, { useState } from 'react';

import { CustomerSummary, GetCustomerData } from 'context/customer/CustomerContext.types';
import { CustomerContext } from 'context/customer/CustomerContext';
import { firebaseRef } from 'api/firebaseRef';
import { onError, onSuccess } from 'providers/providersHelpers';

export const CustomerProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [singleCustomer, setSingleCustomer] = useState<CustomerSummary | null>(null);

  const fetchSingleCustomer = async ({ candidateId, candidatesCollection, jobOfferId }: GetCustomerData) => {
    setLoading(true);
    setSingleCustomer(null);

    try {
      const customersSnapshot = await firebaseRef.functions('europe-west1').httpsCallable('getCustomerProfile')({
        candidateId,
        candidatesCollection,
        jobOfferId,
      });

      if (customersSnapshot) {
        setSingleCustomer(customersSnapshot.data);

        return onSuccess(setLoading, 'Customer data fetched');
      }
      return onError(setLoading, 'Unable to fetch customer data.');
    } catch {
      return onError(setLoading, 'Unable to fetch customer data.');
    }
  };

  return (
    <CustomerContext.Provider
      value={{
        loading,
        fetchSingleCustomer,
        singleCustomer,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};
