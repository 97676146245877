"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StyledButtonMessage = exports.StyledButtonLink = exports.StyledButtonText = exports.StyledButtonOutlined = exports.StyledButtonContained = void 0;
var core_1 = require("@material-ui/core");
exports.StyledButtonContained = core_1.withStyles({
    root: {
        textTransform: 'none',
        borderRadius: 18,
        color: '#fff',
        backgroundColor: '#000',
        fontWeight: 400,
        fontSize: '16px',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
        },
    },
})(core_1.Button);
exports.StyledButtonOutlined = core_1.withStyles({
    root: {
        textTransform: 'none',
        borderRadius: 18,
        color: '#219653',
        borderColor: '#219653',
        backgroundColor: '#fff',
        fontWeight: 400,
        fontSize: '16px',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
        },
    },
})(core_1.Button);
exports.StyledButtonText = core_1.withStyles({
    root: {
        textTransform: 'none',
        borderRadius: 18,
        color: '#717171',
        fontWeight: 400,
        fontSize: '16px',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
        },
    },
})(core_1.Button);
exports.StyledButtonLink = core_1.withStyles({
    root: {
        textTransform: 'none',
        borderRadius: 18,
        color: '#a4a4a4',
        fontWeight: 400,
        fontSize: '16px',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
        },
    },
})(core_1.Button);
exports.StyledButtonMessage = core_1.withStyles({
    root: {
        textTransform: 'none',
        borderRadius: 18,
        backgroundColor: '#f4f4f4',
        color: 'black',
        fontWeight: 400,
        fontSize: '16px',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
    },
    text: {
        padding: '6px 16px',
    },
})(core_1.Button);
