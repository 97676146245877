"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HrMeThemeProvider = exports.WRAPPER_WIDTH = exports.WIDER_WRAPPER_WIDTH = exports.CONTAINER_WIDTH_TABLET = exports.CONTAINER_WIDTH = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var palette_1 = require("./palette");
var typography_1 = require("./typography");
var core_1 = require("@material-ui/core");
exports.CONTAINER_WIDTH = 1128;
exports.CONTAINER_WIDTH_TABLET = 640;
exports.WIDER_WRAPPER_WIDTH = 528;
exports.WRAPPER_WIDTH = 420;
var createHrMeTheme = function () {
    var hrMeTheme = core_1.createMuiTheme({
        palette: palette_1.palette,
        typography: function (palette) { return typography_1.typography(palette); },
        overrides: {
            MuiCssBaseline: {
                '@global': {
                    html: {
                        background: palette_1.colors.white,
                    },
                    body: {
                        backgroundColor: palette_1.colors.white,
                    },
                },
            },
        },
    });
    return __assign(__assign({}, hrMeTheme), { borders: {
            light: palette_1.borders.light,
            main: palette_1.borders.main,
        }, progress: {
            dark: palette_1.progress.main,
            light: palette_1.progress.light,
            active: palette_1.progress.active,
        } });
};
var HrMeThemeProvider = function (_a) {
    var children = _a.children;
    var theme = createHrMeTheme();
    return (jsx_runtime_1.jsxs(core_1.ThemeProvider, __assign({ theme: theme }, { children: [jsx_runtime_1.jsx(core_1.CssBaseline, {}, void 0), children] }), void 0));
};
exports.HrMeThemeProvider = HrMeThemeProvider;
