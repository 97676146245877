import React from 'react';
import { Typography, useTheme } from '@material-ui/core';

import { EducationBoxProps } from './EducationBox.types';
import { useStyles } from './EducationBox.styles';

export const EducationBox: React.FC<EducationBoxProps> = ({ title, subtitle, period }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.container}>
      <div className={classes.bulletBox}>
        <div className={classes.bullet} />
      </div>
      <div className={classes.content}>
        <Typography variant="body2" className={classes.strong}>
          {title}
        </Typography>
        <Typography variant="body2">{subtitle}</Typography>
        <Typography variant="body2">{period}</Typography>
      </div>
    </div>
  );
};
