import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';

import { DividerWithText } from 'ui/dividerWithText/DividerWithText';
import { TextInputVariant } from 'ui/textInputExtendableList/TextInputExtendableList.types';
import { FormControlLabelItem } from 'ui/radioGroupWithOthersInput/RadioGroupWithOthersInput.types';
import { RadioGroupWithOthersInputRHF } from 'ui/radioGroupWithOthersInput/RadioGroupWithOthersInputRHF';
import { TextInputExtendableListRHF } from 'ui/textInputExtendableList/TextInputExtendableListRHF';
import { ExperienceTypes } from '../addJobOfferPositionBasicsRHF/AddJobOfferPositionBasicsRHF.types';
import { TagInputExtendedRHF } from 'ui/tagInputExtended/TagInputExtendedRHF';
import { useKnowledge } from 'hooks/useKnowledge/useKnowledge';

import { AddJobOfferApplicantLanguageRHF } from './addJobOfferApplicantLanguageRHF/AddJobOfferApplicantLanguageRHF';
import { useStyles } from './AddJobOfferApplicantRHF.styles';
import { AddJobOfferApplicantRHFProps } from './AddJobOfferApplicantRHF.types';

const experienceTypes: FormControlLabelItem[] = Object.values(ExperienceTypes);

export const AddJobOfferApplicantRHF: React.FC<AddJobOfferApplicantRHFProps> = ({ methods, offerEdit }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { skills } = useKnowledge();

  const autocompleteSkills = skills.map((skill) => ({ title: skill }));

  const mapToObject = (item: string, idx: number) => ({ key: idx, label: item });

  const mainTechDraft = offerEdit?.mainTechnologies?.map(mapToObject);
  const positionTechDraft = offerEdit?.positionTechnologies?.map(mapToObject);
  const welcomeTechDraft = offerEdit?.welcomeTechnologies?.map(mapToObject);
  const willLearnTechDraft = offerEdit?.willLearnTechnologies?.map(mapToObject);
  const collaboratorsDraft = offerEdit?.collaborators?.map(mapToObject);
  const willLearnDraft = offerEdit?.willLearn?.map(mapToObject);

  const metodologyDraft = offerEdit?.methodology;
  const dailyTasksDraft = offerEdit?.dailyTasks;
  const personalGrowthDraft = offerEdit?.personalGrowth;
  const languageDraft = offerEdit?.language;
  return (
    <div className={classes.wrapper}>
      <Typography variant="h6" className={classes.header}>
        Kandydat
      </Typography>

      <TagInputExtendedRHF
        label="główna technologia"
        subtitle="Wpisz główną technologię jaką powinien posługiwać się kandydat. Główna technologia może być tylko jedna."
        required={true}
        items={mainTechDraft ? mainTechDraft : []}
        methods={methods}
        name={'mainTechnologies'}
        maxItems={1}
        autocompleteOptions={autocompleteSkills}
      />

      <Box mb={3} />

      <RadioGroupWithOthersInputRHF
        items={experienceTypes}
        label={'Lata doświadczenia'}
        other={{ name: 'experienceTime', placeholder: 'Podaj lata doświadczenia' }}
        required={true}
        name="experienceType"
        methods={methods}
        reverse
        helperText={methods?.errors.experienceTime?.message || methods?.errors.experienceType?.message}
      />

      <Box mb={3} />

      <DividerWithText
        title="Technologie na stanowisku"
        subtitle="Dodaj technologie i umiejętności dedykowane tej ofercie"
      />

      <TagInputExtendedRHF
        label="umiejętności obowiązkowe"
        subtitle="Podaj umiejętności jakie kandydat musi posiadać."
        items={positionTechDraft ? positionTechDraft : []}
        required={true}
        methods={methods}
        name={'positionTechnologies'}
        autocompleteOptions={autocompleteSkills}
      />

      <Box mb={2} />

      <TagInputExtendedRHF
        label="umiejętności mile widziane"
        subtitle="Podaj umiejętności jakie kandydat powinien posiadać."
        items={welcomeTechDraft ? welcomeTechDraft : []}
        required={true}
        methods={methods}
        name={'welcomeTechnologies'}
        autocompleteOptions={autocompleteSkills}
      />

      <Box mb={2} />

      <TagInputExtendedRHF
        label="technologie jakich nauczy się kandydat"
        subtitle="Wpisz czego nauczy się kandydat podczas pracy na danym stanowisku."
        items={willLearnTechDraft ? willLearnTechDraft : []}
        required={true}
        methods={methods}
        name={'willLearnTechnologies'}
        autocompleteOptions={autocompleteSkills}
      />

      <Box mb={2} />

      <DividerWithText
        title="Dodatkowe informacje o stanowisku"
        subtitle="Dodaj dodatkowe informacje dotyczące oferty pracy"
      />

      <TagInputExtendedRHF
        label="z kim będzie współpracował kandydat"
        subtitle="Podaj z kim będzie pracować kandydat. Przykładowo Front End Developer może współpracować z PM’em, Backendem, Grafikiem"
        items={collaboratorsDraft ? collaboratorsDraft : []}
        required={true}
        methods={methods}
        name={'collaborators'}
      />

      <Box mb={2} />

      <TagInputExtendedRHF
        label="czego nauczy się kandydat"
        subtitle="Określ w czym może rozwinąć się kandydat podczas pracy na danym stanowisku."
        items={willLearnDraft ? willLearnDraft : []}
        required={true}
        methods={methods}
        name={'willLearn'}
      />

      <Box mb={2} />

      <DividerWithText
        title="Metodologia stanowiska"
        subtitle="Określ kandydatowi z jakiej metolodolgii korzysta zespół i jakie narzędzia do tego wykorzystuje"
      />

      <TextInputExtendableListRHF
        name={'methodology'}
        methods={methods}
        label="dodana metodologia"
        buttonCaption="dodaj element"
        valueName={'methodology'}
        variant={TextInputVariant.Grey}
        listItems={metodologyDraft ? metodologyDraft : []}
        addInputLabel="Podaj typ, narzędzie lub odpowiedź"
        addInputPlaceholder="Np. SCRUM"
        selectItems={[
          { value: 'Agile management', label: 'Agile management' },
          { value: 'Issue tracking tool', label: 'Issue tracking tool' },
          { value: 'Knowledge repository', label: 'Knowledge repository' },
          { value: 'QA manager', label: 'QA manager' },
        ]}
        smallText
      />

      <Box mb={2} />

      <DividerWithText title="Codzienne zadania" subtitle="Czym będzie na codzień zajmować się kadydat?" />
      <TextInputExtendableListRHF
        name={'dailyTasks'}
        methods={methods}
        variant={TextInputVariant.Grey}
        label="codzienne zadania kandydata"
        listItems={dailyTasksDraft ? dailyTasksDraft : []}
        buttonCaption="dodaj zadanie"
        addInputLabel="nazwa zadania"
        addInputPlaceholder="Np. Spotkania z klientami"
      />

      <Box mb={2} />

      <DividerWithText
        title="Możliwość rozwoju"
        subtitle="Jak kandydat może rozwinąć się na stanowisku? Poinformuj go jeżeli oferujesz szkolenia wewnętrzne, czas na własny rozwój lub inne możliwości."
      />

      <TextInputExtendableListRHF
        name={'personalGrowth'}
        methods={methods}
        variant={TextInputVariant.Grey}
        label="Kandydat może rozwijać się przez:"
        listItems={personalGrowthDraft ? personalGrowthDraft : []}
        addInputLabel="wpisz możliwość rozwoju"
        addInputPlaceholder="Np. Szkolenia wewnętrzne"
        buttonCaption="Dodaj kolejną możliwość rozwoju"
      />

      <Box mb={2} />

      <DividerWithText
        title="Wymagane języki obce"
        subtitle="Dodaj wszystkie języki obce jakimi musi posługiwać się kandydat, określ ich poziom zaawansowania."
      />

      <AddJobOfferApplicantLanguageRHF
        name={'language'}
        methods={methods}
        valueName="language"
        variant={TextInputVariant.Grey}
        label="dodane języki"
        listItems={languageDraft ? languageDraft : []}
        addInputLabel="Dodaj wymagany język"
        addInputPlaceholder="Wybierz język z listy"
        buttonCaption="dodaj nowy język"
      />
    </div>
  );
};
