"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlatformSelect = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var core_1 = require("@material-ui/core");
var PlatformSelect_styles_1 = require("./PlatformSelect.styles");
var PlatformSelect = function (_a) {
    var onlyLinks = _a.onlyLinks;
    var theme = core_1.useTheme();
    var classes = PlatformSelect_styles_1.useStyles(theme);
    return (jsx_runtime_1.jsxs(jsx_runtime_1.Fragment, { children: [!onlyLinks && (jsx_runtime_1.jsxs(jsx_runtime_1.Fragment, { children: [jsx_runtime_1.jsx(core_1.Typography, __assign({ variant: "h4", className: classes.header }, { children: "Witaj w gronie HR-ME" }), void 0), jsx_runtime_1.jsx(core_1.Box, { mb: 3 }, void 0), jsx_runtime_1.jsx(core_1.Typography, __assign({ variant: "subtitle1", className: classes.subheader }, { children: "Zaloguj si\u0119 do wybranej platformy" }), void 0), jsx_runtime_1.jsx(core_1.Box, { mb: 3 }, void 0)] }, void 0)), jsx_runtime_1.jsx(core_1.Link, __assign({ color: "primary", href: "https://beta.hr-me.co/" }, { children: jsx_runtime_1.jsx(PlatformSelect_styles_1.StyledButton, __assign({ variant: "contained", color: "secondary" }, { children: "platforma dla u\u017Cytkownik\u00F3w indywidualnych" }), void 0) }), void 0), jsx_runtime_1.jsx(core_1.Box, { mb: 3 }, void 0), jsx_runtime_1.jsx(core_1.Link, __assign({ color: "primary", href: "https://b2b.hr-me.co/login", className: classes.link }, { children: jsx_runtime_1.jsx(PlatformSelect_styles_1.StyledButton, __assign({ className: classes.buttonBusiness }, { children: "platforma dla u\u017Cytkownik\u00F3w biznesowych" }), void 0) }), void 0)] }, void 0));
};
exports.PlatformSelect = PlatformSelect;
