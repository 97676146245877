import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  lowerPanel: {
    flex: 1,
    padding: theme.spacing(4),
    borderRadius: '8px',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'start',
  },
  buttonSend: {
    padding: theme.spacing(1, 3),
    textTransform: 'lowercase',
  },
}));
