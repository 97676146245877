export const AuthenticationPaths = {
  temp: '/temp',
  login: '/login',
  onboarding: '/onboarding',
  register: '/register',
  welcome: '/welcome',
  forgotPassword: '/forgot-password',
  checkEmail: '/check-email',
  addCompany: '/add-company',
  profile: '/profile',
  companyProfiles: '/company-profiles',
  termsAndConditions: '/terms',
  policy: '/policy',
};
