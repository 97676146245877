import React, { useEffect } from 'react';
import { Box, useTheme } from '@material-ui/core';

import { DashboardContentWideNarrowRHFProps } from 'ui/dashboard/dashboardContentWideNarrow/DashboardContentWideNarrow.types';
import { useKnowledge } from 'hooks/useKnowledge/useKnowledge';

import { useStyles } from './AddJobOfferWidePanelRHF.styles';
import { AddJobOfferPositionBasicsRHF, AddJobOfferApplicantRHF } from './components';

export const AddJobOfferWidePanelRHF: React.FC<DashboardContentWideNarrowRHFProps> = ({ methods, offerEdit }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { fetchSuggestions } = useKnowledge();

  useEffect(() => {
    const fetchData = async () => {
      await fetchSuggestions();
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.wrapper}>
      <AddJobOfferPositionBasicsRHF methods={methods} offerEdit={offerEdit} />
      <Box mb={4} />
      <AddJobOfferApplicantRHF methods={methods} offerEdit={offerEdit} />
    </div>
  );
};
