"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CircularProgressWithLabel = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var core_1 = require("@material-ui/core");
var CircularProgressWithLabel_styles_1 = require("./CircularProgressWithLabel.styles");
var CircularProgressWithLabel = function (props) {
    var theme = core_1.useTheme();
    var classes = CircularProgressWithLabel_styles_1.useStyles(theme);
    var value = props.value, _a = props.fontSize, fontSize = _a === void 0 ? 18 : _a, _b = props.size, size = _b === void 0 ? 40 : _b, _c = props.mode, mode = _c === void 0 ? 'success' : _c;
    return (jsx_runtime_1.jsx(jsx_runtime_1.Fragment, { children: jsx_runtime_1.jsxs(core_1.Box, __assign({ position: "relative", display: "inline-flex" }, { children: [jsx_runtime_1.jsx(core_1.CircularProgress, __assign({ size: size, variant: "determinate", style: { color: theme.palette[mode].dark } }, props), void 0), jsx_runtime_1.jsx(core_1.Box, __assign({ top: 0, left: 0, bottom: 0, right: 0, position: "absolute", display: "flex", alignItems: "center", justifyContent: "center" }, { children: jsx_runtime_1.jsx(core_1.Typography, __assign({ component: "div", style: { fontSize: fontSize, color: theme.palette[mode].main }, className: classes.label }, { children: "" + Math.round(value) }), void 0) }), void 0)] }), void 0) }, void 0));
};
exports.CircularProgressWithLabel = CircularProgressWithLabel;
