import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  dashboardContent: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  titleBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titles: {},
  backContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(1),
  },
  title: {
    textAlign: 'left',
  },
  subtitle: {
    textAlign: 'left',
    color: theme.palette.grey.A200,
  },
  gridLeft: {},
  gridRight: {},
}));
