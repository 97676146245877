import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: theme.spacing(1),
    fontSize: 32,
  },
  description: {
    fontSize: 16,
    color: theme.palette.grey.A200,
    textAlign: 'center',
  },
  companyButtonsContainer: {
    margin: 'auto',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridColumnGap: theme.spacing(3.5),
    gridRowGap: theme.spacing(3.5),
  },
  footer: {
    color: theme.palette.grey.A200,
  },
  newClient: {
    color: '#3e2950',
  },
}));
