import React from 'react';
import { Typography, useTheme } from '@material-ui/core';

import { CounterProps } from './Counter.types';
import { useStyles } from './Counter.styles';

export const Counter: React.FC<CounterProps> = ({ value, title, subtitle }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.container}>
      <Typography variant="h4" className={classes.counter}>
        {value ? value.toString() : '0'}
      </Typography>
      <div className={classes.counterLabel}>
        <Typography variant="body2" className={classes.counterTitle}>
          {title}
        </Typography>
        <Typography variant="body2" className={classes.counterSubtitle}>
          {subtitle}
        </Typography>
      </div>
    </div>
  );
};
