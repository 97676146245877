/* eslint-disable @typescript-eslint/no-explicit-any */
import { FirebaseBasicResponse } from 'api/Firebase.types';
import { AddJobOfferInitialValuesTypeRHF } from 'app/jobOffer/addJobOfferRHF/addJobOfferFormWrapperRHF/addJobOfferFormWrapperRHF.types';
import { CustomerSummary } from '../customer/CustomerContext.types';
import { ChipItem } from 'app/applicant/applicantDetails/applicantDetailsHeader/ApplicantDetailsHeader.types';
import { InvitationStatus } from 'ui/dashboard/dashboardApplicantsPanel/DashboardApplicantsPanel.types';

export type JobOfferContextProps = {
  addNewJobOffer: (
    newJobOffer: AddJobOfferInitialValuesTypeRHF,
    status: JobOfferStatus,
    isPAYG: boolean,
  ) => Promise<FirebaseBasicResponse>;
  fetchJobOffers: () => Promise<FirebaseBasicResponse>;
  fetchPrices: () => Promise<FirebaseBasicResponse>;
  fetchLastPayment: () => Promise<FirebaseBasicResponse>;
  fetchCurrentSubscription: () => Promise<FirebaseBasicResponse>;
  sendToCheckout: (
    selectedPrice: string,
    isPAYG: boolean,
    invoiceData: Record<string, string>,
    offerID_PAYG?: string,
  ) => Promise<FirebaseBasicResponse>;
  changeOfferStatus: (offerID: string, status: string) => Promise<FirebaseBasicResponse>;
  updateOffer: (offerID: string, payload: AddJobOfferInitialValuesTypeRHF) => Promise<FirebaseBasicResponse>;
  loading: boolean;
  jobOffers: JobOffer[];
  prices: PricesType | null;
  lastPayment: Record<string, string | number>;
  currentSubscription: Record<string, any> | null;
  subscriptionLoading: boolean;
  createStripeInvoice: (priceRef: string, tokens: number) => Promise<FirebaseBasicResponse>;
  createStripePanelSafeLink: () => Promise<FirebaseBasicResponse>;
  inviteUserToJobOffer: ({ jobOfferId, candidateId, candidatesCollection }: IInvite) => Promise<FirebaseBasicResponse>;
  unlockCandidate: ({ jobOfferId, candidateId, candidatesCollection }: IInvite) => Promise<FirebaseBasicResponse>;
  fetchSingleJobOffer: (offerId: string) => Promise<FirebaseBasicResponse>;
  fetchScheduledOffers: () => Promise<FirebaseBasicResponse>;
  singleJobOffer: AddJobOfferInitialValuesTypeRHF | null;
  scheduledOffers: ScheduledOffersType[] | null;
  setCandidateStatusInJobOffers: (
    jobOfferId: string,
    status: InvitationStatus,
    candidateId: string,
  ) => Promise<FirebaseBasicResponse>;
};

export type JobOffer = AddJobOfferInitialValuesTypeRHF & {
  jobOfferId: string;
  userId: string;
  companyId: string;
  created: string;
  activated: boolean;
  endDate: string;
  applicants: number;
  offersAccepted: number;
  offersSent: number;
  validUntil: number;
  status: JobOfferStatus;
  matchedCandidates: Candidate[];
  potentialCandidates: Candidate[];
};

export type Candidate = CustomerSummary & {
  b2bUserId: string;
  candidateId: string;
  generalScore: number;
  potentialScore: number;
  mainTechnology: ChipItem;
  offerId: string;
  offerName: string;
  languages: ChipItem[];
  yearsOfExperience: {
    text: string;
    checked: boolean;
  };
  seniority: {
    text: string;
    checked?: boolean;
  };
};

export enum JobOfferStatus {
  DRAFT = 'draft',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  EDITED = 'edited',
}

export type CandidatesCollection = 'matched_candidates' | 'potential_candidates';

export type IInvite = {
  jobOfferId: string;
  candidateId: string;
  candidatesCollection: CandidatesCollection;
};

export type PricesType = {
  pay_as_you_go: PricesObject[];
  premium: PricesObject[];
  enterprise: PricesObject[];
};

export type PricesObject = {
  taxIncluded: boolean;
  id: string;
  fullTitle: string;
  value: number;
  interval: string | null;
  priceRef: string;
  promotion: string | null;
  tokens: number | undefined;
};

export type ScheduledOffersType = {
  offerTitle: string;
  validUntil: number;
};
