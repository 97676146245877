import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';

import { IconChecked } from 'ui/icon/iconChecked/IconChecked';
import { IconUnchecked } from 'ui/icon/iconUnchecked/IconUnchecked';

import { ChipBackground, ChipDefaultProps } from './ChipDefault.types';
import { useStyles } from './ChipDefault.styles';

export const ChipDefault: React.FC<ChipDefaultProps> = ({
  caption,
  checked = null,
  background = ChipBackground.LIGHT,
  columnGap = 0,
  rowGap = 0,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div
      className={clsx({
        [classes.container]: true,
        [classes.backgroundDark]: background === ChipBackground.DARK,
        [classes.rowGap]: !!rowGap,
        [classes.columnGap]: !!columnGap,
      })}
    >
      <Typography variant="body2">{caption}</Typography>

      {checked !== null && (
        <React.Fragment>
          <Box mr={0.5} />
          {checked ? <IconChecked /> : <IconUnchecked />}
        </React.Fragment>
      )}
    </div>
  );
};
