import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  listItem: {
    display: 'flex',
  },
  listItemIcon: {
    minWidth: 32,
    margin: 'auto',
  },
  listItemText: {
    margin: 0,
  },
  img: {
    height: '24px',
    width: '24px',
    objectFit: 'contain',
  },
  textSelect: {},
  label: {
    textAlign: 'left',
    marginBottom: theme.spacing(1),
    fontWeight: 400,
    color: theme.palette.grey[800],
  },
  select: {
    minWidth: '100%',
    textAlign: 'left',
    backgroundColor: theme.palette.background.paper,
  },
}));
