const prefix = `main`;

export const MainPaths = {
  theme: `/${prefix}/theme`,
  dashboard: `/${prefix}/dashboard`,
  newPassword: `/${prefix}/new-password`,
  passwordResetConfirm: `/${prefix}/password-reset-confirm`,
  addComapnyConfirmation: `/${prefix}/add-company-confirmation`,
  addCompany: `/${prefix}/add-company`,
  addJobOffer: `/${prefix}/add-job-offer`,
  editProfile: `/${prefix}/edit-profile`,
  editCompany: `/${prefix}/edit-company`,
  jobOfferActivated: `/${prefix}/job-offer-activated`,
  paymentVariants: `/${prefix}/payment-variants`,
  invoice: `/${prefix}/invoice`,
  paymentConfirmed: `/${prefix}/payment-confirmed`,
  paymentHistory: `/${prefix}/payment-history`,
  error: `/${prefix}/error`,
  jobOffers: `/${prefix}/job-offers`,
  jobOfferDetail: `/${prefix}/job-offer-detail`,
  applicantsListFromJobOffers: `/${prefix}/applicants-list-from-job-offers`,
  applicantsListFromCareerPaths: `/${prefix}/applicants-list-from-career-paths`,
  applicantsAttributesMatching: `/${prefix}/applicants-attributes-matching`,
  chooseProfileType: `/${prefix}/choose-profile-type`,
  companyProfles: `/${prefix}/company-profiles`,
  terms: `/${prefix}/terms`,
};
