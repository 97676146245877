"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
var core_1 = require("@material-ui/core");
var palette_1 = require("../../../theme/palette");
var useStyles = function () {
    var style = core_1.makeStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'start',
            width: '100%',
        },
        containerNarrow: {
            width: "calc(50% - 12px)",
            marginRight: 24,
            flex: 1,
        },
        label: {
            textAlign: 'left',
            marginBottom: 8,
            fontWeight: 400,
            color: palette_1.colors.label,
        },
        select: {
            minWidth: '100%',
            marginBottom: 18,
            textAlign: 'left',
            '& .MuiOutlinedInput-root': {
                background: palette_1.colors.white,
                '& fieldset': {
                    borderColor: '#D8D5DC',
                },
                '&:hover fieldset': {
                    borderColor: palette_1.colors.pink,
                },
                '&.Mui-focused fieldset': {
                    borderColor: palette_1.colors.pink,
                },
            },
        },
    });
    return style();
};
exports.useStyles = useStyles;
