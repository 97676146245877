import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  text: {
    fontSize: '15px',
    fontWeight: 400,
    cursor: 'pointer',
  },
}));
