import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  textFieldList: {},
  filledInput: {
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
    backgroundColor: theme.palette.background.default,
  },
}));
