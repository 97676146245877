import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  dashboardContent: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    borderRadius: '5px',
  },
  titleBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titles: {},
  title: {
    textAlign: 'left',
  },
  subtitle: {
    textAlign: 'left',
    color: theme.palette.grey.A200,
  },
  box: {
    backgroundColor: theme.palette.background.default,
  },
  lowerPanels: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'start',
    backgroundColor: theme.palette.background.default,
  },
  link: {
    textDecoration: 'none',
  },
}));
