import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(4),
    border: '1px solid #d8d5dc',
  },
  header: {
    fontWeight: 600,
    textAlign: 'left',
    marginBottom: theme.spacing(3),
  },
  helperTextError: {
    color: '#f44336',
    fontSize: '.8em',
    margin: '0',
    background: '#fff9f9',
    padding: '5px',
  },
}));
