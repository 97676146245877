import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  link: {
    display: 'inline',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
