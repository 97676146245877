import React, { useState } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Fade } from '@material-ui/core';

import { ButtonDefault } from 'ui/button/ButtonDefault';
import { Message } from 'ui/message/Message';
import { delay } from 'helpers/utils';
import { useAuthContext } from 'hooks/useAuthContext/useAuthContext';

import { EditProfilePassword } from './EditProfilePassword';

const initialValues = {
  oldPassword: '',
  newPassword: '',
  newPasswordConfirm: '',
};

const validationSchema = Yup.object({
  oldPassword: Yup.string()
    .min(8, 'Hasło musi składać się z przynajmniej 8 znaków.')
    .required('Pole nie może być puste.'),
  newPassword: Yup.string()
    .min(8, 'Hasło musi składać się z przynajmniej 8 znaków.')
    .required('Pole nie może być puste.'),
  newPasswordConfirm: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Potwierdź nowe hasło'),
});

export const EditProfilePasswordContainer = (): JSX.Element => {
  const { changePassword, loading } = useAuthContext();
  const [formSuccess, setFormSuccess] = useState(false);
  const [formError, setFormError] = useState(false);

  const { register, errors, handleSubmit, reset } = useForm({
    defaultValues: initialValues,
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: typeof initialValues) => {
    const { newPassword, oldPassword } = data;
    const response = await changePassword(newPassword, oldPassword);

    if (response.success) {
      setFormSuccess(true);
      reset();
    } else {
      setFormError(true);
    }

    await delay(2500);
    setFormSuccess(false);
    setFormError(false);
  };

  return (
    <>
      <form>
        <EditProfilePassword register={register} errors={errors} />
        <ButtonDefault loading={loading} onClick={handleSubmit(onSubmit)}>
          zapisz hasło
        </ButtonDefault>
        <Fade style={{ height: formSuccess ? 'auto' : 0 }} in={formSuccess}>
          <Box>
            <Box height={26} />
            <Message text="Pomyślnie zmieniono hasło" type="success" />
          </Box>
        </Fade>
        <Fade style={{ height: formError ? 'auto' : 0 }} in={formError}>
          <Box>
            <Box height={26} />
            <Message text="Wystąpił błąd podczas zmiany hasła" type="error" />
          </Box>
        </Fade>
      </form>
    </>
  );
};
