import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';

import { useStyles } from './PaymentFooter.styles';

export const PaymentFooter: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <>
      <Box mt={4} />
      <div className={classes.wrapper}>
        <Typography variant="body1" className={classes.text}>
          Copyright © 2020 HR-ME
        </Typography>
        <div className={classes.wrapper}>
          <Typography variant="body1" className={classes.text} onClick={() => console.log('kliknieto')}>
            Polityka prywatności
          </Typography>
          <Box mr={8} />
          <Typography variant="body1" className={classes.text} onClick={() => console.log('kliknieto')}>
            Support
          </Typography>
          <Box mr={8} />
          <Typography variant="body1" className={classes.text} onClick={() => console.log('kliknieto')}>
            Contacts
          </Typography>
        </div>
      </div>
      <Box mb={4} />
    </>
  );
};
