import { UseFormMethods } from 'react-hook-form';

import { AddJobOfferInitialValuesTypeRHF } from 'app/jobOffer/addJobOfferRHF/addJobOfferFormWrapperRHF/addJobOfferFormWrapperRHF.types';
import { FormControlLabelItem } from 'ui/radioGroupWithOthersInput/RadioGroupWithOthersInput.types';

export type FormControlLabelEnum = {
  [key: string]: FormControlLabelItem;
};

export const ContractTypes: FormControlLabelEnum = {
  B2B: { value: 'b2b', label: 'b2b' },
  UMOWA_O_PRACE: { value: 'uop', label: 'umowa o pracę' },
  UMOWA_O_DZIELO: { value: 'uod', label: 'umowa o dzieło' },
  UMOWA_ZLECENIE: { value: 'uzl', label: 'umowa zlecenie' },
};

export const Currencies: FormControlLabelEnum = {
  PLN: { value: 'PLN', label: 'PLN' },
  EUR: { value: 'EUR', label: 'EUR' },
  GBP: { value: 'GBP', label: 'GBP' },
  USD: { value: 'USD', label: 'USD' },
  CHF: { value: 'CHF', label: 'CHF' },
};

export const ExperienceTypes: FormControlLabelEnum = {
  NONE: { value: 'none', label: 'brak' },
  JUNIOR: { value: 'junior', label: 'junior' },
  MID: { value: 'mid', label: 'mid' },
  SENIO: { value: 'senior', label: 'senior' },
  EXPERT: { value: 'expert', label: 'ekspert' },
};

export const WorkingTime: FormControlLabelEnum = {
  FULL: { value: 'full', label: '40 godzin tygodniowo (pełny etat)' },
  PART: { value: 'part', label: '20 - 40 godzin tygodniowo' },
  CASU: { value: 'casu', label: '20 godzin tygodniowo (pół etatu)' },
  OTHE: { value: 'othe', label: 'Poniżej 20 godzin tygodniowo' },
};

export const PlaceOfWork: FormControlLabelEnum = {
  OFFICE: { value: 'office', label: 'biuro' },
  REMOTE: { value: 'remote', label: 'zdalnie' },
  COVID: { value: 'covid', label: 'zdalnie na czas COVID' },
  HYBRID: { value: 'hybrid', label: 'praca hybrydowa' },
  OTHER: { value: 'other', label: 'inne' },
};

export const Interview: FormControlLabelEnum = {
  LOCAL: { value: 'local', label: 'na miejscu' },
  REMOTE: { value: 'remote', label: 'zdalnie' },
};

export const Languages: FormControlLabelEnum = {
  BASIC: { value: 'basic', label: 'podstawowy' },
  ADVANCED: { value: 'advanced', label: 'komunikatywny' },
  FLUENT: { value: 'fluent', label: 'biegły' },
};

export type AddJobOfferPositionBasicsRHFProps = {
  methods?: UseFormMethods<AddJobOfferInitialValuesTypeRHF>;
  offerEdit?: AddJobOfferInitialValuesTypeRHF;
};
