import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    marginTop: theme.spacing(1),
    textTransform: 'lowercase',
    fontWeight: 'normal',
    background: `linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.15) 66.67%, rgba(255, 255, 255, 0) 100%), ${theme.palette.secondary.main}`,
  },
}));
