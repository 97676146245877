import { useContext } from 'react';

import { AuthenticationContext } from 'context/authentication/AuthenticationContext';
import { AuthenticationContextProps } from 'context/authentication/AuthenticationContext.types';

export const useAuthContext: () => AuthenticationContextProps = () => {
  const context = useContext(AuthenticationContext);

  if (context === undefined) {
    throw Error('useAuthContext must be used within an AuthenticationContext');
  }

  return context;
};
