import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';

import { ButtonDefault } from 'ui/button/ButtonDefault';

import { ProfileTypeBoxProps } from './ProfileTypeBox.types';
import { useStyles } from './ProfileTypeBox.styles';

export const ProfileTypeBox: React.FC<ProfileTypeBoxProps> = ({
  icon,
  title,
  subtitle,
  buttonCaption,
  buttonAction,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.container}>
      {icon}
      <Box mb={3} />
      <Typography variant="h4" className={classes.title}>
        {title}
      </Typography>
      <Box mb={1} />
      <Typography variant="body2" className={classes.subtitle}>
        {subtitle}
      </Typography>
      <Box mb={5} />
      <ButtonDefault onClick={buttonAction}>{buttonCaption}</ButtonDefault>
    </div>
  );
};
