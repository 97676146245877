"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.typography = exports.fontWeight = void 0;
var SilkaFont = 'Silka';
exports.fontWeight = {
    light: 300,
    normal: 400,
    bold: 700,
};
var typography = function (palette) { return ({
    fontFamily: ['BasierCircle', 'Silka', 'Roboto', 'sans-serif'].join(','),
    h2: {
        fontFamily: SilkaFont,
        fontWeight: exports.fontWeight.bold,
        fontSize: '24px',
        textAlign: 'center',
        lineHeight: '1.2em',
        "@media (min-width: 1024px)": {
            fontSize: '40px',
        },
    },
    h3: {
        fontFamily: SilkaFont,
        fontWeight: exports.fontWeight.bold,
    },
    h5: {
        fontFamily: SilkaFont,
        fontWeight: exports.fontWeight.bold,
    },
    h4: {
        fontFamily: SilkaFont,
        fontWeight: exports.fontWeight.bold,
        fontSize: '24px',
        letterSpacing: 0,
        textAlign: 'center',
    },
    h6: {
        fontFamily: SilkaFont,
        fontWeight: exports.fontWeight.bold,
    },
    body2: {
        fontSize: '16px',
        lineHeight: '24px',
        fontStyle: 'normal',
        fontWeight: 400,
        textAlign: 'center',
    },
    subtitle1: {
        fontSize: '14px',
    },
    subtitle2: {
        fontSize: '16px',
        fontWeight: 'bold',
    },
}); };
exports.typography = typography;
