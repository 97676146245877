/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';

import { TextInput } from 'ui/textInput/TextInput';
import { FormControlLabelItem } from 'ui/radioGroupWithOthersInput/RadioGroupWithOthersInput.types';
import { DividerWithText } from 'ui/dividerWithText/DividerWithText';
import { TagInputExtendedRHF } from 'ui/tagInputExtended/TagInputExtendedRHF';
import { useKnowledge } from 'hooks/useKnowledge/useKnowledge';
import { TextSelect } from 'ui/textSelect/TextSelect';
import { CheckboxGroup } from 'ui/checkboxGroup/CheckboxGroup';
import { useJobOfferContext } from '../../../../../../hooks/useJobOfferContext/useJobOfferContext';

import { AddJobOfferPositionBasicsRHFProps, ContractTypes } from './AddJobOfferPositionBasicsRHF.types';
import { AddJobOfferTimeAndPlaceRHF } from './addJobOfferTimeAndPlaceRHF/AddJobOfferTimeAndPlaceRHF';
import { AddJobOfferSalaryRHF } from './addJobOfferSalaryRHF/AddJobOfferSalaryRHF';
import { useStyles } from './AddJobOfferPositionBasicsRHF.styles';

export const AddJobOfferPositionBasicsRHF: React.FC<AddJobOfferPositionBasicsRHFProps> = ({ methods, offerEdit }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const formControlLabels: FormControlLabelItem[] = Object.values(ContractTypes);
  const { categories } = useKnowledge();
  const { fetchJobOffers, jobOffers } = useJobOfferContext();
  const [fetchedBenefits, setFetchedBenefits] = useState<{ title: string; inputValue: string }[]>([
    { title: 'Multisport', inputValue: 'Multisport' },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchJobOffers();
      const benefitsFromOffers = jobOffers.reduce<string[]>(
        (acc, offer) => {
          const preparedBenefits: string[] = [];
          offer.benefits.forEach((benefit) => {
            if (!acc.includes(benefit)) {
              preparedBenefits.push(benefit);
            }
          });
          return [...acc, ...preparedBenefits];
        },
        fetchedBenefits.map((el) => el.title),
      );
      setFetchedBenefits(
        benefitsFromOffers.map<{ title: string; inputValue: string }>((benefit) => {
          return { title: benefit, inputValue: benefit };
        }),
      );
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const draftBenefits = offerEdit?.benefits?.map((item, idx) => ({ key: idx, label: item }));
  const salaryRangeDraft = offerEdit?.salaryRange;
  const hireASAPdraft = offerEdit?.hireAsap;
  const offerCategories = categories.map((category) => ({ value: category, label: category }));

  return (
    <div className={classes.wrapper}>
      <Typography variant="h6" className={classes.header}>
        Informacje podstawowe
      </Typography>
      <TextInput
        label={'nazwa stanowiska'}
        name={'position'}
        placeholder={'Np. Junior Front end developer'}
        required={true}
        valueName={'position'}
        ref={methods?.register}
        helperText={methods?.errors.position?.message}
        FormHelperTextProps={{ classes: { root: classes.helperTextError } }}
      />
      <Box mb={2} />
      <TextSelect
        name={'category'}
        label={'kategoria oferty'}
        control={methods?.control}
        required
        helperText={methods?.errors.category?.message}
        error={!!methods?.errors.category?.message}
        items={offerCategories}
      />
      <Box mb={2} />
      <CheckboxGroup
        name={'contractType'}
        label={'rodzaj umowy'}
        items={formControlLabels}
        required
        methods={methods}
      />
      <Box mb={2} />
      <DividerWithText title="Wynagrodzenie" subtitle="Wprowadź dane dotyczące wynagrodzenia na tym stanowisku" />
      <AddJobOfferSalaryRHF methods={methods} checked={salaryRangeDraft} />
      <DividerWithText
        title="Czas i miejsce pracy"
        subtitle="Dodaj informacje dotyczące czasu i miejsca wykonywanej pracy"
      />
      <AddJobOfferTimeAndPlaceRHF methods={methods} checked={hireASAPdraft} />
      <DividerWithText title="Dodatkowe informacje" subtitle="Wprowadź dodatkowe informacje na temat oferty" />
      <TagInputExtendedRHF
        label="benefity"
        subtitle="Podaj benefity jakie uzyska kandydat."
        required={true}
        items={draftBenefits ?? []}
        methods={methods}
        name={'benefits'}
        autocompleteOptions={fetchedBenefits || []}
      />
      <Box mb={3} />
      <TextInput
        name="positionDescription"
        ref={methods?.register}
        type="text"
        rows="5"
        label="opis stanowiska"
        required
        placeholder="W tym miejscu możesz opisać stanowisko"
        valueName="positionDescription"
        FormHelperTextProps={{ classes: { root: classes.helperTextError } }}
        helperText={methods?.errors.positionDescription?.message}
      />
    </div>
  );
};
