import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';

import { ChipDefault } from 'ui/chip/chipDefault/ChipDefault';
import { ChipBackground } from 'ui/chip/chipDefault/ChipDefault.types';

import { ChipsBoxProps } from './ChipsBox.types';
import { useStyles } from './ChipsBox.styles';

export const ChipsBox = ({ title, chips }: ChipsBoxProps): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>{title}</Typography>
      <Box mb={3} />
      <div className={classes.chipsWrapper}>
        {chips.map((chip, key) => (
          <ChipDefault
            key={key}
            caption={chip.caption}
            checked={chip.checked}
            background={ChipBackground.DARK}
            columnGap={2}
            rowGap={2}
          />
        ))}
      </div>
    </div>
  );
};
