import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { AuthenticationRoutes } from 'routing/authentication/AuthenticationRoutes';
import { MainRoutes } from 'routing/main/MainRoutes';
import { CompanyProfiles } from 'app/login/profile/companyProfiles/CompanyProfiles';
import './Root.css';

import { ProtectedRoute } from './protectedRoute/ProtectedRoute';
import { AppProviders } from './appProviders/AppProviders';
import { DebugHelper } from './debugHelper';

export const Root: React.FC = () => {
  const development: boolean = process.env.REACT_APP_DEBUGGER === 'true';

  return (
    <AppProviders>
      {development && <DebugHelper />}
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/login" />} />
        {AuthenticationRoutes.map((item, index) => (
          <Route key={index} path={item.path} exact={true} component={item.component} />
        ))}
        <ProtectedRoute path="/company-profiles" exact={true} component={CompanyProfiles} />
        {MainRoutes.map((item, index) => (
          <ProtectedRoute key={index} path={item.path} exact={true} component={item.component} />
        ))}
      </Switch>
    </AppProviders>
  );
};
