import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router } from 'react-router-dom';
import { HrMeThemeProvider } from '@hrme/shared';

import { AuthenticationProvider } from 'providers/authentication/AuthenticationProvider';
import { CompanyProvider } from 'providers/company/CompanyProvider';
import { JobOfferProvider } from 'providers/jobOffer/JobOfferProvider';
import { UserProvider } from 'providers/user/UserProvider';
import { CustomerProvider } from 'providers/customer/CustomerProvider';
import { checkEnv } from 'helpers/envChecker';
import { KnowledgeProvider } from 'providers/knowledge/KnowledgeProvider';
import { CandidateProvider } from 'providers/candidate/CandidateProvider';

import { AppProvidersProps } from './AppProviders.types';

const hotJarID = checkEnv('REACT_APP_HJ_ID');
const hotJarVer = checkEnv('REACT_APP_HJ_VER');

export const AppProviders = ({ children }: AppProvidersProps): JSX.Element => (
  <AuthenticationProvider>
    <UserProvider>
      <CompanyProvider>
        <CustomerProvider>
          <JobOfferProvider>
            <KnowledgeProvider>
              <CandidateProvider>
                <HrMeThemeProvider>
                  <Helmet>
                    <title>HR-ME for Business</title>
                    <script async defer>
                      {`
                      (function(h,o,t,j,a,r){
                          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                          h._hjSettings={hjid:${hotJarID},hjsv:${hotJarVer}};
                          a=o.getElementsByTagName('head')[0];
                          r=o.createElement('script');r.async=1;
                          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                          a.appendChild(r);
                        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                        `}
                    </script>
                  </Helmet>
                  <Router>{children}</Router>
                </HrMeThemeProvider>
              </CandidateProvider>
            </KnowledgeProvider>
          </JobOfferProvider>
        </CustomerProvider>
      </CompanyProvider>
    </UserProvider>
  </AuthenticationProvider>
);
