import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  dashboardContent: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  subtitle: {
    color: theme.palette.grey.A200,
  },
  currentPlanValue: {
    fontWeight: 600,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  link: {
    fontSize: 16,
    color: theme.palette.grey.A200,
    textAlign: 'center',
  },
  button: {
    textTransform: 'lowercase',
    fontWeight: 'normal',
    background: `linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.15) 66.67%, rgba(255, 255, 255, 0) 100%), ${theme.palette.secondary.main}`,
  },
  email: {
    color: theme.palette.text.primary,
    fontWeight: 600,
  },
}));
