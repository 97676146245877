import React from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Typography, useTheme } from '@material-ui/core';

import { MainPaths } from 'routing/main/MainPaths';
import { ButtonDefault } from 'ui/button/ButtonDefault';
import { LoginFlowLayout } from 'ui/loginFlowLayout/LoginFlowLayout';
import { Link } from 'ui/link/Link';

import { useStyles } from './AddCompanyConfirmationForm.styles';

export const AddCompanyConfirmationForm: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <LoginFlowLayout wide={true}>
      <CheckCircleOutlineIcon color="secondary" className={classes.checkIcon} />
      <Typography variant="h4" className={classes.header}>
        Nowa firma została dodana
      </Typography>
      <Typography className={classes.description} align="center">
        Możesz teraz opublikować swoją pierwszą ofertę pracy
      </Typography>
      <div className={classes.buttonContainer}>
        <Link href={MainPaths.addJobOffer}>
          <ButtonDefault>dodaj ofertę pracy</ButtonDefault>
        </Link>
        <div className={classes.dashboard}>
          <Link href={MainPaths.dashboard}>
            <Typography className={classes.dashboardLink}>dashboard</Typography>
          </Link>
        </div>
      </div>
    </LoginFlowLayout>
  );
};
