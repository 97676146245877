import React, { useEffect, useState } from 'react';
import { Box, FormControlLabel, InputLabel, Radio, RadioGroup, Typography, useTheme } from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { TextInput } from 'ui/textInput/TextInput';
import { FieldErrorWrap } from 'ui/fieldErrorWrap/FieldErrorWrap';
import { ShowErrorText } from 'ui/showErrorText/ShowErrorText';

import { RadioGroupWithOthersInputProps } from './RadioGroupWithOthersInput.types';
import { useStyles } from './RadioGroupWithOthersInput.styles';

export const RadioGroupWithOthersInputRHF: React.FC<RadioGroupWithOthersInputProps> = ({
  label,
  items,
  other,
  required,
  name = '',
  methods,
  onChange,
  value,
  helperText,
  reverse,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [radioButtonValue, setRadioButtonValue] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [inputValue, setInputValue] = useState('');

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const value = (target as HTMLInputElement).value as string;
    const type = (target as HTMLInputElement).type as string;

    if (type === 'radio') {
      setRadioButtonValue(value);
      methods?.setValue(name || '', value);

      const inputField = reverse
        ? methods?.getValues(name || '') === items[0].value
        : methods?.getValues(name || '') !== items[items.length - 1].value;
      methods?.clearErrors(name);
      methods?.clearErrors(other?.name);
      setDisabled(inputField);
    }
  };

  useEffect(() => {
    methods?.register(name || '');
  }, [methods, name]);

  const showError = reverse ? !!helperText && !inputValue && radioButtonValue !== 'none' : !!helperText;
  return (
    <FieldErrorWrap showErrorBackground={showError}>
      <InputLabel htmlFor={name}>
        <Typography variant="subtitle2" className={classes.label}>
          <span>{label}</span>
          {required && <span style={{ color: theme.palette.primary.main }}>*</span>}
        </Typography>
      </InputLabel>
      <RadioGroup
        row
        aria-label={name}
        value={value ? value : radioButtonValue}
        onChange={onChange ? onChange : handleChange}
        className={classes.radioGroup}
      >
        {items.map((item, key) => {
          return (
            <FormControlLabel
              key={`${item.value}-${key}`}
              value={item.value}
              checked={methods?.getValues(name) === item.value}
              control={<Radio icon={<RadioButtonUncheckedIcon />} checkedIcon={<CheckCircleOutlineIcon />} />}
              label={item.label}
              className={classes.formControl}
            />
          );
        })}
      </RadioGroup>
      <Box mb={2} />
      {other && (
        <TextInput
          valueName={other.name}
          name={other.name}
          label={reverse ? '' : items[items.length - 1].label}
          placeholder={other.placeholder}
          disabled={disabled}
          ref={methods?.register}
          onChange={(e) => setInputValue(e.target.value)}
        />
      )}
      {showError && items.length > 0 && <ShowErrorText marginTop errorMessage={helperText} />}
    </FieldErrorWrap>
  );
};
