import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  container: {
    width: '100%',
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  border: {
    borderBottom: '1px solid #d8d5dc',
    width: '100%',
  },
  content: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
    padding: theme.spacing(0.5, 2),
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  subtitle: {
    fontSize: '12px',
    maxWidth: 460,
    textAlign: 'center',
  },
}));
