import React from 'react';
import { useLocation } from 'react-router';
import { CircularProgress } from '@material-ui/core';

import { AddJobOfferNarrowPanelRHF } from 'app/jobOffer/addJobOfferRHF/addJobOfferNarrowPanelRHF/AddJobOfferNarrowPanelRHF';
import { DefaultLayoutRHF } from 'ui/defaultLayout/DefaultLayoutRHF';
import { AddJobOfferWidePanelRHF } from 'app/jobOffer/addJobOfferRHF/addJobOfferWidePanelRHF/AddJobOfferWidePanelRHF';
import { DashboardContentWideNarrowRHFProps } from 'ui/dashboard/dashboardContentWideNarrow/DashboardContentWideNarrow.types';
import { MainLayout } from 'ui/mainLayout/MainLayout';
import { AddJobOfferInitialValuesTypeRHF } from '../addJobOfferFormWrapperRHF/addJobOfferFormWrapperRHF.types';
import { useJobOfferContext } from 'hooks/useJobOfferContext/useJobOfferContext';

export const AddJobOfferContainerRHF: React.FC<DashboardContentWideNarrowRHFProps> = () => {
  const location: { state: { edit: AddJobOfferInitialValuesTypeRHF } } = useLocation();
  const { currentSubscription } = useJobOfferContext();
  const isEditing = !!location?.state;
  const offerEdit = location?.state?.edit;
  const editedOffer = {
    offerID: location?.state?.edit?.jobOfferId as string,
    offerStatus: location?.state?.edit?.status as string,
  };

  if (!currentSubscription) return <CircularProgress />;
  return (
    <MainLayout>
      <DefaultLayoutRHF headerText={isEditing ? 'Edytuj ofertę pracy' : 'Dodaj ofertę pracy'}>
        <AddJobOfferWidePanelRHF offerEdit={offerEdit} />
        <AddJobOfferNarrowPanelRHF editedOffer={editedOffer} />
      </DefaultLayoutRHF>
    </MainLayout>
  );
};
