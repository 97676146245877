import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AlertMessage } from '@hrme/shared';

import { ButtonDefault } from 'ui/button/ButtonDefault';
import { useUserContext } from 'hooks/useUserContext/useUserContext';
import { errorMessages } from 'helpers/errorMessages';

import { EditProfileGeneralContainerProps } from './EditProfileGeneral.types';
import { EditProfileGeneral } from './EditProfileGeneral';

const validationSchema = Yup.object({
  firstName: Yup.string().required('imię jest wymagane'),
  lastName: Yup.string().required('nazwisko jest wymagane'),
  companyName: Yup.string().required('nazwa firmy jest wymagana'),
  companyAddressLine1: Yup.string().required('adres jest wymagany'),
  country: Yup.string().required('kraj jest wymagany'),
  city: Yup.string().required('miasto jest wymagane'),
  postcode: Yup.string()
    .required('kod pocztowy jest wymagany')
    .matches(/[0-9]{2}-[0-9]{3}/, 'format kodu XX-XXX'),
  state: Yup.string().required('województwo jest wymagane'),
});

export const EditProfileGeneralContainer = ({ classes }: EditProfileGeneralContainerProps): JSX.Element => {
  const { user, loading, updateUserData, fetchUserData } = useUserContext();
  const [showAlertMessage, setShowAlertMessage] = useState<{ show: boolean; success: boolean; message?: string }>({
    show: false,
    success: false,
    message: '',
  });

  const initialValues = {
    firstName: user?.firstName ? user?.firstName : '',
    lastName: user?.lastName ? user?.lastName : '',
    email: user?.email ? user?.email : '',
    companyName: user?.companyName ? user?.companyName : '',
    companyAddressLine1: user?.companyAddressLine1 ? user?.companyAddressLine1 : '',
    country: user?.country ? user?.country : '',
    city: user?.city ? user?.city : '',
    postcode: user?.postcode ? user?.postcode : '',
    state: user?.state ? user?.state : '',
    nip: user?.nip ? user?.nip : '',
  };

  const { register, errors, handleSubmit, reset, control } = useForm({
    defaultValues: initialValues,
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: typeof initialValues) => {
    if (!user) {
      return;
    }

    const payload = { ...data, email: user.email };

    const response = await updateUserData(payload);

    if (response.success) {
      setShowAlertMessage({ show: true, success: true, message: 'Dane zostały zaktualizowane' });
    } else {
      setShowAlertMessage({ show: true, success: false, message: errorMessages(response.message) });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchUserData();
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user !== undefined) {
      reset(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <form>
      <EditProfileGeneral classes={classes} register={register} errors={errors} control={control} />
      <ButtonDefault loading={loading} onClick={handleSubmit(onSubmit)}>
        zapisz dane
      </ButtonDefault>
      <AlertMessage
        open={showAlertMessage}
        close={() =>
          setShowAlertMessage({ show: false, success: showAlertMessage.success, message: showAlertMessage.message })
        }
        message={showAlertMessage.message}
      />
    </form>
  );
};
