export type JobOfferActivationStatusBarProps = {
  plan: string;
  status: OfferStatus;
  validBy: string;
  offersAvailable: string;
  buttonCaption: string;
};

export enum OfferStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  DRAFT = 'draft',
}
