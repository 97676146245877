import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'start',
    width: 'max-content',
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  panel: {
    width: `calc(33.3% - 2/3*${theme.spacing(2)}px)`,
  },
  fullWidth: {
    width: '100%',
  },
}));
