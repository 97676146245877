import React from 'react';

import { DashboardContentContainer } from 'ui/dashboard/dashboardContentContainer/DashboardContentContainer';
import { DashboardContent } from 'ui/dashboard/dashboardContent/DashboardContent';
import { DashboardContentHeader } from 'ui/dashboard/dashboardContentHeader/DashboardContentHeader';
import { DashboardContentWideNarrow } from 'ui/dashboard/dashboardContentWideNarrow/DashboardContentWideNarrow';
// import { AddJobOfferFormWrapperRHF } from 'app/jobOffer/addJobOfferRHF/addJobOfferFormWrapperRHF/AddJobOfferFormWrapperRHF';

import { DefaultLayoutProps } from './DefaultLayout.types';

export const DefaultLayout: React.FC<DefaultLayoutProps> = ({ headerText, subHeader, children }) => {
  return (
    <DashboardContentContainer>
      <DashboardContent>
        <DashboardContentHeader headerText={headerText} subHeader={subHeader} />
        {/* <AddJobOfferFormWrapperRHF> */}
        <DashboardContentWideNarrow>{children}</DashboardContentWideNarrow>
        {/* </AddJobOfferFormWrapperRHF> */}
      </DashboardContent>
    </DashboardContentContainer>
  );
};
