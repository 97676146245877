import React, { useState } from 'react';
import { Box, InputLabel, Typography, useTheme } from '@material-ui/core';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { Message } from 'ui/message/Message';
import { ButtonDefault } from 'ui/button/ButtonDefault';
import { Link } from 'ui/link/Link';
import { AuthenticationPaths } from 'routing/authentication/AuthenticationPaths';
import { useAuthContext } from 'hooks/useAuthContext/useAuthContext';
import { TextInput } from 'ui/textInput/TextInput';

import { useStyles } from './LoginForm.styles';
import { LoginFormProps } from './LoginForm.types';

const initialValues = {
  email: '',
  password: '',
};

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Required'),
  password: Yup.string().required('Required'),
});

export const LoginForm: React.FC<LoginFormProps> = ({ confirmed }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { loginUser, currentUser } = useAuthContext();

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { handleSubmit, register } = useForm({
    defaultValues: initialValues,
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (values: typeof initialValues) => {
    setError(false);

    const response = await loginUser({ email: values.email, password: values.password });

    if (!response.success) {
      setError(true);

      if (response.message) {
        setErrorMessage(response.message);
      }
    }
  };

  return (
    <div>
      <Typography variant="h4">Zaloguj się do platformy</Typography>
      <Box mb={3} />

      {currentUser?.emailVerified && !error && (
        <>
          <Message text="Konto zostało potwierdzone, możesz się zalogować" type="success" />
          <Box mb={4} />
        </>
      )}

      {error && (
        <>
          <Message text={errorMessage} type="error" />
          <Box mb={4} />
        </>
      )}

      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <InputLabel htmlFor="email" className={classes.label}>
          adres e-mail
        </InputLabel>
        <TextInput
          name={'email'}
          placeholder={'Wpisz swój adres e-mail'}
          ref={register}
          onChange={() => setError(false)}
        />

        <InputLabel htmlFor="password" className={classes.label}>
          hasło
        </InputLabel>
        <TextInput
          name="password"
          placeholder={'Wpisz swoje hasło'}
          type="password"
          ref={register}
          onChange={() => setError(false)}
        />

        <Box mb={2} />
        <ButtonDefault>zaloguj się</ButtonDefault>
      </form>
      <Box mb={3} />
      <Link className={classes.remind} variant="body2" href={AuthenticationPaths.forgotPassword}>
        Zapomniałeś hasła?
      </Link>
    </div>
  );
};
