import React from 'react';
import clsx from 'clsx';
import { Box, useTheme } from '@material-ui/core';

import { PlatformHeader } from 'ui/platformHeader/PlatformHeader';

import { useStyles } from './LoginFlowLayout.styles';
import { LoginFlowLayoutProps } from './LoginFlowLayout.types';

export const LoginFlowLayout = ({
  children,
  footerComponent,
  type = 'default',
  wide = false,
}: LoginFlowLayoutProps): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const containerStyle = clsx({
    [classes.container]: true, //always applies
    [classes.paddingBottom]: type === 'default',
    [classes.smallPadding]: type === 'imageWithPadding',
  });

  const innerWrapperStyle = clsx({
    [classes.innerWrapper]: true,
    [classes.wide]: wide,
  });

  return (
    <div className={classes.wrapper}>
      <div className={innerWrapperStyle}>
        <PlatformHeader />
        <div className={containerStyle}>{children}</div>
        <Box mb={5} />
        <div>{footerComponent}</div>
      </div>
    </div>
  );
};
