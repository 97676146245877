import React, { useRef, useState } from 'react';
import { Box, InputLabel, Typography, useTheme } from '@material-ui/core';

import { TextInput } from 'ui/textInput/TextInput';
import { ButtonAdd } from 'ui/buttonAdd/ButtonAdd';

import { useStyles } from './Other.styles';
import { AddComapnyFormOtherProps } from './Other.types';

export const Other: React.FC<AddComapnyFormOtherProps> = ({ register, errors }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [image, setImage] = useState('');

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (event.target.files) {
      event.persist();
      setImage(URL.createObjectURL(event.target.files[0]));
      setImageLoaded(true);
    }
  };
  return (
    <>
      <InputLabel className={classes.label}>
        <Typography variant="subtitle2">dodaj logo</Typography>
      </InputLabel>
      <label htmlFor="image">
        <div className={classes.draggableContainer}>
          <input
            ref={(e) => {
              register(e);
              fileInputRef.current = e;
            }}
            id="image"
            name="image"
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          {imageLoaded && <img src={image} className={classes.image} alt="logo" />}
        </div>
      </label>
      <ButtonAdd onClick={() => fileInputRef?.current?.click()}>dodaj logo</ButtonAdd>
      <Box mb={2.25} />
      <TextInput
        name={'companyDescription'}
        label={'opis firmy'}
        placeholder={'Wpisz w kilku zdaniach opis firmy'}
        valueName={'companyDescription'}
        ref={register}
        rows={3}
        noBackground
      />
      <TextInput
        name={'predefinedMessage'}
        label={'predefiniowana wiadomość do kandydata'}
        placeholder={'Wpisz wiadomość, którą powinien otrzymać kandydat po zaproszeniu do oferty.'}
        valueName={'predefinedMessage'}
        ref={register}
        rows={3}
        noBackground
      />
    </>
  );
};
