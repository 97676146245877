"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatsLinear = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var core_1 = require("@material-ui/core");
var StatsLinear_styles_1 = require("./StatsLinear.styles");
var StatsLinear = function (_a) {
    var label = _a.label, value = _a.value, color = _a.color;
    var theme = core_1.useTheme();
    var classes = StatsLinear_styles_1.useStyles(theme);
    return (jsx_runtime_1.jsxs("div", __assign({ className: classes.container }, { children: [jsx_runtime_1.jsxs("div", __assign({ className: classes.labelBox }, { children: [jsx_runtime_1.jsx(core_1.Typography, __assign({ variant: "subtitle1" }, { children: label }), void 0), jsx_runtime_1.jsx(core_1.Box, { mr: 0.5 }, void 0), jsx_runtime_1.jsx(core_1.Typography, __assign({ variant: "body2", className: classes.value }, { children: value + "%" }), void 0)] }), void 0), jsx_runtime_1.jsx(core_1.Box, { mb: 1 }, void 0), jsx_runtime_1.jsx("div", __assign({ className: classes.graphBackground }, { children: jsx_runtime_1.jsx("div", { className: classes.graph, style: { backgroundColor: "" + color, width: value + "%" } }, void 0) }), void 0)] }), void 0));
};
exports.StatsLinear = StatsLinear;
