import React from 'react';
import { FormHelperText, useTheme } from '@material-ui/core';
import clsx from 'clsx';

import { ShowErrorTextProps } from './ShowErrorText.types';
import { useStyles } from './ShowErrorText.styles';

export const ShowErrorText: React.FC<ShowErrorTextProps> = ({ errorMessage, marginTop }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const container = clsx({
    [classes.helperTextError]: true,
    [classes.helperTextMargin]: marginTop,
  });

  return <FormHelperText className={container}>{errorMessage}</FormHelperText>;
};
