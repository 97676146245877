import React from 'react';
import { Box } from '@material-ui/core';

import { CompanyProfileBoxContentProps } from 'ui/companyProfileBox/companyProfileBoxContent/CompanyProfileBoxContent.types';
import { CompanyProfileBoxContainer } from 'ui/companyProfileBox/companyProfileBoxContainer/CompanyProfileBoxContainer';
import { ButtonDefault } from 'ui/button/ButtonDefault';
import { CompanyProfileBoxInfo } from 'ui/companyProfileBox/companyProfileBoxInfo/CompanyProfileBoxInfo';

export const CompanyProfileBoxContent: React.FC<CompanyProfileBoxContentProps> = ({
  children,
  logotype,
  name,
  activeOffers,
  buttonCaption = 'wybierz',
  buttonAction = () => console.log('profile box button clicked'),
}) => {
  return (
    <CompanyProfileBoxContainer>
      {children ? (
        children
      ) : (
        <>
          <CompanyProfileBoxInfo logotype={logotype} name={name} activeOffers={activeOffers} />
          <Box mb={3} />
          <ButtonDefault onClick={buttonAction}>{buttonCaption}</ButtonDefault>
        </>
      )}
    </CompanyProfileBoxContainer>
  );
};
