import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    paddingTop: theme.spacing(6),
    paddingLeft: theme.spacing(4),
  },
  text: {
    textAlign: 'left',
    marginBottom: 8,
    fontSize: 40,
    fontWeight: 700,
  },
  subheader: {
    color: '#A4A4A4',
    textAlign: 'left',
  },
}));
