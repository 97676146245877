import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
  },
  containerNarrow: {
    width: `calc(95% - ${theme.spacing(1.5)}px)`,
    marginRight: theme.spacing(3),
    flex: 1,
  },
  label: {
    textAlign: 'left',
    marginBottom: theme.spacing(1),
    fontWeight: 400,
    color: theme.palette.grey[800],
  },
  select: {
    minWidth: '100%',
    marginBottom: theme.spacing(2.25),
    textAlign: 'left',
    backgroundColor: theme.palette.background.paper,
  },
  menuPaper: {
    maxHeight: 200,
  },
  placeholder: {
    color: theme.palette.grey[400],
  },
  helperTextError: {
    color: '#f44336',
    fontSize: '.8em',
    margin: '0',
    background: '#fff9f9',
    padding: '5px',
  },
  smallLabel: {
    fontSize: '11px',
  },
}));
