"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatsBar = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var core_1 = require("@material-ui/core");
var StatsBar_styles_1 = require("./StatsBar.styles");
var StatsBarItems_1 = require("./statsBarItems/StatsBarItems");
var StatsBarGraphs_1 = require("./statsBarGraphs/StatsBarGraphs");
var StatsBar = function (_a) {
    var _b = _a.data, data = _b === void 0 ? [] : _b, _c = _a.valuesInLabels, valuesInLabels = _c === void 0 ? false : _c;
    var theme = core_1.useTheme();
    var classes = StatsBar_styles_1.useStyles(theme);
    return (jsx_runtime_1.jsxs("div", __assign({ className: classes.container }, { children: [jsx_runtime_1.jsx("div", __assign({ className: classes.bars }, { children: jsx_runtime_1.jsx(StatsBarGraphs_1.StatsBarGraphs, { data: data, valuesInLabels: valuesInLabels }, void 0) }), void 0), jsx_runtime_1.jsx(core_1.Box, { mb: 1 }, void 0), jsx_runtime_1.jsx("div", __assign({ className: classes.chartKeys }, { children: jsx_runtime_1.jsx(StatsBarItems_1.StatsBarItems, { data: data, valuesInLabels: valuesInLabels }, void 0) }), void 0)] }), void 0));
};
exports.StatsBar = StatsBar;
