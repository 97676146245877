import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  label: {
    textAlign: 'left',
    marginBottom: theme.spacing(1),
    fontWeight: 400,
    color: theme.palette.grey[800],
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
  },
  formControl: {
    marginRight: theme.spacing(4),
  },
}));
