"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Counter = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var core_1 = require("@material-ui/core");
var Counter_styles_1 = require("./Counter.styles");
var Counter = function (_a) {
    var value = _a.value, title = _a.title, subtitle = _a.subtitle;
    var theme = core_1.useTheme();
    var classes = Counter_styles_1.useStyles(theme);
    return (jsx_runtime_1.jsxs("div", __assign({ className: classes.container }, { children: [jsx_runtime_1.jsx(core_1.Typography, __assign({ variant: "h4", className: classes.counter }, { children: value.toString() }), void 0), jsx_runtime_1.jsxs("div", __assign({ className: classes.counterLabel }, { children: [jsx_runtime_1.jsx(core_1.Typography, __assign({ variant: "body2", className: classes.counterTitle }, { children: title }), void 0), !!subtitle && (jsx_runtime_1.jsx(core_1.Typography, __assign({ variant: "body2", className: classes.counterSubtitle }, { children: subtitle }), void 0))] }), void 0)] }), void 0));
};
exports.Counter = Counter;
