import React, { useEffect, useState } from 'react';
import firebase from 'firebase';
import dayjs from 'dayjs';

import { CompanyContext } from 'context/company/CompanyContext';
import { Company, CompanyStats, NewCompanyProps } from 'context/company/CompanyContext.types';
import { firebaseRef } from 'api/firebaseRef';
import { useAuthContext } from 'hooks/useAuthContext/useAuthContext';
import { onError, onSuccess } from 'providers/providersHelpers';
import { EditCompanyProps } from 'app/user/editCompanyPage/EditCompanyPage.types';

export const CompanyProvider: React.FC = ({ children }) => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedCompany, setSelectedCompany] = useState<Company | undefined>(undefined);
  const { currentUser } = useAuthContext();
  const [companyStats, setCompanyStats] = useState<CompanyStats | null>(null);

  useEffect(() => {
    const fetchAsyncData = async () => {
      await fetchCompanies();
    };
    fetchAsyncData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, selectedCompany]);

  const addNewCompany = async (newCompany: NewCompanyProps) => {
    setLoading(true);

    if (currentUser !== null) {
      const companyDocument = {
        ...newCompany,
        userId: currentUser.uid,
        created: dayjs().format('DD-MM-YYYY'),
      };

      const newDocument = await firebaseRef.firestore().collection('companies').add(companyDocument);

      const userDocument = firebaseRef.firestore().collection('users').doc(currentUser.uid);

      userDocument.update({
        companies: firebase.firestore.FieldValue.arrayUnion(newDocument.id),
      });

      if (selectedCompany === undefined) {
        setSelectedCompany(companyDocument);
      }

      return onSuccess(setLoading, 'A new company has been successfully added');
    } else {
      return onError(setLoading, 'Unable to add new company.');
    }
  };

  const updateCompany = async (newCompany: EditCompanyProps) => {
    const { docRef, ...updateData } = newCompany;
    console.log('new company update', newCompany);

    setLoading(true);

    try {
      const companySnapshot = await firebaseRef.firestore().collection('companies').doc(docRef);
      await companySnapshot.update(updateData);
      if (currentUser !== null && selectedCompany !== undefined) {
        // make a separate copy of the array
        const array = [...companies];
        const index = array.findIndex((el) => el.docRef === docRef);

        if (index !== -1) {
          // Get current state of company
          const currentCompany = array[index];

          // Update with new values
          const newCompany = { ...currentCompany, ...updateData };

          // Replace in copy of array
          array.splice(index, 1, newCompany);

          // Replaces companies array
          setCompanies(array);

          if (newCompany.docRef === selectedCompany.docRef) {
            setSelectedCompany(newCompany);
          }

          return onSuccess(setLoading, 'A new company has been successfully added');
        } else {
          return onError(setLoading, 'Unable to add new company.');
        }
      } else {
        return onError(setLoading, 'Unable to add new company.');
      }
    } catch (error) {
      return onError(setLoading, `Unable to update user data. Error message: ${error}`);
    }
  };

  const fetchCompanies = async () => {
    setLoading(true);

    setCompanies([]);

    try {
      if (!currentUser) return onError(setLoading, 'Unable to add new company.');

      const companiesQuery = await firebaseRef
        .firestore()
        .collection('companies')
        .where('userId', '==', currentUser?.uid)
        .get();
      if (companiesQuery !== null) {
        const companyArray = [] as Company[];

        companiesQuery.forEach((company) => {
          const item = company.data() as Company;
          companyArray.push({ ...item, docRef: company.id });
        });

        setCompanies(companyArray);

        if (selectedCompany === undefined) {
          setSelectedCompany(companyArray[0]);
        }

        return onSuccess(setLoading, 'Companies have been successfully fetched');
      } else {
        return onError(setLoading, 'Unable to fetch companies.');
      }
    } catch (error) {
      return onError(setLoading, 'Unable to fetch companies.');
    }
  };

  const updateSelectedCompany = (companyName: string) => {
    const findCompany = companies.find((el) => el.companyName === companyName);

    if (findCompany !== undefined) {
      setSelectedCompany(findCompany);
    }
  };

  const fetchCompanyStats = async (companyId: string) => {
    setLoading(true);
    setCompanyStats(null);

    try {
      if (!currentUser) return onError(setLoading, 'Unable to fetch company stats.');

      const companiesQuery = await firebaseRef.firestore().collection('companies').doc(companyId).get();

      if (companiesQuery !== null && companiesQuery?.data()?.statistics) {
        setCompanyStats(companiesQuery?.data()?.statistics);

        return onSuccess(setLoading, 'Companies stats have been successfully fetched');
      } else {
        return onError(setLoading, 'Unable to fetch company stats.');
      }
    } catch (error) {
      return onError(setLoading, 'Unable to fetch company stats.');
    }
  };

  const deleteCompany = async (companyId: string) => {
    setLoading(true);

    try {
      if (!currentUser) return onError(setLoading, 'Unable to delete company.');

      // cleaning 'users' collection
      const jobOffers = await firebaseRef
        .firestore()
        .collection('users')
        .doc(currentUser?.uid)
        .collection('job_offers')
        .where('companyId', '==', companyId)
        .get();
      jobOffers.forEach(async (element) => await element.ref.delete());

      // cleaning 'communication/currentUser?.uid/job_offers' collection
      const communicationOffers = await firebaseRef
        .firestore()
        .collection('communication')
        .doc(currentUser?.uid)
        .collection('job_offers')
        .where('companyId', '==', companyId)
        .get();
      communicationOffers.forEach(async (element) => await element.ref.delete());

      // cleaning 'companies' collection
      await firebaseRef.firestore().collection('companies').doc(companyId).delete();
      const companiesArray = firebaseRef.firestore().collection('users').doc(currentUser?.uid);
      companiesArray.update({
        companies: firebase.firestore.FieldValue.arrayRemove(companyId),
      });

      await fetchCompanies();
      setSelectedCompany(companies[0].docRef === companyId ? companies[1] : companies[0]);

      return onSuccess(setLoading, 'Company has been successfully deleted');
    } catch (error) {
      return onError(setLoading, 'Unable to delete company.');
    }
  };

  return (
    <CompanyContext.Provider
      value={{
        updateSelectedCompany,
        addNewCompany,
        selectedCompany,
        updateCompany,
        fetchCompanies,
        companies,
        loading,
        fetchCompanyStats,
        companyStats,
        deleteCompany,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};
