import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: 'auto 345px',
    columnGap: theme.spacing(4),
    padding: theme.spacing(0, 4),
  },

  wrapperFlex: {
    display: 'flex',
    padding: theme.spacing(0, 4),
  },
}));
