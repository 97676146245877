import React from 'react';

import { ExtendableList } from 'ui/extendableListRhf/ExtendableList';
import { TextInput } from 'ui/textInput/TextInput';

import { SocialMediaProps } from './SocialMedia.types';

export const SocialMedia: React.FC<SocialMediaProps> = ({ methods, errors, register }) => {
  return (
    <>
      <TextInput
        name={'facebook'}
        label={'facebook'}
        valueName={'facebook'}
        error={!!errors.facebook}
        ref={register}
        placeholder={'np. https://facebook.com/hr-me'}
      />
      <TextInput
        name={'linkedin'}
        label={'linkedin'}
        valueName={'linkedin'}
        error={!!errors.linkedin}
        ref={register}
        placeholder={'np. https://linkedin.com/hr-me'}
      />
      <ExtendableList
        label="inne portale"
        name="otherSocialMedia"
        buttonCaption="dodaj"
        addInputPlaceholder="Wpisz link"
        setValue={methods.setValue}
        getValues={methods.getValues}
        control={methods.control}
      />
    </>
  );
};
