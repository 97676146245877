import { useContext } from 'react';

import { KnowledgeContextProps } from 'context/knowledge/KnowledgeContext.types';
import { KnowledgeContext } from 'context/knowledge/KnowledgeContext';

export const useKnowledge: () => KnowledgeContextProps = () => {
  const context = useContext(KnowledgeContext);

  if (context === undefined) {
    throw Error('useKnowledge must be used within a KnowledgeContext');
  }

  return context;
};
