import { Box, Button, Typography, useTheme } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { DashboardContentContainer } from 'ui/dashboard/dashboardContentContainer/DashboardContentContainer';
// import { MessageBar } from 'ui/messageBar/MessageBar';
import { JobOfferActivationStatusBar } from 'app/jobOffer/jobOfferActivationStatusBar/JobOfferActivationStatusBar';
import { DashboardContent } from 'ui/dashboard/dashboardContent/DashboardContent';
import { DashboardSection } from 'ui/dashboard/dashboardSection/DashboardSection';
import { DashboardCounterPanel } from 'ui/dashboard/dashboardCounterPanel/DashboardCounterPanel';
import { MainLayout } from 'ui/mainLayout/MainLayout';
import { DashboardApplicantsAccordions } from 'ui/dashboard/dashboardApplicantsAccordions/DashboardApplicantsAccordions';
import { MainPaths } from 'routing/main/MainPaths';
import { useCompanyContext } from 'hooks/useCompanyContext/useCompanyContext';
import { DashboardCounterData } from 'ui/dashboard/dashboardCounterPanel/DashboardCounterPanel.types';
import { useJobOfferContext } from 'hooks/useJobOfferContext/useJobOfferContext';
import { ScheduledOffersType } from 'context/jobOffer/JobOfferContext.types';

import { useStyles } from './Dashboard.styles';

export const Dashboard = (): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();
  const { selectedCompany, fetchCompanyStats, companyStats } = useCompanyContext();
  const { scheduledOffers } = useJobOfferContext();
  const [alerts, setAlers] = useState<ScheduledOffersType[] | null>(null);

  useEffect(() => {
    if (alerts === null) setAlers(scheduledOffers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduledOffers]);

  useEffect(() => {
    const fetchAsyncData = async () => {
      if (selectedCompany?.docRef) await fetchCompanyStats(selectedCompany.docRef);
    };
    fetchAsyncData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany]);

  const totalCandidates = companyStats?.allCandidates ? +companyStats.allCandidates : 0;
  const activeJobs = companyStats?.activeJobOffers ? +companyStats.activeJobOffers : 0;
  const acceptedCandidates = companyStats?.acceptedCandidates ? +companyStats.acceptedCandidates : 0;
  const invitedCandidates = companyStats?.invitedCandidates ? +companyStats.invitedCandidates : 0;
  const inactiveJobs = companyStats?.inactiveJobOffers ? +companyStats.inactiveJobOffers : 0;
  const finishedJobs = companyStats?.finishedJobOffers ? +companyStats.finishedJobOffers : 0;

  const COUNTERS: DashboardCounterData[][] = [
    [
      { label: 'kandydatów do oferty pracy', value: totalCandidates },
      { label: 'kandydatów zaproszonych do oferty', value: invitedCandidates },
      { label: 'kandydatów zainteresowanych ofertą', value: acceptedCandidates },
    ],
    [
      { label: 'aktywne oferty pracy', value: activeJobs },
      { label: 'zakończonych ofert pracy', value: finishedJobs },
      { label: 'nieaktywne oferty pracy', value: inactiveJobs },
    ],
  ];

  return (
    <MainLayout>
      <DashboardContentContainer>
        <JobOfferActivationStatusBar />
        <Box mb={3} />
        {/* {alerts?.map(({ offerTitle, validUntil }, idx) => {
          const date = new Date().getTime() + 604800000;
          const isLessThan7Days = validUntil < date;
          return (
            <>
              {isLessThan7Days && (
                <>
                  <div key={validUntil}>
                    <MessageBar
                      message={`Oferta o tytule: ${offerTitle} za mniej niż 7 dni`}
                      dismissCaption="usuń powiadomienie"
                      dismiss={() => setAlers(alerts.filter((alert) => alert.validUntil !== validUntil))}
                    />
                    <Box mb={1} />
                  </div>
                  {idx === alerts.length - 1 && <Box mb={4} />}
                </>
              )}
            </>
          );
        })} */}
        <DashboardContent hasBottomPadding={false}>
          <DashboardSection title="Dashboard">
            <Box mb={4} />
            <DashboardCounterPanel
              title="Kandydaci"
              link="zobacz kandydatów"
              handleLinkClick={() => history.push(MainPaths.applicantsListFromJobOffers)}
              counters={COUNTERS[0]}
            />
            <Box mb={4} />
            <DashboardCounterPanel
              title="Oferty pracy"
              link="zobacz oferty pracy"
              handleLinkClick={() => history.push(MainPaths.jobOffers)}
              counters={COUNTERS[1]}
            />
          </DashboardSection>
          <Box className={classes.box} />
          <DashboardSection title="Najnowsi kandydaci">
            <Box mb={4} />
            <DashboardApplicantsAccordions />
          </DashboardSection>
          <div className={classes.buttonContainer}>
            <Link to={MainPaths.applicantsListFromJobOffers} className={classes.link}>
              <Button variant="contained" color="secondary" disableElevation size="large" className={classes.button}>
                <Typography variant="body2">zobacz wszystkich kandydatów</Typography>
              </Button>
            </Link>
          </div>
          <Box mb={4} />
          <Box className={classes.box} />
        </DashboardContent>
      </DashboardContentContainer>
    </MainLayout>
  );
};
