import React from 'react';
import { Box, Button, Typography, useTheme } from '@material-ui/core';
import { Redirect, useHistory } from 'react-router-dom';
import { typeConvert } from '@hrme/shared';

import { DashboardContent } from 'ui/dashboard/dashboardContent/DashboardContent';
import { DashboardContentContainer } from 'ui/dashboard/dashboardContentContainer/DashboardContentContainer';
import { SummaryContainer } from 'ui/summaryContainer/SummaryContainer';
import { MainLayout } from 'ui/mainLayout/MainLayout';
import { JobOfferStatus } from 'context/jobOffer/JobOfferContext.types';
import { MainPaths } from 'routing/main/MainPaths';

import { useStyles } from './JobOfferActivated.styles';
import { NewOfferProps } from './JobOfferActivated.types';

export const JobOfferActivated: React.FC<NewOfferProps> = ({ location }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();

  if (!location.state) return <Redirect to={MainPaths.dashboard} />;
  const {
    position = '',
    hireWhen = '',
    company = '',
    salaryRangeFrom = '',
    salaryRangeTo = '',
    contractType = [],
    localization = '',
    mainTechnology = '',
    offerStatus = '',
  } = location.state.offerData;

  const salary = salaryRangeTo ? `${salaryRangeFrom} - ${salaryRangeTo}` : salaryRangeFrom;

  const jobOfferSummaryDetails: { label: string; value: string }[] = [
    { label: 'Tytuł oferty', value: position },
    { label: 'Od kiedy', value: hireWhen },
    { label: 'Firma', value: company },
    { label: 'Zarobki', value: salary },
    { label: 'Rodzaj umowy', value: contractType?.map((type) => typeConvert(type)).join(',') },
    { label: 'Lokalizacja', value: typeConvert(localization) },
    { label: 'Główna technologia', value: mainTechnology },
  ];

  const handleViewOffer = () => {
    history.push({
      pathname: MainPaths.jobOfferDetail,
      state: { ...currentOffer },
    });
  };

  const handleEditOffer = () => {
    history.push({
      pathname: MainPaths.addJobOffer,
      state: { edit: { ...currentOffer } },
    });
  };

  const isDraft = offerStatus === JobOfferStatus.DRAFT;
  const isEdited = offerStatus === JobOfferStatus.EDITED;

  const currentOffer = { ...location.state.offerData, ...location.state, status: 'active' };

  return (
    <MainLayout>
      <DashboardContentContainer>
        <Box mb={3} />
        <DashboardContent>
          <Box className={classes.dashboardContent}>
            <Box mt={6} />
            <Typography variant="h2">
              {isDraft && 'Oferta została zapisana jako robocza'}
              {isEdited && 'Oferta została zaktualizowana'}
              {!isDraft && !isEdited && 'Twoja oferta została aktywowana'}
            </Typography>
            {!isDraft && !isEdited && (
              <>
                <Box mb={1} />
                <Typography variant="body2" className={classes.subtitle}>
                  Nasz algorytm teraz dopasuje do niej kandydatów
                </Typography>
                <Box mt={6} />
                <SummaryContainer title="Podsumowanie zamówienia" summaryItems={jobOfferSummaryDetails} />
              </>
            )}
            <Box mt={6} />
          </Box>
          <Box className={classes.buttonContainer}>
            {!isDraft && (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  disableElevation
                  className={classes.button}
                  onClick={handleViewOffer}
                >
                  zobacz ofertę
                </Button>
                <Box mr={4} />
              </>
            )}
            <Box onClick={handleEditOffer}>
              <Typography className={classes.link} onClick={handleEditOffer}>
                edytuj ofertę
              </Typography>
            </Box>
          </Box>
        </DashboardContent>
      </DashboardContentContainer>
    </MainLayout>
  );
};
