import { useTheme } from '@material-ui/core';
import React from 'react';

import { useStyles } from 'ui/dashboard/dashboardContentContainer/DashboardContentContainer.styles';

export const DashboardContentContainer: React.FC = React.memo(({ children }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return <div className={classes.container}>{children}</div>;
});

DashboardContentContainer.displayName = 'DashboardContentContainer';
