import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
    textTransform: 'lowercase',
    fontWeight: 'normal',
    color: theme.palette.common.black,
  },
}));
