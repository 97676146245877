import React from 'react';

import { DashboardContentContainer } from 'ui/dashboard/dashboardContentContainer/DashboardContentContainer';
import { DashboardContent } from 'ui/dashboard/dashboardContent/DashboardContent';
import { DashboardContentHeader } from 'ui/dashboard/dashboardContentHeader/DashboardContentHeader';
import { AddJobOfferFormWrapperRHF } from 'app/jobOffer/addJobOfferRHF/addJobOfferFormWrapperRHF/AddJobOfferFormWrapperRHF';
import { DashboardContentWideNarrowRHF } from 'ui/dashboard/dashboardContentWideNarrow/DashboardContentWideNarrowRHF';

import { DefaultLayoutProps } from './DefaultLayout.types';

export const DefaultLayoutRHF: React.FC<DefaultLayoutProps> = ({ headerText, subHeader, children }) => {
  return (
    <DashboardContentContainer>
      <DashboardContent>
        <DashboardContentHeader headerText={headerText} subHeader={subHeader} />
        <AddJobOfferFormWrapperRHF>
          <DashboardContentWideNarrowRHF>{children}</DashboardContentWideNarrowRHF>
        </AddJobOfferFormWrapperRHF>
      </DashboardContent>
    </DashboardContentContainer>
  );
};
