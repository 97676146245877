/* eslint-disable @typescript-eslint/ban-ts-comment */

import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, Typography, useTheme } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';

import { FieldErrorWrap } from 'ui/fieldErrorWrap/FieldErrorWrap';
import { ShowErrorText } from 'ui/showErrorText/ShowErrorText';

import { TextSelectProps } from './TextSelect.types';
import { useStyles } from './TextSelect.styles';

export const TextSelectRHF: React.FC<TextSelectProps> = ({
  name,
  items,
  label,
  disabled,
  required,
  narrow,
  error,
  onChange,
  value,
  helperText,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const container = clsx({
    [classes.container]: true,
    [classes.containerNarrow]: narrow,
  });

  return (
    <FieldErrorWrap showErrorBackground={!!helperText}>
      <div className={container}>
        <InputLabel>
          <Typography variant="subtitle2" className={classes.label}>
            <span>{label}</span>
            {required && <span style={{ color: theme.palette.primary.main }}>*</span>}
          </Typography>
        </InputLabel>
        <FormControl variant="outlined" className={classes.select} disabled={disabled} error={error}>
          <Select name={name} value={value || ''} IconComponent={ExpandMoreIcon} onChange={onChange}>
            {items.map((item, index) => (
              <MenuItem key={index} value={item.value} disabled={item.value === 'placeholder'}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
          {helperText && <ShowErrorText errorMessage={helperText} />}
        </FormControl>
      </div>
    </FieldErrorWrap>
  );
};
