import React, { useEffect, useState } from 'react';
import { Box, InputLabel, Typography, useTheme } from '@material-ui/core';

import { FieldErrorWrap } from 'ui/fieldErrorWrap/FieldErrorWrap';
import { ShowErrorText } from 'ui/showErrorText/ShowErrorText';
import { CheckBoxWithLabel } from 'ui/checkBoxWithLabel/CheckBoxWithLabel';

import { CheckboxGroupProps } from './CheckboxGroup.types';
import { useStyles } from './CheckboxGroup.styles';

export const CheckboxGroup: React.FC<CheckboxGroupProps> = ({ name, label, required, items, methods }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [contractTypes, setContractTypes] = useState<string[]>([]);
  const [touched, setTouched] = useState(false);

  const handleChange = (value: string) => {
    const index = contractTypes.indexOf(value);
    if (index === -1) {
      setContractTypes([...contractTypes, value]);
    } else {
      setContractTypes(contractTypes.filter((item) => item !== value));
    }
    setTouched(true);
  };

  useEffect(() => {
    methods?.setValue(name, contractTypes);

    if (touched) {
      if (contractTypes.length > 0) {
        methods?.clearErrors(name);
      } else {
        methods?.setError(name, { message: 'Musisz wybrać formę zatrudnienia' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractTypes]);

  useEffect(() => {
    methods?.register(name || '');
    setContractTypes(methods?.getValues(name) as string[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showError = methods?.errors?.[name]?.message;

  return (
    <FieldErrorWrap showErrorBackground={!!showError}>
      <InputLabel htmlFor={name}>
        <Typography variant="subtitle2" className={classes.label}>
          <span>{label}</span>
          {required && <span style={{ color: theme.palette.primary.main }}>*</span>}
        </Typography>
      </InputLabel>
      <Box mb={1.5} />
      <Box className={classes.checkboxContainer}>
        {items.map((item) => {
          return (
            <CheckBoxWithLabel
              value={contractTypes.includes(item.value)}
              onChange={() => handleChange(item.value)}
              label={item.label}
            />
          );
        })}
      </Box>
      <Box mt={1.5} />
      {showError && <ShowErrorText marginTop errorMessage={showError} />}
    </FieldErrorWrap>
  );
};
