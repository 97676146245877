"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ButtonProfile = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var ButtonProfile_styles_1 = require("./ButtonProfile.styles");
var ButtonProfile = function (_a) {
    var children = _a.children, _b = _a.fullWidth, fullWidth = _b === void 0 ? false : _b, startIcon = _a.startIcon, _c = _a.variant, variant = _c === void 0 ? 'text' : _c, disabled = _a.disabled, onClick = _a.onClick;
    if (variant === 'contained') {
        return (jsx_runtime_1.jsx(ButtonProfile_styles_1.StyledButtonContained, __assign({ variant: variant, disabled: disabled, disableElevation: true, fullWidth: fullWidth, startIcon: startIcon, onClick: onClick }, { children: children }), void 0));
    }
    if (variant === 'outlined') {
        return (jsx_runtime_1.jsx(ButtonProfile_styles_1.StyledButtonOutlined, __assign({ variant: variant, disableElevation: true, disabled: disabled, fullWidth: fullWidth, startIcon: startIcon, onClick: onClick }, { children: children }), void 0));
    }
    if (variant === 'text') {
        return (jsx_runtime_1.jsx(ButtonProfile_styles_1.StyledButtonText, __assign({ variant: variant, disableElevation: true, disabled: disabled, fullWidth: fullWidth, startIcon: startIcon, onClick: onClick }, { children: children }), void 0));
    }
    return null;
};
exports.ButtonProfile = ButtonProfile;
