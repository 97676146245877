import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import firebase from 'firebase';

import { firebaseRef } from 'api/firebaseRef';
import { useAuthContext } from 'hooks/useAuthContext/useAuthContext';
import { onError, onSuccess } from 'providers/providersHelpers';
import { JobOfferContext } from 'context/jobOffer/JobOfferContext';
import { AddJobOfferInitialValuesTypeRHF } from 'app/jobOffer/addJobOfferRHF/addJobOfferFormWrapperRHF/addJobOfferFormWrapperRHF.types';
import { useCompanyContext } from 'hooks/useCompanyContext/useCompanyContext';
import {
  IInvite,
  JobOffer,
  JobOfferStatus,
  PricesType,
  ScheduledOffersType,
} from 'context/jobOffer/JobOfferContext.types';
import { delay } from 'helpers/utils';
import { MainPaths } from 'routing/main/MainPaths';
import { useUserContext } from 'hooks/useUserContext/useUserContext';
import { InvitationStatus } from 'ui/dashboard/dashboardApplicantsPanel/DashboardApplicantsPanel.types';
import { to } from 'helpers/to';

export const JobOfferProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [jobOffers, setJobOffers] = useState<JobOffer[]>([]);
  const [prices, setPrices] = useState<PricesType | null>(null);
  const [currentSubscription, setCurrentSubscription] = useState<Record<string, string | number | unknown> | null>(
    null,
  );
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);
  const [lastPayment, setLastPayment] = useState({
    type: '',
    price: 0,
    tokens: 0,
  });
  const [scheduledOffers, setScheduledOffers] = useState<ScheduledOffersType[] | null>(null);

  const { currentUser } = useAuthContext();
  const { selectedCompany, fetchCompanies } = useCompanyContext();
  const [initialFetch, setInitialFetch] = useState(true);
  const [singleJobOffer, setSingleJobOffer] = useState<AddJobOfferInitialValuesTypeRHF | null>(null);
  const { user } = useUserContext();

  useEffect(() => {
    const fetchAsyncData = async () => {
      setInitialFetch(false);
      await fetchPrices();
      await fetchLastPayment();
      await fetchCurrentSubscription();
      await fetchCompanies();
      await fetchJobOffers();
      await fetchScheduledOffers();
    };
    if (currentUser && initialFetch) fetchAsyncData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, selectedCompany]);

  const addNewJobOffer = async (
    newJobOffer: AddJobOfferInitialValuesTypeRHF,
    status: JobOfferStatus,
    isPAYG: boolean,
  ) => {
    setLoading(true);

    if (!currentUser) return onError(setLoading, 'Unable to add new job offer (draft).');

    const newDocument = firebaseRef.firestore().collection('users').doc(currentUser.uid).collection('job_offers');

    if (newDocument !== null) {
      const newJobOfferRef = await newDocument.add({
        ...newJobOffer,
        status,
        pay_as_you_go: isPAYG,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        applicants: 0,
        offersAccepted: 0,
        offersSent: 0,
      });

      return onSuccess(setLoading, `A new job offer has been successfully added.${newJobOfferRef.id}`);
    }
    return onError(setLoading, 'Unable to add new job offer company.');
  };

  const fetchJobOffers = async () => {
    setLoading(true);

    if (!currentUser) return onError(setLoading, 'No user data - fetch offers not possible.');

    const offersCommunication = await firebaseRef
      .firestore()
      .collection('communication')
      .doc(currentUser.uid)
      .collection('job_offers')
      .orderBy('status', 'asc')
      .get();

    const offersUser = await firebaseRef
      .firestore()
      .collection('users')
      .doc(currentUser.uid)
      .collection('job_offers')
      .where('status', '==', 'draft')
      .get();

    if (offersCommunication !== null) {
      const jobOfferArray = [] as JobOffer[];
      for (const job of offersCommunication.docs) {
        const matchedCandidates: unknown[] = [];
        const potentialCandidates: unknown[] = [];

        const candidatesArray = await job.ref.collection('matched_candidates').get();
        const potentialCandidatesArray = await job.ref.collection('potential_candidates').get();

        candidatesArray.forEach((candidate) => matchedCandidates.push(candidate.data()));
        potentialCandidatesArray.forEach((candidate) => potentialCandidates.push(candidate.data()));

        const obj = { ...job.data(), jobOfferId: job.id, matchedCandidates, potentialCandidates };
        jobOfferArray.push(obj as JobOffer);
      }

      offersUser.forEach((job) => {
        const obj = { ...job.data(), jobOfferId: job.id };
        jobOfferArray.push(obj as JobOffer);
      });
      await delay(500);

      setJobOffers(jobOfferArray);

      return onSuccess(setLoading, 'Job offers have been successfully fetched');
    }
    return onError(setLoading, 'Unable to fetch job offers.');
  };

  const sendToCheckout = async (
    selectedPrice: string,
    isPAYG: boolean,
    invoiceData: Record<string, string>,
    offerID_PAYG?: string,
  ) => {
    if (!currentUser) return onError(setLoading, 'No user data - checkout not possible.');

    try {
      const result = await firebaseRef.functions('europe-west1').httpsCallable('createCheckoutSession')({
        ...(isPAYG ? { mode: 'payment', metadata: { offer_id: offerID_PAYG } } : { mode: 'subscription' }),
        price: selectedPrice,
        success_url: `${window.location.origin}/main/payment-confirmed`,
        cancel_url: `${window.location.origin}/main/payment-variants`,
      });
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY as string);
      const sessionId = result.data;
      await stripe?.redirectToCheckout({ sessionId });
    } catch (err) {
      return onError(setLoading, 'error during chekcout');
    }

    return onSuccess(setLoading, `Checkout success`);
  };

  const fetchPrices = async () => {
    setPrices(null);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const bundleMap = [] as Record<string, any>;

    const fetchedBundles = await firebaseRef.firestore().collection('products').get();

    if (fetchedBundles !== null && currentUser !== null) {
      fetchedBundles.forEach(async (job) => {
        const pricesArray = await firebaseRef
          .firestore()
          .collection(`/products/${job.id}/prices`)
          .where('active', '==', true)
          .get();
        const itemKey = await job.data().name.toLowerCase().replace(/\s+/g, '_');
        const fullTitle = await job.data().name;
        const tokens = await job.data().metadata?.max_offers;

        pricesArray.forEach(async (price) => {
          if (!Array.isArray(bundleMap[itemKey])) bundleMap[itemKey] = [];
          bundleMap[itemKey].push({
            taxIncluded: price.data().tax_behavior === 'inclusive' ? true : false,
            id: price.id,
            fullTitle,
            value: await price.data().unit_amount,
            interval: await price.data().interval,
            priceRef: price.ref.path,
            promotion: (await price.data().metadata.promotion) ?? null,
            tokens,
          });
        });
      });
      await delay(500);

      const sortedPrices = Object.entries(bundleMap as PricesType).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: value.sort((a, b) => (a.interval === 'month' ? -1 : 1)),
        }),
        {},
      );

      setPrices(sortedPrices as PricesType);
      return onSuccess(setLoading, 'Bundle types have been successfully fetched');
    }
    return onError(setLoading, 'Unable to fetch bundle types.');
  };

  const fetchLastPayment = async () => {
    setLoading(true);

    if (!currentUser) return onError(setLoading, 'Unable to fetch last payment.');

    const fetchedPayments = await firebaseRef
      .firestore()
      .collection('users')
      .doc(currentUser.uid)
      .collection('payments')
      .orderBy('created', 'desc')
      .where('status', '==', 'succeeded')
      .limit(1)
      .get();

    const subsCollection = () =>
      firebaseRef
        .firestore()
        .collection('users')
        .doc(currentUser.uid)
        // TODO - change to 'subs' when stops working
        .collection('subscriptions')
        .orderBy('created', 'desc')
        .where('status', '==', 'active')
        .limit(1)
        .get();

    if (fetchedPayments !== null) {
      const payments = fetchedPayments.docs[fetchedPayments.docs.length - 1]?.data();
      const isPAYG = !payments?.stripeLink;
      let firstItem = payments?.items?.[0];

      if (!payments) {
        const subs = await subsCollection();
        firstItem = subs?.docs[subs.docs.length - 1]?.data()?.items?.[0];
      }

      const subPlan = {
        type: isPAYG ? 'Pay as you go' : firstItem?.price?.product?.name,
        price: isPAYG ? Number(firstItem?.amount) / 100 : Number(firstItem?.plan?.amount) / 100,
        tokens: isPAYG ? firstItem?.tokens : firstItem.metadata?.tokens,
      };

      setLastPayment(subPlan);

      return onSuccess(setLoading, 'Last payment has been successfully fetched');
    }
    return onError(setLoading, 'Unable to fetch last payment..');
  };

  const fetchCurrentSubscription = async () => {
    setCurrentSubscription(null);
    setSubscriptionLoading(true);

    if (!currentUser) return onError(setLoading, 'Current user empty - cannot fetch subsriptions');

    let subscriptionObject = {} as Record<string, string | number | unknown>;

    const subsCollection = await firebaseRef
      .firestore()
      .collection('users')
      .doc(currentUser.uid)
      // TODO - change to 'subs' when stops working
      .collection('subscriptions')
      .orderBy('created', 'desc')
      .where('status', '==', 'active')
      .limit(1)
      .get();

    if (subsCollection !== null && subsCollection.size !== 0) {
      subsCollection.forEach(async (job) => {
        subscriptionObject = {
          ...subscriptionObject,
          periodEnd: job.data().current_period_end,
          renewable_tokens: +job.data().items[0].price.product.metadata.max_offers,
          type: job.data().items[0].price.product.name,
          status: job.data().status,
          isPAYG: false,
        };
      });
    } else {
      // assuming the pay-as-you-go plan
      subscriptionObject = {
        ...subscriptionObject,
        isPAYG: true,
        renewable_tokens: 0,
        type: 'Pay-as-you-go',
        status: 'active',
      };
    }

    const [tokensErr, tokens] = await to(
      firebaseRef.firestore().collection('users').doc(currentUser.uid).collection('account_data').doc('tokens').get(),
    );

    if (tokensErr) {
      console.log(tokensErr);
      setCurrentSubscription(subscriptionObject);
      return onError(setSubscriptionLoading, 'Couldnt load active tokens');
    }
    if (tokens) {
      subscriptionObject = {
        ...subscriptionObject,
        active_payg_tokens: tokens.data()?.active_payg_tokens,
        active_subscription_tokens: tokens.data()?.active_subscription_tokens,
      };
    }

    setCurrentSubscription(subscriptionObject);
    return onSuccess(setSubscriptionLoading, 'Subscription has been successfully fetched');
  };

  const changeOfferStatus = async (offerID: string, status: string) => {
    try {
      if (!currentUser) return onError(setLoading, 'Unable to change offer status');

      const isDraft = status === JobOfferStatus.DRAFT;

      const ref = firebaseRef
        .firestore()
        .collection(isDraft ? 'users' : 'communication')
        .doc(currentUser.uid)
        .collection('job_offers')
        .doc(offerID);

      if (status === JobOfferStatus.DRAFT) {
        await ref.update({ status: JobOfferStatus.ACTIVE });
      } else {
        await firebase.app().functions('europe-west1').httpsCallable('jobOfferActivation')({
          offerRef: ref.path,
          status: status,
        });
      }

      return onSuccess(setLoading, `Offer status has been successfully updated.${offerID}`);
    } catch (error) {
      return onError(setLoading, `Error status ${offerID} update.`);
    }
  };

  const updateOffer = async (offerID: string, payload: AddJobOfferInitialValuesTypeRHF) => {
    try {
      if (!currentUser) return onError(setLoading, 'Unable to change offer status');

      await firebaseRef
        .firestore()
        .collection('users')
        .doc(currentUser.uid)
        .collection('job_offers')
        .doc(offerID)
        .update({
          ...payload,
        });
      await fetchJobOffers();

      return onSuccess(setLoading, `Offer status has been successfully updated.${offerID}`);
    } catch (error) {
      return onError(setLoading, `Error status ${offerID} update.`);
    }
  };

  const createStripeInvoice = async (priceRef: string, tokens: number) => {
    try {
      const countryTaxId = await firebase.app().functions('europe-west1').httpsCallable('findTaxRateId')(user?.country);

      const response = await firebase.app().functions('europe-west1').httpsCallable('createStripePAYGInvoicePayment')({
        daysUntilDue: 7,
        item: {
          priceRef: `/${priceRef}`,
          quantity: 1,
        },
        metadata: {
          tokens,
        },
        ...(countryTaxId.data ? { defaultTaxRates: [countryTaxId.data] } : {}),
      });

      for (let i = 0; i < 10; i++) {
        const invoiceData = await (await firebaseRef.firestore().doc(`/${response.data}`).get()).data();
        if (invoiceData?.stripeInvoiceUrl) return onSuccess(setLoading, `${invoiceData?.stripeInvoiceUrl}`);
        await delay(2000);
      }
      return onError(setLoading, `Error fetching invoice.`);
    } catch (error) {
      return onError(setLoading, `Error creating invoice ${error}.`);
    }
  };

  const createStripePanelSafeLink = async () => {
    try {
      const response = await firebase.app().functions('europe-west1').httpsCallable('createPortalLink')({
        returnUrl: `${window.location.origin}${MainPaths.dashboard}`,
      });

      const panelURL = response.data.url;

      return onSuccess(setLoading, panelURL);
    } catch (error) {
      return onError(setLoading, `Error creating panel link.`);
    }
  };

  const inviteUserToJobOffer = async ({ candidateId, jobOfferId, candidatesCollection }: IInvite) => {
    try {
      await firebase.app().functions('europe-west1').httpsCallable('sendInvite')({
        jobOfferId,
        candidateId,
        candidatesCollection,
      });

      return onSuccess(setLoading, `Invitation link sent.`);
    } catch (error) {
      return onError(setLoading, `Error creating panel link.`);
    }
  };

  const unlockCandidate = async ({ candidateId, jobOfferId, candidatesCollection }: IInvite) => {
    try {
      await firebase.app().functions('europe-west1').httpsCallable('useTokenToUnlockCandidate')({
        jobOfferId,
        candidateId,
        candidatesCollection,
      });

      return onSuccess(setLoading, `Unlocked candidate.`);
    } catch (error) {
      return onError(setLoading, `Couldn't unlock candidate using the b2b user token. Error got: ${error}`);
    }
  };

  const fetchSingleJobOffer = async (offerId: string) => {
    setLoading(true);
    setSingleJobOffer(null);

    if (!currentUser) return onError(setLoading, 'No user data - fetch offer not possible.');
    try {
      const singleOffer = await firebaseRef
        .firestore()
        .collection('communication')
        .doc(currentUser.uid)
        .collection('job_offers')
        .doc(offerId)
        .get();

      if (singleOffer) {
        const jobOffer = singleOffer.data() as AddJobOfferInitialValuesTypeRHF;
        setSingleJobOffer(jobOffer);

        return onSuccess(setLoading, 'JobOffer data fetched');
      }
      return onError(setLoading, 'Unable to fetch jobOffer data.');
    } catch {
      return onError(setLoading, 'Unable to fetch jobOffer data.');
    }
  };

  const fetchScheduledOffers = async () => {
    setLoading(true);
    setScheduledOffers(null);

    try {
      const scheduledOffers = await firebaseRef
        .firestore()
        .collection('__schedule')
        .where('userId', '==', currentUser?.uid)
        .get();

      const offers = scheduledOffers.docs.map((doc) => doc.data()).sort((a, b) => a.validUntil - b.validUntil);
      setScheduledOffers(offers as ScheduledOffersType[]);
      return onSuccess(setLoading, 'Job offers have been successfully fetched');
    } catch {
      return onError(setLoading, 'Unable to fetch scheduled offers.');
    }
  };

  const setCandidateStatusInJobOffers = async (jobOfferId: string, status: InvitationStatus, candidateId: string) => {
    try {
      const jobOffersModified = jobOffers.map((offer) => {
        if (offer.jobOfferId === jobOfferId) {
          offer.matchedCandidates[offer.matchedCandidates.findIndex((a) => a.candidateId === candidateId)].status =
            status;
          return offer;
        } else {
          return offer;
        }
      });
      setJobOffers(jobOffersModified);
      return onSuccess(setLoading, 'Candidate status successfully set in jobOffers struct.');
    } catch {
      return onError(setLoading, 'Unable to set candidate status in jobOffers struct.');
    }
  };
  return (
    <JobOfferContext.Provider
      value={{
        jobOffers,
        fetchJobOffers,
        addNewJobOffer,
        loading,
        sendToCheckout,
        fetchPrices,
        prices,
        fetchLastPayment,
        lastPayment,
        fetchCurrentSubscription,
        currentSubscription,
        subscriptionLoading,
        changeOfferStatus,
        updateOffer,
        createStripeInvoice,
        createStripePanelSafeLink,
        inviteUserToJobOffer,
        unlockCandidate,
        fetchSingleJobOffer,
        singleJobOffer,
        fetchScheduledOffers,
        scheduledOffers,
        setCandidateStatusInJobOffers,
      }}
    >
      {children}
    </JobOfferContext.Provider>
  );
};
