import { Redirect, useHistory } from 'react-router';

import { useJobOfferContext } from 'hooks/useJobOfferContext/useJobOfferContext';
import { MainPaths } from 'routing/main/MainPaths';
import { PaymentVariantHeader } from 'ui/paymentVariantHeader/PaymentVariantHeader';

import { BundleTypes } from './VariantHeaders.types';

export const VariantHeaders: React.FC = (): JSX.Element => {
  const history = useHistory();
  // const { currentSubscription, prices } = useJobOfferContext();
  const { prices } = useJobOfferContext();

  if (!prices?.pay_as_you_go.length || !prices?.premium.length || !prices?.enterprise.length)
    return <Redirect to={MainPaths.dashboard} />;

  const payg_price = prices?.pay_as_you_go[0].value / 100;
  const premium_price = prices?.premium[0].value / 100;
  const enterprise_price = prices?.enterprise[0].value / 100;

  const payg_promo = prices?.pay_as_you_go[0].promotion;
  // const premium_promo = prices?.premium[0].promotion;
  // const enterprise_promo = prices?.enterprise[0].promotion;

  // const subscriptionPending = !currentSubscription?.isPAYG;

  const goToPath = (bundle: BundleTypes) => history.push(MainPaths.invoice, { bundleType: bundle });

  return (
    <>
      <PaymentVariantHeader
        title={'Potrzebujesz większego pakietu?'}
        subtitle={'Napisz i ustalmy indywidualne wycenę dla Twojej firmy.'}
        buttonTitle={'Indywidualna Wycena'}
        onClick={() => (window.location.href = 'mailto:hello@hr-me.co')}
        individual
      />
      <PaymentVariantHeader
        caption={'Pojedyncza płatność'}
        title={'Pay-as-you-go'}
        subtitle=""
        money={`${payg_price} zł`}
        subMoney={payg_promo}
        buttonTitle={'wybieram'}
        featured
        onClick={() => goToPath(BundleTypes.PAY_AS_YOU_GO)} // move the onclick from job offer
      />
      <PaymentVariantHeader
        caption={'Subskrypcja'}
        title={'Premium'}
        subtitle={'pakiet'}
        money={`${premium_price} zł`}
        subMoney={'coming soon...'}
        buttonTitle={'wybieram'}
        onClick={() => goToPath(BundleTypes.PREMIUM)}
        disabled
      />
      <PaymentVariantHeader
        caption={'Subskrypcja'}
        title={'Enterprise'}
        subtitle={'pakiet'}
        money={`${enterprise_price} zł`}
        subMoney={'coming soon...'}
        buttonTitle={'wybieram'}
        onClick={() => goToPath(BundleTypes.ENTERPRISE)}
        disabled
      />
    </>
  );
};
