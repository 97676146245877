import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    textTransform: 'lowercase',
    fontSize: 16,
    fontWeight: 'normal',
    padding: theme.spacing(0.5, 2),
  },
}));
