import React from 'react';
import { Box, Divider, Grid, Typography, useTheme } from '@material-ui/core';
import { ButtonDefault } from '@hrme/shared';

import { ButtonAdd } from 'ui/buttonAdd/ButtonAdd';
import { ButtonOutlined } from 'ui/buttonOutlined/ButtonOutlined';

import {
  ButtonContainer,
  FirstColumnVariant,
  useStyles,
  VariantCaption,
  VariantCaptionFeatured,
  VariantColumn,
  VariantColumnFeatured,
} from './PaymentVariantHeader.styles';
import { PaymentVariantHeaderProps } from './PaymentVariantHeader.types';

export const PaymentVariantHeader: React.FC<PaymentVariantHeaderProps> = ({
  caption,
  title,
  subtitle,
  money,
  subMoney,
  buttonTitle,
  onClick,
  featured,
  individual,
  disabled,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const FeaturedCaption = featured ? VariantCaptionFeatured : VariantCaption;
  const FeaturedColumn = featured ? VariantColumnFeatured : VariantColumn;
  const FeaturedButton = featured ? ButtonDefault : ButtonAdd;

  if (individual)
    return (
      <Grid item xs={3}>
        <FirstColumnVariant>
          <Typography className={classes.variantIndividualBoldy}>{title}</Typography>
          <Box mb={1} />
          <Typography>{subtitle}</Typography>
          <Box mb={2} />
          <ButtonOutlined onClick={onClick}>{buttonTitle}</ButtonOutlined>
        </FirstColumnVariant>
      </Grid>
    );

  return (
    <Grid item xs={3}>
      <FeaturedColumn>
        <FeaturedCaption>{caption}</FeaturedCaption>
        <Typography variant="h4">{title}</Typography>
        <Typography variant="body2">{subtitle}</Typography>
        <Box mb={4} />
        <Divider className={classes.divider} />
        <Box mb={4} />
        <Typography className={classes.money}>{money}</Typography>
        <Typography style={{ display: 'inline-block' }}>{subMoney}</Typography>
        <Box mb={4} />
        <ButtonContainer>
          <FeaturedButton disabled={disabled} onClick={onClick}>
            {buttonTitle}
          </FeaturedButton>
        </ButtonContainer>
      </FeaturedColumn>
    </Grid>
  );
};
