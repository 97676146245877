import { makeStyles, styled } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  money: {
    fontWeight: 'bold',
    fontSize: '40px',
  },
  divider: {
    height: '1px',
    width: '85%',
    margin: '0 auto',
    backgroundColor: theme.palette.grey[300],
  },
  variantIndividualBoldy: {
    fontWeight: 'bold',
    width: '100%',
    textAlign: 'left',
  },
}));

export const ButtonContainer = styled('div')({
  paddingLeft: 20,
  paddingRight: 20,
  width: '100%',
});

export const VariantCaption = styled('div')({
  padding: 16,
  background: '#f5f5f5',
  marginBottom: 24,
  textAlign: 'center',
});

export const VariantCaptionFeatured = styled(VariantCaption)({
  background: '#d8527c',
  color: '#fff',
});

export const VariantColumn = styled('div')({
  background: '#fff',
  height: '100%',
  padding: 8,
  paddingBottom: 32,
  marginLeft: 0.5,
  marginRight: 0.5,
  textAlign: 'center',

  '& button': {
    padding: 16,
  },
});

export const VariantColumnFeatured = styled(VariantColumn)({
  position: 'relative',
  boxShadow: '-1px 0 0 #e1e1e1, 1px 0 0 #e1e1e1, 0 -8px 32px rgba(0, 0, 0, 0.1)',
});

export const FirstColumn = styled('div')({
  padding: 32,
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  background: '#fff',
  textAlign: 'left',
  fontWeight: 'bold',
  marginRight: 0.5,
});

export const FirstColumnVariant = styled(FirstColumn)({
  flexDirection: 'column',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
  background: '#f5f5f5',

  '& button': {
    padding: 16,
  },
});
