import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    display: 'flex',
  },
  text: {
    textAlign: 'left',
    fontSize: 24,
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  subTitle: {
    textAlign: 'left',
    fontSize: 16,
    fontWeight: 400,
  },
  buttonContainer: {
    display: 'flex',
    width: '125px',
    marginTop: '24px',
  },
  itemContainter: {
    width: '100%',
    [theme.breakpoints.up('xl')]: {
      width: '564px',
    },
    marginRight: '68px',
  },
}));
