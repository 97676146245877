import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';

import { useStyles } from './termsAndConditionsData.styles';

export const TermsAndConditionsData: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <>
      <Typography variant="h2" style={{ textAlign: 'left' }}>
        Regulamin
      </Typography>
      <Typography variant="body2" className={classes.text} style={{ color: '#a4a4a4' }}>
        Regulamin korzystania ze strony internetowej i platformy hr - me obowiązujący od dnia 18.10.2021r.
      </Typography>
      <Box mb={3} />
      <Typography variant="h4" style={{ textAlign: 'left' }}>
        §1 DEFINICJE
      </Typography>
      <Box mb={3} />
      <Typography className={classes.text}>
        <ul>
          <li>
            Aktywacja Oferty – odblokowanie przez Pracodawcę możliwości nawiązywania kontaktu z Kandydatami
            zasugerowanymi w ramach danej Oferty Pracy, dokonywane poprzez opłacenie usługi na zasadach określonych w
            niniejszym Regulaminie;
          </li>
          <li>
            Kandydat – osoba fizyczna, posiadająca zarejestrowane Konto Użytkownika na Platformie HR-ME, będąca osobą
            pełnoletnią, która może korzystać z wszystkich funkcji platformy HR-ME przeznaczonych dla tego typu
            Użytkowników;
          </li>
          <li>
            Konsument – osoba fizyczna zawierająca z Usługodawcą umowę o świadczenie usług drogą elektroniczną, której
            przedmiot nie jest związany bezpośrednio z jej działalnością gospodarczą lub zawodową, będący konsumentem w
            rozumieniu dyrektywy Parlamentu Europejskiego i Rady 2011/83/UE z dnia 25 października 2011 r. w sprawie
            praw konsumentów.
          </li>
          <li>
            Konto lub Konto Użytkownika – usługa elektroniczna świadczona przez Usługodawcę za pośrednictwem Strony
            Internetowej: oznaczony indywidualną nazwą (loginem) i hasłem podanym przez Użytkownika zbiór zasobów w
            systemie teleinformatycznym Usługodawcy, w którym gromadzone są dane podane przez Użytkownika.{' '}
          </li>
          <li>
            Oferta lub Oferta Pracy – ogłoszenie wprowadzone w ramach platformy HR-ME przez Pracodawcę, za pomocą
            którego Pracodawca publikuje informacje o poszukiwaniu pracownika lub zleceniobiorcy we wskazanym w tym
            ogłoszeniu zakresie oraz inne informacje, które uznaje za niezbędne w procesie rekrutacji;
          </li>
          <li>
            Platforma HR-ME – oprogramowanie Usługodawcy udostępnione Użytkownikom za pośrednictwem strony internetowej
            pod adresem www.hr-me.co w ramach którego Usługodawca świadczy Usługi;
          </li>
          <li>
            Pracodawca – przedsiębiorca bądź osoba fizyczna, posiadający zarejestrowane Konto Użytkownika, który w
            związku z prowadzoną przez siebie działalnością, za pośrednictwem Platformy HR-ME poszukuje poprzez
            zamieszczenie Oferty osób chętnych do nawiązania współpracy (podjęcia zatrudnienia, świadczenia usług);
          </li>
          <li>
            Przedstawiciel Pracodawcy - osoba fizyczna działająca w imieniu i na rzecz Pracodawcy, uprawniona lub
            posiadająca upoważnienie Pracodawcy do składania wszelkich oświadczeń woli niezbędnych do rejestracji Konta
            Pracodawcy, świadczenia Usług i zawarcia Umowy z Usługodawcą
          </li>
          <li>Regulamin – niniejszy regulamin korzystania z usług świadczonych przez Usługodawcę.</li>
          <li>
            Strona Internetowa – strona internetowa prowadzona przez Usługodawcę pod adresem www.hr-me.co, za
            pośrednictwem której możliwe jest korzystanie z usług oferowanych przez Usługodawcę w ramach Platformy
            HR-ME;
          </li>
          <li>
            Umowa – umowa o świadczenie usług droga elektroniczną zawarta pomiędzy Użytkownikiem a Usługodawcą na
            zasadach wskazanych w Regulaminie, której przedmiotem jest w szczególności: umożliwianie Użytkownikom
            niezarejestrowanym wglądu do treści ogólnodostępnych na Stronie Internetowej, usługa dostępu do Konta
            Użytkownika, usługa dotycząca aktywacji Ofert (dostępna dla Pracodawców).
          </li>
          <li>
            Usługi – usługi świadczone drogą elektroniczną przez Usługodawcę na rzecz Użytkowników, za pośrednictwem
            Strony Internetowej pod adresem www.hr-me.co polegające na udostępnieniu Użytkownikom narzędzia - platformy
            internetowej HR-ME służącej w szczególności do tworzenia indywidualnego Konta Użytkownika, nawiązywania
            kontaktu i współpracy pomiędzy Pracodawcami a Kandydatami działającymi w ramach branży IT, do publikowania
            Ofert przez Pracodawców, do rozwijania kwalifikacji zawodowych i opracowywania indywidualnych ścieżek
            kariery dla Kandydatów, a także do śledzenia bieżących trendów w branży IT.{' '}
          </li>
          <li>
            Usługodawca – HR-ME Spółka z ograniczoną odpowiedzialnością z siedzibą w Olsztynie, ul. Władysława
            Trylińskiego 16 (10-683 Olsztyn) wpisana do Krajowego Rejestru Sądowego – rejestru przedsiębiorców przez Sąd
            Rejonowy w Olsztynie, VIII Wydział Gospodarczy Krajowego Rejestru Sądowego, pod nr KRS: 0000780016 NIP:
            8481874572, REGON:382997840, z kapitałem zakładowym o wartości 5.000 zł., świadcząca Usługi.
          </li>
          <li>
            Użytkownik – osoba korzystająca ze Strony Internetowej oraz Platformy HR-ME będąca Pracodawcą albo
            Kandydatem oraz osoba nieposiadająca zarejestrowanego Konta Użytkownika, korzystająca ze Strony Internetowej
            i Platformy HR-ME w zakresie możliwym bez dokonywania rejestracji;
          </li>
          <li>
            Zaproszenie – zgłoszenie przez Pracodawcę woli nawiązania kontaktu z Kandydatem zaproponowanym przez
            Platformę HR-ME w ramach procesu automatycznego dopasowania Kandydatów w odpowiedzi na wprowadzoną Ofertę.
          </li>
        </ul>
      </Typography>
      <Box mb={3} />
      <Typography variant="h4" style={{ textAlign: 'left' }}>
        §2 POSTANOWIENIA OGÓLNE
      </Typography>
      <Box mb={3} />
      <Typography className={classes.text}>
        <ul>
          <li>
            Usługodawca świadczy na rzecz Użytkowników Usługi polegające w szczególności na:
            <ul>
              <li>zapewnianiu dostępu do indywidualnie utworzonego Konta Użytkownika;</li>
              <li>
                pośrednictwie w nawiązaniu kontaktu pomiędzy Kandydatem a Pracodawcą w zakresie podjęcia przez nich
                współpracy zawodowej;
              </li>
              <li>
                wspieraniu Kandydatów w rozwijaniu kwalifikacji zawodowych, opracowywaniu dla nich indywidualnych
                ścieżek kariery;
              </li>
              <li>dostarczaniu narzędzi do śledzenia bieżących trendów w branży HR i IT ; </li>
            </ul>
          </li>
          <li>
            Regulamin określa zasady świadczenia Usług drogą elektroniczną przez Usługodawcę oraz zasady korzystania ze
            Strony Internetowej oraz Platformy HR-ME, w tym w szczególności:
            <ul>
              <li>dane kontaktowe Usługodawcy;</li>
              <li>informacje ogólne;</li>
              <li>procedurę zakładania Konta Użytkownika;</li>
              <li>sposób korzystania ze Strony Internetowej oraz Platformy HR-ME;</li>
              <li>zasady pobierania płatności za świadczone Usługi;</li>
              <li>kwestie praw autorskich;</li>
              <li>kwestie postępowań reklamacyjnych;</li>
              <li>zakres odpowiedzialności Usługodawcy.</li>
            </ul>
          </li>
          <li>
            Treść Regulaminu jest dostępna na Stronie Internetowej i może zostać w dowolny sposób utrwalona przez
            Użytkownika w celu zapoznania się z Regulaminem.
          </li>
          <li>
            Szczegółowe informacje o Stronie Internetowej oraz Platformie HR-ME, w tym w szczególności o ich funkcjach
            oraz świadczonych Usługach znajdują się na Stronie Internetowej.
          </li>
        </ul>
      </Typography>

      <Box mb={3} />
      <Typography variant="h4" style={{ textAlign: 'left' }}>
        §3 DANE KONTAKTOWE USŁUGODAWCY
      </Typography>
      <Box mb={3} />
      <Typography className={classes.text}>
        <ul>
          <li>
            W celu uzyskania kontaktu z Usługodawcą, Użytkownik może kontaktować się pod adresem poczty elektronicznej –
            krzysztof.p@hr-me.co.
          </li>
          <li>Kontakt z Usługodawcą możliwy jest również w oparciu o dane teleadresowe – +48 736 775 700</li>
        </ul>
      </Typography>

      <Box mb={3} />
      <Typography variant="h4" style={{ textAlign: 'left' }}>
        §4 UMOWA O ŚWIADCZENIE USŁUG
      </Typography>
      <Box mb={3} />
      <Typography className={classes.text}>
        <ul>
          <li>
            Umowa o świadczenie usługi polegającej na udostępnieniu Użytkownikowi nieposiadającemu Konta treści
            znajdujących się na Platformie HR-ME oraz na Stronie Internetowej:
            <ul>
              <li>
                zostaje zawarta pomiędzy Usługodawcą a Użytkownikiem w momencie skorzystania przez Użytkownika z
                ogólnodostępnych funkcjonalności Strony Internetowej i Platformy HR-ME;
              </li>
              <li>
                zostaje rozwiązana z momentem zaprzestania korzystania ze Strony Internetowej lub Platformy HR-ME.
              </li>
            </ul>
          </li>
          <li>
            Umowa o świadczenie usługi dostępu do indywidualnego Konta Użytkownika:
            <ul>
              <li>
                zostaje zawarta pomiędzy Usługodawcą a Użytkownikiem w momencie rejestracji Konta Użytkownika zgodnie z
                procedurą opisaną w § 7 Regulaminu;
              </li>
              <li>zostaje zawarta na czas nieokreślony;</li>
              <li>
                może zostać wypowiedziana przez Użytkownika w każdym czasie i zostaje rozwiązana z momentem usunięcia
                Konta Użytkownika;
              </li>
              <li>
                może zostać wypowiedziana przez Usługodawcę ze skutkiem natychmiastowym w przypadkach przewidzianych w
                niniejszym Regulaminie.
              </li>
            </ul>
          </li>
          <li>
            Umowa o świadczenie usług związanych z aktywacją Oferty przez Pracodawcę:
            <ul>
              <li>
                zostaje zawarta pomiędzy Usługodawcą a Pracodawcą w momencie złożenia przez Pracodawcę zamówienia na
                zakup usługi jednorazowej Aktywacji Oferty („pay as you go”) lub zakup wybranego, dostępnego pakietu
                abonamentowego i po dokonaniu opłaty przewidzianej w cenniku;
              </li>
              <li>
                umowa zawarta zostaje na czas określony jednego miesiąca w przypadku płatności „pay as you go” oraz na
                czas nieoznaczony w przypadku zakupu pakietu abonamentowego;
              </li>
              <li>
                umowa zawarta na czas nieoznaczony opłacana miesięcznie może zostać wypowiedziana przez Pracodawcę w
                każdym czasie, ze skutkiem na koniec miesięcznego okresu rozliczeniowego;
              </li>
              <li>
                umowa zawarta na czas nieoznaczony opłacana rocznie z góry może zostać wypowiedziana przez Pracodawcę w
                każdym czasie ze skutkiem na koniec rocznego okresu rozliczeniowego;
              </li>
              <li>
                umowa może zostać wypowiedziana ze skutkiem natychmiastowym przez Usługodawcę w przypadku naruszenia
                przez Pracodawcę niniejszego Regulaminu.
              </li>
            </ul>
          </li>
          <li>
            Poprzez zawarcie Umowy Użytkownik w sposób wyraźny oświadcza, że udziela zgody na uzyskanie dostępu do Usług
            niezwłocznie po zawarciu Umowy oraz w określonych przypadkach po dokonaniu opłaty zgodnie z Umową. Tym samym
            Użytkownik oświadcza, że został poinformowany o tym, że uzyskanie dostępu do Usług przed upływem
            czternastodniowego okresu na odstąpienie od Umowy oznacza, że traci on przysługujące mu prawo do odstąpienia
            od Umowy i wyraża na to zgodę. Udzielenie powyższej zgody Użytkownik potwierdza poprzez zaznaczenia
            odpowiedniego „checkboxa” przy rejestracji Konta i składaniu zamówienia. Udzielenie powyższej zgody jest
            dobrowolne, ale niezbędne do świadczenia Usług przez Usługodawcę.
          </li>
          <li>
            Wypowiedzenie Umowy przez Użytkownika powinno zostać dokonane poprzez przesłanie stosownej informacji do
            Usługodawcy zgodnie z danymi kontaktowymi wskazanymi w § 3 Regulaminu.
          </li>
        </ul>
      </Typography>

      <Box mb={3} />
      <Typography variant="h4" style={{ textAlign: 'left' }}>
        §5 WYMOGI TECHNICZNE STRONY INTERNETOWEJ
      </Typography>
      <Box mb={3} />
      <Typography className={classes.text}>
        Do korzystania przez Użytkownika ze Strony Internetowej, niezbędne jest posiadanie urządzenia wspieranego przez
        przeglądarkę internetową zapewniającą prawidłową obsługę Javascript i plików cookies oraz stałego dostępu do
        sieci Internet.
      </Typography>

      <Box mb={3} />
      <Typography variant="h4" style={{ textAlign: 'left' }}>
        §6 ZASADY OGÓLNE KORZYSTANIA ZE STRONY INTERNETOWEJ ORAZ PLATFORMY HR-ME
      </Typography>
      <Box mb={3} />
      <Typography className={classes.text}>
        <ul>
          <li>
            Korzystanie z Usług świadczonych przez Usługodawcę w ramach Platformy HR-ME odbywa się za pośrednictwem
            Strony Internetowej, na zasadach wskazanych w Regulaminie.
          </li>
          <li>
            Użytkownik ma możliwość korzystania z Usług za pośrednictwem Strony Internetowej bez rejestracji Konta
            Użytkownika lub po jego założeniu.
          </li>
          <li>
            Bez rejestracji Konta Użytkownika na Platformie HR-ME możliwe jest wyłącznie przeglądanie w ograniczonym
            zakresie statystyk dotyczących trendów w branży IT oraz w rekrutacji (giełdy technologii). Pozostałe
            funkcjonalności Platformy HR-ME dostępne są wyłącznie dla Użytkowników posiadających zarejestrowane Konto i
            różnią się w zależności od typu Użytkownika.
          </li>
          <li>Korzystanie z Platformy HR-ME jest dobrowolne i w pełni bezpłatne dla Kandydatów.</li>
          <li>
            Korzystanie z Platformy HR-ME przez Pracodawców jest dobrowolne i odpłatne co do wybranych funkcjonalności,
            na zasadach określonych szczegółowo w § 9.4. Regulaminu.
          </li>
          <li>
            Użytkownik powinien korzystać z Platformy HR-ME z poszanowaniem zasad współżycia społecznego, zgodnie z
            powszechnie obowiązującym prawem oraz Regulaminem. Zakazane jest dostarczanie przez Użytkowników treści o
            charakterze bezprawnym oraz korzystanie z Platformy HR-ME w sposób zakłócający funkcjonowanie aplikacji,
            serwerów lub sieci Usługodawcy oraz dokonywanie samowolnej ingerencji w Stronę Internetową oraz Platformę
            HR-ME lub udostępnione w nich treści.
          </li>
        </ul>
      </Typography>

      <Box mb={3} />
      <Typography variant="h4" style={{ textAlign: 'left' }}>
        §6 PROCEDURA ZAKŁADANIA KONTA NA PLATFORMIE HR-ME
      </Typography>
      <Box mb={3} />
      <Typography className={classes.text}>
        <ul>
          <li>Założenie Konta jest darmowe i dobrowolne.</li>
          <li>
            Przy zakładaniu Konta Użytkownik wybiera czy działa w charakterze Kandydata czy Pracodawcy. W zależności od
            wybranego typu Konta Użytkownik uzyska dostęp do innych funkcjonalności Platformy HR-ME - opisanych
            szczegółowo w § 9 i 10 Regulaminu.
          </li>
          <li>
            Aby założyć Konto, należy wypełnić formularz rejestracji. Wymagane do tego są następujące dane:
            <ul>
              <li>imię i nazwisko Użytkownika;</li>
              <li>adres e-mail Użytkownika.</li>
            </ul>
          </li>
          <li>
            Brak podania przez danych, o których mowa w ust. 3 powyżej skutkuje brakiem możliwości zarejestrowania Konta
            Użytkownika.{' '}
          </li>
          <li>
            W przypadku wyboru Konta Użytkownika o profilu Pracodawcy, w momencie rejestracji wskazać należy czy Konto
            Użytkownika zarejestrowane zostaje bezpośrednio dla Pracodawcy czy dla podmiotu pośredniczącego (agencją
            zatrudnienia, agencję HR). W ramach Konta Użytkownika o profilu Pracodawcy Użytkownik będzie mógł utworzyć
            jeden lub kilka profili firmowych, zależnie od wybranego pakietu usług.
          </li>
          <li>
            Podanie przez Użytkowników innych danych wskazanych w formularzach na Stronie Internetowej i w zakładce
            profilu na Koncie Użytkownika jest dobrowolne i nie jest niezbędne do świadczenia Usług, jednak brak ich
            podania może skutkować niemożnością korzystania z wszystkich funkcjonalności Platformy HR-ME.
          </li>
          <li>
            Do prawidłowego założenia Konta niezbędne jest również utworzenie hasła umożliwiającego dostęp do Konta.
            Hasło musi zawierać łącznie:
            <ul>
              <li>minimalnie 4 znaków;</li>
              <li>minimalnie jedną wielką literę;</li>
              <li>minimalnie jeden znak specjalny.</li>
            </ul>
          </li>
          <li>
            Użytkownik rejestrujący Konto Użytkownika w charakterze Pracodawcy poprzez założenie Konta oświadcza, że
            działa jako Przedstawiciel Pracodawcy i tym samym posiada wszelkie niezbędne do tego uprawnienia, w tym w
            szczególności, że jest upoważniony do prowadzenia działań w imieniu Pracodawcy w związku ze Stroną
            Internetową oraz korzystaniem z Usług świadczonych przez Usługodawcę poprzez Platformę HR-ME.{' '}
          </li>
          <li>
            Zarejestrowanie Konta wymaga zapoznania się i zaakceptowania Regulaminu, a także wyrażenia przez Użytkownika
            zgody na przetwarzanie jego danych osobowych w zakresie niezbędnym do rejestracji oraz prawidłowej
            realizacji usług świadczonych drogą elektroniczną.{' '}
          </li>
          <li>
            Po wprowadzeniu przez Użytkownika danych niezbędnych do zarejestrowania Konta Użytkownika, Usługodawca
            wysyła na adres e-mail podany przy rejestracji wiadomość e-mail zawierającą link aktywacyjny. W celu
            zakończenia procedury rejestracji Konta Użytkownika należy kliknąć w link aktywacyjny. Po tym okresie link
            wygasa, a rejestracja Konta wymaga ponownego wypełnienia formularza rejestracyjnego.{' '}
          </li>
          <li>
            Kliknięcie w przesłany link aktywacyjny oraz zaakceptowanie Regulaminu i Polityki Prywatności skutkuje
            zarejestrowaniem Konta. Z tym momentem następuje zawarcie umowy o świadczenie Usługi na czas nieokreślony.{' '}
          </li>
          <li>
            Poprzez rejestrację Konta Użytkownik potwierdza, że dane podane dla celów rejestracji są kompletne, zgodne
            ze stanem faktycznym i nie naruszają praw osób trzecich, a ponadto, że posiada pełną zdolność do czynności
            prawnych umożliwiającą mu zawarcie Umowy.
          </li>
          <li>Użytkownik ma możliwość zmiany danych podanych z poziomu Konta Użytkownika.</li>
          <li>Użytkownik zobowiązany jest do nieudostępniania hasła do Konta Użytkownika osobom trzecim. </li>
          <li>
            Adres poczty elektronicznej wskazany przy rejestracji Konta Użytkownika służy identyfikacji danego
            Użytkownika oraz przesyłaniu przez Usługodawcę wszelkich komunikatów, wiadomości oraz powiadomień związanych
            z korzystaniem z Usług. W przypadku utraty dostępu do adresu poczty elektronicznej podanego przy rejestracji
            lub jego zmiany Użytkownik zobowiązany jest do niezwłocznego zaktualizowania informacji w tym zakresie na
            Koncie Użytkownika.{' '}
          </li>
        </ul>
      </Typography>

      <Box mb={3} />
      <Typography variant="h4" style={{ textAlign: 'left' }}>
        §8 USUNIĘCIE KONTA UŻYTKOWNIKA
      </Typography>
      <Box mb={3} />
      <Typography className={classes.text}>
        <ul>
          <li>
            Użytkownik ma możliwość usunięcia Konta poprzez skierowania odpowiedniej prośby drogą mailową do Usługodawcy
            na adres wskazany w § 3 Regulaminu. Usunięcie Konta nastąpi w ciągu 14 dni od dnia zgłoszenia takie żądania,
            o czym Użytkownik zostanie powiadomiony drogą mailową.
          </li>
          <li>Usunięcie Konta nie wymaga podania konkretnej przyczyny.</li>
          <li>
            Usługodawca zastrzega sobie prawo do trwałego usunięcia konta Użytkownika w przypadku naruszenia przez
            Użytkownika warunków określonych w Regulaminie lub przepisach prawa, w szczególności w przypadku
            opublikowania przez Użytkownika treści sprzecznych z przepisami prawa, Regulaminem, zasadami współżycia
            społecznego oraz dobrymi obyczajami. Zakazane jest umieszczanie przez Użytkowników jakichkolwiek treści o
            charakterze bezprawnym. W przypadku usunięcia Konta przez Usługodawcę na podstawie niniejszego postanowienia
            Użytkownikowi nie przysługują jakiekolwiek roszczenia wobec Usługodawcy w związku z zaprzestaniem
            świadczenia Usług.{' '}
          </li>
          <li>
            Procedurę postępowania z danymi osobowymi zgromadzonymi w trakcie świadczenia Usług po usunięciu Konta
            Użytkownika reguluje Polityka Prywatności.
          </li>
        </ul>
      </Typography>
      <Box mb={3} />

      <Typography variant="h4" style={{ textAlign: 'left' }}>
        §9 KORZYSTANIE Z PALTFORMY HR-ME PRZEZ KANDYDATA
      </Typography>
      <Box mb={3} />
      <Typography variant="h5" style={{ textAlign: 'left' }}>
        §9.1 POSTANOWIENIA OGÓLNE
      </Typography>
      <Typography className={classes.text}>
        <ul>
          <li>
            Korzystanie przez Kandydata z Usług świadczonych za pośrednictwem Platformy HR-ME jest dobrowolne oraz
            bezpłatne.
          </li>
          <li>
            W ramach korzystania z Platformy HR-ME Usługodawca świadczy Kandydatowi Usługi polegające w szczególności na
            wspieraniu Kandydata w rozwijaniu kwalifikacji zawodowych i opracowywaniu indywidualnych ścieżek kariery dla
            Kandydatów, a także dostarcza narzędzia umożliwiające nawiązanie mu współpracy z Pracodawcą, śledzenie
            bieżących trendów w branży IT. Szczegółowy opis świadczonych Usług dostępny jest na Stronie Internetowej
          </li>
          <li>
            W celu skorzystania z wszystkich funkcjonalności Platformy HR-ME, w tym w szczególności z możliwości
            uzyskania informacji o proponowanych ścieżkach rozwoju zawodowego i podnoszenia kwalifikacji zawodowych oraz
            z możliwości kojarzenia profilu Kandydata z Pracodawcami niezbędne jest utworzenie Konta zgodnie z procedurą
            określoną w § 7 Regulaminu.
          </li>
          <li>
            Podanie przez Kandydata danych innych niż wskazane w § 7 ust. 3 w szczególności danych dotyczących
            dotychczasowego przebiegu kariery zawodowej, doświadczenia, kwalifikacji oraz umiejętności jest dobrowolne i
            nie jest wymagane dla korzystania z Platformy HR-ME, jednak ich niepodanie może wpływać na brak możliwości
            świadczenia określonych Usług przez Usługodawcę i brak możliwości korzystania przez Kandydata z określonych
            funkcjonalności platformy – w szczególności w zakresie dopasowywania profilu Kandydata do zamieszczonych na
            Platformie HR-ME Ofert.
          </li>
          <li>
            Kandydat zamieszczając na Platformie HR-ME treści oświadcza, że są one zgodne ze stanem faktycznym, a ich
            umieszczenie nie naruszy niczyich praw. Usługodawca nie ponosi odpowiedzialności za treści, które Kandydat
            udostępnia Pracodawcy w ramach profilu Kandydata. Usługodawca zastrzega sobie prawo do usunięcia treści
            publikowanych w Platformie HR-ME na zasadach określonych w Regulaminie, o ile naruszają przepisy powszechnie
            obowiązującego w Polsce prawa, postanowienia Regulaminu, zasady współżycia społecznego lub dobre obyczaje.
          </li>
          <li>
            Kandydat poprzez założenie Konta Użytkownika oraz uzupełnienie danych w profilu wyraża zgodę na
            wykorzystywanie wprowadzonych przez niego danych dotyczących w szczególności jego kompetencji i kwalifikacji
            zawodowych, doświadczenia oraz umiejętności w procesie autodopasowania Kandydatów do zamieszczonych na
            Platformie HR-ME Ofert.
          </li>
        </ul>
      </Typography>

      <Box mb={3} />
      <Typography variant="h5" style={{ textAlign: 'left' }}>
        §9.2 AUTODOPASOWYWANIE DO OFERT, NAWIĄZYWANIE KONTAKTU Z PRACODAWCAMI
      </Typography>
      <Typography className={classes.text}>
        <ul>
          <li>
            W przypadku w którym Kandydat spełnia kryteria wskazane w zamieszczonej na Platformie HR-ME Ofercie, dane
            dotyczące w szczególności jego kwalifikacji, kompetencji, doświadczenia zawodowego, umiejętności oraz
            planowanych przez niego ścieżek rozwoju zostaną udostępnione w sposób zanonimizowany Pracodawcy
            zamieszczającemu daną Ofertę. Na tym etapie Pracodawca nie ma wglądu w jakiekolwiek dane osobowe Kandydata
            takie jak imię, nazwisko, adres e-mail czy numer telefonu.
          </li>
          <li>
            Jeśli Pracodawca wyrazi chęć nawiązania kontaktu z danym Kandydatem, Kandydat zostaje o tym poinformowany
            poprzez widoczne na jego profilu powiadomienie o skierowanym przez Pracodawcę Zaproszeniu. Kandydat w
            odpowiedzi skierowane do niego Zaproszenie ma możliwość zapoznania się z daną Ofertą, w ramach której został
            wytypowany i podjęcia decyzji o akceptacji Zaproszenia lub jego odrzuceniu.
          </li>
          <li>
            W przypadku akceptacji Zaproszenia Kandydat wyraża zgodę na nawiązanie kontaktu z danym Pracodawcą, w tym na
            przekazanie mu jego danych osobowych oraz danych kontaktowych.
          </li>
        </ul>
      </Typography>

      <Box mb={3} />
      <Typography variant="h5" style={{ textAlign: 'left' }}>
        §9.3 USŁUGI Z ZAKRESU ROZWOJU KWALIFIKACJI KANDYDATA
      </Typography>
      <Typography className={classes.text}>
        <ul>
          <li>
            W ramach świadczonych Usług Usługodawca dostarcza Kandydatowi poprzez Stronę Internetową oraz Platformę
            HR-ME narzędzia mające na celu wspieranie Kandydata w podnoszeniu jego kwalifikacji i rozwoju.
          </li>
          <li>
            W szczególności Usługodawca na podstawie gromadzonych przez siebie ogólnodostępnych danych z rynku pracy
            oraz analizy Ofert wprowadzonych na Platformie HR-ME przedstawia Kandydatom informacje na temat wiodących
            trendów na rynku, umiejętności i kwalifikacji na które istnieje zapotrzebowanie i które są pożądane przez
            Pracodawców i w których warto się rozwijać.
          </li>
          <li>
            Na podstawie danych wskazanych w ust. 2 powyżej oraz danych podanych przez Kandydata w jego profilu na
            Koncie Użytkownika, Usługodawca sugeruje Kandydatom indywidualnie opracowane ścieżki rozwoju oraz szkolenia
            w zakresie podnoszenia ich kwalifikacji zawodowych.
          </li>
        </ul>
      </Typography>

      <Box mb={3} />
      <Typography variant="h4" style={{ textAlign: 'left' }}>
        §10 ODPOWIEDZIALNOŚĆ USŁUGODAWCY
      </Typography>
      <Box mb={3} />
      <Typography className={classes.text}>
        <ul>
          <li>
            Usługodawca ponosi odpowiedzialność za niewykonanie lub nienależyte wykonanie umowy o świadczenie usług,
            które jest efektem jego zawinionego i umyślnego działania, przy czym odpowiedzialność ta ograniczona jest do
            rzeczywiście poniesionej przez Użytkownika szkody.{' '}
          </li>
          <li>
            Usługodawca nie ponosi odpowiedzialności za niewykonanie lub nienależyte wykonanie Umowy będące skutkiem
            okoliczności od niego niezależnych lub siły wyższej, w tym w szczególności nie ponosi odpowiedzialności za:
            <ul>
              <li>
                ograniczenia lub problemy techniczne w systemach teleinformatycznych, z których korzystają urządzenia
                Użytkowników, a które uniemożliwiają lub ograniczają w jakikolwiek sposób Użytkownikom korzystanie ze
                Strony Internetowej, Platformy HR-ME i świadczonych przez Usługodawcę Usług;
              </li>
              <li>skutki wynikające z udostępnienia przez Użytkownika loginu i hasła do Konta osobom trzecim;</li>
              <li>
                ograniczenia, problemy techniczne lub przerwy w dostępie do Strony Internetowej i Platformy HR-ME
                wynikające z awarii, usterek, prac modernizacyjnych, prac technicznych i innych niezależnych od
                Usługodawcy okoliczności dotyczących serwerów lub innych usług niezbędnych do świadczenia Usług.
              </li>
            </ul>
          </li>
          <li>
            Usługodawca wyłącznie udostępnia Platformę HR-ME, która umożliwia Użytkownikom nawiązywanie kontaktu
            pomiędzy sobą tj. pomiędzy Pracodawcami a Kandydatami, co oznacza, że Usługodawca nie bierze udziału w
            działaniach rekrutacyjnych prowadzonych przez Pracodawców i nie ponosi tym samym jakiejkolwiek
            odpowiedzialności za ich zawarcie (w tym zdolność do zawierania i wykonywania umów przez Użytkowników),
            przebieg oraz efekt. W przypadku podjęcia współpracy (zawarcia umowy) między Pracodawcą a Kandydatem
            stronami takiej umowy są wyłącznie te podmioty, a Usługodawca nie gwarantuje w żaden sposób jej wykonania.
          </li>
          <li>
            Usługodawca w szczególności nie ponosi jakiejkolwiek odpowiedzialności za zobowiązania wynikające z umów,
            które zostały zawarte pomiędzy Użytkownikami, w związku z nawiązaniem przez tych Użytkowników kontaktu za
            pośrednictwem Platformy HR-ME, w tym nie ponosi odpowiedzialności za niewykonanie lub nienależyte wykonanie
            tych umów.
          </li>
          <li>
            Każdy Użytkownik umieszczający jakiekolwiek treści na Stronie Internetowej lub na Platformie HR-ME ponosi
            odpowiedzialność za prawdziwość oraz zgodność tych treści ze stanem faktycznym oraz z obowiązującymi
            przepisami prawa.{' '}
          </li>
          <li>
            Korzystając ze Strony Internetowej lub Platformy HR-ME Użytkownicy zobowiązują się przestrzegać
            obowiązujących przepisów prawa, zasad współżycia społecznego oraz postanowień Regulaminu, w szczególności
            Użytkownicy zobowiązują się:
            <ul>
              <li>
                umieszczać na Platformie HR-ME wyłącznie treści i informacje zgodne ze stanem faktycznym i prawnym;
              </li>
              <li>
                nie podejmować jakichkolwiek działań mogących zakłócić funkcjonowanie Strony Internetowej, Platformy
                HR-ME lub naruszyć prawa Usługodawcy lub innych Użytkowników;
              </li>
              <li>
                komunikować się z innymi Użytkownikami z poszanowaniem zasad współżycia społecznego oraz z zachowaniem
                wzajemnego szacunku.
              </li>
            </ul>
          </li>
          <li>
            Usługodawca zastrzega sobie prawo do usunięcia treści publikowanych na Stronie Internetowej oraz na
            Platformie HR-ME, które naruszają przepisy powszechnie obowiązującego w Polsce prawa, postanowienia
            Regulaminu, zasady współżycia społecznego lub dobre obyczaje, w szczególności treści, które:
            <ul>
              <li>mają charakter bezprawny;</li>
              <li>naruszają dobre obyczaje;</li>
              <li>zawierają sformułowania wulgarne, obsceniczne, zniesławiające lub obelżywe, </li>
              <li>są niezwiązane z przedmiotem Usługi;</li>
              <li>mające charakter spamu. </li>
            </ul>
          </li>
          <li>
            Usługodawca podkreśla, że nie tworzy ani nie przygotowuje treści dostępnych na Platformie HR-ME, a zakres
            Usług polega na publikacji Ofert Pracy oraz udostępnianiu profili Kandydatów umieszczanych przez
            Użytkowników na zasadach określonych w niniejszym Regulaminie, oraz na pośrednictwie w nawiązywaniu kontaktu
            między Pracodawcami a Kandydatami - w związku z czym nie ponosi on odpowiedzialności wobec Użytkowników za
            jakiekolwiek szkody pośrednie lub bezpośrednie jak również za jakąkolwiek utratę zysków lub przychodów a
            wynikających z:
            <ul>
              <li>
                dostępu do Strony Internetowej i Platformy HR-ME, jak również z niemożności korzystania z nich przez
                Użytkownika,{' '}
              </li>
              <li>
                treści publikowanych na Stronie Internetowej i w Platformie HR-ME stworzonych przez Użytkowników, w tym
                zarówno merytorycznej jak i opatrzonych nimi grafik oraz zdjęć;
              </li>
            </ul>
          </li>
          <li>
            Usługodawca zastrzega sobie niniejszym prawo do czasowej przerwy w świadczeniu Usług na rzecz Użytkowników
            wynikającej w szczególności wynikającej z prowadzonych prac konserwacyjnych, technicznych lub
            modernizacyjnych Strony Internetowej lub Platformy HR-ME.
          </li>
        </ul>
      </Typography>
      <Box mb={3} />
      <Typography variant="h4" style={{ textAlign: 'left' }}>
        §11 PRAWA DO STRONY INTERNETOWEJ ORAZ PLATFORMY HR-ME
      </Typography>
      <Box mb={3} />
      <Typography className={classes.text}>
        <ul>
          <li>
            Usługodawca oświadcza, że Strona Internetowa oraz Platforma HR-ME stanowią jego własność oraz wszelkie
            związane z nimi oprogramowanie, logotypy, znaki graficzne należą do Usługodawcy i są chronione prawami
            własności przemysłowej oraz prawami autorskimi. Użytkownik nie jest uprawniony do używania oprogramowania
            lub Usługi w sposób nieobjęty niniejszymi Regulaminem. Z chwilą zawarcia Umowy, Użytkownik udziela
            Usługodawcy nieograniczonej, niewyłącznej, nieodpłatnej, niezbywalnej licencji na korzystanie z wszelkich
            treści Użytkownika zgodnie z warunkami określonymi w Regulaminie i Polityce Prywatności.
          </li>
          <li>
            Użytkownik nie ma prawa przechowywać, kopiować, zwielokrotniać, sprzedawać, opracowywać, modyfikować,
            dekompilować, publikować lub w inny sposób wprowadzać do obrotu lub rozpowszechniać Strony Internetowej,
            Platformy HR-ME oraz ich zawartości (w tym w szczególności programu komputerowego, kodu źródłowego i
            wynikowego do programu komputerowego, jakichkolwiek innych utworów) w całości bądź we fragmentach, w
            szczególności nie ma prawa przesyłać lub udostępniać Strony Internetowej oraz Platformy HR-ME w systemach i
            sieciach komputerowych, systemach dystrybucji aplikacji mobilnych lub jakichkolwiek innych systemach
            teleinformatycznych.{' '}
          </li>
          <li>
            W celu usunięcia wszelkich wątpliwości interpretacyjnych wskazuje się, że zakaz o którym mowa w ust. 2
            powyżej nie obejmuje prawa do kopiowania i rozpowszechniania linków prowadzących do Strony Internetowej
            Usługodawcy w serwisach i portalach społecznościowych takich jak m.in. Facebook, Instagram, Linkedin.{' '}
          </li>
          <li>
            Użytkownik zobowiązuje się również nie podejmować żadnych działań zakłócających lub blokujących Stronę
            Internetową, Platformę HR-ME - w tym w szczególności prowadzących do modyfikacji, usuwania, osłabiania czy
            blokowania systemów zabezpieczeń Usługodawcy.{' '}
          </li>
        </ul>
      </Typography>
      <Box mb={3} />
      <Typography variant="h4" style={{ textAlign: 'left' }}>
        §12 DANE OSOBOWE
      </Typography>
      <Box mb={3} />
      <Typography className={classes.text}>
        <ul>
          <li>
            Administratorem danych osobowych przekazanych przez Użytkownika podczas korzystania ze Strony Internetowej
            oraz Platformy HR-ME jest Usługodawca.{' '}
          </li>
          <li>
            Szczegółowe informacje dotyczące przetwarzania danych osobowych przez Usługodawcę – w tym o celach oraz
            podstawach przetwarzania danych, o odbiorcach danych, oraz o uprawnieniach przysługujących osobie, której
            dane dotyczą znajdują się w Polityce Prywatności dostępnej na Stronie Internetowej.
          </li>
        </ul>
      </Typography>
      <Box mb={3} />
      <Typography variant="h4" style={{ textAlign: 'left' }}>
        §13 ZMIANA REGULAMINU
      </Typography>
      <Box mb={3} />
      <Typography className={classes.text}>
        <ul>
          <li>
            Usługodawca zastrzega sobie prawo do zmiany Regulaminu w każdym czasie, szczególnie w przypadku:
            <ul>
              <li>
                konieczności dostosowania Strony Internetowej lub Platformy HR-ME do przepisów prawa mających wpływ na
                usługi świadczone za ich pośrednictwem;
              </li>
              <li>zmian w zakresie bezpieczeństwa świadczonych Usług;</li>
              <li>zmian w funkcjonalności Strony Internetowej lub Platformy HR-ME;</li>
              <li>zmian w zakresie kosztów utrzymania Strony Internetowej lub Platformy HR-ME;</li>
              <li>zmian w zakresie funkcjonowania Usługodawcy,</li>
              <li>zmiany założeń funkcjonowania Strony Internetowej lub Platformy HR-ME,</li>
            </ul>
          </li>
          <li>
            Komunikat o planowanej zmianie Regulaminu co najmniej na 14 dni przed wprowadzeniem zmian w życie
            umieszczony na Stronie Internetowej. Informacja dotycząca planowanej zmiany Regulaminu zostanie również
            przesłana na adres mailowy podany podczas rejestracji Konta Użytkownika. Komunikat ten będzie widoczny co
            najmniej do pierwszego zalogowania się Użytkownika na Koncie.
          </li>
          <li>
            Dalsze korzystanie z Usług świadczonych za pośrednictwem Strony Internetowej oraz Platformy HR-ME możliwe
            jest wyłącznie po akceptacji wprowadzonych przez Usługodawcę zmian w Regulaminie. Wyrażenie przez
            Użytkownika zgody na zmiany Regulaminu następuje poprzez kliknięcie przycisku „Akceptuję” w okienku
            komunikatu o którym mowa w ust. 2 powyżej.
          </li>
          <li>
            W przypadku sprzeciwu i braku akceptacji dla planowanych zmian Użytkownik powinien usunąć Konto Użytkownika
            i zaprzestać korzystania ze Strony Internetowej oraz Platfofrmy HR-ME. Usunięcie Konta jest tożsame z
            rozwiązaniem umowy o świadczenie Usług.
          </li>
          <li>
            Data ostatniej modyfikacji Regulaminu będzie publikowana na Stronie Internetowej. Poprzednie wersje
            Regulaminu są dostępne na żądanie Użytkownika.
          </li>
        </ul>
      </Typography>
      <Box mb={3} />
      <Typography variant="h4" style={{ textAlign: 'left' }}>
        §14 POSTĘPOWANIE REKLAMACYJNE
      </Typography>
      <Box mb={3} />
      <Typography className={classes.text}>
        <ul>
          <li>
            W przypadku zastrzeżeń co do świadczonych Usług Użytkownik może złożyć reklamację korzystając z danych
            Usługodawcy wskazanych w § 3 Regulaminu.
          </li>
          <li>
            Rozpatrzenie reklamacji przez Usługodawcę nastąpi w terminie 14 dni od otrzymania zgłoszenia reklamacyjnego.{' '}
          </li>
          <li>
            Reklamacje niezawierające oznaczenia Użytkownika (imię, nazwisko, adres poczty elektronicznej lub adres
            korespondencyjny), przedmiotu reklamacji lub informacji o okolicznościach uzasadniających złożoną reklamację
            nie będą przez Usługodawcę rozpatrywane.{' '}
          </li>
          <li>
            O sposobie rozpatrzenia reklamacji Usługodawca poinformuje drogą mailową, na adres poczty elektronicznej
            wskazanej w treści reklamacji, a w przypadku reklamacji złożonych droga pisemną w formie stosownego pisma.{' '}
          </li>
          <li>
            W przypadku, gdy postępowanie reklamacyjne nie przyniesie oczekiwanego przez rezultatu, Konsument może
            skorzystać m.in. z:
            <ul>
              <li>
                mediacji prowadzonej przez właściwy terenowo Wojewódzki Inspektorat Inspekcji Handlowej, do którego
                należy się zwrócić z wnioskiem o mediację. Co do zasady postępowanie jest bezpłatne. Wykaz Inspektoratów
                znajduje się tutaj: https://www.uokik.gov.pl/wazne_adresy.php#faq595.
              </li>
              <li>
                pomocy właściwego terenowo stałego polubownego sądu konsumenckiego działającego przy Wojewódzkim
                Inspektoracie Inspekcji Handlowej, do którego należy złożyć wniosek o rozpatrzenie sprawy przed sądem
                polubownym. Co do zasady postępowanie jest bezpłatne. Wykaz sądów dostępny jest pod adresem:
                https://www.uokik.gov.pl/wazne_adresy.php#faq596.
              </li>
              <li>bezpłatnej pomocy miejskiego lub powiatowego rzecznika konsumentów.</li>
              <li>internetowej platformy ODR dostępnej pod adresem: http://ec.europa.eu/consumers/odr/</li>
            </ul>
          </li>
        </ul>
      </Typography>
      <Box mb={3} />
      <Typography variant="h4" style={{ textAlign: 'left' }}>
        §15 POSTANOWIENIA KOŃCOWE
      </Typography>
      <Box mb={3} />
      <Typography className={classes.text}>
        <ul>
          <li>Regulamin obowiązuje od momentu jego zamieszczenia na Stronie Internetowej,</li>
          <li>Niniejszym Regulamin podlega przepisom prawa polskiego i zgodnie z nim będzie interpretowany.</li>
          <li>
            W przypadku ewentualnych sporów z Użytkownikiem, w zakresie, w którym jest to dopuszczalne ustawowo, sądem
            właściwym będzie sąd określony według właściwości miejscowej Usługodawcy.{' '}
          </li>
          <li>
            Jeżeli którekolwiek z postanowień niniejszego Regulaminu uznane zostanie za nieważne, niezgodne z prawem lub
            niewykonalne, nie będzie to mieć wpływu na ważność i zgodność z prawem pozostałych postanowień.
          </li>
        </ul>
      </Typography>
    </>
  );
};
