import React from 'react';
import { Button, useTheme } from '@material-ui/core';

import { ButtonExtendProps } from './ButtonExtend.types';
import { useStyles } from './ButtonExtend.styles';

export const ButtonExtend: React.FC<ButtonExtendProps> = ({ onClick, children }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Button
      variant="outlined"
      disableElevation
      size="small"
      onClick={onClick}
      className={classes.wrapper}
      type="submit"
    >
      {children}
    </Button>
  );
};
