import { Box, Typography } from '@material-ui/core';
import React from 'react';

import { countryCodes } from 'helpers/countryCodes';
import { ButtonDefault } from 'ui/button/ButtonDefault';
import { DividerWithText } from 'ui/dividerWithText/DividerWithText';
import { TextInput } from 'ui/textInput/TextInput';
import { TextSelect } from 'ui/textSelect/TextSelect';
import { TwoItemsRow } from '../InvoiceScreen.styles';

import { InvoiceScreenFormProps } from './invoiceScreenForm.types';

export const InvoiceScreenForm = ({
  classes,
  register,
  errors,
  disabled,
  control,
  onClick,
  loading,
}: InvoiceScreenFormProps): JSX.Element => (
  <>
    <Typography variant="h6">Dane kontaktowe</Typography>
    <Box mb={2} />
    <TwoItemsRow>
      <div className={classes.flexGrow}>
        <TextInput
          valueName={'firstName'}
          name={'firstName'}
          label={'imię'}
          placeholder={'Jan'}
          ref={register}
          required={true}
          helperText={errors.firstName ? errors.firstName.message : null}
          error={!!errors.firstName}
          noBackground
        />
      </div>
      <Box width={24} />
      <div className={classes.flexGrow}>
        <TextInput
          valueName={'lastName'}
          name={'lastName'}
          label={'nazwisko'}
          placeholder={'Kowalski'}
          ref={register}
          required={true}
          helperText={errors.lastName ? errors.lastName.message : null}
          error={!!errors.lastName}
          noBackground
        />
      </div>
    </TwoItemsRow>
    <TextInput
      disabled={true}
      valueName={'email'}
      name={'email'}
      label={'adres e-mail'}
      placeholder={'hubert.mw@hr-me.co'}
      ref={register}
    />
    <DividerWithText>Dane Firmowe</DividerWithText>
    <TextInput
      valueName={'companyName'}
      name={'companyName'}
      label={'nazwa firmy'}
      placeholder={'hr-me.co'}
      ref={register}
      required={true}
      helperText={errors.companyName ? errors.companyName.message : null}
      error={!!errors.companyName}
      noBackground
    />
    <TextInput
      valueName={'companyAddressLine1'}
      name={'companyAddressLine1'}
      label={'ulica'}
      placeholder={'Wpisz adres'}
      ref={register}
      required={true}
      helperText={errors.companyAddressLine1 ? errors.companyAddressLine1.message : null}
      error={!!errors.companyAddressLine1}
      noBackground
    />
    <TwoItemsRow>
      <div className={classes.city}>
        <TextInput
          name={'city'}
          label={'miasto'}
          placeholder={'Wpisz nazwę miasta'}
          ref={register}
          required={true}
          valueName={'city'}
          helperText={errors.city ? errors.city.message : null}
          error={!!errors.city}
          noBackground
        />
      </div>
      <Box width={24} />
      <div className={classes.postal}>
        <TextInput
          name={'postcode'}
          label={'kod pocztowy'}
          placeholder={'00-000'}
          valueName={'postcode'}
          required={true}
          ref={register}
          helperText={errors.postcode ? errors.postcode.message : null}
          error={!!errors.postcode}
          noBackground
        />
      </div>
    </TwoItemsRow>
    <TextInput
      valueName={'state'}
      name={'state'}
      label={'województwo'}
      placeholder={'Wpisz województwo'}
      ref={register}
      required={true}
      helperText={errors.state ? errors.state.message : null}
      error={!!errors.state}
      noBackground
    />
    <div onClick={onClick}>
      <TextSelect
        name={'country'}
        label={'kraj'}
        control={control}
        required
        helperText={errors.country?.message}
        error={!!errors.country}
        items={countryCodes}
      />
    </div>
    <TextInput
      valueName={'nip'}
      name={'nip'}
      label={'nip'}
      placeholder={'0000000000'}
      ref={register}
      required={true}
      noBackground
    />
    <ButtonDefault disabled={disabled} loading={loading}>
      zapisz dane do następnego zamówienia
    </ButtonDefault>
  </>
);
