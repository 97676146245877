import { UseFormMethods } from 'react-hook-form';

import { TextSelectItem } from 'ui/textSelect/TextSelect.types';
import { AddJobOfferInitialValuesTypeRHF } from 'app/jobOffer/addJobOfferRHF/addJobOfferFormWrapperRHF/addJobOfferFormWrapperRHF.types';

export enum TextInputVariant {
  White = 'WHITE',
  Grey = 'GREY',
}

export type TextInputExtendableListProps = {
  label: string;
  listItems?: string[];
  buttonCaption: string;
  addInputLabel?: string;
  addInputPlaceholder?: string;
  variant?: TextInputVariant;
  selectItems?: TextSelectItem[];
  valueName?: string;
  name?: string;
  methods?: UseFormMethods<AddJobOfferInitialValuesTypeRHF>;
  smallText?: boolean;
};
