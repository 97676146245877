"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AlertMessage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var core_1 = require("@material-ui/core");
var lab_1 = require("@material-ui/lab");
var AlertMessage = function (_a) {
    var open = _a.open, close = _a.close, message = _a.message;
    var show = open.show, success = open.success;
    var handleClose = function (event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        close({ show: false, success: success });
    };
    return (jsx_runtime_1.jsx(core_1.Snackbar, __assign({ open: show, autoHideDuration: 4000, onClose: handleClose, anchorOrigin: { vertical: 'top', horizontal: 'center' } }, { children: jsx_runtime_1.jsx(lab_1.Alert, __assign({ onClose: function () { return close({ show: false, success: success }); }, severity: success ? 'success' : 'error' }, { children: jsx_runtime_1.jsx(jsx_runtime_1.Fragment, { children: message ? message : success ? 'Udało się zapisać dane' : 'Zapis danych nie powiódł się' }, void 0) }), void 0) }), void 0));
};
exports.AlertMessage = AlertMessage;
