"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
var core_1 = require("@material-ui/core");
exports.useStyles = core_1.makeStyles(function (theme) {
    var _a;
    return ({
        chartKey: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
        circle: {
            fontSize: '12px',
            marginRight: theme.spacing(0.5),
        },
        text: (_a = {
                color: theme.palette.grey.A200,
                fontSize: '12px'
            },
            _a[theme.breakpoints.down('md')] = {
                fontSize: '8px',
            },
            _a)
    });
});
