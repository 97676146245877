import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  statusBar: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  currentPlan: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: '18px',
  },
  currentPlanValue: {
    fontWeight: 600,
  },
  statusDetails: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },
  status: {
    padding: theme.spacing(0.5, 1),
    borderRadius: 5,
    color: theme.palette.common.white,
  },
  statusActive: {
    backgroundColor: 'green',
  },
  statusInactive: {
    backgroundColor: 'red',
  },
  statusDraft: {
    backgroundColor: 'goldenrod',
  },
}));
