import React from 'react';
import { Button, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { ButtonExpandProps } from './ButtonExpand.types';
import { useStyles } from './ButtonExpand.styles';

export const ButtonExpand: React.FC<ButtonExpandProps> = ({ expanded, caption, handleClick }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Button
      className={clsx({
        [classes.buttonDetails]: true,
        [classes.buttonDetailsExpanded]: expanded,
      })}
      variant="outlined"
      endIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      onClick={handleClick}
    >
      {caption && <Typography variant="body2">szczegóły</Typography>}
    </Button>
  );
};
