"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Currencies = void 0;
exports.Currencies = {
    PLN: 'PLN',
    EUR: 'EUR',
    GBP: 'GBP',
    USD: 'USD',
    CHF: 'CHF',
};
