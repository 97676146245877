import React from 'react';
import {
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import NoLogo from 'assets/ilustrations/noLogo.svg';
import { TextSelectItem } from 'ui/textSelect/TextSelect.types';

import { useStyles } from './CompanySelectInput.styles';
import { CompanySelectInputProps } from './CompanySelectInput.types';

//TODO: Temporary component, gonna change it in future.
export const CompanySelectInput: React.FC<CompanySelectInputProps> = ({ name, label, value, items, handleChange }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  if (items.length < 1) return null;

  const textItemItems: TextSelectItem[] = items.map((company) => {
    return {
      value: company.companyName,
      label: (
        <div className={classes.listItem}>
          <ListItemIcon className={classes.listItemIcon}>
            <img src={company.logo || NoLogo} alt="Company's logo" className={classes.img} />
          </ListItemIcon>
          <ListItemText>{company.companyName}</ListItemText>
        </div>
      ),
    };
  });

  return (
    <div className={classes.textSelect}>
      <InputLabel htmlFor={name}>
        <Typography variant="subtitle2" className={classes.label}>
          <span>{label}</span>
        </Typography>
      </InputLabel>
      <FormControl variant="outlined" className={classes.select}>
        <Select name={name} value={value.companyName} IconComponent={ExpandMoreIcon} onChange={handleChange}>
          {textItemItems.map((item, index) => (
            <MenuItem key={index} value={item.value} disabled={item.value === 'placeholder'}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
