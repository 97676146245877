import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  checkIcon: {
    width: 64,
    height: 64,
    alignSelf: 'center',
  },
  header: {},
  description: {
    color: theme.palette.grey.A200,
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
  },
  dashboard: {},
}));
