import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '100%',
    background: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
  },
  innerWrapper: {
    width: 485,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  formWrapper: {
    background: theme.palette.background.paper,
    padding: theme.spacing(4),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(6),
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
  },
  header: {
    marginBottom: theme.spacing(1),
    fontWeight: 700,
    fontSize: 24,
  },
  divider: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  form: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(4),
  },
}));
