import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';

import { useStyles } from './policyRules.styles';

export const PolicyRules: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <>
      <Typography variant="h2" style={{ textAlign: 'left' }}>
        Polityka Prywatności
      </Typography>
      <Typography variant="body2" className={classes.text} style={{ color: '#a4a4a4' }}>
        POLITYKA PRYWATNOŚCI STRONY INTERNETOWEJ I PLATFORMY HR-ME
      </Typography>
      <Box mb={6} />
      <Typography className={classes.text}>
        Ochrona zbieranych i przetwarzanych przez HR-ME Spółka z ograniczoną odpowiedzialnością danych osobowych
        Użytkowników, odbywająca się w związku ze świadczeniem na ich rzecz Usług opisanych szczegółowo w Regulaminie
        dostępnym na Stronie Internetowej www.hr-me.co stanowi dla Spółki kwestię priorytetową.
      </Typography>
      <Box mb={2} />
      <Typography className={classes.text}>
        HR-ME Sp. z o.o. dokłada wszelkich starań, by przetwarzanie danych osobowych odbywało się w sposób bezpieczny
        oraz zgodny z obowiązującymi przepisami prawa.
      </Typography>
      <Box mb={2} />
      <Typography className={classes.text}>
        W wypełnieniu obowiązku informacyjnego spoczywającego na Spółce jako na Administratorze Danych Osobowych,
        wprowadza ona niniejszą Politykę Prywatności określającą w szczególności zasady, cele oraz podstawy
        przetwarzania danych osobowych Użytkowników.
      </Typography>
      <Box mb={5} />
      <Typography variant="h4" style={{ textAlign: 'left' }}>
        §1 DEFINICJE
      </Typography>
      <Box mb={3} />
      <Typography className={classes.text}>
        W ramach niniejszej Polityki Prywatności, poprzez poniższe definicje rozumie się:
      </Typography>
      <Box mb={2} />
      <Typography className={classes.text}>
        <ol>
          <li>
            <b>Administrator</b> – HR-ME Spółka z ograniczoną odpowiedzialnością z siedzibą w Olsztynie, ul. Władysława
            Trylińskiego 16 (10-683 Olsztyn) wpisana do Krajowego Rejestru Sądowego – rejestru przedsiębiorców przez Sąd
            Rejonowy w Olsztynie, VIII Wydział Gospodarczy Krajowego Rejestru Sądowego, pod nr KRS: 0000780016 NIP:
            8481874572, REGON:382997840, z kapitałem zakładowym o wartości 5.000 zł., świadcząca Usługi;
          </li>
          <li>
            <b>Dane Osobowe</b> - wszelkie informacje o zidentyfikowanej lub możliwej do zidentyfikowania osobie
            fizycznej, poprzez jeden bądź kilka szczególnych czynników określających fizyczną, fizjologiczną,
            genetyczną, psychiczną, ekonomiczną, kulturową lub społeczną tożsamość, w tym w szczególności imię,
            nazwisko, adres poczty elektronicznej, numer telefonu, IP urządzenia, dane o lokalizacji, identyfikator
            internetowy oraz informacje gromadzone za pośrednictwem plików cookie oraz innej podobnej technologii.
          </li>
          <li>
            <b>Platforma HR-ME</b> – oprogramowanie Administratora udostępnione Użytkownikom za pośrednictwem strony
            internetowej pod adresem www.hr-me.co w ramach którego Administrator świadczy Usługi;
          </li>
          <li>
            <b>Polityka Prywatności</b> – niniejsza Polityka Prywatności;
          </li>
          <li>
            <b>Przetwarzanie</b> – operacje lub zestaw operacji wykonywanych na danych osobowych lub zestawach danych
            osobowych w sposób zautomatyzowany lub niezautomatyzowany, taką jak zbieranie, utrwalanie, organizowanie,
            porządkowanie, przechowywanie, adaptowanie lub modyfikowanie, pobieranie, przeglądanie, wykorzystywanie,
            ujawnianie poprzez przesłanie, rozpowszechnianie lub innego rodzaju udostępnianie, dopasowywanie lub
            łączenie, ograniczanie, usuwanie lub niszczenie;
          </li>
          <li>
            <b>Regulamin</b> – regulamin Administratora regulujący korzystanie ze Strony Internetowej Platformy HR-ME,
            dostępny na Stronie Internetowej;
          </li>
          <li>
            <b>RODO</b> – Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w
            sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu
            takich danych oraz uchylenia dyrektywy 95/46/WE;
          </li>
          <li>
            <b>Strona Internetowa</b> – strona internetowa prowadzona przez Administratora pod adresem www.hr-me.co, za
            pośrednictwem której możliwe jest korzystanie z usług oferowanych przez Administratora w ramach Platformy
            HR-ME;
          </li>
          <li>
            <b>Użytkownik</b> – osoba korzystająca ze Strony Internetowej oraz Platformy HR-ME będąca Pracodawcą albo
            Kandydatem w rozumieniu Regulaminu oraz osoba nieposiadająca zarejestrowanego Konta Użytkownika,
            korzystająca ze Strony Internetowej i Platformy HR-ME w zakresie możliwym bez dokonywania rejestracji;
          </li>
        </ol>
      </Typography>
      <Box mb={2} />
      <Typography className={classes.text}>
        Ponadto wyrazy pisane wielką literą, które nie zostały zdefiniowane powyżej, mają znaczenie takie jakie zostało
        im nadane w § 1 Regulaminu.
      </Typography>
      <Box mb={5} />
      <Typography variant="h4" style={{ textAlign: 'left' }}>
        §2 POSTANOWIENIA OGÓLNE
      </Typography>
      <Box mb={3} />
      <Typography className={classes.text}>
        <ol>
          <li>
            Administratorem Danych Osobowych Użytkowników jest HR-ME Spółka z ograniczoną odpowiedzialnością z siedzibą
            w Olsztynie, ul. Władysława Trylińskiego 16 (10-683 Olsztyn) wpisana do Krajowego Rejestru Sądowego –
            rejestru przedsiębiorców przez Sąd Rejonowy w Olsztynie, VIII Wydział Gospodarczy Krajowego Rejestru
            Sądowego, pod nr KRS: 0000780016 NIP: 8481874572, REGON:382997840, z kapitałem zakładowym o wartości 5.000
            zł.
          </li>
          <li>
            W celach związanych z przetwarzaniem danych osobowych Użytkowników kontaktować należy się bezpośrednio z
            Administratorem w formie elektronicznej pod adresem e-mail: hello@hr-me.co lub w formie pisemnej pod adresem
            Trylińskiego 16, Olsztyn lub z wyznaczonym Inspektorem Ochrony Danych pod adresem mailowym:
            klinke.piotr@gmail.com
          </li>
          <li>
            Wszelkie dane osobowe Użytkowników zbierane i przetwarzane są przez Administratora z zachowaniem środków
            bezpieczeństwa zabezpieczających je przed dostępem osób i podmiotów trzecich, zgodnie z obowiązującymi w tym
            zakresie przepisami prawa.
          </li>
          <li>
            Administrator dokłada szczególnych starań w celu ochrony praw Użytkowników, w szczególności w zakresie
            ochrony prywatności i ochrony informacji mu przekazywanych i dotyczących Użytkowników.
          </li>
          <li>
            Administrator szczególnie podejmuje wszelkie środki organizacyjne i techniczne, by dane osobowe chronione
            były przed ich udostępnieniem osobom nieupoważnionym oraz przed przetwarzaniem z naruszeniem obowiązujących
            w tym zakresie przepisów oraz ich zmianą, utratą, uszkodzeniem lub zniszczeniem.
          </li>
          <li>
            Podanie danych osobowych przez Użytkownika jest dobrowolne, ale brak zgody na przetwarzanie danych osobowych
            oznaczonych jako obligatoryjne, uniemożliwi wykonywanie świadczenie usług, przez Administratora.
          </li>
          <li>Integralną część niniejszej Polityki stanowią jej Załączniki.</li>
        </ol>
      </Typography>
      <Box mb={5} />
      <Typography variant="h4" style={{ textAlign: 'left' }}>
        §3 STOSOWANIE POLITYKI PRYWATNOŚCI
      </Typography>
      <Box mb={3} />
      <Typography className={classes.text}>
        <ol>
          <li>
            Niniejsza Polityka Prywatności dotyczy Strony Internetowej oraz Platformy HR-ME i ma zastosowanie do
            wszystkich przypadków, w których Administrator administruje danymi osobowymi i je przetwarza. Dotyczy to
            zarówno przypadków, w których Administrator przetwarza dane osobowe uzyskane bezpośrednio od osoby, której
            dane dotyczą, jak i przypadków, w których dane osobowe uzyskane zostały z innych źródeł, w tym umów
            dotyczących powierzenia przetwarzania danych.
          </li>
          <li>
            Poprzez ustanowienie niniejszej Polityki Prywatności oraz jej udostępnienie na Stronie Internetowej
            Administrator wypełnia nałożone na niego obowiązki informacyjne określone wynikające z art. 13 i art. 14
            RODO, każdorazowo zgodnie z tymi przepisami.
          </li>
        </ol>
      </Typography>

      <Box mb={5} />
      <Typography variant="h4" style={{ textAlign: 'left' }}>
        §4 RODZAJ, CEL I PODSTAWA PRAWNA PRZETWARZANIA DANYCH OSOBOWYCH
      </Typography>
      <Box mb={3} />
      <Typography className={classes.text}>
        <ol>
          <li>
            Użytkownik odwiedzający Stronę Internetową lub korzystający z Platformy HR-ME posiada kontrolę nad danymi
            osobowymi, które są przez niego podawane. Administrator ogranicza zbieranie i wykorzystywanie informacji o
            Użytkownikach do niezbędnego minimum.
          </li>
          <li>
            Administrator zbiera dane osobowe dotyczące osób fizycznych, w tym osób fizycznych prowadzących jednoosobową
            działalność gospodarczą, w związku z korzystaniem przez Użytkownika ze Strony Internetowej oraz Platformy
            HR-ME, w zakresie niezbędnym do świadczenia Usług. Administrator zbiera również informacje o aktywności
            Użytkownika na Stronie Internetowej oraz Platformie HR-ME.
          </li>
          <li>
            Przetwarzane dane osobowe różnią się w zależności od typu Użytkownika (Kandydat lub Pracodawca) i w
            zależności od świadczonej Usługi oraz celu przetwarzania.
          </li>
          <li>
            W celu zapewnienia Użytkownikom przejrzystej informacji Administrator wskazuje cele i podstawy prawne
            przetwarzania danych osobowych, rodzaj przetwarzanych danych oraz okres ich przetwarzania w Tabeli
            stanowiącej Załącznik nr 1 do niniejszej Polityki.
          </li>
          <li>
            W zakresie danych niezbędnych do rejestracji indywidualnego Konta Użytkownika, niniejsza Polityka
            Prywatności odsyła do treści Regulaminu, w szczególności § 7 Regulaminu. Podanie danych innych niż
            obligatoryjne zależy wyłącznie od decyzji Użytkownika, jednak brak ich podania może wpłynąć na ograniczenie
            możliwości korzystania z określonych funkcjonalności Platformy HR-ME.
          </li>
          <li>
            Administrator nie zbiera ani nie przetwarza szczególnych kategorii danych osobowych stanowiących dane
            wrażliwe. Tym samym Administrator prosi Użytkowników o niepodawanie tego typu informacji (w szczególności
            informacji dotyczących rasy, pochodzenia etnicznego, poglądów politycznych, przekonań religijnych lub
            filozoficznych, informacji dotyczących zdrowia, życia seksualnego, orientacji seksualnej, przeszłości
            kryminalnej, danych genetycznych i biometrycznych). W przypadku podania takich informacji przez Użytkownika
            będzie to oznaczać udzielenie wyraźnej zgody na zbieranie i wykorzystywanie tych informacji przez
            Administratora w sposób określony w niniejszej Polityce Prywatności.
          </li>
          <li>
            Administrator nie przetwarza danych osobowych dotyczących kart kredytowych, numerów rachunków bankowych oraz
            innych danych związanych z procesem dokonywania płatności za pośrednictwem serwisów płatniczych.
            Administrator danych może przetwarzać dane związane z dokonywaniem płatności w sytuacji, gdy Użytkownik
            wybierze dokonanie płatności poprzez bezpośredni przelew na rachunek bankowy Administratora – przetwarzanie
            dotyczy wówczas danych identyfikujących wskazanych w przelewie, w tym numeru rachunku bankowego z którego
            dokonano zapłaty.
          </li>
        </ol>
      </Typography>
      <Box mb={5} />
      <Typography variant="h4" style={{ textAlign: 'left' }}>
        §5 CZAS PRZETWARZANIA DANYCH OSOBOWYCH
      </Typography>
      <Box mb={3} />
      <Typography className={classes.text}>
        <ol>
          <li>
            Czas przez jaki przetwarzane będą przez Administratora dane osobowe jest uzależniony od podstawy prawnej
            przetwarzania tych danych osobowych.
          </li>
          <li>
            Dane pozyskane w związku z rejestracją Konta na Stronie Internetowej i Platformie HR-ME oraz w związku z
            korzystaniem z Usług będą przetwarzane, przez okres przez jaki Użytkownik jest zarejestrowany, a po tym
            okresie będą przetwarzane w celach oraz w czasie związanym z zabezpieczeniem roszczeń Administratora lub
            wynikającym z obowiązujących przepisów prawa.
          </li>
          <li>
            Dane osobowe przetwarzane na potrzeby marketingu produktów lub usług własnych na podstawie uzasadnionego
            interesu prawnego przetwarzane będą do czasu zgłoszenia przez tę osobę sprzeciwu.
          </li>
          <li>
            Dane osobowe przetwarzane przez Administratora na podstawie uzasadnionego interesu administratora danych, w
            celu innym niż marketingowy, przetwarzane będą do momentu ustania tego interesu lub do momentu sprzeciwienia
            się osoby, której dane dotyczą, dalszemu takiemu przetwarzaniu.
          </li>
          <li>
            Dane osobowe przetwarzane w oparciu o zgodę wyrażoną przez Użytkownika będą przetwarzane do momentu jej
            odwołania. Cofnięcie przez Użytkownika zgody na przetwarzanie danych osobowych nie wpływa na zgodność z
            prawem przetwarzania, którego dokonano na podstawie zgody przed jej wycofaniem.
          </li>
          <li>
            Okres przetwarzania danych może być przedłużony w przypadku, gdy przetwarzanie jest niezbędne do ustalenia i
            dochodzenia ewentualnych roszczeń lub obrony przed nimi, a po tym czasie jedynie w przypadku i w zakresie, w
            jakim będą wymagać tego przepisy prawa.
          </li>
          <li>
            Jeśli przetwarzanie danych osobowych wynika z ciążącego na Administratorze obowiązku prawnego, okresy
            przetwarzania danych w tym celu określają odpowiednie przepisy.
          </li>
          <li>
            Szczegółowy okres, przez jaki przetwarzane są dane osobowe Użytkowników w zależności od celu i podstawy
            prawnej przetwarzania wskazany jest w Tabeli stanowiącej Załącznik nr 1 do niniejszej Polityki.
          </li>
        </ol>
      </Typography>
      <Box mb={5} />
      <Typography variant="h4" style={{ textAlign: 'left' }}>
        §6 [ PLIKI COOKIES ]
      </Typography>
      <Box mb={3} />
      <Typography className={classes.text}>
        <ol>
          <li>
            W ograniczonym zakresie Administrator może zbierać dane osobowe automatycznie za pośrednictwem plików
            cookies znajdujących się na Stronie Internetowej. Pliki te tworzone są przez przeglądarkę internetową oraz
            urządzenie Użytkownika za każdym razem kiedy korzysta on ze Strony Internetowej i Platformy HR-ME.
          </li>
          <li>
            Cookies to niewielkie pliki tekstowe zapisywane na komputerze użytkownika lub innym urządzeniu mobilnym, w
            czasie korzystania przez niego z serwisów internetowych, w których zapisano ustawienia użytkownika. Pliki te
            służą m.in. korzystaniu z różnych funkcji przewidzianych na danej stronie internetowej lub potwierdzeniu, że
            dany użytkownik widział określone treści z danej witryny internetowej. Pliki cookies tworzone są przez
            przeglądarkę internetową Użytkownika za każdym razem kiedy wchodzi on na Stronę Internetową. Część z nich
            może pozostać na urządzeniu po zamknięciu przeglądarki. Przy następnej wizycie z tego samego urządzenia
            przeglądarka może sprawdzić, czy na urządzeniu zapisany jest odpowiedni plik cookie i przesłać zawarte w nim
            dane ponownie do strony, która zapisała ciasteczko. Dzięki temu można rozpoznać, że dany użytkownik
            odwiedził ją w przeszłości i w niektórych przypadkach dostosować prezentowaną treść do odbiorcy.
          </li>
          <li>
            Wśród plików cookies wyróżnić możne te, które są niezbędne do działania Strony Internetowej. Należące do tej
            kategorii pliki cookies wykorzystywane są między innymi aby zapewnić:
            <ul>
              <li>utrzymanie sesji użytkownika;</li>
              <li>zapisanie stanu sesji użytkownika;</li>
              <li>umożliwienie autoryzacji z wykorzystaniem Serwisu logowania</li>
              <li>monitoring dostępności usług.</li>
            </ul>
          </li>
          <li>
            Kolejna kategoria plików cookies, to pliki, które wprawdzie nie są niezbędne do korzystania z Platformy
            HR-ME, ale ułatwiają korzystanie z nich. Wykorzystuje się je, aby umożliwić:
            <ul>
              <li>przywrócenie ostatnio odwiedzanego widoku przy następnym zalogowaniu;</li>
              <li>
                zapamiętanie wyboru Użytkownika w kwestii zaprzestania wyświetlania wybranego komunikatu lub
                wyświetlenia go określoną liczbę razy;
              </li>
              <li>przywrócenie sesji Użytkownika</li>
              <li>zapamiętanie ostatnio wybranej kategorii w produkcie;</li>
              <li>sprawdzenie czy zapis do plików cookies funkcjonuje prawidłowo;</li>
              <li>umożliwienie automatycznego zalogowania (opcja "zapamiętaj mnie");</li>
              <li>dopasowanie zawartości Platformy HR-ME do preferencji Użytkowników;</li>
              <li>przywrócenie ostatniego wyniku wyszukiwania;</li>
              <li>wyświetlenie ostatnio wybranego parametru sortowania.</li>
            </ul>
          </li>
          <li>
            Administrator korzysta też z usług podmiotów trzecich, których lista stale zmienia się, a które wykorzystują
            pliki cookies m.in. w następujących celach:
            <ul>
              <li>monitorowanie ruchu na Stronie Internetowej;</li>
              <li>
                zbieranie anonimowych, zbiorczych statystyk, które pozwalają Administratorowi zrozumieć, w jaki sposób
                użytkownicy korzystają ze Strony Internetowej i umożliwiają ciągłe doskonalenie naszych produktów;
              </li>
              <li>ustalanie liczby anonimowych Użytkowników Strony Internetowej;</li>
              <li>kontrolowanie jak często pokazywana jest Użytkownikom wybrana treść;</li>
              <li>kontrolowanie jak często Użytkownicy wybierają daną usługę;</li>
              <li>płatności internetowe.</li>
            </ul>
          </li>
          <li>
            Żadne pliki cookies wykorzystywane na Stronie Internetowej oraz Platformie HR-ME nie gromadzą informacji
            umożliwiających ustalenie tożsamości Użytkownika.
          </li>
          <li>
            Użytkownik może zmieniać sposób korzystania z plików cookie przez przeglądarkę w tym blokować lub usunąć te,
            które pochodzą ze Strony Internetowej. W tym celu należy zmienić ustawienia przeglądarki. Większość
            przeglądarek zapewnia możliwość akceptowania lub odrzucania wszystkich plików cookie, akceptowania tylko
            niektórych rodzajów albo informowania użytkownika za każdym razem, gdy strona internetowa próbuje je
            zapisać. Użytkownik ma również możliwość usuwania plików cookie już zapisanych przez przeglądarkę.
            Zarządzanie i usuwanie plików cookie różni się w zależności od danej przeglądarki, a dokładne informacje na
            ten temat można uzyskać korzystając z funkcji Pomoc w swojej przeglądarce.
          </li>
          <li>
            Zablokowanie lub usunięcie wszystkich ciasteczek ze Strony Internetowej może spowodować trudność w działaniu
            lub zupełnie uniemożliwić korzystanie z niektórych jej funkcjonalności.
          </li>
        </ol>
      </Typography>
      <Box mb={5} />
      <Typography variant="h4" style={{ textAlign: 'left' }}>
        §7 [ AUTOMATYCZNE PRZETWARZANIE DANYCH OSOBOWYCH ]
      </Typography>
      <Box mb={3} />
      <Typography className={classes.text}>
        <ol>
          <li>
            Dane osobowe zbierane i przetwarzane przez Administratora mogą być automatycznie przetwarzane. Przez
            automatyczne przetwarzanie należy rozumieć, że niektóre dane osobowe Użytkownika będą profilowane i
            wykorzystywane do oceny niektórych czynników osobowych Użytkownika będącego osobą fizyczną - w szczególności
            do analizy lub prognozy aspektów dotyczących preferencji lub zainteresowań.
          </li>
          <li>
            Administrator oświadcza, że w ramach świadczonych Usług będzie przeprowadzał proces autodopasowania profilu
            Kandydata do zamieszczonych na Platformie HR-ME Ofert, na zasadach określonych szczegółowo w Regulaminie.
            Celem procesu autodopasowania jest skojarzenia Kandydatów z najlepiej dobranymi do nich Ofertami
            zamieszczonymi przez Pracodawców na Platformie HR-ME. Proces profilowania opiera się o dane podane
            dobrowolnie przez Użytkownika będącego Kandydatem.
          </li>
          <li>
            Administrator jednocześnie oświadcza, że nie będą podejmowane przez niego jakiekolwiek decyzje, które
            wywoływałyby dla Użytkownika skutki prawne lub w inny istotny sposób wpływałyby na jego sytuację, a które
            opierałyby się wyłącznie na zautomatyzowanym przetwarzaniu danych osobowych Użytkownika, w tym na
            profilowaniu.
          </li>
          <li>
            Użytkownik rejestrując się wyraża zgodę na otrzymywanie powiadomień drogą mailową o ofertach pracy
            dopasowanych do jego profilu.
          </li>
        </ol>
      </Typography>

      <Box mb={5} />
      <Typography variant="h4" style={{ textAlign: 'left' }}>
        §8 PRZEKAZYWANIE DANYCH
      </Typography>
      <Box mb={3} />
      <Typography className={classes.text}>
        <ol>
          <li>
            Przekazywanie danych osobowych innym podmiotom następuje wyłącznie gdy zezwalają na to przepisy prawa. W
            takim przypadku, przekazanie danych osobowych do przetwarzania innemu podmiotowi odbywa się na podstawie
            umowy powierzenia przetwarzania danych, która to umowa zapewnia stosowanie przez podmiot przetwarzający
            odpowiednich środków bezpieczeństwa w celu ochrony danych zgodnie z obowiązującymi przepisami prawa.
          </li>
          <li>
            W przypadku uzyskania zgody Użytkownika jego dane mogą zostać także udostępnione innym podmiotom do ich
            własnych celów, w tym celów marketingowych.
          </li>
          <li>
            Odbiorcami danych osobowych, które Administrator przetwarza mogą być w szczególności:
            <ol type="a">
              <li>podmioty przetwarzające dane osobowe na mocy umów powierzenia przetwarzania danych osobowych;</li>
              <li>podmioty świadczące usługi hostingowe na rzecz Administratora;</li>
              <li>podmioty realizujące kampanie marketingowe na rzecz Administratora;</li>
              <li>operatorzy pocztowi;</li>
              <li>banki i operatorzy płatności;</li>
              <li>podmioty świadczący usługi prawne</li>
            </ol>
          </li>
          <li>
            Dane osobowe przekazywane są wyłącznie w zakresie, w jakim jest to rzeczywiście niezbędne dla osiągnięcia
            danego celu.
          </li>
          <li>
            Administrator informuje, że dane osobowe Użytkowników mogą być przekazywane na podstawie umowy powierzenia
            przetwarzania danych poza Europejski Obszar Gospodarczy. Dotyczy to w szczególności przekazywania danych do
            firmy Google LLC, co wynika z faktu, że Administrator korzysta z usług i serwerów tej firmy w celu
            świadczenia usług. Administrator oświadcza jednocześnie, że podjął wszelkie kroki prawne zmierzające do
            zapewnienia w tym zakresie zachowania standardów bezpieczeństwa wynikających z obowiązujących przepisów
            RODO, w tym w szczególności zawarł z Google LLC umowę powierzenia przetwarzania danych zawierającą
            standardowe klauzule umowne przyjęte przez Komisję Europejską i stanowiące właściwy środek zabezpieczenia na
            podstawie art. 46 ust. 2 RODO.
          </li>
        </ol>
      </Typography>
      <Box mb={5} />
      <Typography variant="h4" style={{ textAlign: 'left' }}>
        §9 PRAWA OSOBY KTÓREJ DANE DOTYCZĄ
      </Typography>
      <Box mb={3} />
      <Typography className={classes.text}>
        <ol>
          <li>
            W celu realizacji swoich uprawnień osoba fizyczna może kontaktować się w tej sprawie z Administratorem lub
            Inspektorem Ochrony Danych poprzez wysłanie wniosku w formie wiadomości elektronicznej e-mail lub w formie
            fizycznej zgodnie z danymi kontaktowymi wskazanymi w § 2 ust. 2 Polityki Prywatności. Wniosek powinien
            określać w miarę możliwości precyzyjnie z jakiego uprawnienia dana osoba chce skorzystać, jakiego procesu
            przetwarzania oraz jakich celów przetwarzania dotyczy żądanie.
          </li>
          <li>
            Odpowiedź na zgłoszenie zostanie udzielona w ciągu miesiąca od jego otrzymania. W razie konieczności
            przedłużenia terminu Administrator poinformuje Użytkownika o jego przyczynach. Odpowiedź udzielana będzie na
            adres e-mail z którego został przesłany wniosek, a w przypadku wniosków skierowanych listownie listem
            zwykłym na adres wskazany przez wnioskodawcę, o ile w liście nie zostanie wykazana chęć otrzymania
            odpowiedzi na adres e-mail.
          </li>
          <li>
            Wśród praw Użytkownika należy wskazać w szczególności:
            <ol type="a">
              <li>prawo do informacji o przetwarzaniu danych osobowych,</li>
              <li>prawo do uzyskania kopii danych,</li>
              <li>prawo do sprostowania bądź zmiany danych osobowych,</li>
              <li>prawo do wycofania zgody na przetwarzanie danych osobowych,</li>
              <li>prawo do ograniczenia przetwarzania,</li>
              <li>prawo do wniesienia sprzeciwu wobec przetwarzania danych osobowych;</li>
              <li>prawo do żądania usunięcia danych,</li>
              <li>prawo do przenoszenia danych.</li>
            </ol>
          </li>
        </ol>
      </Typography>

      <Box mb={5} />
      <Typography variant="h4" style={{ textAlign: 'left' }}>
        §10 BEZPIECZEŃSTWO DANYCH OSOBOWYCH
      </Typography>
      <Box mb={3} />
      <Typography className={classes.text}>
        <ol>
          <li>
            Informacje dotyczące Użytkowników, w tym dane osobowe Użytkowników są chronione przez Administratora przed
            ich udostępnieniem osobom nieupoważnionym, a także innymi przypadkami ich ujawnienia albo utraty oraz przed
            zniszczeniem lub nieuprawnioną modyfikacją wskazanych danych oraz informacji poprzez stosowanie odpowiednich
            zabezpieczeń technicznych i organizacyjnych.
          </li>
          <li>
            Administrator podejmuje wszelkie niezbędne działania, by podmioty współpracujące dawały gwarancję stosowania
            odpowiednich środków bezpieczeństwa w każdym przypadku, gdy przetwarzają dane osobowe na zlecenie
            Administratora.
          </li>
        </ol>
      </Typography>

      <Box mb={5} />
      <Typography variant="h4" style={{ textAlign: 'left' }}>
        §11 WSZELKIE POZOSTAŁE PYTANIA, WĄTPLIWOŚCI I SKARGI
      </Typography>
      <Box mb={3} />
      <Typography className={classes.text}>
        <ol>
          <li>
            W razie jakichkolwiek pytań zastrzeżeń lub wątpliwości dotyczących treści niniejszej Polityki Prywatności,
            sposobu przetwarzania przez Administratora danych osobowych, jak również w przypadki zamiaru złożenia skargi
            dotyczących tych kwestii należy przesłać wiadomość wraz ze szczegółowymi informacjami i okolicznościami, w
            tym dotyczącymi skargi, na adres wskazany w §2 ust. 2 Polityki Prywatności
          </li>
          <li>
            Osoby, których dane osobowe przetwarza Administrator mają również prawo wnieść skargę do organu nadzorczego,
            którym jest Prezes Urzędu Ochrony Danych Osobowych na adres – Urząd Ochrony Danych Osobowych, ul. Stawki 2,
            00 – 193 Warszawa.
          </li>
        </ol>
      </Typography>

      <Box mb={5} />
      <Typography variant="h4" style={{ textAlign: 'left' }}>
        §12 ZMIANY POLITYKI PRYWATNOŚCI
      </Typography>
      <Box mb={3} />
      <Typography className={classes.text}>
        <ol>
          <li>
            Administrator wprowadzi zmiany w zakresie niniejszej Polityki Prywatności w sytuacji, gdy okaże się to
            niezbędne lub pożądane, między innymi z uwagi na nowe przepisy prawa, nowe wytyczne organów odpowiedzialnych
            za nadzór nad procesami ochrony danych osobowych oraz zmiany technologii za pomocą której Administrator
            przetwarza dane osobowe.
          </li>
          <li>
            Zmiany Polityki Prywatności nie mogą pozbawiać Użytkowników praw nabytych i odnoszą one skutek na
            przyszłość, od daty wprowadzenia zmiany.
          </li>
          <li>
            Zmiany niniejszej Polityki Prywatności będą dokonywane poprzez jej publikacje na Stronie Internetowej.
            Użytkownik, który nie zgadza się na zmiany Polityki Prywatności może w każdej chwili zaprzestać korzystania
            ze Strony Internetowej lub wypowiedzieć umowę o świadczenie usług na zasadach zawartych w Regulaminie.{' '}
          </li>
        </ol>
        Załącznik: Tabela dot. celów, zakresu, podstaw prawnych oraz okresu przetwarzania danych osobowych
      </Typography>
    </>
  );
};
