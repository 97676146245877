import React from 'react';
import { Button, ButtonProps, useTheme } from '@material-ui/core';

import { useStyles } from './ButtonOutlined.styles';

export const ButtonOutlined: React.FC<ButtonProps> = ({ onClick, children }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Button
      fullWidth
      variant="outlined"
      className={classes.button}
      size="large"
      disableElevation
      type="button"
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
