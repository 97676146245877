export enum Filters {
  STATUS = 'status',
  OFFER = 'offer',
  ORDER = 'order',
}

export enum OfferStatus {
  ALL = 'wszystkie',
  AWAITING = 'oczekuje',
  ACCEPTED = 'zaakceptopwano',
  SENT = 'wyslano',
  REJECTED = 'odrzucono',
  PAID = 'odblokowano',
}

export type ApplicantsListFromJobOffersState = {
  location?: {
    state: {
      jobOfferId: string;
    };
  };
};
