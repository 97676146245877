import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';

import {
  ExperienceTypes,
  Interview,
  PlaceOfWork,
  WorkingTime,
} from 'app/jobOffer/addJobOfferRHF/addJobOfferWidePanelRHF/components/addJobOfferPositionBasicsRHF/AddJobOfferPositionBasicsRHF.types';
import { AddJobOfferInitialValuesTypeRHF } from 'app/jobOffer/addJobOfferRHF/addJobOfferFormWrapperRHF/addJobOfferFormWrapperRHF.types';

const experienceTypes = Object.values(ExperienceTypes).map((experienceType) => experienceType.value);
const workingTimeTypes = Object.values(WorkingTime).map((workingTimeType) => workingTimeType.value);
const placeOfWorkTypes = Object.values(PlaceOfWork).map((placeOfWorkType) => placeOfWorkType.value);
const interviewTypes = Object.values(Interview).map((interviewType) => interviewType.value);

const validationSchema = Yup.object().shape({
  position: Yup.string().required('Nazwa stanowiska jest wymagana'),
  category: Yup.string().required('Kategoria jest wymagana'),
  contractType: Yup.array().min(1, 'Wybierz co najmniej jeden typ umowy'),
  salaryRange: Yup.boolean(),
  salaryRangeFrom: Yup.number().typeError('Wpisz minimalną kwotę wynagrodzenia').min(0).required(),
  salaryRangeTo: Yup.number()
    .min(Yup.ref('salaryRangeFrom'), `Wynagrodzenie 'do' nie może być mniejsze niz 'od'`)
    .typeError('Wpisz maksymalną kwotę wynagrodzenia'),
  currency: Yup.string().required('Wybierz walutę'),
  salaryOnHand: Yup.boolean(),
  isNetto: Yup.boolean(),
  paidLeave: Yup.boolean(),
  relocationPackage: Yup.boolean(),
  hireAsap: Yup.boolean(),
  hireSince: Yup.string().when('hireAsap', {
    is: false,
    then: Yup.string().required('Podaj datę zatrudnienia'),
  }),
  workingTime: Yup.string().oneOf(workingTimeTypes, 'Musisz wybrać wymiar czasu pracy'),
  placeOfWork: Yup.string().oneOf(placeOfWorkTypes, 'Musisz wybrać miejsce pracy'),
  otherPlaceOfWork: Yup.string().when('placeOfWork', {
    is: 'other',
    then: Yup.string().required('Wpisz inne miejsce pracy'),
  }),
  companyCity: Yup.string().required('Miasto jest wymagane'),
  experienceType: Yup.string().oneOf(experienceTypes, 'Wybierz poziom doświadczenia'),
  experienceTime: Yup.string().when('experienceType', {
    is: (experienceType: string) => ['junior', 'mid', 'senior', 'expert'].includes(experienceType),
    then: Yup.string().required('Podaj lata doświadczenia'),
  }),
  interview: Yup.string().oneOf(interviewTypes, 'Wybierz typ rozmowy'),
  benefits: Yup.array().min(1, 'Podaj minumum jedną umiejętność'),
  positionDescription: Yup.string().required('Wpisz opis stanowiska'),
  mainTechnologies: Yup.array().min(1, 'Podaj minumum jedną umiejętność'),
  positionTechnologies: Yup.array().min(1, 'Podaj minumum jedną umiejętność'),
  welcomeTechnologies: Yup.array().min(1, 'Podaj minumum jedną umiejętność'),
  willLearnTechnologies: Yup.array().min(1, 'Podaj minumum jedną umiejętność'),
  collaborators: Yup.array().min(1, 'Podaj minimum jedną umiejętność'),
  willLearn: Yup.array().min(1, 'Podaj minumum jedną umiejętność'),
  confirmed: Yup.bool().oneOf([true], 'Musisz zgodzić się z regulaminem hr-me'),
});

export const AddJobOfferFormWrapperRHF: React.FC = ({ children }) => {
  const location: { state: { edit: AddJobOfferInitialValuesTypeRHF } } = useLocation();
  const offerEdit = location?.state?.edit;

  const defaultValues: AddJobOfferInitialValuesTypeRHF = {
    companyId: offerEdit ? offerEdit.companyId : '',
    position: offerEdit ? offerEdit.position : '',
    category: offerEdit ? offerEdit.category : '',
    contractType: offerEdit ? offerEdit.contractType : [],
    salaryRange: offerEdit ? offerEdit.salaryRange : true,
    salaryRangeFrom: offerEdit && offerEdit.salaryRangeFrom,
    salaryRangeTo: offerEdit && offerEdit.salaryRangeTo,
    currency: offerEdit ? offerEdit.currency : '',
    isNetto: offerEdit ? offerEdit.isNetto : false,
    paidLeave: offerEdit ? offerEdit.paidLeave : false,
    relocationPackage: offerEdit ? offerEdit.relocationPackage : false,
    hireAsap: offerEdit ? offerEdit.hireAsap : false,
    hireSince: offerEdit ? offerEdit.hireSince : '',
    workingTime: offerEdit ? offerEdit.workingTime : '',
    placeOfWork: offerEdit ? offerEdit.placeOfWork : '',
    otherPlaceOfWork: offerEdit ? offerEdit.otherPlaceOfWork : '',
    companyCity: offerEdit ? offerEdit.companyCity : '',
    companyStreet: offerEdit ? offerEdit.companyStreet : '',
    officeHours: offerEdit ? offerEdit.officeHours : '',
    interview: offerEdit ? offerEdit.interview : '',
    experienceTime: offerEdit ? offerEdit.experienceTime : '',
    benefits: offerEdit ? offerEdit.benefits : [],
    positionDescription: offerEdit ? offerEdit.positionDescription : '',
    mainTechnologies: offerEdit ? offerEdit.mainTechnologies : [],
    experienceType: offerEdit ? offerEdit.experienceType : '',
    positionTechnologies: offerEdit ? offerEdit.positionTechnologies : [],
    welcomeTechnologies: offerEdit ? offerEdit.welcomeTechnologies : [],
    willLearnTechnologies: offerEdit ? offerEdit.willLearnTechnologies : [],
    collaborators: offerEdit ? offerEdit.collaborators : [],
    willLearn: offerEdit ? offerEdit.willLearn : [],
    methodology: offerEdit ? offerEdit.methodology : [],
    dailyTasks: offerEdit ? offerEdit.dailyTasks : [],
    personalGrowth: offerEdit ? offerEdit.personalGrowth : [],
    language: offerEdit ? offerEdit.language : [],
    valueLanguageLevel: offerEdit ? offerEdit.valueLanguageLevel : '',
    company: offerEdit ? offerEdit.company : '',
    confirmed: false,
  };

  const methods = useForm({
    defaultValues,
    shouldFocusError: true,
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
  return (
    <FormProvider {...methods}>
      <form>
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, { methods });
          }
          return child;
        })}
      </form>
    </FormProvider>
  );
};
