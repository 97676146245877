import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';

// import Linkedin from 'assets/logo/linkedin.svg';
// import Github from 'assets/logo/github.svg';
// import Mysite from 'assets/logo/mysite.svg';
import { IconChecked } from 'ui/icon/iconChecked/IconChecked';
import { IconUnchecked } from 'ui/icon/iconUnchecked/IconUnchecked';
// import { DividerWithText } from 'ui/dividerWithText/DividerWithText';
// import { SocialMediaLink } from 'ui/socialMediaLink/SocialMediaLink';
// import Behance from 'assets/logo/behance.svg';

import { useStyles } from './ApplicantsAttributesMatchingAbout.styles';
import { ApplicantsAttributesMatchingAboutProps } from './ApplicantsAttributesMatchingAbout.types';

export const ApplicantsAttributesMatchingAbout: React.FC<ApplicantsAttributesMatchingAboutProps> = ({
  aboutApplicantLanguages,
  aboutApplicant,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.contact}>
      <Typography className={classes.typographyRight}>Informacje o kandydacie</Typography>
      <Box mb={3} />
      {aboutApplicant.map((item, key) => (
        <React.Fragment key={key}>
          {item.label && (
            <>
              <Typography variant="caption">{item.label}</Typography>
              <Box mb={0.5} />
            </>
          )}
          <div className={classes.gridRightItem}>
            <Typography variant="subtitle1" className={classes.strong}>
              {item.text}
            </Typography>
            <div>{item.checked ? <IconChecked /> : <IconUnchecked />}</div>
          </div>
          {key !== aboutApplicant.length - 1 && <Box mb={2.25} />}
        </React.Fragment>
      ))}
      {/* <div className={classes.divider}>
        <Box mb={3} />
        <DividerWithText title="Języki" />
      </div>
      <Box mb={1.25} />
      {aboutApplicantLanguages.map((item, key) => (
        <React.Fragment key={key}>
          {item.label && (
            <>
              <Typography variant="caption">{item.label}</Typography>
              <Box mb={0.5} />
            </>
          )}
          <div className={classes.gridRightItem}>
            <Typography variant="subtitle1" className={classes.strong}>
              {item.text}
            </Typography>
            <div>{item.checked ? <IconChecked /> : <IconUnchecked />}</div>
          </div>
          {key !== aboutApplicant.length - 1 && <Box mb={2.25} />}
        </React.Fragment>
      ))} */}
      {/* <div className={classes.divider}>
        <Box mb={3} />
        <DividerWithText title="Linki" />
        <Box mb={1.25} />
        <SocialMediaLink logo={Behance} label="Behance" />
        <Box mb={4.5} />
        <SocialMediaLink logo={Linkedin} label="Linkedin" />
        <Box mb={4.5} />
        <SocialMediaLink logo={Github} label="Github" />
        <Box mb={4.5} />
        <SocialMediaLink logo={Mysite} label="Moja strona" />
      </div> */}
    </div>
  );
};
