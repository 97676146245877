import React, { useState } from 'react';

import { KnowledgeContext } from 'context/knowledge/KnowledgeContext';
import { firebaseRef } from 'api/firebaseRef';
import { onError, onSuccess } from '../providersHelpers';

export const KnowledgeProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<string[]>([]);
  const [skills, setSkills] = useState<string[]>([]);

  const fetchSuggestions = async () => {
    setLoading(true);
    const suggestionsQuery = await firebaseRef
      .firestore()
      .collection('knowledge')
      .doc('commonDictionarySuggestions')
      .get();

    if (suggestionsQuery !== null) {
      const data = suggestionsQuery.data();

      setCategories(data?.categories || []);
      setSkills(data?.skills.sort() || []);

      return onSuccess(setLoading, `Sugestie słownikowe zostały pomyślnie pobrane`);
    } else {
      return onError(setLoading, 'Nie można pobrać żadnych sugestii słownikowych');
    }
  };

  return (
    <KnowledgeContext.Provider value={{ loading, fetchSuggestions, categories, skills }}>
      {children}
    </KnowledgeContext.Provider>
  );
};
