import { makeStyles, styled } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
}));

export const CompanyWrapper = styled('div')({
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
});

export const CompanyLogo = styled('img')({
  width: 24,
  height: 24,
  border: '1px solid #F1F1F1',
  boxSizing: 'border-box',
  content: '',
  borderRadius: 5,
});

export const AddFirm = styled('a')({
  display: 'flex',
  alignItems: 'center',
  padding: 16,
  paddingLeft: 28,
  paddingRight: 28,
  //TODO: define in the palette
  background:
    'linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.15) 66.67%, rgba(255, 255, 255, 0) 100%), #3E2950',
  color: 'white',
  fontSize: 16,
  borderRadius: 5,

  '& svg': {
    marginRight: 16,
  },
});
