import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';

import { CircularProgressWithLabel } from 'ui/circularProgressWithLabel/CircularProgressWithLabel';

import { useStyles } from './ApplicantsAttributesMatchingSummary.styles';
import { ApplicantsAttributesMatchingSummaryProps } from './ApplicantsAttributesMatchingSummary.types';

export const ApplicantsAttributesMatchingSummary: React.FC<ApplicantsAttributesMatchingSummaryProps> = ({ score }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.percentSummary}>
      <div className={classes.summaryLeft}>
        <CircularProgressWithLabel value={score} size={94} fontSize={'48px'} />
        <Box mb={2} />
        <Typography variant="caption" className={classes.caption}>
          Łączne dopasowanie
        </Typography>
      </div>
      {/* <div className={classes.summaryRight}>
        {mockSummaryData.map((item, key) => {
          const classCell = clsx({
            [classes.summaryRightCell]: true,
            [classes.summaryRightColumnLeft]: key % 2 === 0,
            [classes.summaryRightColumnRight]: key % 2 === 1,
          });
          return (
            <div key={key} className={classCell}>
              <Typography className={classes.typography}>{Math.round(score * 10) / 10}</Typography>
              <Box mb={1.5} />
              <Typography variant="caption" className={classes.caption}>
                {item.label}
              </Typography>
            </div>
          );
        })}
      </div> */}
    </div>
  );
};
