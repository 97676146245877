import React from 'react';
import { Typography, useTheme } from '@material-ui/core';
import * as Yup from 'yup';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { PlatformHeader } from 'ui/platformHeader/PlatformHeader';
import { DividerWithText } from 'ui/dividerWithText/DividerWithText';
import { ButtonDefault } from 'ui/button/ButtonDefault';
import { AddCompanyFormInitialValuesType } from 'app/company/addCompany/addCompanyForm/AddCompanyForm.types';
import { useCompanyContext } from 'hooks/useCompanyContext/useCompanyContext';
import { NewCompanyProps } from 'context/company/CompanyContext.types';
import { firebaseRef } from 'api/firebaseRef';
import { MainPaths } from 'routing/main/MainPaths';
import { randomizeFileName } from 'helpers/randomizeFileName';

import { useStyles } from './AddCompanyForm.styles';
import { General } from './general/General';
import { SocialMedia } from './socialMedia/SocialMedia';
import { Other } from './other/Other';

const initialValues: AddCompanyFormInitialValuesType = {
  companyName: '',
  companyAddressLine1: '',
  country: '',
  city: '',
  postcode: '',
  state: '',
  nip: '',
  industry: '',
  facebook: '',
  linkedin: '',
  companySize: '',
  companyDescription: '',
  predefinedMessage: '',
  otherSocialMedia: '',
  image: undefined,
};

const validationSchema = Yup.object({
  companyName: Yup.string().required('uzupełnij'),
  companyAddressLine1: Yup.string().required('uzupełnij'),
  country: Yup.string().required('uzupełnij'),
  city: Yup.string().required('uzupełnij'),
  postcode: Yup.string()
    .required('uzupełnij')
    .matches(/[0-9]{2}-[0-9]{3}/, 'zły format'),
  state: Yup.string().required('uzupełnij'),
  industry: Yup.string().required('uzupełnij'),
  companySize: Yup.string().required('wybierz'),
});

export const AddCompanyForm: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const methods = useForm({
    defaultValues: initialValues,
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const { register, handleSubmit, control, errors } = methods;

  const { addNewCompany } = useCompanyContext();
  const history = useHistory();

  const onSubmit = async (values: typeof initialValues) => {
    const { image, ...restValues } = values;
    const file = Object.values(values.image || {});
    const otherSocials = values.otherSocialMedia || [];

    const newCompanyObject: NewCompanyProps = {
      ...restValues,
      otherSocialMedia: otherSocials as string[],
      logo: '',
    };

    if (file !== undefined && file.length) {
      const randomizedFileName = randomizeFileName(file[0].name);

      const uploadTask = firebaseRef.storage().ref(`/images/${randomizedFileName}`).put(file[0]);
      uploadTask.on('state_changed', console.log, console.error, () => {
        firebaseRef
          .storage()
          .ref('images')
          .child(randomizedFileName)
          .getDownloadURL()
          .then(async (url) => {
            newCompanyObject.logo = url;
            const companyResponse = await addNewCompany(newCompanyObject);
            if (companyResponse.success) {
              history.push(MainPaths.addComapnyConfirmation);
            } else {
              alert('Error occurred: ' + companyResponse.message);
            }
          });
      });
    } else {
      const companyResponse = await addNewCompany(newCompanyObject);

      if (companyResponse.success) {
        history.push(MainPaths.addComapnyConfirmation);
      } else {
        alert('Error occurred: ' + companyResponse.message);
      }
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.innerWrapper}>
        <PlatformHeader />
        <div className={classes.formWrapper}>
          <Typography variant="h6" className={classes.header}>
            Dodaj firmę
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <DividerWithText>Dane firmowe</DividerWithText>
            <General register={register} control={control} errors={errors} />
            <DividerWithText>Social media</DividerWithText>
            <SocialMedia methods={methods} />
            <DividerWithText>Firma</DividerWithText>
            <Other register={register} errors={errors} />
            <div className={classes.button}>
              <ButtonDefault>zapisz firmę</ButtonDefault>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
