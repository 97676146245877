import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalBody: {
    width: 'fit-content',
    height: 'fit-content',
    background: 'white',
    padding: '10px 50px',
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    padding: '20px',
  },
}));
