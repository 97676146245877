import { Box, Typography } from '@material-ui/core';
import React from 'react';

import { DividerWithText } from 'ui/dividerWithText/DividerWithText';
import { TextInput } from 'ui/textInput/TextInput';
import { TwoItemsRow } from '../EditProfilePage.styles';
import { TextSelect } from 'ui/textSelect/TextSelect';
import { countryCodes } from 'helpers/countryCodes';

import { EditProfileGeneralProps } from './EditProfileGeneral.types';

export const EditProfileGeneral = ({ classes, register, errors, control }: EditProfileGeneralProps): JSX.Element => {
  return (
    <>
      <Typography variant="h6">Edycja profilu</Typography>
      <Box mb={2} />
      <TwoItemsRow>
        <div className={classes.flexGrow}>
          <TextInput
            ref={register}
            valueName={'firstName'}
            name={'firstName'}
            label={'imię'}
            placeholder={'Jan'}
            helperText={errors.firstName ? errors.firstName.message : null}
            error={!!errors.firstName}
            required={true}
            noBackground
          />
        </div>
        <Box width={24} />
        <div className={classes.flexGrow}>
          <TextInput
            ref={register}
            valueName={'lastName'}
            name={'lastName'}
            label={'nazwisko'}
            placeholder={'Kowalski'}
            helperText={errors.lastName ? errors.lastName.message : null}
            error={!!errors.lastName}
            required={true}
            noBackground
          />
        </div>
      </TwoItemsRow>
      <TextInput disabled={true} valueName={'email'} name={'email'} label={'adres e-mail'} ref={register} />
      <DividerWithText>Dane Firmowe</DividerWithText>
      <TextInput
        valueName={'companyName'}
        name={'companyName'}
        label={'nazwa firmy'}
        placeholder={'hr-me.co'}
        required={true}
        ref={register}
        helperText={errors.companyName ? errors.companyName.message : null}
        error={!!errors.companyName}
        noBackground
      />
      <TextInput
        valueName={'nip'}
        name={'nip'}
        label={'nip'}
        placeholder={'0000000000'}
        required={true}
        ref={register}
        noBackground
      />
      <TextInput
        valueName={'companyAddressLine1'}
        name={'companyAddressLine1'}
        label={'ulica'}
        placeholder={'Wpisz adres'}
        required={true}
        ref={register}
        helperText={errors.companyAddressLine1 ? errors.companyAddressLine1.message : null}
        error={!!errors.companyAddressLine1}
        noBackground
      />

      <TextSelect
        name={'country'}
        label={'kraj'}
        control={control}
        required
        helperText={errors.country?.message}
        error={!!errors.country}
        items={countryCodes}
      />
      <TwoItemsRow>
        <div className={classes.city}>
          <TextInput
            name={'city'}
            label={'miasto'}
            placeholder={'Wpisz nazwę miasta'}
            valueName={'city'}
            ref={register}
            helperText={errors.city ? errors.city.message : null}
            error={!!errors.city}
            noBackground
          />
        </div>
        <Box width={24} />
        <div className={classes.postal}>
          <TextInput
            name={'postcode'}
            label={'kod pocztowy'}
            placeholder={'00-000'}
            valueName={'postcode'}
            ref={register}
            helperText={errors.postcode ? errors.postcode.message : null}
            error={!!errors.postcode}
            noBackground
          />
        </div>
      </TwoItemsRow>
      <TextInput
        valueName={'state'}
        name={'state'}
        label={'województwo'}
        placeholder={'Wpisz województwo'}
        required={true}
        ref={register}
        helperText={errors.state ? errors.state.message : null}
        error={!!errors.state}
        noBackground
      />
    </>
  );
};
