"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
var core_1 = require("@material-ui/core");
exports.useStyles = core_1.makeStyles(function (theme) { return ({
    confirmContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        alignItems: 'center',
        marginBottom: theme.spacing(3),
    },
    confirmCheckbox: {
        padding: 0,
        marginRight: theme.spacing(1),
    },
    confirmLabel: {
        fontWeight: 400,
        lineHeight: '18px',
        textAlign: 'left',
        cursor: 'pointer',
    },
}); });
