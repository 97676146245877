import { TextSelectItem } from '@hrme/shared/lib/index.types';

export const countryCodes: TextSelectItem[] = [
  { label: 'Polska', value: 'Poland' },
  { label: 'Afganistan', value: 'Afghanistan' },
  { label: 'Albania', value: 'Albania' },
  { label: 'Algieria', value: 'Algeria' },
  { label: 'American Samoa', value: 'AmericanSamoa' },
  { label: 'Andora', value: 'Andorra' },
  { label: 'Angola', value: 'Angola' },
  { label: 'Anguilla', value: 'Anguilla' },
  { label: 'Antarktyda', value: 'Antarctica' },
  { label: 'Antigua i Barbuda', value: 'AntiguaAndBarbuda' },
  { label: 'Arabia Saudyjska', value: 'SaudiArabia' },
  { label: 'Argentyna', value: 'Argentina' },
  { label: 'Armenia', value: 'Armenia' },
  { label: 'Aruba', value: 'Aruba' },
  { label: 'Australia', value: 'Australia' },
  { label: 'Austria', value: 'Austria' },
  { label: 'Azerbejdżan', value: 'Azerbaijan' },
  { label: 'Bahamy', value: 'Bahamas' },
  { label: 'Bahrajn', value: 'Bahrain' },
  { label: 'Bangladesz', value: 'Bangladesh' },
  { label: 'Barbados', value: 'Barbados' },
  { label: 'Belgia', value: 'Belgium' },
  { label: 'Belize', value: 'Belize' },
  { label: 'Benin', value: 'Benin' },
  { label: 'Bermudy', value: 'Bermuda' },
  { label: 'Bhutan', value: 'Bhutan' },
  { label: 'Białoruś', value: 'Belarus' },
  { label: 'Boliwia', value: 'Bolivia' },
  { label: 'Botswana', value: 'Botswana' },
  { label: 'Bośnia i Hercegowina', value: 'BosniaAndHerzegovina' },
  { label: 'Brazylia', value: 'Brazil' },
  { label: 'Brunei Darussalam', value: 'BruneiDarussalam' },
  { label: 'Brytyjskie Terytorium Oceanu Indyjskiego', value: 'BritishIndianOceanTerritory' },
  { label: 'Brytyjskie Wyspy Dziewicze', value: 'VirginIslandsBritish' },
  { label: 'Burkina Faso', value: 'BurkinaFaso' },
  { label: 'Burundi', value: 'Burundi' },
  { label: 'Bułgaria', value: 'Bulgaria' },
  { label: 'Cape Verde', value: 'CapeVerde' },
  { label: 'Chile', value: 'Chile' },
  { label: 'Chiny', value: 'China' },
  { label: 'Chorwacja', value: 'Croatia' },
  { label: 'Curaçao', value: 'Curaçao' },
  { label: 'Cypr', value: 'Cyprus' },
  { label: 'Czad', value: 'Chad' },
  { label: 'Czarnogóra', value: 'Montenegro' },
  { label: 'Czechy', value: 'CzechRepublic' },
  { label: 'Dalekie Wyspy Mniejsze Stanów Zjednoczonych', value: 'UnitedStatesOutlyingIslands' },
  { label: 'Dania', value: 'Denmark' },
  { label: 'Dominica', value: 'Dominica' },
  { label: 'Dominikana', value: 'DominicanRepublic' },
  { label: 'Dżibuti', value: 'Djibouti' },
  { label: 'Egipt', value: 'Egypt' },
  { label: 'Ekwador', value: 'Ecuador' },
  { label: 'ElSalvador', value: 'ElSalvador' },
  { label: 'Erytrea', value: 'Eritrea' },
  { label: 'Estonia', value: 'Estonia' },
  { label: 'Etiopia', value: 'Ethiopia' },
  { label: 'Falklandy', value: 'FalklandIslands' },
  { label: 'Federacja Rosyjska', value: 'RussianFederation' },
  { label: 'Fidżi', value: 'Fiji' },
  { label: 'Filipiny', value: 'Philippines' },
  { label: 'Finlandia', value: 'Finland' },
  { label: 'Francja', value: 'France' },
  { label: 'Francuskie Terytoria Południowe', value: 'FrenchSouthernTerritories' },
  { label: 'Gabon', value: 'Gabon' },
  { label: 'Gambia', value: 'Gambia' },
  { label: 'Georgia Południowa', value: 'SouthGeorgiaAndSandwichIsl' },
  { label: 'Ghana', value: 'Ghana' },
  { label: 'Gibraltar', value: 'Gibraltar' },
  { label: 'Grecja', value: 'Greece' },
  { label: 'Grenada', value: 'Grenada' },
  { label: 'Grenlandia', value: 'Greenland' },
  { label: 'Gruzja', value: 'Georgia' },
  { label: 'Guam', value: 'Guam' },
  { label: 'Guernsey', value: 'Guernsey' },
  { label: 'Gujana', value: 'Guyana' },
  { label: 'Gujana Francuska', value: 'FrenchGuiana' },
  { label: 'Gwadelupa', value: 'Guadeloupe' },
  { label: 'Gwatemala', value: 'Guatemala' },
  { label: 'Gwinea', value: 'Guinea' },
  { label: 'Gwinea Bissau', value: 'GuineaBissau' },
  { label: 'Gwinea Równikowa', value: 'EquatorialGuinea' },
  { label: 'Haiti', value: 'Haiti' },
  { label: 'Hiszpania', value: 'Spain' },
  { label: 'Honduras', value: 'Honduras' },
  { label: 'Hongkong', value: 'HongKong' },
  { label: 'Indie', value: 'India' },
  { label: 'Indonezja', value: 'Indonesia' },
  { label: 'Iran', value: 'Iran' },
  { label: 'Iraq', value: 'Iraq' },
  { label: 'Irlandia', value: 'Ireland' },
  { label: 'Islandia', value: 'Iceland' },
  { label: 'Izrael', value: 'Israel' },
  { label: 'Jamajka', value: 'Jamaica' },
  { label: 'Japonia', value: 'Japan' },
  { label: 'Jersey', value: 'Jersey' },
  { label: 'Jordan', value: 'Jordan' },
  { label: 'Kajmany', value: 'CaymanIslands' },
  { label: 'Kambodża', value: 'Cambodia' },
  { label: 'Kamerun', value: 'Cameroon' },
  { label: 'Kanada', value: 'Canada' },
  { label: 'Karaiby Niderlandzkie', value: 'BonaireSintEustatiusSaba' },
  { label: 'Katar', value: 'Qatar' },
  { label: 'Kazachstan', value: 'Kazakhstan' },
  { label: 'Kenia', value: 'Kenya' },
  { label: 'Kirgistan', value: 'Kyrgyzstan' },
  { label: 'Kiribati', value: 'Kiribati' },
  { label: 'Kolumbia', value: 'Colombia' },
  { label: 'Komory', value: 'Comoros' },
  { label: 'Kongo', value: 'Congo' },
  { label: 'Kongo Demokratyczna Republika', value: 'CongoDemocraticRepublic' },
  { label: 'Korea', value: 'Korea' },
  { label: 'KoreaDemokratyczna Republika Ludowa', value: 'KoreaDemocraticPeoplesRepublic' },
  { label: 'Kostaryka', value: 'CostaRica' },
  { label: 'Kuba', value: 'Cuba' },
  { label: 'Kuwejt', value: 'Kuwait' },
  { label: 'Laos', value: 'LaoPeoplesDemocraticRepublic' },
  { label: 'Lebanon', value: 'Lebanon' },
  { label: 'Lesotho', value: 'Lesotho' },
  { label: 'Liberia', value: 'Liberia' },
  { label: 'Libia', value: 'LibyanArabJamahiriya' },
  { label: 'Liechtenstein', value: 'Liechtenstein' },
  { label: 'Litwa', value: 'Lithuania' },
  { label: 'Łotwa', value: 'Latvia' },
  { label: 'Luksemburg', value: 'Luxembourg' },
  { label: 'Macedonia', value: 'Macedonia' },
  { label: 'Madagaskar', value: 'Madagascar' },
  { label: 'Majotta', value: 'Mayotte' },
  { label: 'Makao', value: 'Macao' },
  { label: 'Malawi', value: 'Malawi' },
  { label: 'Malediwy', value: 'Maldives' },
  { label: 'Malezja', value: 'Malaysia' },
  { label: 'Mali', value: 'Mali' },
  { label: 'Malta', value: 'Malta' },
  { label: 'Mariany Północne', value: 'NorthernMarianaIslands' },
  { label: 'Maroko', value: 'Morocco' },
  { label: 'Martynika', value: 'Martinique' },
  { label: 'Mauretania', value: 'Mauritania' },
  { label: 'Mauritius', value: 'Mauritius' },
  { label: 'Meksyk', value: 'Mexico' },
  { label: 'Mikronezja', value: 'Micronesia' },
  { label: 'Monako', value: 'Monaco' },
  { label: 'Mongolia', value: 'Mongolia' },
  { label: 'Montserrat', value: 'Montserrat' },
  { label: 'Mozambik', value: 'Mozambique' },
  { label: 'Mołdawia', value: 'Moldova' },
  { label: 'Myanmar', value: 'Myanmar' },
  { label: 'Namibia', value: 'Namibia' },
  { label: 'Nauru', value: 'Nauru' },
  { label: 'Nepal', value: 'Nepal' },
  { label: 'Niderlandy', value: 'Netherlands' },
  { label: 'Niemcy', value: 'Germany' },
  { label: 'Niger', value: 'Niger' },
  { label: 'Nigeria', value: 'Nigeria' },
  { label: 'Nikaragua', value: 'Nicaragua' },
  { label: 'Niue', value: 'Niue' },
  { label: 'Norfolk', value: 'NorfolkIsland' },
  { label: 'Norwegia', value: 'Norway' },
  { label: 'Nowa Kaledonia', value: 'NewCaledonia' },
  { label: 'Nowa Zelandia', value: 'NewZealand' },
  { label: 'Oman', value: 'Oman' },
  { label: 'Pakistan', value: 'Pakistan' },
  { label: 'Palau', value: 'Palau' },
  { label: 'Panama', value: 'Panama' },
  { label: 'Papua Nowa Gwinea', value: 'PapuaNewGuinea' },
  { label: 'Paragwaj', value: 'Paraguay' },
  { label: 'Peru', value: 'Peru' },
  { label: 'Pitcairn', value: 'Pitcairn' },
  { label: 'Polinezja Francuska', value: 'FrenchPolynesia' },
  { label: 'Portugalia', value: 'Portugal' },
  { label: 'PuertoRico', value: 'PuertoRico' },
  { label: 'Republika Południowej Afryki ', value: 'SouthAfrica' },
  { label: 'Republika Środkowoafrykańska', value: 'CentralAfricanRepublic' },
  { label: 'Reunion', value: 'Reunion' },
  { label: 'Rumunia', value: 'Romania' },
  { label: 'Rwanda', value: 'Rwanda' },
  { label: 'Sahara Zachodnia  ', value: 'WesternSahara' },
  { label: 'Saint Kitts i Nevis', value: 'SaintKittsAndNevis' },
  { label: 'Saint Lucia', value: 'SaintLucia' },
  { label: 'Saint Martin', value: 'SaintMartin' },
  { label: 'Saint Vincent i Grenadyny', value: 'SaintVincentAndGrenadines' },
  { label: 'Samoa', value: 'Samoa' },
  { label: 'San Marino', value: 'SanMarino' },
  { label: 'Senegal', value: 'Senegal' },
  { label: 'Serbia', value: 'Serbia' },
  { label: 'Seszele', value: 'Seychelles' },
  { label: 'SierraLeone', value: 'SierraLeone' },
  { label: 'Singapur', value: 'Singapore' },
  { label: 'Sint Maarten', value: 'SintMaarten' },
  { label: 'Somalia', value: 'Somalia' },
  { label: 'SriLanka', value: 'SriLanka' },
  { label: 'Sudan', value: 'Sudan' },
  { label: 'Sudan Południowy', value: 'SouthSudan' },
  { label: 'Surinam', value: 'Suriname' },
  { label: 'Svalbard', value: 'SvalbardAndJanMayen' },
  { label: 'Swaziland', value: 'Swaziland' },
  { label: 'Syria', value: 'SyrianArabRepublic' },
  { label: 'Szwajcaria', value: 'Switzerland' },
  { label: 'Szwecja', value: 'Sweden' },
  { label: 'Słowacja', value: 'Slovakia' },
  { label: 'Słowenia', value: 'Slovenia' },
  { label: 'Święta Helena', value: 'SaintHelena' },
  { label: 'Tadżykistan', value: 'Tajikistan' },
  { label: 'Tajlandia', value: 'Thailand' },
  { label: 'Tajwan', value: 'Taiwan' },
  { label: 'Tanzania', value: 'Tanzania' },
  { label: 'Terytorium Palestyńskie', value: 'PalestinianTerritory' },
  { label: 'Timor Wschodni', value: 'TimorLeste' },
  { label: 'Togo', value: 'Togo' },
  { label: 'Tokelau', value: 'Tokelau' },
  { label: 'Tonga', value: 'Tonga' },
  { label: 'Trinidad i Tobago', value: 'TrinidadAndTobago' },
  { label: 'Tunezja', value: 'Tunisia' },
  { label: 'Turcja', value: 'Turkey' },
  { label: 'Turkmenistan', value: 'Turkmenistan' },
  { label: 'Turks i Caicos', value: 'TurksAndCaicosIslands' },
  { label: 'Tuvalu', value: 'Tuvalu' },
  { label: 'Uganda', value: 'Uganda' },
  { label: 'Ukraina', value: 'Ukraine' },
  { label: 'UnitedKingdom', value: 'UnitedKingdom' },
  { label: 'Urugwaj', value: 'Uruguay' },
  { label: 'Uzbekistan', value: 'Uzbekistan' },
  { label: 'Vanuatu', value: 'Vanuatu' },
  { label: 'Wallis i Futuna', value: 'WallisAndFutuna' },
  { label: 'Watykan', value: 'HolySeeVaticanCityState' },
  { label: 'Wenezuela', value: 'Venezuela' },
  { label: 'Wielka Brytania', value: 'UnitedStates' },
  { label: 'Wietnam', value: 'Vietnam' },
  { label: 'Wspólnota Saint-Barthélemy', value: 'SaintBarthelemy' },
  { label: 'Wspólnota Terytorialna Saint Pierre i Miquelon', value: 'SaintPierreAndMiquelon' },
  { label: 'Wybrzeże Kości Słoniowej', value: 'CoteDIvoire' },
  { label: 'Wyspa Bouveta', value: 'BouvetIsland' },
  { label: 'Wyspa Bożego Narodzenia', value: 'ChristmasIsland' },
  { label: 'Wyspa Man', value: 'IsleOfMan' },
  { label: 'Wyspy Alandzkie', value: 'AlandIslands' },
  { label: 'Wyspy Cooka', value: 'CookIslands' },
  { label: 'Wyspy Dziewicze', value: 'VirginIslandsUS' },
  { label: 'Wyspy Heard i McDonald', value: 'HeardIslandMcdonaldIslands' },
  { label: 'Wyspy Kokosowe', value: 'CocosKeelingIslands' },
  { label: 'Wyspy Marshalla', value: 'MarshallIslands' },
  { label: 'Wyspy Owcze', value: 'FaroeIslands' },
  { label: 'Wyspy Salomona', value: 'SolomonIslands' },
  { label: 'Wyspy Świętego Tomasza i Książęca', value: 'SaoTomeAndPrincipe' },
  { label: 'Węgry', value: 'Hungary' },
  { label: 'Włochy', value: 'Italy' },
  { label: 'Yemen', value: 'Yemen' },
  { label: 'Zambia', value: 'Zambia' },
  { label: 'Zimbabwe', value: 'Zimbabwe' },
  { label: 'Zjednoczone Emiraty Arabskie', value: 'UnitedArabEmirates' },
];
