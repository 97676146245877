import { makeStyles } from '@material-ui/core';
import { HrMeTheme } from '@hrme/shared/lib/index.types';

export const useStyles = makeStyles((theme: HrMeTheme) => ({
  countersPanel: {},
  countersPanelHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'end',
  },
  title: {
    fontSize: '18px',
  },
  link: {
    color: theme.palette.common.black,
    textDecoration: 'underline',
  },
  counters: {
    border: `1px solid ${theme.borders.main}`,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4, 0),
  },
  counter: {
    width: '33%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
