import React, { useState } from 'react';
import { AppBar, Badge, Box, IconButton, Menu, MenuItem, Toolbar, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import notificationBell from 'assets/icons/notificationBell.svg';
import selectTriangle from 'assets/icons/selectTriangle.svg';
import { useAuthContext } from 'hooks/useAuthContext/useAuthContext';
import { useUserContext } from '../../hooks/useUserContext/useUserContext';

import { useStyles } from './Navbar.styles';

export const Navbar: React.FC = React.memo(() => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { logoutUser } = useAuthContext();
  const { user } = useUserContext();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const logout = async () => {
    await logoutUser();
    history.replace('/');
  };

  return (
    <AppBar elevation={0} position="fixed" className={classes.appBar}>
      <Toolbar>
        <Box style={{ flexGrow: 1 }} />

        <Box style={{ display: 'flex' }}>
          <IconButton disabled className={classes.root}>
            <Badge badgeContent={0} color="error">
              <img src={notificationBell} alt="notifications" />
            </Badge>
            <Box mr={1} />
            <img src={selectTriangle} alt="notifications" />
          </IconButton>

          <Box mr={2.5} />

          <IconButton onClick={handleMenu} className={classes.root}>
            <Box className={classes.profile}>{user?.firstName ? user.firstName.toUpperCase().split('')[0] : 'A'}</Box>
            <Box mr={1} />
            <img src={selectTriangle} alt="notifications" />
          </IconButton>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem onClick={logout}>Wyloguj</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
});

Navbar.displayName = 'Navbar';
