import React from 'react';

import { TextInput } from 'ui/textInput/TextInput';
import { TextInputExtendableList } from 'ui/textInputExtendableList/TextInputExtendableList';

import { AddComapnyFormSocialMediaProps } from './SocialMedia.types';

export const SocialMedia: React.FC<AddComapnyFormSocialMediaProps> = ({ methods }) => (
  <>
    <TextInput
      name={'facebook'}
      label={'facebook'}
      valueName={'facebook'}
      placeholder={'np. https://facebook.com/hr-me'}
      ref={methods?.register}
    />
    <TextInput
      name={'linkedin'}
      label={'linkedin'}
      valueName={'linkedin'}
      placeholder={'np. https://linkedin.com/hr-me'}
      ref={methods?.register}
    />
    <TextInputExtendableList
      methods={methods}
      label="inne portale"
      name="otherSocialMedia"
      buttonCaption="dodaj"
      addInputPlaceholder="Wpisz link"
    />
  </>
);
