import { makeStyles } from '@material-ui/core';
import { HrMeTheme } from '@hrme/shared/src/theme/theme';

export const useStyles = makeStyles<HrMeTheme>((theme) => ({
  summaryContainer: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  summaryHeader: {
    textAlign: 'left',
    fontSize: '18px',
    fontWeight: 600,
  },
  summaryItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.borders.light}`,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  summaryItemLast: {
    paddingBottom: 0,
    borderBottom: 0,
  },
  summaryItemValue: {
    fontWeight: 600,
  },
  summarySubtitle: {
    marginTop: theme.spacing(1),
    color: theme.palette.grey[500],
    marginBottom: theme.spacing(3),
  },
  positionDescription: {
    border: 'none',
    paddingBottom: theme.spacing(1),
  },
}));
