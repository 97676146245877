import React from 'react';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { Box, Button, Typography, useTheme } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import { Link } from 'ui/link/Link';

import { MessageBarProps } from './MessageBar.types';
import { useStyles } from './MessageBar.styles';

export const MessageBar: React.FC<MessageBarProps> = ({ message, actionCaption, dismissCaption, action, dismiss }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.container}>
      <div className={classes.message}>
        <ReportProblemOutlinedIcon className={classes.messageIcon} />
        <Box mr={2} />
        <Typography className={classes.messageTypography}>{message}</Typography>
      </div>
      <div className={classes.buttonContainer}>
        {actionCaption && (
          <Button variant="contained" color="default" disableElevation className={classes.button} onClick={action}>
            {actionCaption}
          </Button>
        )}
        <Box mr={4} />
        <div onClick={dismiss}>
          <Link href="#">
            {dismissCaption ? (
              <Typography className={classes.link}>{dismissCaption}</Typography>
            ) : (
              <ClearIcon fontSize="default" className={classes.cross} />
            )}
          </Link>
        </div>
      </div>
    </div>
  );
};
