import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  buttonExpanded: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  greyed: {
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.grey.A200,
  },
  accordionDetails: {},
}));
