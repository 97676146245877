import React from 'react';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useTheme } from '@material-ui/core';

import { useStyles } from './IconUnchecked.styles';

export const IconUnchecked = (): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return <HighlightOffIcon fontSize="small" className={classes.icon} />;
};
