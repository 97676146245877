import React from 'react';
import { Typography, useTheme } from '@material-ui/core';

import { MessageProps } from './Message.types';
import { useStyles } from './Message.styles';

export const Message: React.FC<MessageProps> = ({ text, type }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes[type]}>
      <Typography className={type === 'success' ? classes.successText : classes.errorText}>{text}</Typography>
    </div>
  );
};
