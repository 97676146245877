"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
var core_1 = require("@material-ui/core");
var palette_1 = require("../../../theme/palette");
var useStyles = function () {
    var style = core_1.makeStyles({
        regularLink: {
            cursor: 'pointer',
            color: palette_1.colors.pink,
        },
        boldLink: {
            cursor: 'pointer',
            fontWeight: 'bold',
            color: palette_1.colors.pink,
        },
    });
    return style();
};
exports.useStyles = useStyles;
