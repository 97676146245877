export type ChipDefaultProps = {
  caption: string;
  checked?: boolean | null;
  background?: ChipBackground;
  columnGap?: number;
  rowGap?: number;
};

export enum ChipBackground {
  LIGHT = 'light',
  DARK = 'dark',
}
