import { Candidate } from 'context/jobOffer/JobOfferContext.types';

export enum InvitationStatus {
  WAITS = 'waits',
  SENT = 'sent',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  PAID = 'paid',
}

export enum AvailabilityWork {
  ALL = 'all',
  OTHER = 'other',
  ONEMONTH = 'onemonth',
  NONE = 'none',
}

export enum AvailabilityHours {
  TO_TEN = 1,
  TO_FOURTY = 2,
  OVER_FOURTY = 3,
  TO_TWENTY = 4,
}

export enum AvailabilityWorkType {
  OFFICE = 'office',
  REMOTE = 'remote',
  HYBRID = 'hybrid',
}

export type PositionState = {
  position_name: string;
  other_position: string[];
  otherValue: string;
};

export type SpecializationState = {
  specialization: string[];
  other: string[];
};

export type AvailabilityState = {
  work: AvailabilityWork;
  hours: AvailabilityHours;
  workType: AvailabilityWorkType;
};
export type ExperienceItemType = {
  company: string;
  position: string;
  seniority: string;
  city: string;
  country: string;
  type: string;
  skills: string[];
  stillWorking: boolean;
  start: string;
  end: string | null;
};

export type ExperienceState = ExperienceItemType[];

export type TechnologyState = {
  known: string[];
  learning: string[];
  wantToLearn: string[];
};

export type SoftSkillsState = {
  softSkills: string[];
  other: string[];
};

export type EducationState = {
  education: string;
};

export type LanguagesState = Array<{
  language: string;
  level: string;
  certificate: string[];
}>;

export type PortfolioSocialMediaState = {
  github: string;
  linkedin: string;
  facebook: string;
  instagram: string;
  otherLinks: string[];
};

export enum Gender {
  FEMALE = 'female',
  MALE = 'male',
  OTHER = 'other',
  NONE = 'none',
}

export type FillState = {
  gender: Gender;
  age: number;
  city: string;
};

export type SchoolItem = {
  name: string;
  major: string;
  city: string;
  country: string;
  stillLearning: boolean;
  start: string;
  end: string | null;
};

export type SchoolState = SchoolItem[];

export type NewCareerPathFormData = {
  pathName: string;
  pathRef: string;
  experienceLevel: string;
  experienceYears: string;
  openForOffers: boolean;
  expectedSalary: number;
};

export type CareerPath = {
  name: string;
  technology: Technology[];
  pathRef: string;
};

export type CareerPathStepLearningSource = {
  title: string;
  platform?: string;
  link: string;
};

export type CareerPathStepTask = {
  title: string;
  completed: boolean;
  link: string;
  hash?: string;
};

export type Technology = {
  description: string;
  hasGroup: string | null;
  name: string;
  sources: CareerPathStepLearningSource[];
  tasks: CareerPathStepTask[];
};

export type UserCareerPath = CareerPath &
  NewCareerPathFormData & {
    pathId: string;
  };

export type CertificateState = Certificate[];
export type TrainingState = Training[];

export type Certificate = {
  certificate: string;
  company: string;
  issueDate: string;
};

export type Training = {
  training: string;
  company: string;
  skillsLearned: string[];
  dateStarted: string;
  dateEnded: string;
  trainingTime: string;
};

export type ApplicantDashboardData = {
  name: string;
  email: string;
  offerId: string;
  offerName: string;
  status: InvitationStatus;

  position?: PositionState;
  specialization?: SpecializationState;
  availability?: AvailabilityState;
  experience?: ExperienceState;
  technology?: TechnologyState;
  softSkills?: SoftSkillsState;
  education?: EducationState;
  portfolioSocialMedia?: PortfolioSocialMediaState;
  fillData?: FillState;
  school?: SchoolState;
  careerPaths?: UserCareerPath[];
  certificates?: CertificateState;
  trainings?: TrainingState;
};

export type DashboardApplicantsPanelProps = {
  titles?: DashboardApplicantsTableHeader;
  applicants: Candidate[];
};

export type DashboardApplicantsTableHeaderTitle = {
  title: string;
};

export type DashboardApplicantsTableHeader = DashboardApplicantsTableHeaderTitle[];

export const COLUMN_HEADERS: DashboardApplicantsTableHeader = [
  { title: 'Procent dopasowania / Kandydat' },
  { title: 'Oferta pracy' },
  { title: 'Status' },
  { title: 'Akcje' },
];
