import React from 'react';
import { Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';

import { OfferStatus } from 'app/jobOffer/jobOfferActivationStatusBar/JobOfferActivationStatusBar.types';

import { StatusProps } from './Status.types';
import { useStyles } from './Status.styles';

export const Status: React.FC<StatusProps> = ({ status }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const statusStyle = clsx({
    [classes.status]: true,
    [classes.statusActive]: status === OfferStatus.ACTIVE,
    [classes.statusInactive]: status === OfferStatus.INACTIVE,
    [classes.statusDraft]: status === OfferStatus.DRAFT,
  });

  const offerStatus = (status: OfferStatus) => {
    switch (status) {
      case OfferStatus.ACTIVE:
        return 'Aktywna';
      case OfferStatus.INACTIVE:
        return 'Nieaktywna';
      default:
        return 'Robocza';
    }
  };

  return <Typography className={statusStyle}>{offerStatus(status)}</Typography>;
};
