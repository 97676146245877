import React from 'react';
import { Typography, useTheme } from '@material-ui/core';

import { DashboardSectionProps } from './DashboardSection.types';
import { useStyles } from './DashboardSection.styles';

export const DashboardSection: React.FC<DashboardSectionProps> = ({ title, children }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.dashboardContent}>
      {title && (
        <Typography variant="h2" className={classes.title}>
          {title}
        </Typography>
      )}
      {children}
    </div>
  );
};
