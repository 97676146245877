import { Grid, useTheme } from '@material-ui/core';

import { FirstColumn, NextColumn, LastColumn } from 'app/paymentVariantScreen/PaymentVariantScreen.styles';

import { ColumnDataProps } from './ColumnData.types';
import { useStyles } from './ColumnData.styles';

export const ColumnData: React.FC<ColumnDataProps> = ({ firstColumn, secondColumn, thirdColumn, fourthColumn }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Grid className={classes.gridContainer} container spacing={0}>
      <Grid item xs={3}>
        <FirstColumn>{firstColumn}</FirstColumn>
      </Grid>
      <Grid item xs={3}>
        <NextColumn>{secondColumn}</NextColumn>
      </Grid>
      <Grid item xs={3}>
        <NextColumn>{thirdColumn}</NextColumn>
      </Grid>
      <Grid item xs={3}>
        <LastColumn>{fourthColumn}</LastColumn>
      </Grid>
    </Grid>
  );
};
