import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';

import { ExperienceBoxProps } from './ExperienceBox.types';
import { useStyles } from './ExperienceBox.styles';

export const ExperienceBox: React.FC<ExperienceBoxProps> = ({
  company,
  position,
  period,
  city = '',
  technology = '',
  learned = '',
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.container}>
      <div className={classes.line}>
        <Typography variant="body2" className={classes.strong}>
          Firma:
        </Typography>
        <Box mr={0.5} />
        <Typography variant="body2" className={classes.text}>
          {company}
        </Typography>
      </div>
      <Box mb={1} />
      <div className={classes.line}>
        <Typography variant="body2" className={classes.strong}>
          Miasto:
        </Typography>
        <Box mr={0.5} />
        <Typography variant="body2" className={classes.text}>
          {city}
        </Typography>
      </div>
      <Box mb={1} />
      <div className={classes.line}>
        <Typography variant="body2" className={classes.strong}>
          Stanowisko:
        </Typography>
        <Box mr={0.5} />
        <Typography variant="body2" className={classes.text}>
          {position}
        </Typography>
      </div>
      <Box mb={1} />
      <div className={classes.line}>
        <Typography variant="body2" className={classes.strong}>
          Lata pracy:
        </Typography>
        <Box mr={0.5} />
        <Typography variant="body2" className={classes.text}>
          {period}
        </Typography>
      </div>
      {technology && (
        <>
          <Box mb={1} />
          <div className={classes.line}>
            <Typography variant="body2" className={classes.strong}>
              Główna technologia:
            </Typography>
            <Box mr={0.5} />
            <Typography variant="body2" className={classes.text}>
              {technology}
            </Typography>
          </div>
        </>
      )}
      {learned && (
        <>
          <Box mb={1} />
          <div className={classes.line}>
            <Typography variant="body2" className={classes.strong}>
              Zdobyte umiejętności:
            </Typography>
            <Box mr={0.5} />
            <Typography variant="body2" className={classes.text}>
              {learned}
            </Typography>
          </div>
        </>
      )}
    </div>
  );
};
