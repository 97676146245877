import React, { useEffect } from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import NoLogo from 'assets/ilustrations/noLogo.svg';
import { LoginFlowLayout } from 'ui/loginFlowLayout/LoginFlowLayout';
import { Link } from 'ui/link/Link';
import {
  CompanyProfilesProps,
  EXAMPLE_COMPANY_PROFILES_DATA,
} from 'app/login/profile/companyProfiles/CompanyProfiles.types';
import { CompanyProfileBoxContent } from 'ui/companyProfileBox/companyProfileBoxContent/CompanyProfileBoxContent';
import { AuthenticationPaths } from 'routing/authentication/AuthenticationPaths';
import { useCompanyContext } from 'hooks/useCompanyContext/useCompanyContext';
import { Company } from 'context/company/CompanyContext.types';
import { MainPaths } from 'routing/main/MainPaths';

import { useStyles } from './CompanyProfiles.styles';

export const CompanyProfiles: React.FC<CompanyProfilesProps> = ({
  profiles = EXAMPLE_COMPANY_PROFILES_DATA,
  maxOffers = 3,
}) => {
  const { companies, fetchCompanies, updateSelectedCompany } = useCompanyContext();
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      await fetchCompanies();
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onProfileSelect = (company: Company) => {
    updateSelectedCompany(company.companyName);
    history.replace(MainPaths.dashboard);
  };

  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <LoginFlowLayout
      wide={true}
      footerComponent={
        <Link href={MainPaths.dashboard}>
          <Typography variant="body2" className={classes.footer}>
            Pomiń ten krok
          </Typography>
        </Link>
      }
    >
      <Typography variant="h4" className={classes.header}>
        Profile firmowe
      </Typography>
      <Typography variant="body2" className={classes.description} align="center">
        Wybierz lub dodaj nową firmę
      </Typography>
      <Box mb={5} />
      <div className={classes.companyButtonsContainer}>
        {companies.map((profile, key) => (
          <CompanyProfileBoxContent
            key={key}
            logotype={profile.logo || NoLogo}
            name={profile.companyName}
            activeOffers={profile?.statistics?.activeJobOffers}
            buttonCaption={'wybierz'}
            buttonAction={() => onProfileSelect(profile)}
          />
        ))}
        <Link href={AuthenticationPaths.addCompany}>
          <CompanyProfileBoxContent>
            <Typography variant="body2" className={classes.newClient}>
              + Dodaj nowego klienta
            </Typography>
          </CompanyProfileBoxContent>
        </Link>
      </div>
      <Box mb={4} />
      {/* <Typography align="center">
        Masz {profiles.length}/{maxOffers} klientów w planie free, <Link href={MainPaths.invoice}>zwiększ plan</Link>{' '}
        aby dodać więcej firm.
      </Typography> */}
    </LoginFlowLayout>
  );
};
