export enum BundleTimestamp {
  NONE = '-',
  MONTH = 'month',
  YEAR = 'year',
}

export type InvoiceClasses = Record<
  'city' | 'widePanel' | 'flexGrow' | 'narrowPanel' | 'wideContainer' | 'linkActive' | 'postal',
  string
>;
