import { makeStyles, styled } from '@material-ui/core';
import { HrMeTheme } from '@hrme/shared/src/theme/theme';
import { Link } from 'react-router-dom';

export const useStyles = makeStyles<HrMeTheme>((theme) => ({
  widePanel: {
    flex: 2.4,
    marginLeft: theme.spacing(4),
  },
  wideContainer: {
    padding: theme.spacing(4),
    border: `1px solid ${theme.borders.light}`,
    textAlign: 'left',
  },
  narrowPanel: {
    flex: 1,
    height: 'fit-content',
    padding: theme.spacing(4),
    textAlign: 'left',
    border: `1px solid ${theme.borders.light}`,
  },
  linkActive: {
    background: theme.palette.background.default,
  },
  flexGrow: {
    flexGrow: 1,
  },
  city: {
    flexGrow: 12,
  },
  postal: {
    flexGrow: 0.1,
  },
}));

export const EditNavigationLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  padding: theme.spacing(2),
  borderRadius: 5,
  color: theme.palette.common.black,
  textDecoration: 'none',

  '&:hover': {
    textDecoration: 'underline',
  },
}));

export const TwoItemsRow = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});
