import React from 'react';
import { Typography } from '@material-ui/core';

export const ProjectTheme: React.FC = () => {
  return (
    <>
      <Typography>Typography | 1rem (16px) | 400</Typography>
      <Typography variant="h1">Typography: h1 | 6rem | 300</Typography>
      <Typography variant="h2" style={{ color: 'blue' }}>
        Typography: h2 | 40px | 700
      </Typography>
      <Typography variant="h3">Typography: h3 | 3rem | 400</Typography>
      <Typography variant="h4" style={{ color: 'blue' }}>
        Typography: h4 | 24px | 700
      </Typography>
      <Typography variant="h5">Typography: h5 | 1.5rem | 400</Typography>
      <Typography variant="h6">Typography: h6 | 1.25rem | 500</Typography>
      <Typography variant="subtitle1" style={{ color: 'blue' }}>
        Typography: subtitle1 | 14px | 400
      </Typography>
      <Typography variant="subtitle2">Typography: subtitle2 | 0.875rem | 500</Typography>
      <Typography variant="body1">Typography: body1 | 1rem | 400</Typography>
      <Typography variant="body2" style={{ color: 'blue' }}>
        Typography: body2 | 16px | 400
      </Typography>
      <Typography variant="button" style={{ marginRight: 16 }}>
        Typography: button | 0.875rem | 500
      </Typography>
      <Typography variant="caption" style={{ marginRight: 16 }}>
        Typography: caption | 0.75rem | 400
      </Typography>
      <Typography variant="overline">Typography: overline | 0.75rem | 400</Typography>
    </>
  );
};
