import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import dayjs from 'dayjs';
import { useHistory } from 'react-router';

import { ButtonExtend } from 'ui/buttonExtend/ButtonExtend';
import { useJobOfferContext } from 'hooks/useJobOfferContext/useJobOfferContext';
import { MainPaths } from 'routing/main/MainPaths';
import { BundleTypes } from 'app/paymentVariantScreen/components/variantHeaders/VariantHeaders.types';

import { useStyles } from './JobOfferActivationStatusBar.styles';

export const JobOfferActivationStatusBar: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { currentSubscription, subscriptionLoading } = useJobOfferContext();
  const history = useHistory();

  if (subscriptionLoading) return <div />;

  const handleExtend = (bundle: BundleTypes) => history.push(MainPaths.invoice, { bundleType: bundle });

  const isSubscription = !currentSubscription?.isPAYG;

  // TODO: move this to candidates view
  const validBy = isSubscription
    ? dayjs.unix(currentSubscription?.periodEnd?.seconds).format('DD-MM-YYYY')
    : 'bezterminowo';
  const plan = isSubscription ? currentSubscription?.type : 'Pay-as-you-go';
  const slots = isSubscription
    ? `${currentSubscription?.active_subscription_tokens}/${currentSubscription?.renewable_tokens}`
    : currentSubscription?.active_payg_tokens;

  return (
    <div className={classes.statusBar}>
      <div className={classes.currentPlan}>
        <Typography>Twój aktualny pakiet:</Typography>
        <Box mr={1} />
        <Typography className={classes.currentPlanValue}>{plan}</Typography>
      </div>
      <div className={classes.statusDetails}>
        <Box mr={2} />
        <Box mr={4} />
        {isSubscription && (
          <>
            <Typography variant="body2">Ważny do:</Typography>
            <Box mr={1} />
            <Typography variant="body2" className={classes.currentPlanValue}>
              {validBy}
            </Typography>
          </>
        )}
        <Box mr={4} />
        <Typography variant="body2">Dostępnych kontaktów z kandydatami</Typography>
        <Box mr={1} />
        <Typography variant="body2" className={classes.currentPlanValue}>
          {slots ? slots : 0}
        </Typography>
        <Box mr={4} />
        <ButtonExtend onClick={() => handleExtend(BundleTypes.PAY_AS_YOU_GO)}>kup więcej</ButtonExtend>
      </div>
    </div>
  );
};
