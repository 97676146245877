/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { useAuthContext } from 'hooks/useAuthContext/useAuthContext';

export const ProtectedRoute: React.FC<RouteProps> = ({ component: Component, ...rest }) => {
  const { authPending, currentUser } = useAuthContext();
  const canOpenProtectedRoute = authPending || (currentUser !== null && currentUser.emailVerified);

  return (
    <Route
      {...rest}
      //@ts-ignore
      render={(routeProps) => (canOpenProtectedRoute ? <Component {...routeProps} /> : <Redirect to="/" />)}
    />
  );
};
