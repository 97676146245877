import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';

import { DashboardContentHeaderProps } from 'ui/dashboard/dashboardContentHeader/DashboardContentHeader.types';
import { useStyles } from 'ui/dashboard/dashboardContentHeader/DashboardContentHeader.styles';

export const DashboardContentHeader: React.FC<DashboardContentHeaderProps> = ({ headerText, subHeader }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.wrapper}>
      <Typography variant="h4" className={classes.text}>
        {headerText}
      </Typography>
      {subHeader && <Typography className={classes.subheader}>{subHeader}</Typography>}
      <Box mb={6} />
    </div>
  );
};
