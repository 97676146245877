import { makeStyles, styled } from '@material-ui/core';
import { HrMeTheme } from '@hrme/shared/src/theme/theme';

export const useStyles = makeStyles<HrMeTheme>((theme) => ({
  widePanel: {
    flex: 2.4,
    marginRight: theme.spacing(4),
  },
  narrowPanel: {
    flex: 1,
  },
  narrowContainer: {
    padding: theme.spacing(4),
    border: `1px solid ${theme.borders.light}`,
    textAlign: 'left',
  },
  dashboardContent: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  titleBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titles: {},
  title: {
    textAlign: 'left',
  },
  subtitle: {
    textAlign: 'left',
    color: theme.palette.grey.A200,
  },
  box: {
    height: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
  },
  backContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(1),
    cursor: 'pointer',
  },
  countersContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bottomContentContainer: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
  },
}));

export const TwoItemsRow = styled('div')({
  width: '100%',
  display: 'flex',
  marginBottom: 8,
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export const SummaryRowRegular = styled('p')({
  fontWeight: 'normal',
  margin: 0,
  padding: 0,
  marginBottom: 8,
});

export const SummaryRowBold = styled(SummaryRowRegular)({
  fontWeight: 'bold',
});
