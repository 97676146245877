import React from 'react';
// import { Box, Grid, Typography, useTheme } from '@material-ui/core';
import { Box, Typography, useTheme } from '@material-ui/core';
import { useHistory, Link, Redirect } from 'react-router-dom';
import dayjs from 'dayjs';
import { useState } from 'react';
import { typeConvert } from '@hrme/shared';

import { DashboardContentContainer } from 'ui/dashboard/dashboardContentContainer/DashboardContentContainer';
import { DashboardContent } from 'ui/dashboard/dashboardContent/DashboardContent';
import BackArrow from 'assets/icons/back_arrow.svg';
import { Counter } from 'ui/counter/Counter';
import { ButtonDefault } from 'ui/button/ButtonDefault';
import { SummaryContainer } from 'ui/summaryContainer/SummaryContainer';
// import { ButtonAdd } from 'ui/buttonAdd/ButtonAdd';
import { Status } from 'ui/status/Status';
import { MainLayout } from 'ui/mainLayout/MainLayout';
import { MainPaths } from 'routing/main/MainPaths';
import { useJobOfferContext } from 'hooks/useJobOfferContext/useJobOfferContext';
import { OfferStatus } from 'app/jobOffer/jobOfferActivationStatusBar/JobOfferActivationStatusBar.types';

import { SummaryRowBold, SummaryRowRegular, TwoItemsRow, useStyles } from './JobOfferDetail.styles';
import { JobOfferDetailTypes } from './jobOfferDetail.types';
// import { TempChart } from './tempChart';

export const JobOfferDetail: React.FC<JobOfferDetailTypes> = ({ location }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [jobStatus, setJobStatus] = useState(location?.state?.status || OfferStatus.ACTIVE);
  const { changeOfferStatus } = useJobOfferContext();
  const history = useHistory();

  if (!location?.state) return <Redirect to={MainPaths.jobOffers} />;

  const creationDateValid = location?.state?.createdAt?.seconds;

  const amountOfMatchedCandidates = location.state.matchedCandidates?.length;
  const offerCreated = creationDateValid
    ? dayjs.unix(location.state.createdAt.seconds).format('DD.MM.YYYY')
    : dayjs().format('DD.MM.YYYY');
  const offerValidUntil = location.state.validUntil ? dayjs(location.state.validUntil).format('DD.MM.YYYY') : '-';
  const offferStatus = location.state.status;
  const jobOfferId = location.state.jobOfferId;

  const switchOfferStatus = jobStatus === OfferStatus.ACTIVE ? OfferStatus.INACTIVE : OfferStatus.ACTIVE;

  const handleToggleOffer = async () => {
    await changeOfferStatus(jobOfferId, switchOfferStatus);
    setJobStatus(switchOfferStatus);
  };

  const {
    currency,
    position,
    category,
    company,
    contractType,
    hireAsap,
    hireSince,
    officeHours,
    placeOfWork,
    companyCity,
    companyStreet,
    interview,
    benefits,
    positionDescription,
    methodology,
    dailyTasks,
    personalGrowth,
    language,
    salaryRange,
    salaryRangeFrom,
    salaryRangeTo,
    paidLeave,
    relocationPackage,
    mainTechnologies,
    experienceType,
    experienceTime,
    positionTechnologies,
    welcomeTechnologies,
    willLearnTechnologies,
    willLearn,
    collaborators,
    salaryOnHand,
    isNetto,
  } = location.state;

  const convertArrayToString = (array: string[]) => array.join(', ');
  const convertArrayToItem = (array: string[]) => array.map((item) => ({ label: item }));

  const jobOfferSummary = [
    { label: 'Nazwa oferty', value: position },
    { label: 'Kategoria oferty', value: category },
    { label: 'Firma', value: company },
    { label: 'Rodzaj umowy', value: contractType?.map((type) => typeConvert(type)).join(',') },
  ];

  const jobOfferSalary = [
    {
      label: 'Zarobki',
      value: `${salaryRange ? `${salaryRangeFrom} - ${salaryRangeTo}` : `${salaryRangeFrom}`} ${currency} ${
        isNetto ?? salaryOnHand ? 'netto' : 'brutto'
      }`,
    },
    { label: 'Płatny urlop', value: paidLeave ? 'Tak' : 'Nie' },
    { label: 'Pakiet relokacyjny', value: relocationPackage ? 'Tak' : 'Nie' },
  ];

  const jobOfferHire = [
    { label: 'Data zatrudnienia', value: hireAsap ? 'od zaraz' : hireSince },
    { label: 'Wymiar czasu pracy', value: typeConvert(officeHours) },
    { label: 'Miejsce pracy', value: typeConvert(placeOfWork) },
    { label: 'Miasto', value: companyCity },
    { label: 'Ulica', value: companyStreet ? companyStreet : 'nie podano' },
    { label: 'Rozmowa rekrutacyjna', value: typeConvert(interview) },
  ];

  const jobOfferExtra = [
    { label: 'Benefity', value: convertArrayToString(benefits) },
    { label: 'Opis stanowiska' },
    { label: positionDescription },
  ];

  const jobOfferCandidate = [
    { label: 'Główna technologia', value: convertArrayToString(mainTechnologies) },
    { label: 'Wymagane doświadczenie kandydata', value: typeConvert(experienceType) },
    { label: 'Lata doświadczenia', value: experienceTime ? experienceTime : 'nie podano' },
  ];

  const jobOfferRequired = [
    { label: 'Umiejętności obowiązkowe', value: convertArrayToString(positionTechnologies) },
    { label: 'Umiejętności mile widziane', value: convertArrayToString(welcomeTechnologies) },
    { label: 'Technologie jakich nauczy się kandydat', value: convertArrayToString(willLearnTechnologies) },
  ];

  const jobOfferMore = [
    { label: 'Z kim będzie pracować kandydat', value: convertArrayToString(collaborators) },
    { label: 'Czego nauczy się kandydat', value: convertArrayToString(willLearn) },
  ];

  const jobMetodology = convertArrayToItem(methodology);
  const jobDailyTasks = convertArrayToItem(dailyTasks);
  const jobGrowth = convertArrayToItem(personalGrowth);
  const jobLanguage = convertArrayToItem(language);

  return (
    <MainLayout>
      <DashboardContentContainer>
        <DashboardContent hasBottomPadding={false}>
          <div className={classes.dashboardContent}>
            <Box mb={6} />
            <div className={classes.titleBar}>
              <div className={classes.titles}>
                <div className={classes.backContainer}>
                  <img src={BackArrow} alt="back" />
                  <Box mr={1.5} />
                  <Link to={MainPaths.jobOffers} className={classes.link}>
                    <Typography variant="subtitle2">Wszystkie oferty pracy</Typography>
                  </Link>
                </div>
                <Typography variant="h2" className={classes.title}>
                  {position}
                </Typography>
                <Box mb={1} />
                <Typography variant="body2" className={classes.subtitle}>
                  Sprawdź swoją ofertę pracy
                </Typography>
              </div>
            </div>
            <Box mt={6} />
          </div>
        </DashboardContent>
        <DashboardContent hasBottomPadding={false}>
          <div className={classes.dashboardContent}>
            <Box mb={6} />
            <div className={classes.countersContainer}>
              <Counter
                value={amountOfMatchedCandidates}
                title="Liczba kandydatów"
                subtitle="Liczba osób dopasowanych do oferty"
              />
              <Counter value={0} title="Wysłanych ofert" subtitle="Liczba osób dopasowanych do oferty" />
              <Counter value={0} title="Zaakceptowanych ofert" subtitle="Liczba osób dopasowanych do oferty" />
            </div>
            <Box mb={6} />
            <ButtonDefault
              onClick={() => {
                history.push({
                  pathname: MainPaths.applicantsListFromJobOffers,
                  state: { jobOfferId: location.state.jobOfferId },
                });
              }}
            >
              {' '}
              ZOBACZ KANDYDATÓW DOPASOWANYCH DO OFERTY{' '}
            </ButtonDefault>
            <Box mt={6} />
          </div>
        </DashboardContent>
        {/* ◽◽◽◽◽◽◽◽◽◽◽◽◽◽◽◽◽◽◽◽◽◽◽◽◽◽◽◽◽◽◽◽◽◽◽ temporarily hidden */}
        {/* <Box mb={1} />
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <Box bgcolor="#fff" padding="24px">
              <Typography align="left" variant="h6">
                Statystyka
              </Typography>
              <TempChart />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="#fff" padding="24px">
              <Typography align="left" variant="h6">
                Statystyka
              </Typography>
              <TempChart />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="#fff" padding="24px">
              <Typography align="left" variant="h6">
                Statystyka
              </Typography>
              <TempChart />
            </Box>
          </Grid>
        </Grid>
        <Box mb={3} /> */}
        <div className={classes.bottomContentContainer}>
          <div className={classes.widePanel}>
            <SummaryContainer title="Podsumowanie zamówienia" summaryItems={jobOfferSummary} />
            <SummaryContainer title="Wynagrodzenie" summaryItems={jobOfferSalary} />
            <SummaryContainer title="Czas i miejsce pracy" summaryItems={jobOfferHire} />
            <SummaryContainer title="Dodatkowe informacje" summaryItems={jobOfferExtra} positionDescription />
            <Box mb={4} />
            <SummaryContainer title="Informacje o kandydacie" summaryItems={jobOfferCandidate} />
            <SummaryContainer title="Technologie na stanowisku" summaryItems={jobOfferRequired} />
            <SummaryContainer title="Dodatkowe informacje o stanowisku" summaryItems={jobOfferMore} />
            <SummaryContainer title="Metodologia stanowiska" summaryItems={jobMetodology} />
            <SummaryContainer title="Codzienne zadania" summaryItems={jobDailyTasks} />
            <SummaryContainer title="Możliwość rozwoju" summaryItems={jobGrowth} />
            <SummaryContainer title="Wymagane języki obce" summaryItems={jobLanguage} />
          </div>
          <div className={classes.narrowPanel}>
            <div className={classes.narrowContainer}>
              <Typography variant="h6">Informacje podstawowe</Typography>
              <Box mb={3} />
              <TwoItemsRow>
                <SummaryRowRegular>Data dodania</SummaryRowRegular>
                <SummaryRowBold>{offerCreated}</SummaryRowBold>
              </TwoItemsRow>
              <TwoItemsRow>
                <SummaryRowRegular>Ważność końca</SummaryRowRegular>
                <SummaryRowBold>{offerValidUntil}</SummaryRowBold>
              </TwoItemsRow>
              <TwoItemsRow>
                <SummaryRowRegular>Status</SummaryRowRegular>
                <Status status={jobStatus} />
              </TwoItemsRow>
              <Box mb={3} />
              {/* <ButtonAdd>przedłuż ofertę</ButtonAdd>
              <Box mb={3} /> */}
              {offferStatus !== OfferStatus.DRAFT && (
                <Typography variant="body2" align="center" className={classes.link} onClick={handleToggleOffer}>
                  {jobStatus === OfferStatus.ACTIVE ? 'dezaktywuj ofertę' : 'aktywuj ofertę'}
                </Typography>
              )}
            </div>
          </div>
        </div>
        <Box mb={4} />
      </DashboardContentContainer>
    </MainLayout>
  );
};
