import { FirebaseBasicResponse } from 'api/Firebase.types';

export type UserContextProps = {
  loading: boolean;
  user: User | undefined;
  fetchUserData: () => Promise<FirebaseBasicResponse>;
  updateUserData: (updateUserObject: UserUpdateObject) => Promise<FirebaseBasicResponse>;
  updateUserProfileType: (updateUserObject: UserUpdateObject) => Promise<FirebaseBasicResponse>;
  setInitialData: () => Promise<FirebaseBasicResponse>;
};

export type User = UserUpdateObject & {
  companies?: string[];
};

export type UserUpdateObject = {
  firstName: string;
  lastName: string;
  email: string;
  companyName?: string;
  companyAddressLine1?: string;
  country?: string;
  city?: string;
  postcode?: string;
  state?: string;
  nip?: string;
  profileType?: UserProfileType;
};

export enum UserProfileType {
  AGENCY = 'agencja',
  COMPANY = 'firma',
}
