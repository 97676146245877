import React, { useMemo, useState } from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { ButtonDefault } from '@hrme/shared';
import { useHistory } from 'react-router';

import { DashboardSection } from 'ui/dashboard/dashboardSection/DashboardSection';
import { ApplicantsListAccordion } from 'ui/applicantsListAccordion/ApplicantsListAccordion';
import { IHash } from 'ui/applicantsListAccordion/ApplicantsListAccordion.types';
import { DashboardApplicantsPanelDetails } from 'ui/dashboard/dashboardApplicantsPanelDetails/DashboardApplicantsPanelDetails';
import { OfferStatus } from 'app/applicant/applicantsListFromJobOffers/ApplicantsListFromJobOffers.types';
import { Candidate, JobOfferStatus } from 'context/jobOffer/JobOfferContext.types';
import { findNameByStatus } from 'helpers/findNameByStatus';
import { useJobOfferContext } from 'hooks/useJobOfferContext/useJobOfferContext';
import { useCompanyContext } from 'hooks/useCompanyContext/useCompanyContext';
import { useKnowledge } from 'hooks/useKnowledge/useKnowledge';
import { ButtonAdd } from 'ui/buttonAdd/ButtonAdd';
import { useCandidateContext } from 'hooks/candidate/useCandidateContex';
import Cross from 'assets/icons/cross.svg';
import { InvitationStatus } from '../dashboardApplicantsPanel/DashboardApplicantsPanel.types';
import { MainPaths } from 'routing/main/MainPaths';
import { BundleTypes } from 'app/paymentVariantScreen/components/variantHeaders/VariantHeaders.types';

import { useStyles } from './DashboardApplicantsAccordions.styles';
import { DashboardApplicantsAccordionsProps } from './DashboardApplicantsAccordions.types';

export const DashboardApplicantsAccordions: React.FC<DashboardApplicantsAccordionsProps> = ({
  titles,
  jobStatusSelection = OfferStatus.ALL,
  jobOfferSelection = 'all',
  careerPath,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();
  const [expandedPanel, setExpandedPanel] = useState('');
  const { jobOffers, unlockCandidate, setCandidateStatusInJobOffers, fetchCurrentSubscription } = useJobOfferContext();
  const { selectedCompany } = useCompanyContext();
  const { categories } = useKnowledge();
  const initialCandidatesToShow = 20;
  const [candidatesCount, setCandidatesCount] = useState(initialCandidatesToShow);
  const { isPopupVisible, showPopup, candidate } = useCandidateContext();
  const { currentSubscription } = useJobOfferContext();
  const [loadingUnlockingCandidate, setLoadingUnlockingCandidate] = useState(false);

  const isSubscription = !currentSubscription?.isPAYG;

  const plan = isSubscription ? currentSubscription?.type : 'Pay-as-you-go';
  let slots = isSubscription
    ? `${currentSubscription?.active_subscription_tokens}/${currentSubscription?.renewable_tokens}`
    : currentSubscription?.active_payg_tokens;
  slots = slots ? slots : 0;
  const areThereFreeSlots = slots === 0 ? true : false;

  const categoryTranslator: IHash = {};
  categories.forEach((el) => (categoryTranslator[el.trim().toLowerCase().replace(' ', '_')] = el));

  const handleChange = (panel: string) => {
    setExpandedPanel(panel);
  };

  const handleBuyMore = (bundle: BundleTypes) => history.push(MainPaths.invoice, { bundleType: bundle });

  const candidates = useMemo(() => {
    if (!jobOffers.length) return [];

    let matchedCandidates: Candidate[] = [];
    const newApplicants: Candidate[] = [];
    const outputApplicants: Candidate[] = [];

    if (jobOfferSelection === 'all') {
      jobOffers
        .filter(
          (jobOffer) => jobOffer.companyId === selectedCompany?.docRef && jobOffer.status === JobOfferStatus.ACTIVE,
        )
        .forEach((jobOffer) => {
          const matchSelect = careerPath ? jobOffer.potentialCandidates : jobOffer.matchedCandidates;
          if (matchSelect) matchedCandidates = matchedCandidates.concat(matchSelect);
        });
    } else {
      matchedCandidates =
        jobOffers.filter((jobOffer) => jobOffer.jobOfferId === jobOfferSelection)[0].matchedCandidates || [];
    }

    matchedCandidates.forEach((candidate) => {
      const newJobOfferDetails = {
        ...candidate.jobOfferDetails,
        status: candidate.status,
      };

      newApplicants.push({
        ...candidate,
        name: findNameByStatus(candidate, candidate.status),
        jobOfferDetails: { ...newJobOfferDetails },
      });
    });

    if (careerPath) {
      newApplicants.sort((a, b) => b.potentialScore - a.potentialScore);
    } else {
      newApplicants.sort((a, b) => b.generalScore - a.generalScore);
    }
    if (!titles) newApplicants.length = 3;

    if (jobStatusSelection !== OfferStatus.ALL) {
      return [...newApplicants.filter((applicant) => applicant.status === jobStatusSelection)];
    } else {
      outputApplicants.push(
        ...newApplicants.filter((applicant) => applicant.jobOfferDetails.status === InvitationStatus.PAID),
      );
      outputApplicants.push(
        ...newApplicants.filter((applicant) => applicant.jobOfferDetails.status === InvitationStatus.ACCEPTED),
      );
      outputApplicants.push(
        ...newApplicants.filter(
          (applicant) =>
            applicant.jobOfferDetails.status !== InvitationStatus.PAID &&
            applicant.jobOfferDetails.status !== InvitationStatus.ACCEPTED,
        ),
      );
      return outputApplicants;
    }

    return [...newApplicants];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobOffers, jobOfferSelection, jobStatusSelection, selectedCompany]);

  const thereAreNoCandidates = !candidates[0];

  if (thereAreNoCandidates)
    return (
      <>
        <Box mt={3} />
        <Typography variant="body1">Tutaj pojawią się kandydaci</Typography>
        <Box mt={3} />
      </>
    );

  return (
    <DashboardSection>
      {isPopupVisible && (
        <div>
          <div
            className={classes.cover}
            onClick={() => {
              showPopup(!isPopupVisible);
            }}
          ></div>
          <div className={classes.absolute}>
            <div className={classes.crossContainer}>
              <div className={classes.warning}>Uwaga</div>
              <img
                src={Cross}
                alt="cross"
                className={classes.cross}
                onClick={() => {
                  showPopup(false);
                }}
              />
            </div>
            <div className={classes.crossContainer}>Czy chcesz odblokować profil wybranego kandydata?</div>
            <div className={classes.status}>
              <div>
                Twój aktualny pakiet: <strong>{plan}</strong>
              </div>
              <div>
                Dostępnych kontaktów do odblokowania: <strong>{slots ? slots : 0}</strong>
              </div>
            </div>
            <div className={classes.unlockContainer}>
              <ButtonDefault
                loading={loadingUnlockingCandidate}
                className={classes.submitButton}
                disabled={areThereFreeSlots}
                onClick={async () => {
                  setLoadingUnlockingCandidate(true);
                  if (candidate) {
                    const result = await unlockCandidate({
                      jobOfferId: candidate.offerId,
                      candidateId: candidate.candidateId,
                      candidatesCollection: careerPath ? 'potential_candidates' : 'matched_candidates',
                    });
                    if (result.success) {
                      setCandidateStatusInJobOffers(candidate.offerId, InvitationStatus.PAID, candidate.candidateId);
                      await fetchCurrentSubscription();
                      history.push({
                        pathname: MainPaths.applicantsAttributesMatching,
                        state: {
                          candidateId: candidate.candidateId,
                          offerId: candidate.offerId,
                          b2bUserId: candidate.b2bUserId,
                          score: candidate.score,
                          careerPath: careerPath,
                        },
                      });
                    } else {
                      console.log(`Unlocking candidate failed - result: ${result?.message}`);
                    }
                  } else {
                    console.log(`Candidate empty??`);
                  }
                  showPopup(false);
                  setLoadingUnlockingCandidate(false);
                }}
              >
                {' '}
                odblokuj kandydata
              </ButtonDefault>
              <div className={classes.unlockLabel} onClick={() => handleBuyMore(BundleTypes.PAY_AS_YOU_GO)}>
                dokup odblokowania
              </div>
            </div>
          </div>
        </div>
      )}
      {titles && (
        <div className={classes.tableHeader}>
          {titles.map((title, index) => {
            return (
              <Typography
                key={`${title}-${index}`}
                variant="caption"
                className={clsx({ [classes.titleTypography]: true, [classes.firstTitle]: index === 0 })}
              >
                {title.title}
              </Typography>
            );
          })}
        </div>
      )}
      {candidates.slice(0, candidatesCount).map((applicant, index) => (
        <React.Fragment key={index}>
          <ApplicantsListAccordion
            panel={index.toString()} // Todo: rename to id
            expanded={expandedPanel === index.toString()}
            handleChange={handleChange}
            caption="szczegóły"
            applicant={applicant}
            categoryTranslator={categoryTranslator}
          >
            <DashboardApplicantsPanelDetails
              applicant={applicant}
              status={applicant.status}
              index={index}
              inAccordion={true}
              careerPath={careerPath}
            />
          </ApplicantsListAccordion>
        </React.Fragment>
      ))}
      <br />
      {candidates.length > initialCandidatesToShow && (
        <ButtonAdd
          disabled={candidatesCount > candidates.length}
          onClick={() => setCandidatesCount(candidatesCount + 10)}
        >
          {' '}
          ⬇ pokaż więcej kandydatów ⬇{' '}
        </ButtonAdd>
      )}
    </DashboardSection>
  );
};
