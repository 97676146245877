"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
var core_1 = require("@material-ui/core");
exports.useStyles = core_1.makeStyles(function (theme) { return ({
    container: {
        marginRight: theme.spacing(3),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    counter: {
        padding: theme.spacing(1.5),
        border: "1px solid $#E1E1E1",
        borderRadius: 5,
        minWidth: 56,
        marginRight: theme.spacing(3),
    },
    counterLabel: { minWidth: 298 },
    counterTitle: {
        textAlign: 'left',
        fontWeight: 700,
    },
    counterSubtitle: {
        textAlign: 'left',
        fontSize: '15px',
        //TODO: not found in theme palette yet
        color: '#595c97',
    },
}); });
