import * as React from 'react';
import { Link as MuiLink, useTheme } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from './Link.styles';
import { LinkProps } from './Link.types';

export const Link = ({ children, type = 'regular', className, href, external }: LinkProps): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const linkStyle = clsx({
    [classes.regularLink]: type === 'regular',
    [classes.boldLink]: type === 'bold',
  });

  return (
    <MuiLink
      variant="body2"
      href={href}
      className={clsx([className, linkStyle])}
      {...(external ? { rel: 'noopener noreferrer', target: '_blank' } : {})}
    >
      {children}
    </MuiLink>
  );
};
