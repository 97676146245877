import { useTheme } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import { useStyles } from 'ui/dashboard/dashboardContent/DashboardContent.styles';

import { DashboardContentProps } from './DashboardContent.types';

export const DashboardContent: React.FC<DashboardContentProps> = React.memo(({ children, hasBottomPadding = true }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const styles = clsx({
    [classes.content]: true,
    [classes.noPadding]: !hasBottomPadding,
  });

  return <div className={styles}>{children}</div>;
});

DashboardContent.displayName = 'DashboardContent';
