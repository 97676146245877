import { Typography } from '@material-ui/core';
import React from 'react';

import { AuthenticationPaths } from 'routing/authentication/AuthenticationPaths';
import { Link } from 'ui/link/Link';
import { LoginFlowLayout } from 'ui/loginFlowLayout/LoginFlowLayout';

import { ForgotPasswordForm } from './forgotPasswordForm/ForgotPasswordForm';

const ForgotPasswordFooter = () => (
  <Typography variant="body2">
    Posiadasz już konto?{' '}
    <Link href={AuthenticationPaths.login} type="bold">
      Zaloguj się
    </Link>
  </Typography>
);

export const ForgotPasswordPage: React.FC = () => {
  return (
    <LoginFlowLayout footerComponent={<ForgotPasswordFooter />}>
      <ForgotPasswordForm />
    </LoginFlowLayout>
  );
};
