import React, { useState } from 'react';

import { CandidateDetails } from 'context/candidate/CandidateContext.types';
import { CandidateContext } from 'context/candidate/CandidateContext';

export const CandidateProvider: React.FC = ({ children }) => {
  const [isPopupVisible, showPopup] = useState(false);
  const [candidate, setCandidates] = useState<CandidateDetails | null>(null);

  return (
    <CandidateContext.Provider
      value={{
        isPopupVisible,
        showPopup,
        candidate,
        setCandidates,
      }}
    >
      {children}
    </CandidateContext.Provider>
  );
};
