import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Box, InputLabel, Typography, useTheme } from '@material-ui/core';

import { TextInputVariant } from 'ui/textInputExtendableList/TextInputExtendableList.types';
import { TextInputWithClose } from 'ui/textInputWithClose/TextInputWithClose';
import { ButtonAdd } from 'ui/buttonAdd/ButtonAdd';
import { FormControlLabelItem } from 'ui/radioGroupWithOthersInput/RadioGroupWithOthersInput.types';
import { TextSelectRHF } from 'ui/textSelect/TextSelectRHF';
import { RadioGroupWithOthersInputRHF } from 'ui/radioGroupWithOthersInput/RadioGroupWithOthersInputRHF';
import { ChipData } from 'ui/tagInputExtended/TagInputExtended.types';
import { Languages } from '../../addJobOfferPositionBasicsRHF/AddJobOfferPositionBasicsRHF.types';
import { FieldErrorWrap } from 'ui/fieldErrorWrap/FieldErrorWrap';
import { ShowErrorText } from 'ui/showErrorText/ShowErrorText';
import { TagInputExtendedRHF } from 'ui/tagInputExtended/TagInputExtendedRHF';

import { AddJobOfferApplicantLanguageProps, LANGUAGES } from './AddJobOfferApplicantLanguageRHF.types';
import { useStyles } from './AddJobOfferApplicantLanguageRHF.styles';

export const AddJobOfferApplicantLanguageRHF: React.FC<AddJobOfferApplicantLanguageProps> = ({
  label,
  listItems = [],
  buttonCaption,
  addInputLabel,
  variant = TextInputVariant.White,
  name = '',
  methods,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const languages: FormControlLabelItem[] = Object.values(Languages);

  const listContainerStyle = clsx({
    [classes.listContainer]: true,
    [classes.listContainerGrey]: variant === TextInputVariant.Grey,
  });

  const labelStyle = clsx({
    [classes.label]: true,
    [classes.labelGrey]: variant === TextInputVariant.Grey,
  });

  const [items, setItems] = useState(listItems);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [certificates, setCertificates] = useState<ChipData[]>([]);

  const fieldError = methods?.errors?.[name];

  const resetField = () => {
    setSelectedLanguage('');
    setCertificates([]);
    methods?.setValue('valueLanguageLevel', null);
  };

  const handleAddButtonClick = () => {
    if (!selectedLanguage) {
      return;
    }
    const languageName = LANGUAGES.filter((item) => item.value === selectedLanguage)[0].label;
    const languageLevel = languages.filter((item) => item.value === methods?.getValues('valueLanguageLevel'))[0].label;
    const languageCertificates = certificates.length
      ? `, Certyfikat ${certificates.map((item) => item.label).join(', ')}`
      : '';
    const newItem = `${languageName} - ${languageLevel}${languageCertificates}`;

    const elementExists = items.find((el) => el.toLowerCase() === newItem.toLowerCase());

    if (elementExists === undefined) {
      setItems([...items, newItem]);
    }
    resetField();
  };

  useEffect(() => {
    methods?.register(name);
    methods?.setValue(name, items);
    // TODO to be removed soon
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    methods?.setValue(name, items);
  }, [items, methods, name]);

  return (
    <>
      <FieldErrorWrap showErrorBackground={!!fieldError}>
        <div className={listContainerStyle}>
          <InputLabel>
            <Typography variant="subtitle2" className={labelStyle}>
              {label}
            </Typography>
          </InputLabel>
          {items.map((item, key) => {
            return (
              <TextInputWithClose
                key={key}
                value={item}
                onClick={() => {
                  setItems(items.filter((element) => element !== item));
                }}
                variant={variant}
              />
            );
          })}
        </div>
      </FieldErrorWrap>
      {fieldError && <ShowErrorText errorMessage="Dodaj minumum jeden język" />}
      <div>
        <Box mb={2.25} />
        <TextSelectRHF
          label={addInputLabel}
          value={selectedLanguage}
          onChange={(event) => setSelectedLanguage(event.target.value as string)}
          items={LANGUAGES}
        />
        <RadioGroupWithOthersInputRHF
          label="poziom znajomości języka"
          name="valueLanguageLevel"
          items={languages}
          methods={methods}
        />

        <TagInputExtendedRHF
          label="certyfikaty"
          subtitle="Czy wymagasz aby znajomość języka była potwierdzona certyfikatami? Jeżeli tak uzupełnij je poniżej"
          setCertificates={setCertificates}
          items={certificates}
        />
        <Box mb={2} />

        <ButtonAdd onClick={handleAddButtonClick}>{buttonCaption}</ButtonAdd>
      </div>
    </>
  );
};
