import { useTheme } from '@material-ui/core';
import React from 'react';

import { useStyles } from './CompanyProfileBoxContainer.styles';

export const CompanyProfileBoxContainer: React.FC = ({ children }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return <div className={classes.wrapper}>{children}</div>;
};
