import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'start',
    width: 'max-content',
  },
  label: {
    textAlign: 'left',
    marginBottom: theme.spacing(1),
    fontWeight: 400,
    color: theme.palette.grey[800],
  },
}));
