"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ButtonBoxAdd = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var core_1 = require("@material-ui/core");
var ButtonBoxAdd_styles_1 = require("./ButtonBoxAdd.styles");
var icons_1 = require("@material-ui/icons");
var ButtonBoxAdd = function (_a) {
    var children = _a.children, onClick = _a.onClick;
    var theme = core_1.useTheme();
    var classes = ButtonBoxAdd_styles_1.useStyles(theme);
    return (jsx_runtime_1.jsxs("button", __assign({ type: "button", onClick: onClick, className: classes.wrapper }, { children: [jsx_runtime_1.jsx(icons_1.Add, { className: classes.add }, void 0), jsx_runtime_1.jsx(core_1.Box, { mr: 3 }, void 0), jsx_runtime_1.jsx(core_1.Typography, __assign({ variant: "body2" }, { children: children }), void 0)] }), void 0));
};
exports.ButtonBoxAdd = ButtonBoxAdd;
