import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  dashboardContent: {
    padding: theme.spacing(4),
    borderRadius: '5px',
  },
  title: {
    textAlign: 'left',
  },
}));
