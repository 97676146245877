import React, { useEffect } from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { LoginFlowLayout } from 'ui/loginFlowLayout/LoginFlowLayout';
import { ProfileTypeBox } from 'ui/profileTypeBox/ProfileTypeBox';
import { IconFirma } from 'ui/icon/iconFirma/IconFirma';
import { IconAgencja } from 'ui/icon/iconAgencja/IconAgencja';
import { useUserContext } from 'hooks/useUserContext/useUserContext';
import { UserProfileType, UserUpdateObject } from 'context/user/UserContext.types';
import { FirebaseBasicResponse } from 'api/Firebase.types';
import { AuthenticationPaths } from 'routing/authentication/AuthenticationPaths';
import { MainPaths } from 'routing/main/MainPaths';

import { useStyles } from './ChooseProfileType.styles';

export const ChooseProfileType: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { user, loading, updateUserProfileType } = useUserContext();
  const history = useHistory();

  const updateProfileType = async (
    payload: UserUpdateObject,
    updateUserProfileType: (updateUserObject: UserUpdateObject) => Promise<FirebaseBasicResponse>,
  ) => {
    const response = await updateUserProfileType(payload);

    if (response.success) {
      if (!user?.companies?.length) {
        history.replace(AuthenticationPaths.addCompany);
      } else {
        history.replace(MainPaths.dashboard);
      }
    } else {
      console.error(response.message);
    }
  };

  useEffect(() => {
    if (!loading) {
      if (!user) {
        history.push(AuthenticationPaths.login);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <LoginFlowLayout wide={true}>
      <Typography variant="h4" className={classes.header}>
        Wybierz rodzaj profilu
      </Typography>
      <Typography variant="body2" className={classes.description} align="center">
        Wybierz rodzaj profilu dla swojego konta
      </Typography>
      <Box mb={5} />
      <div className={classes.typeButtonsContainer}>
        <ProfileTypeBox
          icon={<IconFirma />}
          title={'Firma'}
          subtitle={'Jeżeli szukasz pracowników dla siebie'}
          buttonCaption={'wybierz'}
          buttonAction={() => {
            if (user) updateProfileType({ ...user, profileType: UserProfileType.COMPANY }, updateUserProfileType);
          }}
        >
          ChooseProfileType1
        </ProfileTypeBox>
        <ProfileTypeBox
          icon={<IconAgencja />}
          title={'Agencja HR'}
          subtitle={'Jeżeli szukasz pracowników dla wielu firm'}
          buttonCaption={'wybierz'}
          buttonAction={() => {
            if (user) updateProfileType({ ...user, profileType: UserProfileType.AGENCY }, updateUserProfileType);
          }}
        >
          ChooseProfileType2
        </ProfileTypeBox>
      </div>
    </LoginFlowLayout>
  );
};
