import React from 'react';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core';

import { FieldErrorWrapProps } from './FieldErrorWrap.types';
import { useStyles } from './FieldErrorWrap.styles';

export const FieldErrorWrap: React.FC<FieldErrorWrapProps> = ({ showErrorBackground, children, narrow }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const fieldHasError = clsx({
    [classes.fieldError]: showErrorBackground,
    [classes.wide]: !narrow,
  });

  return <div className={fieldHasError}>{children}</div>;
};
