import { makeStyles } from '@material-ui/core';
import { HrMeTheme } from '@hrme/shared/src/theme/theme';

export const useStyles = makeStyles<HrMeTheme>((theme) => ({
  percentSummary: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.background.default}`,
  },
  summaryLeft: {
    padding: theme.spacing(0, 4),
    borderRight: `1px solid ${theme.palette.background.default}`,
    height: 196,
    minWidth: 196,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  caption: {
    fontSize: '12px',
  },
  summaryRight: {
    width: '100%',
    display: 'grid',
    gridTemplateRows: '1fr 1fr',
    gridTemplateColumns: '1fr 1fr',
    gridRowGap: theme.spacing(3.5),
    gridColumnGap: theme.spacing(2),
  },
  summaryRightCell: {
    width: 115,
  },
  summaryRightColumnLeft: {
    justifySelf: 'end',
  },
  summaryRightColumnRight: {
    justifySelf: 'start',
  },
  typography: {
    fontSize: '24px',
  },
}));
