/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { Box, Chip, InputLabel, Paper, TextField, Typography, useTheme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useState } from 'react';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import { AutocompleteOptionType, TagInputExtendedRHFProps } from 'ui/tagInputExtended/TagInputExtendedRHF.types';
import { FieldErrorWrap } from 'ui/fieldErrorWrap/FieldErrorWrap';
import { ShowErrorText } from 'ui/showErrorText/ShowErrorText';

import { ChipData } from './TagInputExtended.types';
import { useStyles } from './TagInputExtended.styles';

const filter = createFilterOptions<AutocompleteOptionType>();

export const TagInputExtendedRHF: React.FC<TagInputExtendedRHFProps> = ({
  label,
  subtitle,
  required,
  items,
  methods,
  name = '',
  maxItems,
  autocompleteOptions = [],
  setCertificates,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [chipData, setChipData] = useState<ChipData[]>(items);

  const [autocompleteValue, setAutocompleteValue] = React.useState<AutocompleteOptionType | null>(null);

  const fieldError = methods?.errors?.[name];

  useEffect(() => {
    if (setCertificates) setChipData(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  useEffect(() => {
    if (name) methods?.register(name);
  }, [methods, name]);

  useEffect(() => {
    if (name)
      methods?.setValue(
        name,
        chipData.map((item) => item.label),
      );
  }, [methods, name, chipData]);

  useEffect(() => {
    addChip();
    if (setCertificates) setCertificates(chipData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autocompleteValue]);

  const onAutocompleteChange = (
    event: React.ChangeEvent<Record<string, unknown>>,
    newValue: string | AutocompleteOptionType | null,
  ) => {
    event.preventDefault();
    if (typeof newValue === 'string') {
      setAutocompleteValue({
        title: newValue,
      });
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      setAutocompleteValue({
        title: newValue.inputValue,
      });
    } else {
      setAutocompleteValue(newValue);
    }
  };

  const onAutocompleteInputKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') addChip();
  };

  const addChip = () => {
    if (!autocompleteValue || (!autocompleteValue.inputValue && !autocompleteValue.title)) return;
    if (chipData.some((item) => item.label.toLowerCase() === autocompleteValue.title.toLowerCase())) return;

    setChipData((prevData) => {
      const newArray = [
        ...prevData,
        {
          key: chipData.length,
          label: autocompleteValue?.inputValue
            ? (autocompleteValue?.inputValue as string)
            : (autocompleteValue?.title as string),
        },
      ];

      if (newArray.length > 0) {
        methods?.clearErrors(name);
      }

      return newArray;
    });
    setAutocompleteValue(null);
  };

  return (
    <FieldErrorWrap showErrorBackground={!!fieldError}>
      <div className={classes.container}>
        <InputLabel>
          <Typography variant="subtitle2" className={classes.label}>
            {label}
            {required && <span style={{ color: theme.palette.primary.main }}>*</span>}
          </Typography>
          {subtitle && (
            <Typography variant="caption" className={classes.subtitle}>
              {subtitle}
            </Typography>
          )}
        </InputLabel>
        <Box mb={1} />
        <Paper variant="outlined" className={classes.root}>
          {chipData.map((data) => {
            const handleDelete = (chipToDelete: ChipData) => () => {
              setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
              if (chipData.length === 1)
                methods?.setError(name, {
                  type: 'manual',
                });
            };

            return (
              <li key={data.key}>
                <Chip
                  size="medium"
                  label={data.label}
                  onDelete={handleDelete(data)}
                  className={classes.chip}
                  deleteIcon={<CloseIcon fontSize="small" />}
                />
              </li>
            );
          })}
          <Box ml={1} />
          {(maxItems === undefined || chipData.length < maxItems) && (
            <Autocomplete
              value={autocompleteValue}
              onChange={onAutocompleteChange}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    title: `Dodaj "${params.inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              // TODO: check is this 'id' needed at all
              // id="free-solo-with-text-demo"
              options={autocompleteOptions}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.title;
              }}
              renderOption={(option) => option.title}
              style={{ width: 200 }}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  label=""
                  className={classes.textField}
                  placeholder="Wprowadź"
                  onKeyPress={onAutocompleteInputKeyPress}
                />
              )}
            />
          )}
        </Paper>
      </div>
      {fieldError && <ShowErrorText errorMessage="Podaj minumum jedną pozycję" />}
    </FieldErrorWrap>
  );
};
