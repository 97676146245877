import { makeStyles } from '@material-ui/core';
import { HrMeTheme } from '@hrme/shared/src/theme/theme';

export const useStyles = makeStyles<HrMeTheme>((theme) => ({
  tableHeader: {
    width: 'calc(100% - 140px)',
    borderBottom: `1px solid ${theme.borders.main}`,
    paddingBottom: theme.spacing(3),
    display: 'grid',
    gridTemplateColumns: '52px 3fr 3fr 1.5fr 2.5fr',
    columnGap: theme.spacing(2),
  },
  titleTypography: {
    fontSize: '12px',
    color: theme.palette.grey.A200,
    textAlign: 'left',
  },
  firstTitle: {
    gridColumn: '1 / span 2',
  },
  absolute: {
    position: 'fixed',
    background: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '33%',
    height: '45%',
    margin: '40px',
    borderRadius: '5px',
    zIndex: 99999,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    boxShadow: '8px 8px 24px 0px rgba(0, 0, 0, 0.5)',
  },
  cover: {
    position: 'fixed',
    top: '0',
    left: '0',
    transform: 'translate(0, 0)',
    width: '100%',
    height: '100%',
    background: 'rgb(222, 224, 227, 0.4)',
    zIndex: 99998,
    cursor: 'pointer',
  },
  crossContainer: {
    display: 'flex',
    width: '90%',
    justifyContent: 'space-between',
    color: 'black',
  },
  warning: {
    fontSize: '1.5em',
    fontWeight: 'bold',
  },
  cross: {
    cursor: 'pointer',
  },
  status: {
    width: '90%',
    height: '28%',
    background: '#F5F5F5',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    padding: '16px',
  },
  unlockContainer: {
    width: '90%',
    height: '22%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
  },
  submitButton: {
    color: '#FAFAFA',
    background: '#D8527C',
    width: '100%',
    height: '50%',
    textTransform: 'lowercase',
  },
  unlockLabel: {
    color: '#D8527C',
    cursor: 'pointer',
  },
}));
