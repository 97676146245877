"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ButtonDefault = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var core_1 = require("@material-ui/core");
var ButtonDefault_styles_1 = require("./ButtonDefault.styles");
var SpinnerAdornment = function () { return jsx_runtime_1.jsx(core_1.CircularProgress, { style: { color: '#ffd1df ' }, size: 26 }, void 0); };
var ButtonDefault = function (_a) {
    var onClick = _a.onClick, children = _a.children, _b = _a.type, type = _b === void 0 ? 'submit' : _b, startIcon = _a.startIcon, _c = _a.loading, loading = _c === void 0 ? false : _c, _d = _a.disabled, disabled = _d === void 0 ? false : _d;
    var theme = core_1.useTheme();
    var classes = ButtonDefault_styles_1.useStyles(theme);
    return (jsx_runtime_1.jsxs(core_1.Button, __assign({ disabled: disabled, variant: "contained", color: "primary", disableElevation: true, size: "large", onClick: onClick, className: classes.button_wrapper, type: type, startIcon: startIcon }, { children: [!loading && children, loading && jsx_runtime_1.jsx(SpinnerAdornment, {}, void 0)] }), void 0));
};
exports.ButtonDefault = ButtonDefault;
