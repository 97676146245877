import React, { useEffect, useState } from 'react';

import { onError, onSuccess } from 'providers/providersHelpers';
import { UserContext } from 'context/user/UserContext';
import { User, UserUpdateObject } from 'context/user/UserContext.types';
import { useAuthContext } from 'hooks/useAuthContext/useAuthContext';
import { firebaseRef } from 'api/firebaseRef';

export const UserProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User | undefined>(undefined);
  const { currentUser } = useAuthContext();

  useEffect(() => {
    const fetchData = async () => {
      await fetchUserData();
    };
    if (!user) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, currentUser]);

  const fetchUserData = async () => {
    setLoading(true);
    setUser(undefined);
    if (!currentUser) return onError(setLoading, 'Unable to fetch user data.');

    const userSnapshot = await firebaseRef.firestore().collection('users').doc(currentUser.uid).get();

    const userData = userSnapshot.data() as User | undefined;

    if (userData !== undefined) {
      setUser(userData);
      return onSuccess(setLoading, 'User data fetched');
    } else {
      return onError(setLoading, 'Unable to fetch user data.');
    }
  };

  const setInitialData = async () => {
    setLoading(true);

    try {
      if (currentUser !== null) {
        const { uid, email } = currentUser;

        const userData = await firebaseRef.firestore().collection('users_customers').doc(uid).get();
        const splitName = userData.data()?.name.split(' ');

        const documentObject = {
          firstName: splitName[0],
          lastName: splitName[1],
          email,
        };

        await firebaseRef.firestore().collection('users').doc(uid).set(documentObject);

        return onSuccess(setLoading, 'Account successfully created');
      } else {
        return onError(setLoading, 'Current user can not be null');
      }
    } catch (ex) {
      return onError(setLoading, 'Unable to set user initial data.');
    }
  };

  const updateUserData = async (userUpdateObject: UserUpdateObject) => {
    setLoading(true);

    try {
      await firebaseRef.functions('europe-west1').httpsCallable('updateStripeCustomer')(userUpdateObject);

      if (currentUser !== null) {
        const newUser = { ...user, ...userUpdateObject };
        setUser(newUser);

        return onSuccess(setLoading, 'User data updated');
      } else {
        return onError(setLoading, 'Unable to update user data.');
      }
    } catch (error) {
      return onError(setLoading, `Unable to update user data. Error message: ${error}`);
    }
  };
  const updateUserProfileType = async (userUpdateObject: UserUpdateObject) => {
    setLoading(true);

    try {
      const userSnapshot = await firebaseRef.firestore().collection('users').doc(currentUser?.uid);
      await userSnapshot.update(userUpdateObject);

      if (currentUser !== null) {
        const newUser = { ...user, ...userUpdateObject };
        setUser(newUser);

        return onSuccess(setLoading, 'User data updated');
      } else {
        return onError(setLoading, 'Unable to update user data.');
      }
    } catch (error) {
      return onError(setLoading, `Unable to update user data. Error message: ${error}`);
    }
  };

  return (
    <UserContext.Provider
      value={{ loading, user, setInitialData, fetchUserData, updateUserData, updateUserProfileType }}
    >
      {children}
    </UserContext.Provider>
  );
};
