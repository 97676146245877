import React, { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { useCompanyContext } from 'hooks/useCompanyContext/useCompanyContext';
import { firebaseRef } from 'api/firebaseRef';
import { delay } from 'helpers/utils';
import { randomizeFileName } from 'helpers/randomizeFileName';

import { EditCompanyProps } from './EditCompanyPage.types';
import { EditCompanyPage } from './EditCompanyPage';

const validationSchema = Yup.object({
  companyName: Yup.string().required('nazwa jest wymagana'),
  companyAddressLine1: Yup.string().required('adres jest wymagany'),
  country: Yup.string().required('kraj jest wymagany'),
  city: Yup.string().required('miasto jest wymagane'),
  postcode: Yup.string()
    .required('kod pocztowy jest wymagany')
    .matches(/[0-9]{2}-[0-9]{3}/, 'format kodu XX-XXX'),
  state: Yup.string().required('województwo jest wymagane'),

  industry: Yup.string().required('wymagany sektor działalności'),
  facebook: Yup.string(),
  linkedin: Yup.string(),
  otherSocialMedia: Yup.array(),
  companySize: Yup.string().required('ilość osób w firmie'),
});

export const EditCompanyPageContainer: React.FC = () => {
  const { selectedCompany, updateCompany, loading } = useCompanyContext();
  const [formSuccess, setFormSuccess] = useState(false);
  const [formError, setFormError] = useState(false);

  const defaultValues = useMemo(() => {
    if (selectedCompany === undefined) return;

    return {
      companyName: selectedCompany.companyName,
      companyAddressLine1: selectedCompany.companyAddressLine1,
      country: selectedCompany.country,
      city: selectedCompany.city,
      postcode: selectedCompany.postcode,
      state: selectedCompany.state,
      nip: selectedCompany.nip,
      industry: selectedCompany.industry,
      facebook: selectedCompany.facebook,
      linkedin: selectedCompany.linkedin,
      otherSocialMedia: selectedCompany.otherSocialMedia ? selectedCompany.otherSocialMedia : [],
      companySize: selectedCompany.companySize,
      companyDescription: selectedCompany.companyDescription,
      predefinedMessage: selectedCompany.predefinedMessage,
      logo: selectedCompany.logo,
      file: undefined,
    };
  }, [selectedCompany]);

  const methods = useForm<EditCompanyProps>({
    reValidateMode: 'onChange',
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (selectedCompany !== undefined) {
      methods.reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany]);

  useEffect(() => {
    methods.register({ name: 'file', type: 'custom' });
  }, [methods]);

  const onSubmit = async (values: EditCompanyProps) => {
    if (selectedCompany === undefined) return;

    const { file, otherSocialMedia, ...restValues } = values;

    if (values.logo === selectedCompany.logo) {
      const newCompanyObjet: EditCompanyProps = {
        ...restValues,
        otherSocialMedia: otherSocialMedia === undefined ? [] : otherSocialMedia,
        docRef: selectedCompany.docRef,
      };

      const companyResponse = await updateCompany(newCompanyObjet);

      if (companyResponse.success) {
        setFormSuccess(true);
      } else {
        setFormError(true);
      }

      await delay(2500);
      setFormSuccess(false);
      setFormError(false);
    } else if (file !== undefined) {
      const randomizedFileName = randomizeFileName(file.name);

      const uploadTask = firebaseRef.storage().ref(`/images/${randomizedFileName}`).put(file);

      uploadTask.on('state_changed', console.log, console.error, () => {
        firebaseRef
          .storage()
          .ref('images')
          .child(randomizedFileName)
          .getDownloadURL()
          .then(async (url) => {
            const newCompanyObjet: EditCompanyProps = {
              ...restValues,
              logo: url,
              otherSocialMedia: otherSocialMedia === undefined ? [] : otherSocialMedia,
              docRef: selectedCompany.docRef,
            };

            const companyResponse = await updateCompany(newCompanyObjet);

            if (companyResponse.success) {
              setFormSuccess(true);
            } else {
              setFormError(true);
            }

            await delay(2500);
            setFormSuccess(false);
            setFormError(false);
          });
      });
    }
  };

  return (
    <EditCompanyPage
      formSuccess={formSuccess}
      formError={formError}
      methods={methods}
      onSubmit={onSubmit}
      loading={loading}
    />
  );
};
