import { useTheme } from '@material-ui/core';
import React from 'react';

import { countryCodes } from 'helpers/countryCodes';
import { TextInput } from 'ui/textInput/TextInput';
import { TextSelect } from 'ui/textSelect/TextSelect';

import { useStyles } from './General.styles';
import { AddComapnyFormGeneralProps } from './General.types';

export const General: React.FC<AddComapnyFormGeneralProps> = ({ register, control, errors }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <>
      <TextInput
        name={'companyName'}
        label={'nazwa firmy'}
        placeholder={'Wprowadź nazwę firmy'}
        valueName={'companyName'}
        ref={register}
        required
        helperText={errors.companyName?.message}
        error={!!errors.companyName}
        noBackground
      />
      <TextInput
        name={'companyAddressLine1'}
        label={'ulica'}
        placeholder={'Wprowadź adres siedziby'}
        valueName={'companyAddressLine1'}
        ref={register}
        required
        helperText={errors.companyAddressLine1?.message}
        error={!!errors.companyAddressLine1}
        noBackground
      />

      <div className={classes.cityPostcode}>
        <div className={classes.city}>
          <TextInput
            name={'city'}
            label={'miasto'}
            placeholder={'Wpisz nazwę miasta'}
            valueName={'city'}
            ref={register}
            required
            helperText={errors.city?.message}
            error={!!errors.city}
            noBackground
          />
        </div>
        <div className={classes.postcode}>
          <TextInput
            name={'postcode'}
            label={'kod pocztowy'}
            placeholder={'00-000'}
            valueName={'postcode'}
            ref={register}
            required
            helperText={errors.postcode?.message}
            error={!!errors.postcode}
            noBackground
          />
        </div>
      </div>
      <TextInput
        name={'state'}
        label={'województwo'}
        placeholder={'Wpisz województwo'}
        valueName={'state'}
        ref={register}
        required
        helperText={errors.state?.message}
        error={!!errors.state}
        noBackground
      />
      <TextSelect
        name={'country'}
        label={'kraj'}
        control={control}
        defaultValue={countryCodes[0].value}
        placeholder={'Wybierz kraj'}
        required
        helperText={errors.country?.message}
        error={!!errors.country}
        items={countryCodes}
        noBackground
      />
      <TextInput
        name={'nip'}
        label={'nip'}
        placeholder={'Wpisz nr NIP'}
        valueName={'nip'}
        ref={register}
        required
        noBackground
      />
      <TextSelect
        name={'companySize'}
        label={'wielkość firmy'}
        control={control}
        placeholder={'do 150 osób'}
        required
        noBackground
        helperText={errors.companySize?.message}
        error={!!errors.companySize}
        items={[
          { value: 'do 10 osób', label: 'do 10 osób' },
          { value: '10-50 osób', label: '10-50 osób' },
          { value: '50-200 osób', label: '50-200 osób' },
          { value: '200-1000 osób', label: '200-1000 osób' },
          { value: 'powyżej 1000 osób', label: 'powyżej 1000 osób' },
        ]}
      />
      <TextInput
        name={'industry'}
        label={'sektor działalności'}
        placeholder={'IT'}
        valueName={'industry'}
        ref={register}
        required
        helperText={errors.industry?.message}
        error={!!errors.industry}
        noBackground
      />
    </>
  );
};
