import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'start',
  },
  helperTextError: {
    color: '#f44336',
    fontSize: '.8em',
    margin: '0',
    background: '#fff9f9',
    padding: '5px',
  },
}));
