"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabeledCheckbox = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var LabeledCheckbox_styles_1 = require("./LabeledCheckbox.styles");
var core_1 = require("@material-ui/core");
var react_hook_form_1 = require("react-hook-form");
var LabeledCheckbox = function (_a) {
    var label = _a.label, name = _a.name, methods = _a.methods, _b = _a.checked, checked = _b === void 0 ? false : _b, onBlur = _a.onBlur;
    var theme = core_1.useTheme();
    var classes = LabeledCheckbox_styles_1.useStyles(theme);
    var setValue = methods.setValue;
    // TODO: solve that in an other way...
    /*
     * To initialize 'checked' with the proper, previously stored value...
     * */
    react_1.useEffect(function () {
        if (name)
            setValue(name, checked);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (jsx_runtime_1.jsx("div", __assign({ className: classes.confirmContainer }, { children: jsx_runtime_1.jsx(react_hook_form_1.Controller, { control: methods === null || methods === void 0 ? void 0 : methods.control, name: name || '', defaultValue: checked, render: function (_a) {
                var onChange = _a.onChange, _b = _a.value, value = _b === void 0 ? checked : _b, ref = _a.ref;
                return (jsx_runtime_1.jsxs(jsx_runtime_1.Fragment, { children: [jsx_runtime_1.jsx(core_1.Checkbox, { name: name, className: classes.confirmCheckbox, checked: value, onChange: function (e) { return __awaiter(void 0, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    onChange(e.target.checked);
                                    if (onBlur)
                                        onBlur();
                                    return [2 /*return*/];
                                });
                            }); }, ref: ref }, void 0), jsx_runtime_1.jsx(core_1.Typography, __assign({ onClick: function () {
                                onChange(!value);
                                if (onBlur) {
                                    onBlur();
                                }
                            }, variant: "subtitle1", className: classes.confirmLabel }, { children: label }), void 0)] }, void 0));
            } }, void 0) }), void 0));
};
exports.LabeledCheckbox = LabeledCheckbox;
