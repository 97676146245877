"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DividerWithText = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var core_1 = require("@material-ui/core");
var DividerWithText_styles_1 = require("./DividerWithText.styles");
var DividerWithText = function (_a) {
    var children = _a.children, title = _a.title, subtitle = _a.subtitle;
    var theme = core_1.useTheme();
    var classes = DividerWithText_styles_1.useStyles(theme);
    return (jsx_runtime_1.jsx("div", { children: jsx_runtime_1.jsxs("div", __assign({ className: classes.wrapper }, { children: [jsx_runtime_1.jsxs("div", __assign({ className: classes.container }, { children: [jsx_runtime_1.jsx("div", { className: classes.border }, void 0), jsx_runtime_1.jsx(core_1.Typography, __assign({ className: classes.content }, { children: children ? children : title }), void 0), jsx_runtime_1.jsx("div", { className: classes.border }, void 0)] }), void 0), subtitle && (jsx_runtime_1.jsxs(jsx_runtime_1.Fragment, { children: [jsx_runtime_1.jsx(core_1.Box, { mb: 1 }, void 0), jsx_runtime_1.jsx(core_1.Typography, __assign({ className: classes.subtitle }, { children: subtitle }), void 0)] }, void 0))] }), void 0) }, void 0));
};
exports.DividerWithText = DividerWithText;
