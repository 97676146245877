import { makeStyles } from '@material-ui/core';

import photoImage from 'assets/photo.svg';

export const useStyles = makeStyles((theme) => ({
  label: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    marginBottom: theme.spacing(1),
  },
  draggableContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 150,
    border: '2px dashed #d8d5dc',
    borderRadius: 5,
    background: `url(${photoImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '75px 75px',
  },
  dropZoneArea: {
    width: '100%',
    minHeight: 150,
    background: `url(${photoImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '75px 75px',
  },
  icon: {
    display: 'none',
  },
  image: {
    width: 'inherit',
    height: 'inherit',
    padding: '10px',
    objectFit: 'contain',
  },
}));
