import React from 'react';
import { Box, Divider, Typography, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';

import { DashboardContentContainer } from 'ui/dashboard/dashboardContentContainer/DashboardContentContainer';
import { DashboardContent } from 'ui/dashboard/dashboardContent/DashboardContent';
import { ButtonAdd } from 'ui/buttonAdd/ButtonAdd';
import { FiltersPanel } from 'ui/filtersPanel/FiltersPanel';
import { MainLayout } from 'ui/mainLayout/MainLayout';
import { DashboardApplicantsAccordions } from 'ui/dashboard/dashboardApplicantsAccordions/DashboardApplicantsAccordions';
import { COLUMN_HEADERS } from 'ui/dashboard/dashboardApplicantsPanel/DashboardApplicantsPanel.types';
import { MainPaths } from 'routing/main/MainPaths';
import { JobOfferActivationStatusBar } from 'app/jobOffer/jobOfferActivationStatusBar/JobOfferActivationStatusBar';

import { useStyles } from './ApplicantsListFromCareerPaths.styles';

export const ApplicantsListFromCareerPaths: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <MainLayout>
      <DashboardContentContainer>
        <JobOfferActivationStatusBar />
        <Box mb={3} />
        <DashboardContent hasBottomPadding={false}>
          <div className={classes.dashboardContent}>
            <Box mb={5} />
            <div className={classes.titleBar}>
              <div className={classes.titles}>
                <Typography variant="h2" className={classes.title}>
                  Lista kandydatów w trakcie nauki
                </Typography>
                <Box mb={1} />
                <Typography variant="body2" className={classes.subtitle}>
                  Znajdziesz tutaj kandydatów, którzy na chwilę obecną nie posiadają umiejetności wpisujących się w
                  opublikowane oferty pracy, <br />
                  ale chcą się w nich rozwijać lub już się ich uczą.
                </Typography>
              </div>
              <Link to={MainPaths.addJobOffer} className={classes.link}>
                <ButtonAdd startIcon={<AddIcon />}>dodaj nową ofertę</ButtonAdd>
              </Link>
            </div>
            <Box mt={5} />
            <Divider />
            <Box mt={5} />
            <FiltersPanel />
            <Box mt={5} />
          </div>
          <Box pb={2} className={classes.box} />
          <DashboardApplicantsAccordions titles={COLUMN_HEADERS} careerPath />
        </DashboardContent>
      </DashboardContentContainer>
    </MainLayout>
  );
};
