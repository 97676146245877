import { useContext } from 'react';

import { CandidateContext } from 'context/candidate/CandidateContext';
import { CandidateContextProps } from 'context/candidate/CandidateContext.types';

export const useCandidateContext: () => CandidateContextProps = () => {
  const context = useContext(CandidateContext);

  if (context === undefined) {
    throw Error('useCandidateContext must be used within an CandidateContext');
  }

  return context;
};
