import React, { useEffect, useState } from 'react';
import { Box, InputLabel, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';

import { TextInputWithClose } from 'ui/textInputWithClose/TextInputWithClose';
import { TextInput } from 'ui/textInput/TextInput';
import { ButtonAdd } from 'ui/buttonAdd/ButtonAdd';
import { FieldErrorWrap } from 'ui/fieldErrorWrap/FieldErrorWrap';
import { ShowErrorText } from 'ui/showErrorText/ShowErrorText';
import { TextSelect } from 'ui/textSelect/TextSelect';

import { TextInputExtendableListProps, TextInputVariant } from './TextInputExtendableList.types';
import { useStyles } from './TextInputExtendableList.styles';

export const TextInputExtendableListRHF: React.FC<TextInputExtendableListProps> = ({
  label,
  listItems = [],
  buttonCaption,
  addInputLabel,
  addInputPlaceholder,
  variant = TextInputVariant.White,
  selectItems,
  valueName = '',
  name = '',
  methods,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const listContainerStyle = clsx({
    [classes.listContainer]: true,
    [classes.listContainerGrey]: variant === TextInputVariant.Grey,
  });

  const labelStyle = clsx({
    [classes.label]: true,
    [classes.labelGrey]: variant === TextInputVariant.Grey,
  });

  const [items, setItems] = useState(listItems);
  const [inputValue, setInputValue] = useState('');
  const [inputValueError, setInputValueError] = useState(false);
  const [inputValueHelperText, setInputValueHelperText] = useState('');
  const [selectValue, setSelectValue] = useState('');

  const matches = useMediaQuery('(max-width:1600px)');
  const fieldError = methods?.errors?.[name];

  const handleAddButtonClick = () => {
    if (!inputValue) {
      setInputValueHelperText('adres nie może być pusty');
      return;
    }

    const newValue = selectValue ? `${selectValue} - ${inputValue}` : `${inputValue}`;

    const elementExists = items.find((el) => el.toLowerCase() === newValue.toLowerCase());

    if (elementExists === undefined) {
      setItems([...items, newValue]);
    }

    setSelectValue('');
    setInputValue('');
  };

  useEffect(() => {
    methods?.setValue(name, items);
  }, [items, methods, name]);

  useEffect(() => {
    methods?.register(name);
    methods?.setValue(name, items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <FieldErrorWrap showErrorBackground={!!fieldError}>
      <div className={listContainerStyle}>
        <InputLabel>
          <Typography variant="subtitle2" className={labelStyle}>
            {label}
          </Typography>
        </InputLabel>
        {items.map((item, key) => {
          return (
            <TextInputWithClose
              key={key}
              value={item}
              onClick={() => {
                setItems(items.filter((element) => element !== item));
              }}
              variant={variant}
            />
          );
        })}
      </div>

      <div>
        <Box mb={2.25} />
        <div className={classes.inputContainer} style={{ flexDirection: matches ? 'column' : 'row' }}>
          {selectItems && (
            <TextSelect
              name={'workingTime'}
              label="metodologia"
              control={methods?.control}
              items={selectItems}
              onChange={(event) => {
                setSelectValue(event.target.value as string);
              }}
            />
          )}
          <Box mr={5} />
          <TextInput
            label={addInputLabel}
            placeholder={addInputPlaceholder}
            error={inputValueError}
            helperText={inputValueHelperText}
            value={inputValue}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              setInputValueError(false);
              setInputValueHelperText('');
              setInputValue(event.target.value);
            }}
          />
        </div>
        <ButtonAdd onClick={handleAddButtonClick}>{buttonCaption}</ButtonAdd>
      </div>
      {fieldError && <ShowErrorText errorMessage="Dodaj minimum jedną pozycję" />}
    </FieldErrorWrap>
  );
};
