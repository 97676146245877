"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatsBarGraphs = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var StatsBarGraphs = function (_a) {
    var _b = _a.data, data = _b === void 0 ? [] : _b;
    var totalBarLength = data
        .map(function (item) { return item.value; })
        .reduce(function (accumulator, currentValue) {
        return accumulator + currentValue;
    }, 0);
    var totalRecordsLength = data.reduce(function (i, obj) { return i + obj.value; }, 0);
    var gapWidth = (3 * (data.length - 1)) / data.length;
    var RADIUS = 5;
    return (jsx_runtime_1.jsx(jsx_runtime_1.Fragment, { children: totalRecordsLength !== 0 ? (data.map(function (item, index) { return (jsx_runtime_1.jsx("div", { style: {
                backgroundColor: item.color,
                borderRadius: "calc(0.5 * " + RADIUS + "px)",
                height: RADIUS,
                width: "calc(100% * " + item.value / totalBarLength + " - " + gapWidth + "px)",
            } }, index)); })) : (jsx_runtime_1.jsx("div", { style: {
                backgroundColor: 'white',
                borderRadius: "calc(0.5 * " + RADIUS + "px)",
                height: RADIUS,
                width: '100%',
                border: '1.5px solid #e0e0e0',
            } }, void 0)) }, void 0));
};
exports.StatsBarGraphs = StatsBarGraphs;
