import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  dashboardContent: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  title: {
    textAlign: 'left',
  },
  subtitle: {
    textAlign: 'left',
    color: theme.palette.grey.A200,
  },
  loginButton: {
    width: '400px',
    margin: '0 auto',
  },
}));
