import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Box, Divider, LinearProgress, Typography, useTheme } from '@material-ui/core';
import PanoramaFishEyeIcon from '@material-ui/icons/PanoramaFishEye';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import { Link, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { typeConvert } from '@hrme/shared';

import { ButtonAdd } from 'ui/buttonAdd/ButtonAdd';
import { ButtonDefault } from 'ui/button/ButtonDefault';
import { Counter } from 'ui/counter/Counter';
import { MainPaths } from 'routing/main/MainPaths';
import { useJobOfferContext } from 'hooks/useJobOfferContext/useJobOfferContext';
import { JobOfferStatus } from 'context/jobOffer/JobOfferContext.types';
import { delay } from 'helpers/utils';

import { JobOffersItemProps } from './JobOffersItem.types';
import { useStyles } from './JobOffersItem.styles';

export const JobOffersItem: React.FC<JobOffersItemProps> = ({ offers, setOpenModal }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { currentSubscription, changeOfferStatus, fetchJobOffers } = useJobOfferContext();
  const [loadingState, setLoadingState] = useState(false);

  const history = useHistory();

  const toggleOfferStatus = async (status: JobOfferStatus, offerID: string, isPAYG: boolean) => {
    await changeOfferStatus(
      offerID,
      status === JobOfferStatus.ACTIVE ? JobOfferStatus.INACTIVE : JobOfferStatus.ACTIVE,
    );
    if (isPAYG) {
      await delay(5000);
      setLoadingState(false);
      await fetchJobOffers();
    }
  };

  const activeOffers = currentSubscription?.activeOffers;

  useEffect(() => {
    setLoadingState(false);
  }, [activeOffers]);

  if (!currentSubscription) return <div />;
  if (loadingState) return <LinearProgress />;
  return (
    <>
      {offers.map((item, key) => {
        const { status, pay_as_you_go } = item;
        const isDraft = status === 'draft';
        const isActive = status === 'active';
        const isInactive = status === 'inactive';
        const isPAYG = !!pay_as_you_go;

        // statistics
        const matchedCandidates = item?.matchedCandidates?.length;
        const sentOffers = item?.matchedCandidates?.filter((item) => item.status === 'sent').length;
        const acceptedOffers = item?.matchedCandidates?.filter((item) => item.status === 'accepted').length;

        // temporary solution, until database is not consistent
        const possibleContract = Array.isArray(item.contractType)
          ? item.contractType?.map((type) => typeConvert(type)).join(', ')
          : typeConvert(item.contractType);

        const offerBoxActive = clsx({
          [classes.offerBoxIsActive]: status === 'active',
          [classes.offerBoxIsDraft]: status === 'draft',
          [classes.offerBoxIsInactive]: status === 'inactive',
        });

        return (
          <React.Fragment key={key}>
            <div id={`${key}`} className={classes.offerBox}>
              <div className={classes.offerBoxButtonBar}>
                <div className={classes.offerBoxButtonBarLeft}>
                  <Typography className={classes.offerBoxPosition}>{item.position}</Typography>
                  <Typography variant="body2" className={offerBoxActive}>
                    <PanoramaFishEyeIcon />
                    <Box component="span" mr={1.25} />
                    {isDraft && <span>Wersja robocza</span>}
                    {isInactive && <span>Oferta nieaktywna</span>}
                    {isActive && !isPAYG && <span>Oferta pakietowa</span>}
                    {isActive && isPAYG && (
                      <span>{`Oferta ważna do: ${dayjs
                        .unix(item.validUntil / 1000)
                        .format('DD-MM-YYYY')} - Pay as you go`}</span>
                    )}
                  </Typography>
                </div>
                <div className={classes.offerBoxButtonBarRight}>
                  {isDraft && (
                    <div>
                      <ButtonAdd
                        startIcon={<AddIcon />}
                        onClick={() =>
                          history.push({
                            pathname: MainPaths.addJobOffer,
                            state: { edit: item },
                          })
                        }
                      >
                        edytuj
                      </ButtonAdd>
                    </div>
                  )}
                  <Box mr={2} />
                  {!isDraft && !isActive && (
                    <>
                      <ButtonAdd
                        startIcon={<AddIcon />}
                        onClick={async () => {
                          if (!isActive && currentSubscription.isPAYG) {
                            setOpenModal(true);
                            history.push({
                              pathname: MainPaths.invoice,
                              state: { offerID: item.jobOfferId },
                            });
                          } else {
                            setLoadingState(true);
                            await toggleOfferStatus(item.status, item.jobOfferId, isPAYG);
                          }
                        }}
                      >
                        {isActive ? 'deaktywuj' : 'aktywuj'}
                      </ButtonAdd>
                      <Box mr={2} />
                    </>
                  )}
                  <div>
                    <ButtonDefault startIcon={<SearchIcon />}>
                      <Link
                        to={{
                          pathname: MainPaths.jobOfferDetail,
                          state: item,
                        }}
                        className={classes.newOfferButtonText}
                      >
                        zobacz
                      </Link>
                    </ButtonDefault>
                  </div>
                </div>
              </div>
              <Box mb={4} />
              <Divider />
              <Box mb={4} />
              <div className={classes.offerBoxCounters}>
                <Counter
                  value={matchedCandidates}
                  title="Liczba kandydatów"
                  subtitle="Liczba osób dopasowanych do oferty"
                />
                <Counter value={sentOffers} title="Wysłanych ofert" subtitle="Liczba osób dopasowanych do oferty" />
                <Counter
                  value={acceptedOffers}
                  title="Zaakceptowanych ofert"
                  subtitle="Liczba osób dopasowanych do oferty"
                />
              </div>
              <Box mb={4} />
              <Divider />
              <Box mb={4} />
              <div className={classes.offerBoxDetails}>
                <Typography variant="subtitle1">Firma:</Typography>
                <Box mr={1} />
                <Typography variant="subtitle1" className={classes.strong}>
                  {item.company}
                </Typography>
                <Box mr={3} />
                <Divider orientation="vertical" flexItem />
                <Box mr={3} />
                <Typography variant="subtitle1">Zarobki:</Typography>
                <Box mr={1} />
                <Typography variant="subtitle1" className={classes.strong}>
                  {`${item.salaryRangeFrom}`}{' '}
                  {`${item.salaryRangeTo > 0 ? `- ${item.salaryRangeTo}` : ''} ${item.currency} ${
                    item?.isNetto ?? item?.salaryOnHand ? 'netto' : 'brutto'
                  }`}
                </Typography>
                <Box mr={3} />
                <Divider orientation="vertical" flexItem />
                <Box mr={3} />
                <Typography variant="subtitle1">Rodzaj umowy:</Typography>
                <Box mr={1} />
                <Typography variant="subtitle1" className={classes.strong}>
                  {possibleContract}
                </Typography>
                <Box mr={3} />
                <Divider orientation="vertical" flexItem />
                <Box mr={3} />
                <Typography variant="subtitle1">Lokalizacja:</Typography>
                <Box mr={1} />
                <Typography variant="subtitle1" className={classes.strong}>
                  {item.companyCity}
                </Typography>
                <Box mr={3} />
                <Divider orientation="vertical" flexItem />
                <Box mr={3} />
                <Typography variant="subtitle1">Główna technologia:</Typography>
                <Box mr={1} />
                <Typography variant="subtitle1" className={classes.strong}>
                  {item?.mainTechnologies?.map((tech, index) =>
                    index === item.mainTechnologies.length - 1 ? `${tech}` : `${tech},`,
                  )}
                </Typography>
              </div>
            </div>
            <Box className={classes.box} />
          </React.Fragment>
        );
      })}
    </>
  );
};
